import styled, { css } from "styled-components";

export const FieldWrap = styled.div`
position: relative;
display: flex;
flex-direction: column;

flex-basis: 100%;


${({grow}) => grow && css`
flex: ${grow};
`}

label {
  font-weight: 700;
  font-size: 13px;
  line-height: 110%;
  color: #363636;
  padding-bottom: 5px;
}

.field_password {
  position: relative;
  & > input {
    width: 100%;
    padding-right: 36px;
  }
  .password__show {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    border: none;
    cursor: pointer;
    background: transparent;
  }
}

input {
  height: 36px;
  background: #f5f5f5;
  border-radius: 4px;
  border: none;
  outline: none;
  padding: 10px;
  font-size: 14px;
  line-height: 110%;
  color: #363636;
}

.field__error-mes {
  position: absolute;
  bottom: -1px;
  transform: translateY(100%);
  font-size: 10px;
  color: #ff7575;
}

label[required]::after {
  content: "*";
}

.field__help-elem {
  position: absolute;
  right: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 110%;
  color: #34c57f;
  cursor: pointer;
}
  textarea {
    max-width: 100%;
    resize: none;
    border: none;
    outline: none;
    background: #F5F5F5;
    border-radius: 4px;
    padding: 9px 12px;
  }
`;


export const FormBtnsWrap = styled.div`
  display: flex;
    button {
      flex: 1;
      height: 36px;
      border-radius: 8px;
      font-weight: 500;
      font-size: 13px;
      line-height: 140%;
      border: none;
      cursor: pointer;
      min-height: 36px;
    }
    .btns__cancel {
      color: #34c57f;
      border: 1px solid #34c57f;
      background: transparent;
    }
    .btns__submit {
      background: #34c57f;
      color: #fff;
      &[disabled] {
        opacity: 0.3;
      }
    }
  }

`;