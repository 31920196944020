
export const registration_by = {
    'login': 'Увайсці',
    'registration': 'Рэгістрацыя',
    'registerButton': 'Зарэгістравацца',
    'enterEmail': 'Увядзіце Email для генерацыі ніка',
    'registerSuccess': 'Рэгістрацыя прайшла паспяхова, на ўказаную пошту выслана ліст.',

    'data': {
        'name': 'Your name',
        'surname': 'Ваша прозвішча',
        'patronymic': 'Ваша імя па бацьку',
        'nickname': 'Ваш нік',
        'email': 'Электронная пошта',
        'password': 'Пароль',
        'passwordEqual': 'Паўтарыце пароль',
    },
}