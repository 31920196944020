import styled from "styled-components";

const WrapProfileModals = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .background-modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00000080;
    z-index: 1;
  }

  .profile-modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 372px;
    background: #ffffff;
    border-radius: 8px;
    padding: 16px 16px 30px;
    z-index: 2;

    .close-btn {
        position: absolute;
        right: 16px;
        top: 16px;
        width: 26px;
        height: 26px;        
        background: #F5F5F5;
        border-radius: 8.66667px;
        border: none;
        cursor: pointer;
        & > svg {
            display: block;
            margin: auto;
        }
    }

    & > h2, & > p {
        text-align: center;
    }

    .modal__head {
        font-weight: 700;
        font-size: 15px;
        line-height: 17px;        
        color: #363636;
        padding-top: 8px;
    }
    .modal__subhead {
        font-size: 13px;
        line-height: 16px;  
        color: #484848;
        padding: 14px 0px
    }

    .modal__submit-btn {
      height: 36px;
      background: #34C57F;
      border-radius: 8px;
      font-weight: 500;
      font-size: 13px;
      line-height: 140%;
      color: #FFFFFF;
    }
  }
`;

const WrapChangePassword = styled.div`
  .form-fields {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .form-btns {
    flex-direction: column;
    gap: 10px;
    padding-top: 30px;
  }
`;

const WrapEmailForm = styled.div`
  .form-btns {
    flex-direction: column;
    gap: 10px;
    padding-top: 30px;
  }
`;

const WrapResetLinkEmail = styled.div`

`;

export { WrapProfileModals, WrapChangePassword, WrapEmailForm, WrapResetLinkEmail };
