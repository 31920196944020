import { initialStateProfile } from "./profileSlice";

const reducers = {
    setProfileState(state, action) {
        return {...state, ...action.payload}
    },

    setProfileTableState(state, {payload}) {
        state.table = {...state.table, ...payload};
    },

    setAppointmentsState(state, action) {
        state.appointments = {...state.appointments, ...action.payload}
    },

    setParticipantsState(state, action) {
        state.appointments.listParticipants = {...state.appointments.listParticipants, ...action.payload}
    },

    setContactsState(state, action) {
        state.appointments.listContacts = {...state.appointments.listContacts, ...action.payload}
    },

    resetProfileState(state, {payload}) {
        switch (payload) {
            case "userData":
                state.userData = initialStateProfile.userData;
                break;
            case "table":
                state.table = initialStateProfile.table;
                break;
        
            default:
                return initialStateProfile;
        }
    }
};

export default reducers;