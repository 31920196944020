import React from "react";
import { WrapStatistics } from "./statistics.style";
import { svg_icons } from "../../assets/imgs/svg_icons";
import AppSelect from "./../../components/AppSelect/AppSelect";
import AppDatePicker from "./../../components/AppDatePicker/AppDatePicker";
import AppModals from "./../../components/AppModals/AppModals";

const Statistics = ({
  handlerActions,
  statisticsList,
  settingsSelect,
  localText,
  datePickerRef,
}) => {
  const { arrowBack } = svg_icons;

  return (
    <WrapStatistics>
      <AppModals>
        <AppDatePicker type="range" datePickerRef={datePickerRef}/>
      </AppModals>

      <div className="statistics-head">
        <div className="head__back">
          <button onClick={handlerActions("goBack")}>{arrowBack}</button>
          <h2>{localText.head}</h2>
        </div>
        <div className="head-desc">
          <h3>{localText.head2}</h3>
          <p>{localText.subhead}</p>
        </div>
        <div className="head-filter">
          <AppSelect
            settings={settingsSelect}
            onChange={handlerActions("changePeriod")}
          />
          <button
            className="filter__download"
            onClick={handlerActions("downloadReport")}
          >
            {localText.downloadReport}
          </button>
        </div>
      </div>
      <div className="statistics-list">
        {statisticsList.list?.map((stat, i) => (
          <div key={i} className="wrap-statistics">
            <h3 className="statistics__head">{stat.head}</h3>
            <p className="statistics__date">{statisticsList.date}</p>
            <div className="statistics-desc">
              <h4 className="desc__head">{stat.total}</h4>
              <p className="desc__text">{stat.new}</p>
            </div>
            <button
              className="statistics__go"
              onClick={handlerActions("toPage", stat.to)}
            >
              {stat.toText}
            </button>
          </div>
        ))}
      </div>
    </WrapStatistics>
  );
};

export default Statistics;
