import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHttp } from "../../hooks/useHttp";
import { useNavigate } from "react-router-dom";
import { setAppChatState } from "../AppChat/appChatSlice/appChatSlice";
import { getStandsByPavId } from "../../store/slices/unitySlice/unityExtraReducers";
import NavMainBtns from "./NavMainBtns";
import { useSendUnity } from "./../../hooks/useSendUnity";
import { resetUnityState } from "../../store/slices/unitySlice/unitySlice";
import { setOpenDialogState } from "../../store/slices/appChatSlice/appChatSlice";
import { useOpenDialogNotFromChat } from "../../hooks/useOpenDialogNotFromChat";
import { useKeyboardUnity } from './../../hooks/useKeyboardUnity';

function NavMainBtnsContainer() {
  let { initRequest } = useHttp();
  const navigate = useNavigate();

  const [
    { chooseStand, selectedStand, openSettings, selectedSet },
    setLocalState,
  ] = useState({
    chooseStand: false,
    selectedStand: null,
    openSettings: false,
    selectedSet: null,
  });
  let userData = useSelector((state) => state.profile.userData);
  let withoutRegistration = useSelector(
    (state) => state.auth.withoutRegistration
  );
  let { language } = useSelector((state) => state.app.appText);

  let mobileVersion = useSelector((state) => state.appChat.mobileVersion);
  const {
    isScene,
    pavilions: {
      selectedPavilion: { stands, id, shortName },
    },
    networkUser,
    copmanyStandFull: { openInfoStand },
  } = useSelector((state) => state.unity);
  const dispatch = useDispatch();
  const sendUnity = useSendUnity();
  const onKeyboardUnity = useKeyboardUnity();
  const openDialogNotFromChat = useOpenDialogNotFromChat();

  const countMes = 0;

  const handlerActions = (key, data) => async (e) => {
    // sendUnity("LoadExhibition", parseInt(localStorage.getItem("pavilionId")));
    sendUnity("AllowCaptureAllKeyboardInput", "false");

    const closeNetworkUser = () => {
      dispatch(resetUnityState("networkUser"));
      onKeyboardUnity();
    };

    switch (key) {
      case "openProfile":
        navigate("/profile?tab=main");
        break;
      case "closeNavWindow":
        if(mobileVersion) {
          dispatch(setAppChatState({ key: "mobileVersion", value: false }));
          onKeyboardUnity();
        }
        if(openInfoStand) {
          dispatch(resetUnityState("copmanyStandFull"));
          onKeyboardUnity()
        }
        openSettings && handlerActions("openSettings")();
        networkUser && handlerActions("closeNetworkUser")();
        chooseStand && handlerActions("closeChooseStand")();
        break;
      case "openChat":
        dispatch(setAppChatState({ key: "mobileVersion", value: true }));
        break;
      case "choosePavilion":
        navigate("/pavilions", { state: { goBack: true } });
        break;
      case "searchStands":
        (await dispatch(
          getStandsByPavId({
            pavilionId: id || localStorage.getItem("pavilionId"),
            search: e.target.value,
          })
        ));
        break;
      case "chooseStand":
        console.log("stands", stands);
        !stands &&
          (await dispatch(
            getStandsByPavId({
              pavilionId: id || localStorage.getItem("pavilionId"),
            })
          ));
        sendUnity("ShowMap");
        setLocalState((state) => ({ ...state, chooseStand: true }));
        break;
      case "closeChooseStand":
        setLocalState((state) => ({
          ...state,
          chooseStand: false,
          selectedStand: null,
        }));
        sendUnity("HideMap");
        break;
      case "openSettings":
        openSettings ? sendUnity("ResumeMenu") : sendUnity("PauseMenu");
        openSettings && onKeyboardUnity();
        setLocalState((state) => ({
          ...state,
          openSettings: !state.openSettings,
          selectedSet: null,
        }));
        break;
      case "selectStand":
        console.log(data)
        setLocalState((state) => ({ ...state, selectedStand: data }));
        sendUnity("ShowStandByCode", data.url);
        break;
      case "selectSet":
        setLocalState((state) => ({ ...state, selectedSet: data }));
        break;
      case "goToStand":
        sendUnity("HideMap");
        sendUnity("TeleportToStand", selectedStand.url);
        setLocalState((state) => ({
          ...state,
          chooseStand: false,
          selectedStand: null,
        }));
        onKeyboardUnity();
        break;
      case "login":
      case "registration":
        navigate(`/${key}`);
        break;
      case "setGraph":
        sendUnity("SetQuality", selectedSet.value);
        sendUnity("ResumeMenu");
        onKeyboardUnity();
        setLocalState((state) => ({
          ...state,
          openSettings: false,
          selectedSet: null,
        }));

        break;
      case "mesNetworkUser":
        openDialogNotFromChat({mobile: true, dialogUser: data})
        closeNetworkUser();
        break;
      case "toogleNetworkUser":
        dispatch(
          setAppChatState({
            key: "modal",
            value: {
              type: data.contact
                ? "removeContactFromUnity"
                : "addToContactFromUnity",
              content: data,
            },
          })
        );
      case "closeNetworkUser":
        closeNetworkUser();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    onKeyboardUnity();
  }, []);

  // useEffect(() => {
  //   if(!id) navigate('/login');
  // }, [id]);

  // console.log(stands, shortName)
  // console.log("payload 2", shortName)

  return (
    <NavMainBtns
      countMes={countMes}
      mobileVersion={mobileVersion}
      userData={userData}
      handlerActions={handlerActions}
      openInfoStand={openInfoStand}
      language={language}
      chooseStand={chooseStand}
      shortName={shortName}
      stands={stands}
      selectedStand={selectedStand}
      isScene={isScene}
      withoutRegistration={withoutRegistration}
      networkUser={networkUser}
      openSettings={openSettings}
      selectedSet={selectedSet}
    />
  );
}

export default NavMainBtnsContainer;
