import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAppChatState } from "../../appChatSlice/appChatSlice";
import { checkDateForToday, dateToLocaleString, dateToLocaleTimeString } from "../../utils/helpers";
import Messages from "./Messages";
import { scrollTo } from './../../utils/helpers';

const MessagesContainer = () => {

    const dispatch = useDispatch();
    const openDialog = useSelector(state => state.appChat.openDialog);
    let userData = useSelector(state => state.profile.userData);
    let messages = openDialog.messages;

    const handlerActions = (key) => (e) => {
        switch (key) {
            case "modalBlockUser":
                dispatch(setAppChatState({key: "modal", value: {type: "blockUser"}}))
                break;
            case "addToContact":
                dispatch(setAppChatState({key: "modal", value: {type: "addToContact"}}))
                break;
            case "scrollMessView":
                if(e.target.scrollTop < 5 && messages) {
                    e.target.scrollTop = 6;
                }

                break;
            default:
                break;
        }

    }

    // if(!messages) return null;
    useEffect(() => {
        if(messages) {
            scrollTo("scrollMessEnd", 0.1);
        }
    }, [messages])


    if(!messages) return null;


    messages = messages?.map(mes => ({
        ...mes, 
        align: +userData?.id === +mes.userId,
        displayTime: checkDateForToday(mes.time) ? 
          dateToLocaleTimeString(mes.time, "sec") || "-" : dateToLocaleString(mes.time, null, "sec"),
      }))
      
    return <Messages handlerActions={handlerActions} openDialog={openDialog} messages={messages}/>
}

export default MessagesContainer