
export const registration_ru = {
    'login': 'Вход',
    'registration': 'Регистрация',
    'registerButton': 'Зарегистрироваться',
    'enterEmail': 'Введите Email для генерации ника',
    'registerSuccess': 'Регистрация прошла успешно, на указанную почту выслано письмо.',

    'data': {
        'name': 'Ваше имя',
        'surname': 'Ваша фамилия',
        'patronymic': 'Ваше отчество',
        'nickname': 'Ваш никнейм',
        'email': 'Электронная почта',
        'password': 'Пароль',
        'passwordEqual': 'Повторите пароль',
    },
}