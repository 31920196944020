
export const statisticsPage_by = {

    head: "Статыстыка",
    head2: "Агульныя дадзеныя",
    subhead: "Тут вы можаце азнаёміцца з агульнай статыстыкай стэндаў і старонкі вашай арганізацыі",
    timePeriod: "Часавы перыяд",
    today: "Сёння",
    week: "Тыдзень",
    month: "Месяц",
    year: "Год",
    setPeriod: "Задаць перыяд",
    downloadReport: "Спампаваць справаздачу",
    visitors3D: "Наведвальнікі 3D", 
    visitors: "наведвальнікаў",
    visitorsSite: "Наведвальнікі сайта",
    reqMeet: "Заяўкі на сустрэчу",
    reqs: "заяўвак",
    documents: "Дакументы", 
    downedDocs: "запампаваных дакумента", 
    listUsers: "Спіс карыстальнікаў",
    listReqs: "Спіс заяўвак",
    listDocs: "Спіс дакументаў",


    'header': {
        'statistics': 'Статыстыка',
        'pageTitle': 'Лабараторыя статыстыкі cleVR',
        'pageTitle2': 'Кампанія:',
        'registered': 'Зарэгістраваны: ',
        'lastUpdate': 'Апошняе абнаўленне: ',
    },
    'standsList': {
        'header': 'Спіс вашых стэндаў',
        'noStands': 'У Вас няма стэндаў',
        'allStands': 'Статыстыка па ўсіх стэндах',
        'infoCompany': 'Старонка з інфармацыяй аб кампаніі',
        'currentStand': 'Статыстыка цяперашняга стэнда кампаніі',
        'error1': 'Невядомы карыстальнік',
        'error2': 'Карыстальнік не належыць ні да якой кампаніі',
        'error3': 'У кампаніі карыстальніка няма стэндаў',
    },
    'filter': {
        'selectIndicator': 'Выбраць паказчык: ',
        'uniqueVisitors': 'Колькасць унікальных наведвальнікаў',
        'numberVisits': 'Колькасць наведванняў',
        'numberFileDownloaded': 'Колькасць загрузак дакументацыі',
        'numberVisitsDev': 'Колькасць наведванняў (у распрацоўцы)',
        'numberClicks': 'Колькасць клікаў',
        'numberClicksDev': 'Колькасць клікаў (у распрацоўцы)',
        'visitors': 'Колькасць наведвальнікаў',
        'transitions': 'Колькасць пераходаў з знешніх спасылак (у распрацоўцы)',
        'selectPeriod': 'Выбраць цікавіць перыяд: ',
        'from': 'Ат: ',
        'to': 'Да: '
    },
    'currentStand': {
        'totalClick': "Колькасць клікаў:",
        'Assistant': "Асістэнт",
        'Tumba': "Стойка",
        'Banner': "Банэр",
    },
    'visitChart': {
        'header': 'Агульная статыстыка ўсіх вашых стэндаў',
        'labelVisits': 'Колькасць наведванняў',
        'labelClicks': 'Колькасць клікаў',
    },
    'downloadVisitors': {
        'paragraph': 'Глядзіце дадзеныя аб наведвальніках',
    },
    'development': 'У распрацоўцы',
}