import React, { useEffect, useRef } from "react";
import Unity, { UnityContext } from "react-unity-webgl";
import { generateUrlsBuild } from "../../../utils/helpers";

function UnityIframe() {
  //service

  const getBuildUrlByKey = (key) => {
    const getParamFromUrl = (param) => {
      let params = new URLSearchParams(document.location.search);
      return params.get(param);
    };

    let result,
      buildId = key || getParamFromUrl("buildId");
    if (buildId) {
      result = generateUrlsBuild(buildId);
      result = {
        dataUrl: result.data,
        frameworkUrl: result.framework,
        codeUrl: result.wasm,
        loaderUrl: result.loader,
      };
    } else {
      result = {
        dataUrl: `${window.location.origin}/Build/1/prodBuild.data.unityweb`,
        frameworkUrl: `${window.location.origin}/Build/1/prodBuild.framework.js.unityweb`,
        codeUrl: `${window.location.origin}/Build/1/prodBuild.wasm.unityweb`,
        loaderUrl: `${window.location.origin}/Build/1/prodBuild.loader.js`,
      };
    }
    return result;
  };

  // hepler

  const reloadApp = async (unityProviderApp) => {
    try {
      await unityProviderApp.unityInstance?.Quit();
      unityProviderApp.unityInstance = null;
      const canvas = document.getElementById("unity-canvas-2");
      if (canvas) {
        const gl = canvas.getContext("webgl2");
        gl.clear(gl.DEPTH_BUFFER_BIT);
        gl.clear(gl.DEPTH_BUFFER_BIT | gl.COLOR_BUFFER_BIT);
        const query = gl.getQuery(gl.ANY_SAMPLES_PASSED, gl.CURRENT_QUERY);
        gl.deleteQuery(query);
        gl.clearBufferfv(gl.COLOR, 0, [0.0, 0.0, 0.0, 0.0]);
        gl.clearBufferfi(gl.DEPTH_STENCIL, 0, 1.0, 0);
      }
      try {
        window.top.isReloadIframeUnity = true;
        // window.top.hiddenFunctions?.resetProvider();
      } catch (error) {}
      window.location.reload(true);
    } catch (error) {
      console.log("ERROR_REALOD_UNITY");
      console.log(error);
    }
  };

  //code app unity

  const getConfigUnity = () => {
    try {
      if (!window.top.unityProviderForIFrame) {
        throw "";
      }
      return window.top.unityProviderForIFrame;
    } catch (error) {
      return new UnityContext(getBuildUrlByKey(1));
    }
  };

  const { current: unityProviderApp } = useRef(getConfigUnity());
  let { current: isReloadIframeUnity } = useRef(() => {
    try {
      return window.top.isReloadIframeUnity;
    } catch (error) {
      return false;
    }
  });

  let onmessageRef = useRef();

  useEffect(() => {
    if (!onmessageRef.current) {
      window.addEventListener("message", async function (event) {
        let message;
        try {
          message = JSON.parse(event?.data);
        } catch (error) {
          message = event?.data;
        }
        // if (event.origin !== 'http://localhost:3000') {
        //   return;
        // }
        switch (message?.type) {
          case "reload":
            reloadApp(unityProviderApp);
            break;
          default:
            break;
        }
        console.log(unityProviderApp);
      });
      onmessageRef.current = true;
    }
  }, []);

  // code unity

  const openStandManagement = (companyUrl) => {
    unityProviderApp.send("=== Player UI ===", "Set3dViewerActive", "true");
    setTimeout(() => {
      unityProviderApp.send("3dViewer/Stands", "SetStandName", companyUrl);
    }, 300);
  };

  window.standLoaded = function (progress) {
    try {
      window.top.standLoaded(progress);
    } catch (error) {}
  };

  window.showProgress = function (progress) {
    try {
      window.top.showProgress(progress);
    } catch (error) {}
    console.log("window.showProgress = ", progress);
  };

  window.showOtherUserCard = function (otherUserId) {
    try {
      window.top.showOtherUserCard(otherUserId);
    } catch (error) {}
    console.log("window.otherUserId = ", otherUserId);
  };

  window.openStandInfo = function (name, id, itemType) {
    try {
      console.log(name, id, itemType);
      window.top.openStandInfo(name, id, itemType);
    } catch (error) {}
  };

  window.standConfigLoaded = function () {
    try {
      window.top.standConfigLoaded();
    } catch (error) {}
  };

  window.sceneOpened = function () {
    try {
      window.top.sceneOpened();
    } catch (error) {}
  };

  window.getCompanyName = (e) => {
    try {
      window.top.getCompanyName(e);
    } catch (error) {}
}

  window.webSocketSendMessage = function (mes) {
    try {
      window.top.webSocketSendMessage(mes);
    } catch (error) {}
  };

  const quittingUnity = async () => {
    reloadApp(unityProviderApp);
  };

  window.hiddenFunctions = {
    quittingUnity,
    openStandManagement,
  };

  useEffect(() => {
    if(unityProviderApp) {
        unityProviderApp.on("loaded", async function (e) {
          console.log(e)
          // createUnityContext().send('=== Scripts ===', 'SetLanguage', language);
        });
        unityProviderApp.on("progress", function (progression) {
          try {
            window.top.unityProgress(progression);
            console.log(progression)
          } catch (error) { 
          }

        });
      }
  }, [unityProviderApp]);

  console.log(unityProviderApp);

  return (
    <div
      className="wrap-unity"
      style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
    >
      {!isReloadIframeUnity.current && (
        <Unity
          unityContext={unityProviderApp}
          style={{ height: "100vh", width: "100%" }}
          className="unity-desktop"
        />
      )}
    </div>
  );
}

export default UnityIframe;
