import styled from "styled-components";

const WrapPavilions = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  color: #363636;
  padding: 28px 16px;
  background: #FFFFFF;
  overflow: auto;

  .pavilions__back-btn {
    position: absolute;
    top: 16px;
    left: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border: none;
    & > div {
      width: 26px;
      height: 26px;
      background: #F6F6F6;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & > span {
      padding-left: 10px;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #363636;
    }
  }

  .clevr__logo {
    margin-top: auto;
    min-height: 49px;
  }

  & > h2 {
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    padding-top: 12px;
  }
  & > p {
    font-size: 13px;
    line-height: 16px;
    padding: 7px 0 24px;
  }

  .pavilions-list {
    display: flex;
    max-width: 100%;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: auto;

    .wrap-pavilion {
      display: flex;
      flex-direction: column;
      width: 269px;
      min-width: 269px;
      background: #f5f5f5;
      border-radius: 4px;
      overflow: hidden;
      .wrap-pav-logo {
        width: 100%;
        height: 162px;
        padding: 24px;
        .pavilion__logo {
          width: 100%;
          height: 100%;
          &:hover {
            cursor: pointer;
          }
          &:hover {
            filter: invert(38%) sepia(86%) saturate(333%) hue-rotate(96deg) brightness(97%) contrast(83%);
            &.Tibo {
              filter: invert(56%) sepia(28%) saturate(4777%) hue-rotate(300deg) brightness(96%) contrast(95%);
            }
          }
        }
      }
      .pavilion-full-info {
        display: flex;
        flex-direction: column;
        padding: 16px;
        flex: 1;
        .pavilion__head {
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          padding-bottom: 16px;
        }

        .pavilion__stands {
          display: flex;
          justify-content: space-between;
          border-width: 0.5px 0px;
          border-style: solid;
          border-color: #d4d4d4;
          padding: 12px 0;
          span:first-child {
            font-weight: 600;
            font-size: 13px;
            line-height: 110%;
          }
          span:last-child {
            font-weight: 400;
            font-size: 12px;
            line-height: 110%;
          }
        }

        .pavilion-desc {
          padding-top: 11px;
          padding-bottom: 16px;
          position: relative;
          overflow: hidden;
          flex: 1;
          min-height: 135px;
          .desc__head {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
          }
          .desc__text {
            position: absolute;
            overflow: auto;
            top: 30px;
            left: 0;
            right: 0;
            bottom: 16px;
            font-size: 13px;
          }
        }
        .pavilion__go {
          border: 1px solid #34c57f;
          border-radius: 8px;
          height: 36px;
          color: #34c57f;
          font-weight: 500;
          font-size: 13px;
          line-height: 140%;
          cursor: pointer;
          background-color: transparent;
        }
      }
    }
  }
`;

export { WrapPavilions };
