import React, {useState} from 'react';
import VisitChart from "./VisitChart/VisitChart";
import StandsList from "./StandsList/StandsList";
import s from './StatisticsPage.module.css'
import DownloadVisitors from "./DownloadVisitors/DownloadVisitors";
import Header from "./Header/Header";
import Filters from "./Filters/Filters";
import BackButton from "../BackButton/BackButton";
import { connect, useSelector } from 'react-redux';
import CurrentStand from './CurrentStand/CurrentStand';

const StatisticsPage = () => {


        // new code
        const {statistics: localText, language} = useSelector(state => state.app.appText);
        // old code
    
    const [localState, setLocalState] = useState(null);
    const [selectedStand, setSelectedStand] = useState('page');
    const [standId, setStandId] = useState('page');
    const [isDownloadFile, setIsDownloadFile] = useState(false);
    const [visitsData, setVisitsData] = useState([]);
    const [date, setDate] = useState({modifiedTime: '', registrationDate: ''})
    const [error, setError] = useState(false);

    const [indicatorsList, setIndicatorsList] = useState([
        {id: 'numberVisits', text: localText.filter.numberVisits},
        {id: 'uniqueVisitors', text: localText.filter.uniqueVisitors},
        {id: 'numberFileDownloaded', text: localText.filter.numberFileDownloaded},
    ]);

    const [selectedIndicator, setSelectedIndicator] = useState(indicatorsList[0]);


    return (
        <div className={s.mainPage}>
            <BackButton path={'/profile'}/>
            <Header selectedStand={selectedStand} setSelectedStand={setSelectedStand}
                    localText={localText.header} standId={standId} date={date}/>
            <div className={s.decorLine}/>
            {(error.type && error.type !== "no stands") ? <div className={s.errorContainer}>
                <h1 className={s.errorContainer_header}>{error.value}</h1>
            </div> 
            : <>
            <StandsList className={s.container} localText={localText.standsList} setError={setError}
            selectedStand={selectedStand} setSelectedStand={setSelectedStand}
            setStandId={setStandId} visitsData={visitsData} setIsDownloadFile={setIsDownloadFile}
            />
            <div className={s.decorLine}/>
            {(error.type === "no stands" && standId === 'currentStand') ?  
                <div className={s.errorContainer}>
                    <h1 className={s.errorContainer_header}>{error.value}</h1>
                </div> 
            :
                <div className={s.wrapStatistics}>
                    <Filters standId={standId} setLocalState={setLocalState} localText={localText.filter}
                             indicatorsList={indicatorsList} setIndicatorsList={setIndicatorsList}
                             selectedIndicator={selectedIndicator} setSelectedIndicator={setSelectedIndicator}/>
                    <div className={s.decorLine}/>
                    {standId === 'currentStand' && <CurrentStand localState={localState} setDate={setDate} localText={localText.currentStand}/>}
                    {standId !== 'currentStand' && <VisitChart localState={localState} standId={standId}
                                                               setDate={setDate} setIsDownloadFile={setIsDownloadFile}
                                                               setVisitsData={setVisitsData} selectedIndicator={selectedIndicator}
                                                               selectedStand={selectedStand}/>}
                    {standId !== 'currentStand' && <DownloadVisitors localState={localState} localText={localText.downloadVisitors} standId={standId}
                                    selectedStand={selectedStand} isDownloadFile={isDownloadFile} language={language}/>}
                    {(standId !== 'page' && standId !== 'currentStand') 
                    && 
                    <div className={s.wrapDevelopment}>
                        <h1 className={s.wrapDevelopment_header}>{localText.development}</h1>
                    </div>}
                </div>
            }
            </>
            }

        </div>
    );
}



export default StatisticsPage;
