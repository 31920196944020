import { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { scrollTo } from './../utils/helpers';
import { socketChatUrl } from "../../../api/generateDomenUrlsApp";
import { getDialogs } from "../../../store/slices/appChatSlice/appChatExtraReducers";
import { setAppChatState, setNewMessage } from "../../../store/slices/appChatSlice/appChatSlice";
import downloadFile from "../../../services/downloadFile.service";

const useWebSocket = () => {

  const dispatch = useDispatch();
  const wsClient = useSelector(state => state.appChat.wsClient);
  let userData = useSelector(state => state.profile.userData);
  let token = useSelector(state => state.auth.token);
  const clientRef = useRef(wsClient);
  let intervalPing = useRef();
  const navigate = useNavigate()


  useEffect(() => {
    // console.log("mobileVersion", mobileVersion)
    // console.log("wsClient", wsClient)
    if(!wsClient && userData?.id && token) {

      const reconnect = () => {
        clearInterval(intervalPing.current)
        intervalPing.current = null;
        setTimeout(() => {
          dispatch(setAppChatState({key: "wsClient", value: null}));
        }, 1500);
      }

      clientRef.current = new WebSocket(`${socketChatUrl}/${token}`);

      clientRef.current.onerror = (e) => {
        reconnect();
      }

      clientRef.current.onopen = () => {
        dispatch(setAppChatState({key: "wsClient", value: clientRef.current}));
        dispatch(getDialogs());
        console.log('ws opened')
        intervalPing.current = setInterval(() => {
          clientRef.current.send(JSON.stringify({
            messageTypeId: 4,
            messageType: "PING",
            payload: {},
        })); 
        }, 5000);
      }

      clientRef.current.onclose = (e) => {
        reconnect();
        console.log("ws close", e)
      }

      clientRef.current.onmessage = async (e) => {

        console.log(JSON.parse(e.data || 0))

        let {payload = {}, messageTypeId} = JSON.parse(e.data || 0);

        switch (+messageTypeId) {
          case 1:
            let files = payload.files;
            if(files && files.length) {
              files = await Promise.all(files.map(async file => ({
                ...file,
                previewUrl: await downloadFile({
                  type: "previewDialogFile",
                  data: {
                    fileId: file.fileId,
                    name: file.fileName,
                    dialogId: payload.dialogId,
                }
                })
              }))
            )
            }
            dispatch(setNewMessage({value: {...payload, files}}));
            scrollTo("scrollMessEnd", 0.5);
            break;
        
          default:
            break;
        }

    }
    }

    return () => wsClient && wsClient.close(3000, "close")

  }, [wsClient, userData?.id, token]);

  return clientRef.current;
};

export default useWebSocket;
