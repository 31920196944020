import styled, { css } from "styled-components";

const WrapDocuments = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: -12px;
  padding-right: 8px;

  .wrap-document {
    display: flex;
    padding: 12px;
    min-width: 300px;
    width: calc(33% - 8px);
    height: 60px;
    background: #f5f5f5;
    border-radius: 4px;

    .document__icon {
      display: flex;
      align-items: center;
      width: 29.25px;
      height: 100%;
      min-width: 29.25px;
      min-height: 36px;
    }
    .document-info {
      display: flex;
      align-items: center;
      width: calc(100% - 29px);
      justify-content: space-between;
      padding-left: 12px;

      .info-text {
        max-width: calc(100% - 60px);
      }

      .document__name {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #363636;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .document__size {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #484848;
        text-transform: uppercase;
      }
    }

    .info__more {
      border: none;
      background-color: #efefef;
      border-radius: 8px;
      width: 30px;
      height: 30px;
      min-width: 30px;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export { WrapDocuments };
