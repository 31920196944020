
export const appointmentPage_by = {
    'header': {
        'appointments': 'Cустрэчы',
        'title': 'Спіс прызначаных сустрэч',
        'search': 'Пошук',
        'data': {
            'from': 'ад',
            'to': 'Да',
        }
    },
    'table': {
        'header': {
            'theme' : 'Тэма',
            'duration' : 'Працягласць',
            'status' : 'Анлайн',
            'time' : 'Дата / Час',
            'participants': 'Удзельнікі',
        },
        'body': {
            'durationHours': 'г.',
            'durationMin': 'хвіл.',
        },
        'leaveModal':{
            'header': 'Вы ўпэўнены, што жадаеце выдаліць гэтую сустрэчу?',
            'yes': 'Так',
            'no': 'Не',
        },
        'editModal':{
            'header': 'Рэдагаванне сустрэчы',
            'confirmed': 'Пацвердзіце сустрэчу',
            'theme': 'Тэма сустрэчы',
            'yes': 'Рэдагаваць',
            'no': 'Адмяніць',
        },
        'noAppointments': 'Спіс сустрэч у дадзены момант пусты',
    },
    'listParticipants': {
        'header': 'Спіс удзельнікаў',
        'confirm': {
            'yes': 'Пацвердзіць удзел',
            'no': 'Адмяніць пацверджанне ўдзелу',
        },
        'addContact': 'Дадаць карыстальніка',
        'appointment': {
            'leave': 'Пакінуць сустрэчу',
            'remove': 'Выдаліць сустрэчу',
        },    
    },
    'listAddContacts': {
        'headerCon': 'Кантакты',
        'headerPar': 'Удзельнікі',
    },
    'videoConference': {
        'text1': 'Ваш нік ',
        'text2': " з'яўляецца лагінам (ID) для ўваходу ў відэаканферэнцыю. Калі ласка, увядзіце, калі ласка, пароль які пацвярджае, што вы-гэта Вы",
        'btn': 'Прадоўжыць',
        'dev': 'Відэаканферэнцсувязь у распрацоўцы',
    },
}