
export const mediaPage_ru = {
    'company': 'Компания',
    'editing': 'Редактировать',
    'loading': 'Загрузка',
    'await': 'Прорисовка загруженной модели...',
    'defaultPosition': 'Позиция по умолчанию',
    'files': 'Файлы',
    'update': 'Обновить',
    'communication': {
        'communication': 'Коммуникация',
        'chatWithRepresentative': 'Чат с представителем',
        'contactRequest': 'Заявка на встречу',
        'addDate': {
            'chooseDate': 'Дата, время и длительность встречи:',
            'enterTheme': 'Тема встречи:',
            'submit': 'Отправить',
            'successSubmit': 'Заявка отправлена на рассмотрение представителю предприятия',
            'availableUsers': 'Список пользователей:',
            'select': 'Выбрать',
            'minutes': 'минут',
            'hour': 'час',
            'hours': 'часа',
            'isVideoConference': 'Онлайн по видео',
            'meetingScheduled': 'Встреча назначена',
            'alreadyAppointmentAtThisTime': 'У Вас уже есть встреча в это время',
            'userIsNotAvailable': 'Пользователь недоступен в это время',
            'toMeetingList': 'К списку встреч',
        }
    },
    'changeModal': {
        'imageVideo': 'Изображение/Видео',
        '_3d': '3D модель',
        'video': 'видео',
        'file': 'файл',
        'company': 'информация о компании',
        'whatDownloadText': 'Что вы хотите загрузить?',
        'send': 'Отправить',
        'threeDModel': {
            'chooseImage': 'Выберите изображение, которое будет отображаться на баннере:',
            'chooseAssetBundles': 'Выберите файл 3D модели asset bundle:',
            'uploadPhoto': 'Загрузить фото',
            'uploadAssetBundles': 'Загрузить asset bundle',
            'uploaded': 'Загружено',
            'from': 'из',
            'allLoaded': 'Все фото загружены',
            'selected': 'Выбрано:',
            'images': 'изображений',
            'lincToAssetBundles': 'Ссылка на то как создать asset bundle: ',
            'contactUs': '. Также для преобразования вашей модели в необходимый формат есть возможность связаться с нами: ',
            'allowedSize': 'Допустимый размер картинки - 10МБ. Ваш размер - ',
            'allowedFileSize': 'Допустимый размер файла - 50МБ. Ваш размер - ',
            'mb': 'МБ',
        }
    }
}