import React from "react";
import LeftBox from "./LeftBox";
import { useDispatch } from "react-redux";
import { setAppChatState } from "../../../store/slices/appChatSlice/appChatSlice";


const LeftBoxContainer = ({mobileVersion}) => {

    const dispatch = useDispatch();

    const handlerActions = (type, data) => () => {
        switch (type) {
          case "addDialog":
            dispatch(setAppChatState({key: "modal", value: {type: "createNewDialog"}}))
            break;
          default:
            break;
        }
      };

    return <LeftBox mobileVersion={mobileVersion} handlerActions={handlerActions}/>
}

export default LeftBoxContainer
