import React, { useEffect, useState } from "react";
import { WrapProfileMain } from "../profile.style";
import { svg_icons } from "../../../assets/imgs/svg_icons";
import { Link } from "react-router-dom";

const ProfileMain = ({handlerActions, userData}) => {
  const {
    exitIcon,
    statistactsIcon,
    userIcon,
    personIcon,
    requestsIcon,
    meetingsIcon,
    notificationsIcon,
    chatsIcon,
    cubeIcon,
    documentIcon,
    favoritesIcon,
  } = svg_icons;

  let adminNav = [
    userData?.role === "ROLE_EXHIBITOR" ? { icon: statistactsIcon, head: "Статистика", to: "statistics" } : "",
    { icon: userIcon, head: "Контакты", to: "contacts" },
    { icon: personIcon, head: "Персонаж", to: "hero" },
    { icon: requestsIcon, head: "Заявки", to: "requests" },
    { icon: meetingsIcon, head: "Встречи", to: "meetings" },
    // { icon: notificationsIcon, head: "Уведомления" },
    { icon: chatsIcon, head: "Чаты", to: "chat" },
    userData?.role === "ROLE_EXHIBITOR" ? { icon: cubeIcon, head: "Конструктор стендов", to: "manage-stand" } : "",
    { icon: documentIcon, head: "Документы", to: "documents" },
    { icon: favoritesIcon, head: "Избранные экспоненты", to: "favorites" },
  ];

  return (
    <WrapProfileMain>
      <div className="profile-nav-list">
        {adminNav.map((nav) => nav ? (
          <Link key={nav.head} to={nav.to}>
            <div className="profile-nav">
              <div className="nav__icon">{nav.icon}</div>
              <p>{nav.head}</p>
            </div>
          </Link>
        ): "")}
      </div>
      <div className="profile-nav-btns">
        <button onClick={handlerActions("exitProfile")}>
          {exitIcon}
          {"Выйти из профиля"}
        </button>
        <button onClick={handlerActions("goUnity")}>{"Вернуться на выставку"}</button>
      </div>
    </WrapProfileMain>
  );
};

export default ProfileMain;
