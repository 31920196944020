import React, {useState, useEffect} from "react";
import { CompanyStandMaterials } from "./CompanyStandMaterials.jsx";
import { useDispatch, useSelector } from "react-redux";
import { useHttp } from "../../../hooks/useHttp.js";
import { useNavigate } from "react-router-dom";
import { useSendUnity } from "../../../hooks/useSendUnity.js";
import { getCopmanyStandFull } from "../../../store/slices/unitySlice/unityExtraReducers.js";
import { resetUnityState } from "../../../store/slices/unitySlice/unitySlice.js";
import { useKeyboardUnity } from "../../../hooks/useKeyboardUnity.js";


export const CompanyStandMaterialsContainer = ({ typeContentId, companyKey, setIsLoading, mediaId }) => {

  const {company} = useSelector((state) =>  state.unity.copmanyStandFull);
  const dispatch = useDispatch() 
  const navigate = useNavigate() 
  const [type, setType] = useState(typeContentId === 6 ? "3dModel" : typeContentId === 7 ? "image" : "video")
  // const language = useSelector((state) => state.app.appText);
  let { initRequest } = useHttp();
  const sendUnity = useSendUnity();
  const onKeyboardUnity = useKeyboardUnity();

  const handlerActions = (key, data) => () => {
    switch (key) {
      case "close":
        navigate("/");
        onKeyboardUnity()
        break;
      case "changeType":
        setType(data);
        break;
    
      default:
        break;
    }
  }

  useEffect(() => {
    const fetch = async () => {
      await dispatch(getCopmanyStandFull({companyKey}));
      setIsLoading(false);
    }
    fetch()
  }, [dispatch]);

  useEffect(() => () => dispatch(resetUnityState("copmanyStandFull")), [dispatch]);

  console.log("CompanyStandMaterials", company)

  if(!company) return null;

  return (
    <CompanyStandMaterials   
    handlerActions={handlerActions}
    type={type}
    mediaByTypes={company.mediaByTypes}
    company={company}
    mediaId={mediaId}
    />
  )
};