import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { WrapLoadingUnity } from "./loaderUnity.style";
import {
  resetUnityState,
  setUnityState,
} from "../../store/slices/unitySlice/unitySlice";

function LoaderUnity({ typeLoad = "main" }) {
  const {
    progress,
    providerId,
    builds,
    downloadBuild,
    pavilions: {
      selectedPavilion: { shortName, id },
    },
  } = useSelector((state) => state.unity);
  const dispatch = useDispatch();

  // console.log(progress)

  useEffect(() => {
    dispatch(setUnityState({ isScene: false }));
    return () => {
      dispatch(resetUnityState("loader"));
    };
  }, []);

  useEffect(() => {
    window.unityProgress = (progressUnity) => {
      if(+providerId === +id && (builds && builds[providerId]) && !downloadBuild) {
        console.log("PROGRESS_UNITY", progressUnity)
        dispatch(setUnityState({progress: parseInt(progressUnity * 100)}));
      }
    };
  }, [providerId, id, downloadBuild]);

  return (
    <WrapLoadingUnity progress={progress || 0} id="progressUnity">
      <div className="load__line-progress"></div>
      <div className="load-content">
        <svg
          width="94"
          height="36"
          viewBox="0 0 94 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M75.3062 5.44027C75.5313 5.74822 75.8037 5.96142 76.0406 6.21015C81.5364 11.7059 87.044 17.2135 92.5397 22.7093C93.1201 23.2897 93.582 23.9293 93.6057 24.7939C93.6294 25.9191 92.978 26.9614 91.9594 27.3878C90.846 27.8379 89.8156 27.6247 88.9509 26.8075C88.1218 26.0139 87.3401 25.1848 86.5347 24.3675C81.714 19.535 76.9052 14.7144 72.0846 9.8819C71.8951 9.69239 71.7056 9.52657 71.4805 9.31337C71.4568 9.70423 71.4213 10.0714 71.4213 10.4267C71.4213 15.1645 71.4213 19.9022 71.4213 24.6399C71.4213 25.2677 71.3384 25.8836 70.9831 26.4284C70.1421 27.7313 68.176 27.98 67.0152 26.9022C65.9018 25.8599 64.824 24.7584 63.7462 23.6805C57.4568 17.4031 51.1793 11.1256 44.89 4.84806C44.2859 4.24399 43.8714 3.55702 43.8595 2.69239C43.8477 1.5198 44.4636 0.631473 45.5533 0.193232C46.5482 -0.209475 47.6497 0.0274117 48.4433 0.809138C54.1996 6.57733 59.9678 12.3455 65.7242 18.1137C65.7952 18.1848 65.8663 18.2795 65.9729 18.4098C66.0321 18.09 66.0677 17.8413 66.0677 17.5926C66.0677 13.5773 66.0558 9.5621 66.0558 5.54687C66.0558 4.65855 66.0203 3.77022 66.0203 2.8819C66.0203 1.2 67.11 0.0984777 68.7919 0.0984777C76.0998 0.0984777 83.3959 0.0984777 90.7039 0.0984777C91.5804 0.0984777 92.3858 0.299832 92.9425 1.02234C94.2216 2.6687 93.3452 4.8599 91.5922 5.2863C91.1658 5.3929 90.7276 5.38105 90.3012 5.38105C85.8358 5.38105 81.3587 5.38105 76.8934 5.38105C76.3604 5.36921 75.8511 5.38105 75.3062 5.44027Z"
            fill="#3DC381"
          />
          <path
            d="M52.1267 22.1642C51.5226 21.1219 50.7646 20.2454 49.6512 19.7361C48.0404 19.0017 46.3822 18.8004 44.7358 19.5939C42.9 20.4823 41.6563 21.8917 41.2891 23.9645C40.7325 27.044 43.0658 30.1828 46.1572 30.5499C46.6783 30.6092 47.1995 30.5973 47.7088 30.7395C49.059 31.1422 49.8526 32.3148 49.6631 33.6295C49.4617 35.0153 48.3602 35.9391 46.8797 35.9273C44.724 35.9154 42.7342 35.3114 40.9457 34.1151C38.4702 32.4569 36.8238 30.2065 36.2079 27.2691C34.9169 21.1574 38.7308 15.33 44.8306 14.0745C49.7105 13.0677 54.6732 15.6142 56.8644 20.0203C57.5396 21.3824 57.8831 22.8274 57.9778 24.3435C58.0133 24.995 58.037 25.6227 57.7291 26.2149C57.2435 27.1625 56.4617 27.6718 55.4076 27.6955C52.5531 27.7547 49.6986 27.7428 46.856 27.6955C45.3873 27.6718 44.2502 26.5229 44.2265 25.0779C44.2029 23.5618 45.3162 22.3774 46.7968 22.3063C47.9812 22.2471 49.1656 22.2589 50.3501 22.3063C50.9304 22.3181 51.5108 22.2352 52.1267 22.1642Z"
            fill="#363636"
          />
          <path
            d="M0.398438 25.0672C0.398438 19.5122 4.41367 14.8218 9.73177 14.0282C13.6404 13.4479 16.945 14.6086 19.681 17.4394C20.1785 17.9487 20.5338 18.5172 20.593 19.2516C20.6878 20.3176 20.2732 21.1585 19.3967 21.7626C18.5321 22.343 17.5964 22.3548 16.6726 21.881C16.2225 21.6441 15.8553 21.3007 15.5118 20.9335C14.28 19.6306 12.7521 19.0147 10.9636 19.2042C8.35783 19.4885 6.36798 21.3717 5.87052 23.9538C5.26646 27.0925 7.51688 30.2313 10.6912 30.6814C11.8874 30.8472 13.0482 30.7761 14.1379 30.2076C14.6945 29.9115 15.1683 29.5206 15.5829 29.0469C16.104 28.4546 16.6726 27.9335 17.5017 27.7914C18.615 27.6018 19.7165 28.1348 20.2969 29.1534C20.8536 30.1247 20.7352 31.2499 19.9653 32.1145C17.4898 34.8861 14.3866 36.2008 10.6675 35.9758C5.5981 35.6678 1.34598 31.7592 0.552414 26.7491C0.457659 26.145 0.422126 25.5409 0.398438 25.0672Z"
            fill="#363636"
          />
          <path
            d="M27.9197 19.2264C27.9197 22.9337 27.9197 26.6528 27.9197 30.3601C27.9197 30.597 27.967 30.6681 28.2158 30.6681C29.8977 30.6562 31.5914 30.6562 33.2733 30.6681C34.4459 30.6799 35.4882 31.4616 35.7843 32.5632C36.1041 33.7239 35.6422 34.9439 34.6472 35.5835C34.209 35.8559 33.7234 35.9743 33.2141 35.9743C30.5846 35.9743 27.967 35.9862 25.3376 35.9743C23.6912 35.9625 22.6134 34.8491 22.6016 33.2028C22.6016 24.888 22.6016 16.5852 22.6016 8.27043C22.6016 6.10292 24.698 4.80004 26.5694 5.80681C27.517 6.31612 27.9078 7.1926 27.9078 8.24675C27.9197 11.9066 27.9197 15.5665 27.9197 19.2264Z"
            fill="#363636"
          />
        </svg>
        {shortName ? <h2 className="content__head">{shortName}</h2> : ""}
        <p className="content__subhead">
          Уже загружаем павильон! <br /> Загрузка займёт несколько секунд :)
        </p>
        <div className="content-progress">
          <p className="progress__points">
            <span></span>
          </p>
          <p className="progress__result">{progress ? `${progress} %` : ""}</p>
        </div>
      </div>
    </WrapLoadingUnity>
  );
}

export default LoaderUnity;
