import {createSlice} from '@reduxjs/toolkit';
import reducers from './appReducers';
import { handlerData } from './../../../services/handlerData.service';
import { getParamFromUrl } from '../../../utils/helpers';

const {getAppText} = handlerData();

export const initialStateApp = {
  appText: getAppText(),
  selectedTab: getParamFromUrl("tab"),
  contextMenu: null,
  modal: {type: ''}
};

const appSlice = createSlice({
  name: 'app',
  initialState: initialStateApp,
  reducers,
})

const appReducer = appSlice.reducer;

export const {setAppTextLang, setAppState, resetAppState} = appSlice.actions;

export default appReducer;