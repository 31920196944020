import styled, { css } from "styled-components";

const WrapFavorites = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: -12px;
  padding-right: 8px;

  .wrap-favorite {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    width: calc(33% - 8px);
    height: 254.5px;
    background: #f6f6f6;
    border-radius: 4px;

    .favorite-wrap-logo {
      width: 100%;
      height: 162px;
      display: flex;
      align-items: center;
      justify-content: center;
      .favorite__logo {
        display: flex;
        align-items: center;
        width: 190px;
        height: 74px;
      }
    }

    .favorite-info,
    .favorite-view {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 12px;
    }

    .favorite-info {
      padding-top: 19px;
      .favorite__name {
        width: 90%;
        font-weight: 600;
        font-size: 13px;
        line-height: 110%;
        color: #363636;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .favorite__more {
        border: none;
        background-color: #efefef;
        border-radius: 8px;
        width: 30px;
        height: 30px;
        min-width: 30px;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .favorite-view {
      margin: auto 0;
      .favorite__3d {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 13px;
        color: #363636;
        background: transparent;
        svg {
          width: 14px;
          height: 14px;
          margin-right: 9px;
        }
      }
    }
  }
`;


const WrapFilterBtns = styled.div`
  display: flex;
  gap: 12px;
  .app-select {
    height: 34px;
    min-width: 195px;
  }
`;

export { WrapFavorites, WrapFilterBtns };
