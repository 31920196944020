
export const messages_by = {
    'development': 'Данная старонка знаходзіцца на этапе распрацоўкі.',
    'confirm': 'Для пацверджання змяненняў дадзеных неабходна перайсці па спасылцы, якая была выслана на вашу электронную пошту.',
    'notFound': 'Каб змяніць пароль, унясіце змены ў адрас электроннай пошты, праверыўшы пошту.',
    'wrongPasswordOld': 'Няправільны стары пароль.',
    'wrongPassword': 'Няправільны пароль.',
    'differentPasswords': 'Несупадзенне пароля.',
    'logout': 'Выхад з прыкладання.',
    'changeLanguageError': 'Памылка перакладу старонкі на іншую мову.',
    'changeCameraError': 'Значэнне бясплатнай камеры не змянілася.',
    'fileSize': 'Памер файла павінен быць да 100 МБ.',
    'notFoundVideoCon': 'Сустрэча яшчэ не пачалася. Пачатак сустрэчы запланаваны на',
    'logInAgain': "Паўтарыце ўваход",
    thisPavilion: "Вы знаходзіцеся на гэтым павільёне",
    userNotFound: "Акаўнт не знойдзены",
}