
export const mediaPage_by = {
    'company': 'Кампания',
    'editing': 'Рэдактаваць',
    'loading': 'Загрузка',
    'await': 'Прамалёўка загружанай мадэлі...',
    'defaultPosition': 'Пазіцыя па змаўчанні',
    'files': 'Файлы',
    'update': 'Абнавіць',
    'communication': {
        'communication': 'Камунікацыя',
        'chatWithRepresentative': 'Чат з прадстаўніком',
        'contactRequest': 'Заяўка на сустрэчу',
        'addDate': {
            'chooseDate': 'Дата, час і працягласць сустрэчы:',
            'enterTheme': 'Тэма сустрэчы:',
            'submit': 'Адправіць',
            'successSubmit': 'Заяўка адпраўлена на разгляд прадстаўніку прадпрыемства',
            'availableUsers': 'Спіс карыстальнікаў:',
            'select': 'Абраць',
            'minutes': 'хвілін',
            'hour': 'гадзіна',
            'hours': 'гадзіны',
            'isVideoConference': 'Анлайн па відэа',
            'meetingScheduled': 'Сустрэча прызначаная',
            'alreadyAppointmentAtThisTime': 'У Вас ужо ёсць сустрэча ў гэты час',
            'userIsNotAvailable': 'Карыстальнік недаступны ў гэты час',
            'toMeetingList': 'Да спісу сустрэч',
        }
    },
    'changeModal': {
        'imageVideo': 'Малюнак/Відэа',
        '_3d': '3D мадэль',
        'video': 'відэа',
        'file': 'файл',
        'company': 'інфармацыя аб кампаніі',
        'whatDownloadText': 'Што вы хочаце загрузіць?',
        'send': 'Адправіць',
        'threeDModel': {
            'chooseImage': 'Абярыце малюнак, які будзе адлюстроўвацца на банеры:',
            'chooseAssetBundles': 'Абярыце файл 3D мадэлі asset bundle:',
            'uploadPhoto': 'Загрузіць фота',
            'uploadAssetBundles': 'Загрузіць asset bundle',
            'uploaded': 'Загружана',
            'from': 'з',
            'allLoaded': 'Усе фота загружаныя',
            'selected': 'Абрана:',
            'images': 'малюнкаў',
            'lincToAssetBundles': 'Спасылка на тое як стварыць asset bundle:',
            'contactUs': '. Таксама для пераўтварэння вашай мадэлі ў неабходны фармат ёсць магчымасць звязацца з намі:',
            'allowedSize': 'Дапушчальны памер карцінкі – 10МБ. Ваш памер - ',
            'allowedFileSize': 'Дапушчальны памер файла – 50МБ. Ваш памер - ',
            'mb': 'МБ'
        }
    }
}