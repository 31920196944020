import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderLeftBox from "./HeaderLeftBox";
import { setAppChatState } from "../../appChatSlice/appChatSlice";
import { useSendUnity } from "../../../../hooks/useSendUnity";
import { useNavigate } from "react-router-dom";
import { setDialogsChat } from "../../../../store/slices/appChatSlice/appChatSlice";
import { useKeyboardUnity } from "../../../../hooks/useKeyboardUnity";

const HeaderLeftBoxContainer = () => {
  const { dialogs, mobileVersion } = useSelector((state) => state.appChat);
  const dispatch = useDispatch();
  const sendUnity = useSendUnity();
  const navigate = useNavigate();
  let initDialogs = useRef(dialogs);
  const onKeyboardUnity = useKeyboardUnity();
  const [localState, setLocalState] = useState({ openSearch: false });


  const handlerActions = (type, data) => (e) => {
    switch (type) {
      case "closeMobileChat":
        dispatch(setAppChatState({ key: "mobileVersion", value: false }));
        onKeyboardUnity();
        break;
      case "goBack":
        navigate("/profile?tab=main");
        break;
      case "toggleSearch":
        setLocalState(state => ({...state, openSearch: !state.openSearch}))
        break;
      case "searchDialogs":
        let lowerValue = e.target.value.toLowerCase();
        let filterDialogs = initDialogs.current?.filter((dialog) =>
        `${dialog.user.firstName} ${dialog.user.laststName}`.toLowerCase().includes(lowerValue)
        );
        filterDialogs && dispatch(setDialogsChat({ value: filterDialogs }));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (dialogs && !initDialogs.current) initDialogs.current = dialogs;
  }, [dialogs]);

  return (
    <HeaderLeftBox
      dialogs={dialogs}
      handlerActions={handlerActions}
      mobileVersion={mobileVersion}
      localState={localState}
    />
  );
};

export default HeaderLeftBoxContainer;
