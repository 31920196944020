import {createAsyncThunk} from "@reduxjs/toolkit";


export const getLocation = createAsyncThunk(
  'appChat/getLocation',
  async(locationId) => {

  }
);

const extraReducers = {
  [getLocation.fulfilled]: (state, action) => {

  },
  
};

export default extraReducers;