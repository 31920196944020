import styled, { css } from "styled-components";

const WrapContacts = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: -12px;
  padding-right: 8px;

  .wrap-contact {
    display: flex;
    padding: 12px;
    min-width: 300px;
    width: calc(33% - 8px);
    height: 66px;
    background: #f5f5f5;
    border-radius: 4px;

    .contact__avatar {
      width: 42px;
      height: 42px;
      min-width: 42px;
      min-height: 42px;
      border-radius: 50%;
    }
    .contact-info {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      padding-left: 12px;

      .contact__name {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #363636;
        .contact__online {
          display: inline-block;
          width: 6px;
          height: 6px;
          background: #34C57F;
          border-radius: 50%;
          margin: 2px 0;
        }
      }
      .contact__role {
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        color: #484848;
      }
    }

    .info__more {
      border: none;
      background-color: #efefef;
      border-radius: 8px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    height: 66px;
    background: #f5f5f5;
    border-radius: 4px;
  }
`;

const WrapFilterBtns = styled.div`
  color: #363636;
  .toggle-btns {
    display: flex;
    height: 30px;
    background: #f5f5f5;
    border-radius: 10px;
    overflow: hidden;
    .toggle-btns__all,
    .toggle-btns__online {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      border-radius: 10px;
      background: transparent;
      border: none;
      cursor: pointer;
    }
    .toggle-btns__all {
      width: 172px;
      ${({ activeBtn }) =>
        activeBtn === "all" &&
        css`
          background: #34c57f;
          color: #ffffff;
        `}
    }
    .toggle-btns__online {
      width: 162px;
      ${({ activeBtn }) =>
        activeBtn === "online" &&
        css`
          background: #34c57f;
          color: #ffffff;
        `}
    }
  }
`;

export { WrapContacts, WrapFilterBtns };
