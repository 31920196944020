import React, { useEffect, useRef } from "react";
import PaginationContainer from "../../Pagination/PaginationContainer";
import s from "./ListParticipants.module.css";
import { appointmentSvg } from "../img/AppointmentSvg";
import {  useDispatch, useSelector } from "react-redux";

import ContactsList from "./ContactsList/ContactsList";
import { useLoading } from "../../../../hooks/checkLoading";
import { useHttp } from "../../../../hooks/useHttp";
import {
  confirmAppointment,
  leaveAppointment,
  toggleParticipant,
} from "../../../../store/slices/profileSlice/profileExtraReducers";
import { getListParticipants } from "./../../../../store/slices/profileSlice/profileExtraReducers";
import {
  setAppointmentsState,
  setContactsState,
  setParticipantsState,
} from "../../../../store/slices/profileSlice/profileSlice";

const ListParticipants = ({
  localText,
  paginationList,
}) => {
  // new code
  const dispatch = useDispatch();
  const {
    userData: {id: userId},
    appointments: {loading: loadType,
    listParticipants,
    listContacts,
    showConrolBtns,}
  } = useSelector((state) => state.profile);

  const handlerToggleParticipant = async ({method, userId}) => {
    dispatch(toggleParticipant({id: "test", userId, method,}));

    //   if(type === 'addParticipants') getContactsList(paginationContacts.currentPage, paginationContacts)(dispatch, getState);
    //   getListParticipants(initRequest, pagination.currentPage, pagination, type)(dispatch, getState);
  }



  //old code

  let { initRequest } = useHttp();

  let participants = listParticipants.data;
  let isOwner = listParticipants.isOwner;
  let confirmed = listParticipants.confirmed;
  let loading = useLoading("listParcitipants", loadType);
  let textCurrent = localText.listParticipants;
  let pagination = useRef({
    currentPage: 1,
    totalPage: listParticipants.totalPage || 0,
    changeCurrentPage(numPage) {
      dispatch(
        getListParticipants({
          page: numPage,
          id: "test",
          lang: "test",
        })
      );
    },
  });

  const closeList = (e) => {
    if (e.target?.id === "closeList" && !listContacts.open)
      dispatch(setParticipantsState({ ...listParticipants, open: false }));
    if (
      (e.target?.id === "closeList" || e.target?.id === "closeAddList") &&
      listContacts.open
    ) {
        dispatch(setContactsState({ ...listContacts, open: false }));
      dispatch(
        getListParticipants({
          page: 1,
          id: "test",
          lang: "test",
        })
      );
    }
  };

  const initFunction = (type) => async () => {
    let value = type === "confirm" ? confirmed : isOwner;

    if (type === "confirm") {
      await dispatch(confirmAppointment({ confirm: value, id: "test" }));
    } else {
      await dispatch(leaveAppointment({ type: value, id: "test" }));

      // paginationList
    }
  };

  useEffect(() => {
    getListParticipants(initRequest, 1, pagination.current);
  }, []);

  return (
    <div className={s.wrapListParticipants} id="closeList" onClick={closeList}>
      <div className={s.listParticipants}>
        <div className={s.listParticipantsHeader}>
          <span
            className={s.listParticipantsHeader_back}
            onClick={() =>
              dispatch(
                setParticipantsState({ ...listParticipants, open: false })
              )
            }
          >
            {appointmentSvg.arrows.back}
          </span>
          <p>{textCurrent.header}</p>
          <div className={s.wrapHeaderContolsBtns}>
            <div
              className={`${s.headerControlBtnOpen} ${
                showConrolBtns && s.activeControl
              }`}
              onClick={() =>
                dispatch(
                  setAppointmentsState({ showConrolBtns: !showConrolBtns })
                )
              }
            >
              {appointmentSvg.ellipsis}
            </div>
            {showConrolBtns && (
              <div className={s.wrapControl}>
                <button
                  className={s.wrapControl__btn}
                  onClick={initFunction("confirm")}
                >
                  <span>
                    {confirmed
                      ? textCurrent.confirm.no
                      : textCurrent.confirm.yes}
                  </span>
                </button>
                <button
                  className={s.wrapControl__btn}
                  onClick={() =>  dispatch(setContactsState({ open: true }))}
                >
                  <span>{textCurrent.addContact}</span>
                </button>
                <button
                  className={`${s.wrapControl__btn} ${s.btnRed}`}
                  onClick={initFunction(false)}
                >
                  <span>
                    {isOwner
                      ? textCurrent.appointment.remove
                      : textCurrent.appointment.leave}
                  </span>
                </button>
              </div>
            )}
            {showConrolBtns && (
              <div
                className={s.headerControlBtnClose}
                onClick={() =>
                  dispatch(setAppointmentsState({ showConrolBtns: false }))
                }
              ></div>
            )}
          </div>
        </div>
        <div className={s.listParticipantsContent}>
          <div className={s.list}>
            {participants ? (
              participants.map((user) => (
                <div key={user.id} className={s.participant}>
                  <p className={s.participant__name}>
                    {`${user.firstName} ${user.lastName}`}
                    {isOwner && +userId === +user.id && <span>*</span>}
                  </p>
                  {isOwner && +userId !== +user.id && (
                    <button
                      className={s.participant__deleteBtn}
                      onClick={() => handlerToggleParticipant({method: "delete", userId: user.id})}
                    >
                      <span>×</span>
                    </button>
                  )}
                </div>
              ))
            ) : (
              <p>Загрузка</p>
            )}
          </div>
          <PaginationContainer
            classNameBtn={s.paginationBtns}
            paginationData={pagination.current}
          />
          {loading && <div className="backgroundLoading"></div>}
        </div>
      </div>
      {listContacts.open && (
        <ContactsList localText={localText} closeList={closeList} />
      )}
    </div>
  );
};

export default ListParticipants;
