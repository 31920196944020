import React from 'react';
import { Outlet } from 'react-router-dom';
import { WrapProfileRoute } from './profile.style';


const ProfileRoute = () => {


    return (
        <WrapProfileRoute>
            <Outlet/>
        </WrapProfileRoute>
    );
}


export default ProfileRoute;

