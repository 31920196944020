import styled from "styled-components";

const WrapProfileRoute = styled.div`
  position: absolute;
  min-height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  background: #ffffff;
  overflow: auto;
  overflow-x: hidden;
  max-height: 100vh;
`;

const WrapProfile = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;

  .profile-head {
    .head-background {
      position: relative;
      height: 111px;
      background: #f5f5f5;
      margin-bottom: 72px;

      & > img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 0);
        width: 111px;
        height: 111px;
        border: 2px solid #ffffff;
        border-radius: 50%;
      }
    }

    & > h2 {
      font-weight: 700;
      font-size: 15px;
      line-height: 19px;
      color: #363636;
    }

    & > p {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: #484848;
      padding-bottom: 24px;
    }
  }
  .divider {
    height: 0.5px;
    background: #d4d4d4;
    margin: 34px 0;
  }
`;

const WrapProfileMain = styled.div`
  .profile-nav-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
    padding: 0 16px 80px;
    max-width: 1490px;
    margin: auto;
    .profile-nav {
      width: 268.8px;
      height: 132px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #f5f5f5;
      border-radius: 10px;
      cursor: pointer;
      color: #363636;
    }
  }

  .profile-nav-btns {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding-bottom: 16px;
    button {
      width: 164px;
      height: 36px;
      border-radius: 8px;
      border: none;
      font-weight: 500;
      font-size: 13px;
      line-height: 140%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #363636;
      background: transparent;
      cursor: pointer;
      &:first-child {
        border: 1px solid #363636;
        svg {
          margin-right: 6px;
        }
      }
      &:last-child {
        border: 1px solid #34c57f;
        color: #34c57f;
      }
    }
  }
`;

const WrapProfileData = styled.div`
  .profile-data-form {
    max-width: 700px;
    margin: auto;

    .form-fields {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }

    .field-wrap {
      text-align: start;
    }

    .form-btns {
      display: flex;
      justify-content: center;
      gap: 10px;
      padding-top: 56px;
      button {
        max-width: 186px;
      }
    }
  }
`;

const WrapProfileSecurity = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin: auto;

  .security-form {
    display: flex;
    gap: 16px;
    .security-password,
    .security-email {
      width: 340px;
      display: flex;
      flex-wrap: wrap;

      button {
        border: none;
        background: transparent;
        color: #34c57f;
        cursor: pointer;
      }
    }

    .security__label {
      font-weight: 700;
      font-size: 13px;
      line-height: 110%;
      color: #363636;
    }

    .security__help {
      margin-left: auto;
    }

    .security-elem-wrap {
      display: flex;
      align-items: center;
      width: 100%;
      height: 36px;
      background: #f5f5f5;
      border-radius: 4px;
      padding: 10px;
      margin-top: 8px;
      & > p {
        line-height: 110%;
        color: #363636;
        text-align: start;
      }
      & > button {
        text-align: start;
        margin-left: auto;
        height: 22px;
        background: #ffffff;
        border: 1px solid #34c57f;
        border-radius: 6px;
        padding: 4px 10px;
        font-weight: 500;
        font-size: 12px;
        line-height: 110%;
      }
    }
  }
  .security__close {
    align-self: center;
    margin-top: 58px;
    width: 186px;
    height: 36px;
    border: 1px solid #34c57f;
    border-radius: 8px;
    color: #34c57f;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    background: transparent;
  }
`;

export {
  WrapProfileRoute,
  WrapProfile,
  WrapProfileMain,
  WrapProfileData,
  WrapProfileSecurity,
};
