import React, { useState } from 'react';
import s from './ContextMenu.module.css'
import basket from './img/basket.png'
import moveFolder from './img/folder.png'
import download from './img/downloadSvg.svg'
import rename from './img/rename.png'
import { svg } from './img/directory/svg';
import { serverUrl } from '../../../../../api/generateDomenUrlsApp';


const ContextMenu = ({contextMenu, localText}) => {

   if(!contextMenu.eInfo) contextMenu.eInfo = {}

   console.log(contextMenu)

   let indexFormat = contextMenu.eInfo?.name?.lastIndexOf('.');
   let nameContent = indexFormat !== -1 ? 
       contextMenu.eInfo?.name?.slice(0,indexFormat) : contextMenu.eInfo?.name;
   const [name, setName] = useState(nameContent);

   let styleUl = {
    position: `${(contextMenu.type === 'table' || contextMenu.type === 'tableMeeting') ? 'fixed' : 'absolute'}`,
    top: `${(contextMenu.type === 'table' || contextMenu.type === 'tableMeeting') ? contextMenu.top : 'auto'}`,
    bottom: contextMenu.bottom, 
    left: contextMenu.left,
    transform: `${(contextMenu.type === 'table' || contextMenu.type === 'tableMeeting') ? 'inherit' : 'translateY(100%)'}`,
};

    const setCheckboxChecked = (id) => {
        if(contextMenu.openSelect) {
            contextMenu.toggleSelectControl();
        } else {
            contextMenu.toggleSelectControl();
            for(let checkbox of contextMenu.checkboxesSelect){
                if(+checkbox.id === +id) {
                    contextMenu.setCheckboxesSelect(
                        {
                            type: 'selectCheckbox', 
                            id, 
                            value: !checkbox.checked,
                        }
                )
                }
            }
        }

    }

    if(contextMenu.type === 'table') {
        return (
            <ul className={s.contextMenu} 
                style={styleUl}>
                <li> 
                    <a rel="noreferrer" target='_blank' download
                       href={contextMenu.fileId ? `${serverUrl}/files/download?fileId=${contextMenu.fileId}&token=${localStorage.getItem('token')}` 
                       : `${serverUrl}/files/api/folder/download/?folderId=${contextMenu.folderId}&token=${localStorage.getItem('token')}`}>
                            <button>
                                    <img src={download} alt="download"/>
                                    <p>{localText.downloadBtn}</p>
                            </button>
                    </a>   
                </li>
                <li>    
                    <button onClick={contextMenu.deleteFile}>
                        <img src={basket} alt="basket"/>
                        <p>{localText.deleteBtn}</p>
                    </button>
                </li>
                <li>    
                    <button onClick={() => {
                        contextMenu.setMoveFolder({
                            open: true, 
                            id: contextMenu.fileId ? contextMenu.fileId : contextMenu.folderId,
                            type: contextMenu.fileId ? 'file' : 'folder'})
                        }}>
                        <img src={moveFolder} alt="basket"/>
                        <p>{localText.moveFolder}</p>
                    </button>
                </li>
                <li>
                    <button onClick={(e) => {
                        e.stopPropagation();
                        contextMenu.openEdit();
                    }

                        }>
                        <img src={rename} alt="rename"/>
                        <p>{localText.renameBtn}</p>
                    </button>
                    {contextMenu.openRename ? 
                        <div className={s.renameModal}  onClick={e => e.stopPropagation()}>
                            <input type="text" value={name} onChange={(e) => setName(e.target.value)}/>
                            <div>
                                <button onClick={(e) => contextMenu.openEdit()}>{localText.cancelBtn}</button>
                                <button onClick={(e) => contextMenu.openEdit(name, true)}>{localText.okBtn}</button>
                            </div>
                        </div>
                        : null
                    }
                </li>
                <li>    
                    <button onClick={() => setCheckboxChecked(contextMenu.fileId ? contextMenu.fileId : contextMenu.folderId)}>
                        {svg.select}
                        {contextMenu.openSelect ? <p>{localText.closeSelect}</p> : <p>{localText.select}</p>}
                    </button>
                </li>
            </ul>
    )
    }
    else if (contextMenu.type === 'tableMeeting') {
        return (
        <div className={s.wrapContextMenu} onClick={() => contextMenu.setOpenContextMenu(false)}>
            <ul className={s.contextMenu} 
            style={styleUl}>
                <li>    
                    <button onClick={() => contextMenu.openDeleteModal = true}>
                        <img src={basket} alt="basket"/>
                        <p>{localText.deleteBtn}</p>
                    </button>
                </li>
                <li>
                    <button onClick={() => contextMenu.openEditModal = true}>
                        <img src={rename} alt="rename"/>
                        <p>{localText.editBtn}</p>
                    </button>
                </li>
            </ul>
        </div>
        )
    }
    else {
        return (
            <ul className={s.contextMenu} 
                style={styleUl}>
                <li> 
                    <button id='openModalCreateFolder_menu' onClick={contextMenu.createFolder}>
                        {svg.createFolder}
                        <p>{localText.createFolder}</p>
                    </button>  
                </li>
                <li>    
                    <label id='uploadFile' className={s.labelFile}>
                        {svg.uploadFile}
                        <p>{localText.uploadFile}</p>
                        <input type={'file'} name="file" multiple id="file" onChange={(e) => {
                            contextMenu.addFile(e);
                            contextMenu.setOpenContextMenu(false);
                        }}/>
                    </label>
                </li>
                <li> 
                    <button onClick={contextMenu.toggleSelectControl}>
                        {svg.select}
                        {contextMenu.openSelect ? <p>{localText.closeSelect}</p> : <p>{localText.select}</p>}
                    </button>  
                </li>
            </ul>
    )
    }

}

export default ContextMenu;
