import styled, { css } from "styled-components";


const WrapAppChat = styled.div`

    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
    border-width: 0.5px;
    border-style: solid;
    border-color: #D4D4D4;
    background: #ffffff;


    //MOBILE
    ${({mobile}) => mobile && css`
        position: fixed;
        top: 100px;
        left: auto;
        bottom: 16px;
        right: 16px;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
        border-radius: 16px;
        width: 360px;
        overflow: hidden;
    `}

`;

export {
    WrapAppChat
}