import styled, { css } from "styled-components";

const WrapNavigate = styled.div`
  width: 0;
  height: 0;
  .nav-background-close {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 4;
  }
`;


const WrapNavMainBtns = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  z-index: 3;
  margin: 16px;
  cursor: pointer;

  &.nav-stands {
    top: 0;
    left: 0;
    .stands__click {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      cursor: pointer;
    }

    &.stands_open {
      width: 340px;
      height: auto;
      max-height: calc(100% - 32px);
      flex-direction: column;
      justify-content: initial;
      align-items: initial;
      padding: 16px;
      z-index: 5;

      .stands-head {
        display: flex;
        align-items: center;
        & > svg {
          width: 14px;
          height: 14px;
        }
        h2 {
          font-weight: 700;
          font-size: 13px;
          line-height: 16px;
          color: #363636;
          padding-left: 9px;
          padding-bottom: 4px;
        }
        .head__close {
          width: 24px;
          height: 24px;
          background: #f5f5f5;
          border-radius: 8.66667px;
          border: none;
          cursor: pointer;
          margin-left: auto;
          svg {
            margin: auto;
            display: block;
          }
        }
      }

      .stands__subhead {
        font-size: 12px;
        line-height: 14px;
        color: #363636;
        padding-top: 8px;
        padding-bottom: 12px;
      }

      .stands-search {
        display: flex;
        align-items: center;
        padding: 8px 10px;
        height: 34px;
        background: #f5f5f5;
        border-radius: 10px;
        margin-bottom: 16px;
        svg {
          width: 16px;
          height: 16px;
        }
        input {
          flex: 1;
          background: transparent;
          border: none;
          outline: none;
          padding: 0 6px;
        }
      }

      .stands-result-search {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1;
        border: 0.5px solid #d4d4d4;
        border-radius: 4px;
        overflow: hidden;
        padding: 2px 2px 2px 0;
        .result-scroll {
          overflow: auto;
          height: 100%;
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 10px 10px 10px 12px;
          button {
            display: flex;
            align-items: center;
            padding: 0 12px;
            min-height: 36px;
            max-height: 36px;
            height: 36px;
            background: #f5f5f5;
            border-radius: 6px;
            font-weight: 500;
            font-size: 13px;
            line-height: 100%;
            color: #363636;
            padding-bottom: 2px;
            text-align: initial;
            &.selected {
              background: #34c57f;
              color: #ffffff;
            }
          }
        }
      }
      .stands-btns {
        display: flex;
        padding-top: 16px;
        justify-content: space-between;
        gap: 8px;
        button {
          flex: 1;
          cursor: pointer;
          font-weight: 500;
          font-size: 13px;
          line-height: 140%;
          height: 36px;
          border-radius: 8px;
          border: none;
          &:first-child {
            border: 1px solid #34c57f;
            background: transparent;
            color: #34c57f;
          }
          &:last-child {
            background: #34c57f;
            color: #ffffff;
            &:disabled {
              opacity: 0.3;
            }
          }
        }
      }
    }
  }

  &.nav-settings {
    left: 0;
    bottom: 0;
    ${({ settingsStyles }) =>
      settingsStyles &&
      css`
        left: auto;
        bottom: auto;
        right: 0;
        top: 0;
      `}
      .settings__click {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        cursor: pointer;
      }
      &.settings_open {
        width: 340px;
        height: auto;
        max-height: calc(100% - 32px);
        flex-direction: column;
        justify-content: initial;
        align-items: initial;
        padding: 16px;
        color: #363636;
        z-index: 5;

        .settings-head {
          display: flex;
          align-items: center;
          padding-bottom: 14px;
          & > svg {
            width: 16px;
            height: 16px;
          }
          h2 {
            font-weight: 700;
            font-size: 13px;
            line-height: 16px;
            padding-left: 10px;
          }
          .head__close {
              width: 24px;
              height: 24px;
              background: #f5f5f5;
              border-radius: 8.66667px;
              border: none;
              cursor: pointer;
              margin-left: auto;
              svg {
                margin: auto;
                display: block;
            }
          }
        }
        .settings__subhead {
          font-size: 12px;
          line-height: 14px;
          padding-bottom: 10px;
        }

        .settings-list {
          display: flex;
          flex-direction: column;
          gap: 10px;
          flex: 1;
          border: 0.5px solid #d4d4d4;
          border-radius: 4px;
          padding: 12px;
          button {
            display: flex;
            align-items: center;
            padding: 0 12px;
            min-height: 36px;
            max-height: 36px;
            height: 36px;
            background: #f5f5f5;
            border-radius: 6px;
            font-weight: 500;
            font-size: 13px;
            line-height: 100%;
            color: #363636;
            padding-bottom: 2px;
            &.selected {
              background: #34c57f;
              color: #ffffff;
            }
          }
        }
        .settings-btns {
          display: flex;
          padding-top: 16px;
          justify-content: space-between;
          gap: 8px;
          button {
            flex: 1;
            cursor: pointer;
            font-weight: 500;
            font-size: 13px;
            line-height: 140%;
            height: 36px;
            border-radius: 8px;
            border: none;
            &:first-child {
              border: 1px solid #34c57f;
              background: transparent;
              color: #34c57f;
            }
            &:last-child {
              background: #34c57f;
              color: #ffffff;
              &:disabled {
                opacity: 0.3;
              }
            }
          }
        }
      }
  }

  &.nav-profile {
    right: 0;
    top: 0;
    overflow: hidden;
    border: 1px solid #ffffff;
    img {
      width: 100%;
      height: 100%;
    }
  }

  &.nav-pavilions {
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    max-width: calc(100% - 150px);
    height: 34px;
    overflow: hidden;
    box-shadow: 1px 4px 24px rgba(0, 0, 0, 0.15);
    padding: 10px 16px;
    p {
      font-weight: 700;
      font-size: 13px;
      line-height: 15px;
      color: #363636;
      span {
        font-weight: 500;
      }
    }
  }

  &.nav-chat {
    right: 0;
    bottom: 0;
    & > div {
      position: relative;
      span {
        position: absolute;
        right: -3px;
        top: -4px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 14px;
        height: 14px;
        background: #ff5449;
        border: 2px solid #ffffff;
        border-radius: 15px;
        color: white;
        font-size: 8px;
      }
    }
  }
`;

const WrapLoginBtns = styled.div`
  position: fixed;
  left: 50%;
  bottom: 26px;
  z-index: 3;
  transform: translateX(-50%);
  color: #ffffff;
  & > h3 {
    text-align: center;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
  }

  & > div {
    display: flex;
    padding-top: 16px;
    gap: 10px;
    & > button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 182px;
      height: 36px;
      border-radius: 8px;
      border: none;
      color: #ffffff;
      cursor: pointer;
    }
    .btns__registration {
      border: 1px solid #ffffff;
      background: transparent;
    }

    .btns__login {
      background: #34c57f;
    }
  }
`;

const WrapNavWithUser = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 3;
  min-width: 271px;
  height: 60px;
  transform: translate(-50%, -50%);
  color: #363636;
  background: #FFFFFF;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  padding: 12px;

  .close__btn {
    position: absolute;
    right: -20px;
    top: -20px;
    width: 24px;
    height: 24px;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > img {
    width: 36px;
    height: 36px;
    border: 0.5px solid #D4D4D4;
    border-radius: 50%;
  }

  .nav-user-info {
    padding: 0 10px 0 12px;
    flex: 1;
    h3 {
      font-weight: 700;
      font-size: 13px;
      line-height: 16px
    }
    p {
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;      
      opacity: 0.7;
    }
  }

  .nav-user-btns {
    display: flex;
    button {
      width: 32px;
      height: 32px;
      background: #F5F5F5;
      border-radius: 10px;

      &:first-child {
        svg {
          width: 17px;
          height: 17px;
          vertical-align: top;
        }
      }
      &:last-child {
        margin-left: 8px;
      }
    }
}
`;

export { WrapNavMainBtns, WrapLoginBtns, WrapNavWithUser, WrapNavigate };
