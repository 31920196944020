import {createSlice} from '@reduxjs/toolkit';
import reducers from './profileReducers';
import extraReducers from "./profileExtraReducers"


export const initialStateProfile = {
  userData: null,
  listCompanies: [],
  standExists: false,
  superStandExists: false,
  modal: false,
  statistics: null,
  table: {
    data: null,
    filter: {
      search: "",
    },
  },
  appointments: {
    theme: false,
    search: '',
    contactsSearch: '',
    password: {open: false, value: '', hidden: true},
    fromDate: new Date().setHours(0,0,0,0),
    toDate: new Date(new Date().setDate(new Date().getDate() + 7)).setHours(23,59,0,0),
    listAppointments: false,
    listParticipants: {open: false},
    listParticipantsAdd: false,
    listContacts: {open: false},
    loading: false,
    showConrolBtns: false,
  },
};

const profileSlice = createSlice({
  name: 'profile',
  initialState: initialStateProfile,
  reducers,
  extraReducers,
})

const profileReducer = profileSlice.reducer;

export const {setProfileState, setAppointmentsState, setParticipantsState, setContactsState, resetProfileState, setProfileTableState} = profileSlice.actions;

export default profileReducer;