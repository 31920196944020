import React, { useEffect, useState } from "react";
import { WrapProfileData } from "../profile.style";
import { FormFieldProps } from "../../../utils/classTempates";
import { Formik, Form } from "formik";
import BaseFormField from "./../../../components/BaseComponentsForm/BaseFormField";
import BaseFormBtns from "./../../../components/BaseComponentsForm/BaseFormBtns";

const ProfileData = ({ handlerActions, userData }) => {

  const settingsFields = {
    lastName: new FormFieldProps("lastName", "Фамилия", "Введите фамилию"),
    firstName: new FormFieldProps("firstName", "Имя", "Введите имя"),
    patronymic: new FormFieldProps("patronymic", "Отчетво", "Введите отчество"),
  };

  const settingsBtns = {
    cancelName: "Закрыть",
    submitName: "Сохранить изменения",
  };

  const validate = (values) => {};

  if(!userData) return null;

  return (
    <WrapProfileData>
      <div className="profile-data-form">
        <Formik
        enableReinitialize 
          initialValues={{
            lastName: userData.lastName,
            firstName: userData.firstName,
            patronymic: userData.patronymic,
          }}
          validate={validate}
          // validationSchema={validateSchema}
          onSubmit={handlerActions("changeUserData")}
        >
          {({ dirty, isValid }) => {
            return (
              <Form>
                <div className="form-fields">
                  <BaseFormField settings={settingsFields.lastName} />
                  <BaseFormField settings={settingsFields.firstName} grow={2} />
                  <BaseFormField
                    settings={settingsFields.patronymic}
                    grow={2}
                  />
                </div>
                <BaseFormBtns
                  settings={settingsBtns}
                  cancel={handlerActions("cancel")}
                  disabled={!dirty || !isValid}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </WrapProfileData>
  );
};

export default ProfileData;
