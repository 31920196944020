import {createAsyncThunk} from "@reduxjs/toolkit";
import { getRequest } from "../../../api/appService";
import { handlerLocalStorage } from "../../../services/handlerLocalStorage.service";

export const getNetworkUser = createAsyncThunk(
  'unity/getNetworkUser',
  async(data = {}, thunkAPI) => {
    let { unityAPI } =  getRequest();
    let user = await unityAPI.getNetworkUser(data);
    //нет id в обьекте, пока берем так, потом можно это удалить
    return {...user, id: data.id}
  });

export const getPavilions = createAsyncThunk(
  'unity/getPavilions',
  async(data, thunkAPI) => {
    let { pavilionsAPI } =  getRequest();
    return await pavilionsAPI.getPavilions();
  });

export const getPavilionById = createAsyncThunk(
  'unity/getPavilionById',
  async(id, thunkAPI) => {
    let { pavilionsAPI } =  getRequest();
    let language = thunkAPI.getState().app.appText.language;
    let pav = await pavilionsAPI.getPavilionById({id})
    pav.language = language;
    return pav;
  });

export const getStandsByPavId = createAsyncThunk(
  'unity/getStandsByPavId',
  async(data, thunkAPI) => {
    let { pavilionsAPI } =  getRequest();
    return await pavilionsAPI.getStands(data);
  });

export const getCopmanyStandFull = createAsyncThunk(
  'unity/getCopmanyStandFull',
  async(data, thunkAPI) => {
    let lang = localStorage.getItem("language") || thunkAPI.getState().app.appText.language || "ru";
    let { unityAPI } = getRequest();
    let companyKey = data.companyKey;
    const withoutRegistration = thunkAPI.getState().auth.withoutRegistration;
    // let standElemInfo = data.objectId ? await unityAPI.getStandElemInfo({companyKey, objectId: data.objectId}) : {}
    let standElemInfo = {}
    let company = await unityAPI.getCompany({companyKey, noStatistics: data.noStatistics || false, noHead: withoutRegistration});
    company = {
      ...company,
      name: company.name[lang],
      subName: company.subName[lang],
      subNameShort: company.subNameShort[lang],
      text: company.text[lang],
      prodText: company.prodText[lang],
      serviceText: company.serviceText[lang],
      shortText: company.shortText[lang],
      mediaByTypes: {
        images: company.media.filter(elem => elem.type === "image") || [],
        videos: company.media.filter(elem => elem.type === "video") || [],
        models: company.media.filter(elem => elem.type === "model") || [],
      }
     }
    return {
      standElemInfo,
      company,
    }
  });

  export const updateCopmanyStandFullUsers = createAsyncThunk(
    'unity/updateCopmanyStandFullUsers',
    async(companyId, thunkAPI)=> {
      const userData = thunkAPI.getState().profile.userData;
      const {unityAPI} = getRequest();
      return await unityAPI.getCompanyUsers({companyId, noHead: !userData})
    }
  );

  export const createRequestMeet = createAsyncThunk(
    'unity/createRequestMeet',
    async(body, thunkAPI)=> {
      const {unityAPI} = getRequest();
      console.log(body)
      return await unityAPI.createRequestMeet(body)
    }
  );

  export const toggleFavoritesCompany = createAsyncThunk(
    'unity/toggleFavoritesCompany',
    async(data = {}, thunkAPI)=> {
      let {profileAPI} = getRequest();
      await profileAPI.toggleFavoritesCompany(data);
      return data
    }
  );

const extraReducers = {

  [toggleFavoritesCompany.fulfilled]: (state, {payload}) => {
    if(state.copmanyStandFull.company?.id === payload.id) {
      state.copmanyStandFull.company.isFavorite = !state.copmanyStandFull.company.isFavorite;
    }
  },

  [createRequestMeet.fulfilled]: (state, {payload}) => {
    state.companyIdForRequest = null;
  },

  [updateCopmanyStandFullUsers.fulfilled]: (state, {payload}) => {
    state.copmanyStandFull.company = { ...state.copmanyStandFull.company,  representatives: payload};
  },

  [getCopmanyStandFull.fulfilled]: (state, {payload}) => {
    state.copmanyStandFull.object = { ...state.copmanyStandFull.object, ...payload.standElemInfo };
    state.copmanyStandFull.company = { ...state.copmanyStandFull.company, ...payload.company };
  },

  [getNetworkUser.fulfilled]: (state, {payload}) => {
    state.networkUser = payload;
  },

  [getNetworkUser.rejected]: (state, {payload}) => {
    try {
      window.unityProviderForIFrame.send("GameManager", "AllowCaptureAllKeyboardInput", "true");
    } catch (error) {
      console.log("NOT UNITY")
    }
  },

  [getPavilionById.fulfilled]: (state, {payload}) => {
    const {set} = handlerLocalStorage();
    let langkey = payload.language === "ru" ? "Ru" : payload.language === "en" ? "En" : "By"
    let pav = {
      ...payload,
      shortName: payload[`shortName${langkey}`],
      description: payload[`description${langkey}`],
      name: payload[payload.language]
    };

    state.pavilions.selectedPavilion = pav
    set("pavilionId", pav.id);
    set("nameKey", pav.nameKey);
    set("selectedPavilion", pav.name);
    set("shortName", pav.shortName);
  },

  [getPavilions.fulfilled]: (state, action) => {
    state.pavilions.list = action.payload;
  },

  [getStandsByPavId.fulfilled]: (state, action) => {
    state.pavilions.selectedPavilion.stands = action.payload.companies;
    if(action.payload.companies?.length > 0) {
      state.pavilions.selectedPavilion.selectedStand = action.payload.companies[0].url;
  }

  }
};

export default extraReducers;