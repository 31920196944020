import { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUnityState } from "../store/slices/unitySlice/unitySlice";
import { useSendUnity } from "./useSendUnity";
import { socketUnityUrl } from "../api/generateDomenUrlsApp";

const useWebSocketUnity = () => {

  const dispatch = useDispatch();
  const wsClient = useSelector((state) => state.unity.wsClient);
  const clientRef = useRef(wsClient);
  const sendUnity = useSendUnity();
  let userData = useSelector(state => state.profile.userData);
  let token = useSelector(state => state.auth.token);

  useEffect(() => {
    if (!wsClient && userData?.id && token) {
      const reconnect = () => {
        setTimeout(() => {
          dispatch(setUnityState({ key: "wsClient", value: null }));
        }, 1500);
      };
      clientRef.current = new WebSocket(`${socketUnityUrl}/${userData?.id}/${token}`);

      clientRef.current.onerror = (e) => {
        reconnect();
      };

      clientRef.current.onopen = () => {
        dispatch(setUnityState({ key: "wsClient", value: clientRef.current }));
        console.log("ws opened");
      };

      clientRef.current.onclose = (e) => {
        reconnect();
        console.log("ws close", e);
      };

      clientRef.current.onmessage = (e) => {
        sendUnity("OnTextMessage", e.data);
      };

      function webSocketSendMessage(s) {
        clientRef.current?.readyState !== 3 && clientRef.current?.send(s);
      }

      window.webSocketSendMessage = webSocketSendMessage;
    }
    return () => wsClient && wsClient.close(3001, "close")
  }, [wsClient, userData?.id, token]);

  return clientRef.current;
};

export default useWebSocketUnity;
