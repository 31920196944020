
export const companyStandInfo_en = {
    inFavorite: "To favorites",
    deleteFavorite: "Delete favorites",
    requestMeet: "Request for a meeting",
    pageCompany: "Exhibitor's page",
    desc: "Description",
    representatives: "Representatives",
    notRepresentatives: "No representatives",
    representative: "Representative",
    documents: "Documents",
    notDocuments: "No documents",
    document: "Document",
    showMore: "Show more",
    showLess: "Show less",
}