import { handlerLocalStorage } from "../../../services/handlerLocalStorage.service";
import { initialStateAuth } from "./authSlice";

const {get,remove} = handlerLocalStorage();

const reducers = {
    setAuthState(state, {payload}) {
        return {...state, ...payload}
    },

    resetAuthState(state, action) {
        switch (action.payload) {
            case "registration":
                state.registration = initialStateAuth.registration
                state.error = initialStateAuth.error
                break;
            case "login":
                remove('nickName');
                remove('role');
                remove('selectedPlayer');
                remove('userId');
                remove('token');
                remove('tokenTime');
                remove('pavilionId');
                remove('selectedPavilion');
                remove('nameKey');
                remove('shortName');
                return {...initialStateAuth, token: get('token')};
            default:
                return initialStateAuth;
        }
    },


};

export default reducers;