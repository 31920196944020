import React from "react"
import { useDispatch, useSelector } from "react-redux"
import icons_svg from "../../imgs/icons_svg";
import { WrapContextMenuBtn, ListContextMenu } from "./contextMenuBtn.style";
import ModalBackground from './../ModalBackground/ModalBackground';
import { resetAppChatState, setAppChatState, setOpenDialogState } from "../../appChatSlice/appChatSlice"
import { useNavigate } from 'react-router-dom';
import { useOpenDialogNotFromChat } from "../../../../hooks/useOpenDialogNotFromChat";
import { openDialogById } from "../../../../store/slices/appChatSlice/appChatExtraReducers";



const ContextMenuBtn = ({data: {type, dialog, user}, onClose}) => {

    const {contextMenu, dialogs, mobileVersion, openDialog} = useSelector(state => state.appChat);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { messageIcon, block, clearHistory, trash, search, videoCall, removeUserIcon, plusInCircle } = icons_svg;
    const openDialogNotFromChat = useOpenDialogNotFromChat();

    const hanlderActions = (key, content) => (e) => {
        e.stopPropagation();
        onClose && onClose(e);
        switch (key) {
            case "closeMenu":
                //  dispatch(resetAppChatState("contextMenu"));
                break;
            case "sendMesChat":
            if(openDialog.dialogId === dialog.dialogId) return ''; 
            dispatch(setOpenDialogState({...dialog, messages: null}))
            dispatch(openDialogById({id: dialog.dialogId, navigate: mobileVersion ? false : navigate}));
            dispatch(setAppChatState({key: "dialogs", value: dialogs?.map(elem => 
                elem.dialogId === dialog.dialogId ? {...elem, unread: 0} : elem)})
            );
                break;
            case "blockUserChat":
                console.log(content)
                dispatch(setAppChatState({key: "modal", value: {type: "blockUser", content}}));
                break;
            case "unblockUserChat":
                dispatch(setAppChatState({key: "modal", value: {type: "unblockUser", content}}));
                break;
            case "clearHistoryChat":
                dispatch(setAppChatState({key: "modal", value: {type: "clearHistory"}}))
                break;
            case "removeDialog":
                dispatch(setAppChatState({key: "modal", value: {type: "removeDialog", content}}))
                break;
            case "addToContact":
                dispatch(setAppChatState({ key: "modal", value: {type: "addToContact", content} }))
                break;
            case "removeContact":
                dispatch(setAppChatState({ key: "modal", value: {type: "removeContact", content} }))
                break;
            case "sendMesFromStand":
                openDialogNotFromChat({mobile: true, dialogUser: user})                
                break;
        
            default:
                console.log("call")
                dispatch(setAppChatState({ key: "toast", value: true }))
                break;
        }   
    }

    const buildContextMenu = (key) => {
        let listMenu;
        switch (key || type) {
            case "dialog":
                console.log(dialog)
                
                listMenu = [
                    {icon: messageIcon, text: 'Написать сообщение', onClick: hanlderActions("sendMesChat")},
                    dialog.user?.block ===  0 || dialog.user?.block ===  2 ?
                    {icon: block, text: 'Заблокировать', onClick: hanlderActions("blockUserChat", dialog.user || dialog),}
                    :
                    {icon: block, text: 'Разблокировать', onClick: hanlderActions("unblockUserChat", dialog.user || dialog), },
                    // {icon: clearHistory, text: 'Очистить историю', onClick: hanlderActions("clearHistoryChat")},
                    {icon: trash, text: 'Удалить чат', onClick: hanlderActions("removeDialog", dialog), class: " remove-chat"},
                ];
                return <ListContextMenu>
                    {listMenu.map((item, i) => (
                        <div key={i} className={`list-item${item.class || ""}`} onClick={item.onClick}>
                            {item.icon} {item.text}
                        </div>
                    ))}
                </ListContextMenu>
            case "toolsUser":
                listMenu = [
                    // {icon: search, text: 'Поиск', onClick: hanlderActions()},
                    // {icon: videoCall, text: 'Видео звонок', onClick: hanlderActions()},
                    // {icon: removeUserIcon, text: 'Удалить из контактов', onClick: hanlderActions("removeContact")},
                    dialog.user?.block ===  0 || dialog.user?.block ===  2 ?
                    {icon: block, text: 'Заблокировать', onClick: hanlderActions("blockUserChat", dialog.user || dialog),}
                    :
                    {icon: block, text: 'Разблокировать', onClick: hanlderActions("unblockUserChat", dialog.user || dialog), },
                    // {icon: clearHistory, text: 'Очистить историю', onClick: hanlderActions("clearHistoryChat"),},
                    {icon: trash, text: 'Удалить чат', onClick: hanlderActions("removeDialog", dialog), class: " remove-chat"},
                ];
                return <ListContextMenu>
                    {listMenu.map((item, i) => (
                        <div key={i} className={`list-item${item.class || ""}`} onClick={item.onClick}>
                            {item.icon} {item.text}
                        </div>
                    ))}
                </ListContextMenu>
            case "userCompanyFromStandInfo":
                console.log(user)
                listMenu = [
                    {icon: messageIcon, text: 'Написать сообщение', onClick: hanlderActions("sendMesFromStand")},
                    user.contact ? 
                    {icon: removeUserIcon, text: 'Удалить из контактов', onClick: hanlderActions("removeContact", user)}
                    :
                    {icon: plusInCircle, text: 'Добавить в контакты', onClick: hanlderActions("addToContact", user)},
                    user.block ===  0 || user.block ===  2 ?
                    {icon: block, text: 'Заблокировать', onClick: hanlderActions("blockUserChat", user),}
                    :
                    {icon: block, text: 'Разблокировать', onClick: hanlderActions("unblockUserChat", user), },
                ];
                return <ListContextMenu>
                    {listMenu.map((item, i) => (
                        <div key={i} className={`list-item${item.class || ""}`} onClick={item.onClick}>
                            {item.icon} {item.text}
                        </div>
                    ))}
                </ListContextMenu>
        
            default:
                return null;
        }
    }



    if(!(contextMenu || type)) return null;

    return <>
            <ModalBackground onClose={hanlderActions("closeMenu")}/>
            <WrapContextMenuBtn className="wrap-context-menu-btn">
                {buildContextMenu(contextMenu)}
            </WrapContextMenuBtn>
    </>

}

export default ContextMenuBtn;