import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import { WrapFirstStart } from "./firstStart.style";
import firstWindow from "../../background_app/first-window.json"

const FirstStart = () => {
    
    const [open, setOpen] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            let progressNode = document.querySelector("#Lottie");
            if(progressNode) progressNode.style.opacity = "0";
        }, 2500);
        setTimeout(() => {
            let progressNode = document.querySelector("#wrap-first-start");
            if(progressNode) progressNode.style.opacity = "0";
        }, 3400);
        setTimeout(() => {
            setOpen(false);
        }, 3600);
    }, [])

    if(!open) return null;
    
    
    return (
  <WrapFirstStart id="wrap-first-start">
    <Lottie className="lottie" id="Lottie" animationData={firstWindow} loop={true} />
  </WrapFirstStart>
)};

export default FirstStart;
