import {createSlice} from '@reduxjs/toolkit';
import reducers from './authReducers';
import extraReducers from './authExtraReducers';
import { handlerLocalStorage } from './../../../services/handlerLocalStorage.service';
import { getParamFromUrl } from '../../../utils/helpers';

const {get} = handlerLocalStorage();

export const initialStateAuth = {
  token: getParamFromUrl("t") || get('token'),
  error: "",
  withoutRegistration: false,
  login: {
    email: "",
    password: "",
  },
  registration: {
    success: false,
  }
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialStateAuth,
  reducers,
  extraReducers,
})

const authReducer = authSlice.reducer;

export const {setAuthState, resetAuthState} = authSlice.actions;

export default authReducer;