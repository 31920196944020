import { useDispatch } from 'react-redux';
import { setUnityState } from '../store/slices/unitySlice/unitySlice';

export const useResetIframeUnity = () => {

    const dispatch = useDispatch()
    
    const resetUnity = async () => {
        let unity_iframe = window.frames.unity_iframe;
        console.log(unity_iframe);
        if(!unity_iframe) return;
    
        try {
          await unity_iframe?.hiddenFunctions?.quittingUnity();
        } catch (error) {
            unity_iframe.postMessage(JSON.stringify({ type: "reload" }), "*");
        }

        // const resetProvider = async () => {
        //     dispatch(setUnityState({
        //         provider: null,
        //     }));
        //   };
        
        // window.hiddenFunctions = {
        //     resetProvider,
        // };
    };

    return resetUnity;
}


