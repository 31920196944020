import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Contacts from "./Contacts";
import {
  deleteUserFromContacts,
  getContactsUser,
  makeCompanyOwner,
} from "../../store/slices/profileSlice/profileExtraReducers";
import { setProfileTableState } from "../../store/slices/profileSlice/profileSlice";
import { setAppState } from "../../store/slices/appSlice/appSlice";
import { svg_icons } from "../../assets/imgs/svg_icons";
import { setAppChatState } from "../../components/AppChat/appChatSlice/appChatSlice";
import { setOpenDialogState } from "../../store/slices/appChatSlice/appChatSlice";
import { useNavigate } from "react-router-dom";
import { useOpenDialogNotFromChat } from "../../hooks/useOpenDialogNotFromChat";

const ContactsContainer = () => {

  let { table, userData } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const { blockIcon, messageIcon, trashIcon, userIcon } = svg_icons;
  const navigate = useNavigate();
  const openDialogNotFromChat = useOpenDialogNotFromChat();

  const handlerActions = (type, data) => async (e) => {
    switch (type) {
      case "changeUserData":
        break;
      case "toggleFilterBtn":
        dispatch(
          setProfileTableState({ filter: { ...table.filter, btn: data } })
        );
        break;
      case "contextMenu":
        dispatch(
          setAppState({
            contextMenu: {
              currentTarget: e.currentTarget,
              listMenu: [
                (data.role === "ROLE_USER" ||
                (data.role === "ROLE_REPRESENTATIVE" && data.companyId === userData?.companyId)) ? {
                  onClick: () => {
                      dispatch(
                        makeCompanyOwner({
                          post: data.role === "ROLE_USER",
                          contactId: data.id,
                          updateContacts: true, 
                        })
                      );
                  },
                  icon: userIcon,
                  text: data.role === "ROLE_USER" ? "Сделать представителем" : "Удалить из представителей",
                } : "",
                {
                  onClick: () => {
                    openDialogNotFromChat({dialogUser: data})
                  },
                  icon: messageIcon,
                  text: "Написать сообщение",
                },
                // {
                //   onClick: () => {
                //     console.log(data);
                //   },
                //   icon: "",
                //   text: "Заявка на встречу",
                // },
                {
                  onClick: () =>
                    dispatch(
                      setAppChatState({
                        key: "modal",
                        value: {
                          type:
                            data.block === 0 || data.block === 2
                              ? "blockUserFromContacts"
                              : "unblockUserFromContacts",
                          content: data,
                        },
                      })
                    ),
                  icon:
                    data.block === 0 || data.block === 2
                      ? blockIcon
                      : blockIcon,
                  text:
                    data.block === 0 || data.block === 2
                      ? "Заблокировать "
                      : "Разблокировать",
                },
                {
                  onClick: () => {
                    dispatch(
                      setAppChatState({
                        key: "modal",
                        value: {
                          type: "removeContactFromContacts",
                          content: data,
                        },
                      })
                    );
                  },
                  icon: trashIcon,
                  text: "Убрать из контактов",
                  class: " error",
                },
              ],
            },
          })
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(getContactsUser(table.filter));
  }, [dispatch, table.filter]);

  // console.log(table.data)

  return <Contacts handlerActions={handlerActions} table={table} />;
};

export default ContactsContainer;
