import { initialStateUnity } from "./unitySlice";

const reducers = {
    setUnityState(state, action) {
        let payload = action.payload;
        if(payload?.key) {
            state[payload?.key] = payload.value;
        } else {
            return {...state, ...payload}
        }
    },
    setPavilionsState(state, {payload}) {
            state.pavilions = {...state.pavilions, ...payload}
    },

    setCopmanyStandFull(state, {payload}) {
        console.log(payload)
        if(payload?.key) {
            state.copmanyStandFull[payload.key] = {...state.copmanyStandFull[payload.key] , ...payload.value};
        } else {
            state.copmanyStandFull = {...state.copmanyStandFull, ...payload}
        }
        
    },
    
    setSelectedPavState(state, {payload}) {
        state.pavilions.selectedPavilion = {...state.pavilions.selectedPavilion, ...payload}
    },
    
    setManageStandState(state, action) {
        state.manageStand = {...state.manageStand, ...action.payload}
    },

    resetUnityState(state, {payload}) {
        switch (payload) {
            case "loader":
                state.progress = 0;
                state.isProgress = false;
                state.downloadBuild = false;
                break;
            case "networkUser":
                state.networkUser = null;
                break;
            case "selectedPavilion":
                state.pavilions.selectedPavilion = {
                    id: null,
                    shortName: null,
                  }
                break;
            case "copmanyStandFull":
                state.copmanyStandFull = initialStateUnity.copmanyStandFull;
                break;
        
            default:
                return initialStateUnity
        }
    }
    

};

export default reducers;