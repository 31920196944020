import styled, { css } from "styled-components";

const WrapCompanyStandInfo = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 372px;
  background: #ffffff;
  padding: 16px;
  padding-top: 42px;
  overflow: auto;
  overflow-x: hidden;
  z-index: 5;

  .head-block {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #363636;
  }

  .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    background: #f5f5f5;
    border-radius: 8.66667px;
    border: none;
    cursor: pointer;
  }

  .company-head {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      max-width: 80px;
      max-height: 50px;
    }

    h2 {
      font-weight: 700;
      font-size: 15px;
      line-height: 17px;
      color: #363636;
      text-align: center;
      padding: 22px 0 24px;
    }
  }

  .company-btns {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 28px;
    flex-wrap: wrap;
    button {
      flex: 1;
      border: none;
      height: 36px;
      border-radius: 8px;
      cursor: pointer;
      font-weight: 500;
      font-size: 13px;
      line-height: 140%;
    }
    .company__page {
      height: 60px;
      background: #F5F5F5;
      border-radius: 10px;
      flex: 100%;
      padding: 12px;
      button {
        width: 100%;
        height: 36px;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 10px;
        cursor: initial;
        a {
          color: #34C57F;
          text-decoration-line: underline;
          cursor: pointer;
        }
      }

    }
    .company__favorite {
      border: 1px solid #34C57F;    
      background: #ffffff;
      color: #34c57f; 
    }
    .company__request {
      background: #34c57f;
      color: #ffffff;
    }
  }

  .wrap-app-tabs {
    gap: 2px;
    padding-bottom: 19px;
    .app-tabs__tab {
      border-radius: initial;
      width: 112px;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
    }
  }

  .company-desc {
    p {
      position: relative;
      font-size: 13px;
      line-height: 140%;
      color: #363636;
      margin: 4px 0 13px;

      max-height: 150px;
      display: -webkit-box;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
      overflow: hidden;

      ${({moreInfo}) => moreInfo && css`
        max-height: initial;
        -webkit-line-clamp: initial;
      `}

      //maybe
      // &:after {
      //   content: "..."
      //   position: absolute;
      //   right: 0px;
      //   bottom: 0px;
      // }
    }

    .desc__full {
      width: 146px;
      height: 30px;
      padding: 6px 12px;
      background: #f4f4f4;
      border-radius: 8px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        color: #363636;
        cursor: pointer;
      }
      svg {
        ${({ moreInfo }) =>
          `transform: translateY(1px) rotate(${moreInfo ? "180" : "0"}deg);`}
      }
    }
  }
`;

const CompanyList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .wrap-users {
    flex: 1;
    position: relative;
    border: ${({oneUser}) => oneUser ? "none" :  "0.5px solid #d4d4d4"};
    border-radius: 4px;
    margin-top: 5px;
    min-height: 66px;

    .scroll-users {
      display: flex;
      flex-direction: column;
      gap: 12px;
      position: absolute;
      top: 0;
      left: 0px;
      right: 0px;
      bottom: 0px;
      overflow: auto;
      overflow-x: hidden;
      padding: ${({oneUser}) => oneUser ? "initial" : "16px"};

      ${({moreInfo}) => moreInfo && css`
        position: relative;
        max-height: 255px;
      `}
    }

    .item {
      display: flex;
      align-items: center;
      height: 66px;
      background: #f5f5f5;
      border-radius: 4px;
      padding: 12px;

      .item-preview-img {
        img {
          width: 42px;
          height: 42px;
          border-radius: 50%;
        }
      }

      .item-info {
        display: flex;
        flex-direction: column;
        padding-left: 12px;

        span:first-child {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #363636;
        }
        span:last-child {
          font-weight: 500;
          font-size: 11px;
          line-height: 13px;
          text-transform: capitalize;
          color: #484848;
          opacity: 0.7;
        }
      }

      .item__context-menu {
        position: relative;
        width: 24px;
        min-width: 24px;
        height: 24px;
        background: #ebebeb;
        border-radius: 8px;
        border: none;
        margin-left: auto;
        cursor: pointer;
        & > svg  {
          margin: auto;
          display: block;
        }
      }
    }
  }
`;

export { WrapCompanyStandInfo, CompanyList };
