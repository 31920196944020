import React, {useCallback, useEffect, useState} from 'react';
import {Bar} from 'react-chartjs-2';
import {getRequest} from '../../../../api/appService';
import { useHttp } from '../../../../hooks/useHttp';
import s from './VisitChart.module.css';

const canvasStyle = {
    position: 'static',
    margin: '0 40px'
}

let VisitChart = ({localState, standId, setIsDownloadFile, setVisitsData, setDate, selectedStand, selectedIndicator}) => {
    const [chartData, setChartData] = useState({});
    const [visits, setVisits] = useState(null);
    const [clicks, setClicks] = useState(null);
    const [timestamp, setTimestamp] = useState(null);
    const [loadChar, setLoadChar] = useState(false);
    let { initRequest } = useHttp();
    let { getNumberFilesDownload, getUniqueVisitors, getVisitData } =  getRequest(initRequest);

    let userId = localStorage.getItem('userId');

    const buildChart = (data) => {
        console.log('data1', data)
        let visits = []
        let clicks = [];
        let timestamp = [];

        if (data.visitsAndClicks) {
            data.visitsAndClicks.forEach((key) => {
                if(selectedIndicator.id === 'numberFileDownloaded') {
                    visits.push(key.downloaded);
                } else {
                    visits.push(key.visits);
                }
                clicks.push(key.clicks);
                timestamp.push(new Date(key.timestamp).toLocaleDateString());
            });
        } else {
            data.forEach((key) => {
                if(selectedIndicator.id === 'numberFileDownloaded') {
                    visits.push(key.downloaded);
                } else {
                    visits.push(key.visits);
                }
                clicks.push(key.clicks);
                timestamp.push(new Date(key.timestamp).toLocaleDateString());
            });
        }

        if (selectedStand && selectedStand.id === standId) {
            setDate({
                modifiedTime: new Date(data.modifiedTime).toLocaleDateString(),
                registrationDate: new Date(data.registrationDate).toLocaleDateString()
            })
        }

        if (data.length === 0) {
            setIsDownloadFile(false);
        } else {
            setVisitsData(data)
            setIsDownloadFile(true);
        }

        setVisits(visits);
        setClicks(clicks);
        setTimestamp(timestamp);
    }

    const chart = useCallback(async () => {
        // console.log(selectedIndicator.id )
        if(selectedIndicator.id === 'numberVisits') {
            await getVisitData(localState, standId).then((data) => {
                buildChart(data);
            });
        } else if(selectedIndicator.id === 'uniqueVisitors') {
            await getUniqueVisitors(localState).then(data => {
                buildChart(data);
            })
        } else if(selectedIndicator.id === 'numberFileDownloaded') {
            await getNumberFilesDownload(localState).then(data => {
                buildChart(data);
            })
        }

    }, [selectedStand, setDate, setIsDownloadFile, setVisitsData, standId, localState, userId, selectedIndicator]);

    const updateChar = async () => {
        setLoadChar(true);
        await chart().then();
        setLoadChar(false);
    }

    useEffect(() => {
        if (localState) {
            chart().then();
        }
    }, [localState, standId, chart, selectedIndicator]);


    useEffect(() => {
        if (visits && clicks && timestamp) {
            
            setChartData({
                labels: timestamp,
                datasets: [{
                    label: selectedIndicator.text,
                    data: visits,
                    backgroundColor: [
                        'rgba(93, 176, 117, 0.6)'
                    ],
                    borderWidth: 1
                }, 
                // {
                //     label: text.labelClicks,
                //     data: clicks,
                //     backgroundColor: [
                //         'rgba(203, 159, 237, 0.6)'
                //     ],
                //     borderWidth: 1
                // }
            ]
            })
        }
    }, [timestamp, clicks, visits, selectedIndicator]);


    return (
        <div className={s.wrapVisitChar}>
            <span onClick={updateChar}
                className={`material-icons ${s.wrapVisitChar_update} ${loadChar ? s.rotate : ''}`}>cached</span>
            {visits && clicks && timestamp && chartData.datasets &&
            <Bar data={chartData} type={'bar'} style={canvasStyle}/>}
        </div>
    );
}

export default VisitChart;
