import React from "react";

import { WrapNavigate, WrapNavMainBtns, WrapLoginBtns, WrapNavWithUser } from "./navMainBtns.style";
import { icons_svg_main_nav } from "./icons_svg_main_nav";
import avatarDefault from "./avatarDefault.svg";

import AppChatContainer from "./../AppChat/AppChatContainer";

import CompanyStandInfoContainer from "../StandContent/CompanyStandInfo/CompanyStandInfoContainer";

import icons_svg from "../AppChat/imgs/icons_svg";
import { filesUrl } from "../../api/generateDomenUrlsApp";
import { svg_icons } from './../../assets/imgs/svg_icons';

function NavMainBtns({
  countMes,
  mobileVersion,
  userData,
  handlerActions,
  openInfoStand,
  language,
  chooseStand,
  shortName,
  stands,
  selectedStand,
  isScene,
  withoutRegistration,
  networkUser,
  openSettings,
  selectedSet
}) {
  const {messageIcon, settingsIcon, closeIcon, standsIcon, searchIcon, addUser, removeUserIcon} = svg_icons;

  const settingsGraph = [
    {value: 0, text: "Низкое качество"},
    {value: 1, text: "Допустимое качество"},
    {value: 2, text: "Среднее качество"},
    {value: 3, text: "Высокое качество"},
    {value: 5, text: "Наилучшее качество"},
  ]

  return (
    <WrapNavigate>
      {(mobileVersion || openSettings || networkUser || chooseStand || openInfoStand) && <div className="nav-background-close" onClick={handlerActions("closeNavWindow")}/>}
      {isScene && withoutRegistration ? <WrapLoginBtns>
        <h3>Войдите в CleVR и используйте все функции</h3>
        <div>
        <button className="btns__registration" onClick={handlerActions("registration")}>Регистрация</button>
        <button className="btns__login" onClick={handlerActions("login")}>Вход</button>
        </div>

      </WrapLoginBtns> : ""}

      {networkUser ? (
        <WrapNavWithUser
        >
          <button className="close__btn" onClick={handlerActions("closeNetworkUser")}>{closeIcon}</button>
          <img src={filesUrl + networkUser.avatar} alt="avatar" />
          <div className="nav-user-info">
            <h3>{networkUser.firstName} {networkUser.lastName}</h3>
            <p>{networkUser.position}</p>
          </div>
          <div  className="nav-user-btns">
            <button onClick={handlerActions("mesNetworkUser", networkUser)}>{messageIcon}</button>
            <button onClick={handlerActions("toogleNetworkUser", networkUser)}>{networkUser.contact ? removeUserIcon : addUser}</button>
          </div>

        </WrapNavWithUser>
      ) : (
        ""
      )}
      {isScene ? 
        <WrapNavMainBtns 
          settingsStyles={withoutRegistration} 
          className={`nav-settings${openSettings ? " settings_open" : ""}`} 
          onClick={handlerActions("settings")}>
        {!openSettings ? settingsIcon : ""}
        {!openSettings ? (
          <div
            className="settings__click"
            onClick={handlerActions("openSettings")}
          />
        ) : (
          ""
        )}
        {/* {chooseStand} */}
        {openSettings ? (
          <>
            <div className="settings-head">
              {settingsIcon}
              <h2>Настройки изображения</h2>
              <button
                className="head__close"
                onClick={handlerActions("openSettings")}
              >
                {closeIcon}
              </button>
            </div>
            <p className="settings__subhead">
              Используйте настройки отображения выставки чтобы ускорить процесс загрузки материалов
            </p>
            <div className="settings-list">
            {settingsGraph?.map((set) => (
                  <button
                    className={selectedSet?.value === set.value ? "selected" : ""}
                    key={set.value}
                    onClick={handlerActions("selectSet", set)}
                  >
                    {set.text}
                  </button>
                ))}
            </div>
            <div className="settings-btns">
              <button onClick={handlerActions("openSettings")}>
                Закрыть
              </button>
              <button
                disabled={!selectedSet?.text}
                onClick={handlerActions("setGraph")}
              >
                Применить
              </button>
            </div>
          </>
        ) : (
          ""
        )}
      </WrapNavMainBtns> : ""}
      {isScene ? <WrapNavMainBtns
        className={`nav-stands${chooseStand ? " stands_open" : ""}`}
      >
        {!chooseStand ? standsIcon : ""}
        {!chooseStand ? (
          <div
            className="stands__click"
            onClick={handlerActions("chooseStand")}
          />
        ) : (
          ""
        )}
        {/* {chooseStand} */}
        {chooseStand ? (
          <>
            <div className="stands-head">
              {standsIcon}
              <h2>Стенды</h2>
              <button
                className="head__close"
                onClick={handlerActions("closeChooseStand")}
              >
                {closeIcon}
              </button>
            </div>
            <p className="stands__subhead">
              Выберите стенд предприятия чтобы ускорить ваши перемещения по
              павильону выставки
            </p>
            <div className="stands-search">
              {searchIcon}
              <input
                type="text"
                name="search-stand"
                placeholder="Начните вводить"
                onChange={handlerActions("searchStands")}
              />
            </div>
            <div className="stands-result-search">
              <div className="result-scroll">
                {stands?.map((stand) => (
                  <button
                    className={selectedStand?.id === stand.id ? "selected" : ""}
                    key={stand.id}
                    onClick={handlerActions("selectStand", stand)}
                  >
                    {stand.name[language]}
                  </button>
                ))}
              </div>
            </div>
            <div className="stands-btns">
              <button onClick={handlerActions("closeChooseStand")}>
                Закрыть
              </button>
              <button
                disabled={!selectedStand?.id}
                onClick={handlerActions("goToStand")}
              >
                Перейти на стенд
              </button>
            </div>
          </>
        ) : (
          ""
        )}
      </WrapNavMainBtns> : ""}

      {userData ?<WrapNavMainBtns
        className="nav-profile"
        onClick={handlerActions("openProfile")}
      >
        <img
          src={
            userData?.avatar ? `${filesUrl}${userData.avatar}` : avatarDefault
          }
          alt="user avatar"
        />
      </WrapNavMainBtns> : ""}
      {shortName ? (
        <WrapNavMainBtns
          className="nav-pavilions"
          onClick={handlerActions("choosePavilion")}
        >
          <p>
            Павильон:
            <span> {shortName}</span>
          </p>
        </WrapNavMainBtns>
      ) : (
        ""
      )}
      <>
        {userData && !mobileVersion ? <WrapNavMainBtns
          className="nav-chat"
          onClick={handlerActions("openChat")}
        >
          <div>
            {messageIcon}
            {countMes ? <span>{countMes}</span> : null}
          </div>
        </WrapNavMainBtns> : ""}
        {mobileVersion && <AppChatContainer />}
      </>

      {openInfoStand && <CompanyStandInfoContainer />}
    </WrapNavigate>
  );
}



export default NavMainBtns;
