import React from "react";
import s from './MainLogoPage.module.css';

const MainLogoPage = ({localText, logo}) => {

    return (
        <div className={s.containerLogo}>
            <p>{localText}</p>
            <div className={s.logo}>
                {logo}
            </div>
        </div>
    )
}

export default MainLogoPage;
