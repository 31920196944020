import React from 'react';
import folder_icon from './img/folder.png';
import loadingSVG from './img/spinner.svg';
import { filesUrl, serverUrl } from '../../../../api/generateDomenUrlsApp';

let clickTime;

const borderNone = {
    borderRight: 'none',
    textAlign: 'center',
};

const centerAlign = {
    textAlign: 'center',
    paddingLeft: '0',
    paddingRight: '0'
};

const TableBody = ({documentData, showContextMenu, localText,
                      currentPage, load, s, widthTbody, getDocumentData, directoryFolder,
                      openSelect, checkboxesSelect, setCheckboxesSelect, openContextMenu}) => {

    let options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
    };
    // let load = {}

    let sizeInformation = {
        'kilobyte': 2 ** 10,
        'megabyte': 2 ** 20,
        'gigabyte': 2 ** 30,
    }
    // useEffect(() => {
    //     cacheImg(fileIcons)
    // }, [centerAlign])
    
    if (!documentData) return '';

    const calcSize = (sizeFile) => {
        if (sizeFile >= sizeInformation.kilobyte && sizeFile < sizeInformation.megabyte) {
            return `${(sizeFile / sizeInformation.kilobyte).toFixed(1)} ${localText.kilobyte}`;
        } else if (sizeFile >= sizeInformation.megabyte && sizeFile < sizeInformation.gigabyte) {
            return `${(sizeFile / sizeInformation.megabyte).toFixed(1)} ${localText.megabyte}`;
        } else if (sizeFile >= sizeInformation.gigabyte) {
            return `${(sizeFile / sizeInformation.gigabyte).toFixed(1)} ${localText.gigabyte}`;
        } else {
            return `${(+sizeFile).toFixed(1)} ${localText.byte}`
        }
    }

    const calcClickTime = (e, timeMouseUp, type) => {
        let timeMouseDown = (timeMouseUp - clickTime) / 1000
        if (timeMouseDown > 1) {
            showContextMenu(e, type)
        }
    }

    const showImg = (e, item) => {
        if(e.target.src === `${serverUrl}/files/download?fileId=${item.fileId}&token=${localStorage.getItem('token')}`) return
        if((item.fileType === 'transparent_image' || item.fileType === 'image') && (item.name.includes('.jpg') || item.name.includes('.png')))  {
            e.target.src = `${serverUrl}/files/download?fileId=${item.fileId}&token=${localStorage.getItem('token')}`;
        }
    }

    const openFolder = (e, folderName, folderId) => {
        if(e.target.className === s.colSelect || e.target.className ===  s.colCheckbox) return '';
        directoryFolder.current.push({name: folderName, id: folderId})
        getDocumentData();
    }

    const selectCheckbox = (e = {}, checkboxId) => setCheckboxesSelect({type: 'selectCheckbox', id: checkboxId, value: e.target?.checked});

    const specifyCheckedId = (id) => {
        for(let checkbox of checkboxesSelect){
            if(+checkbox.id === +id) {
                return checkbox.checked;
            }
        }
    }

    const downloadFile = (e, fileId) => {
        if(e.target.className === s.colSelect || e.target.className ===  s.colCheckbox) return '';
        let link_url = document.createElement("a");
        let url = `${serverUrl}/files/download?fileId=${fileId}&token=${localStorage.getItem('token')}`
        link_url.target= '_blank';
        link_url.download = url.substring((url.lastIndexOf("/") + 1), url.length);
        link_url.href = url;
        document.body.appendChild(link_url);
        link_url.click();
        document.body.removeChild(link_url);
    }
    // setCheckboxChecked(item.fileId)
    let startNumber = currentPage * 25 - 24;

    return (
        <tbody ref={widthTbody}>
        {documentData.foldersAndFiles.map((item, i) => (
            item.type === 'FILE' ? <tr
                    onMouseDown={() => clickTime = Date.now()}
                    onMouseUp={(e) => calcClickTime(e, Date.now(), item)}
                    onContextMenu={(e) => showContextMenu(e, item)}
                    onClick={(e) => !openContextMenu && downloadFile(e, item.fileId)}
                    key={item.fileId} id={item.fileId}
                >
                    <td className={`${s.colNumber}`} style={centerAlign}>{startNumber++}</td>
                    {openSelect &&<td className={`${s.colSelect}`} style={centerAlign}>
                        <input className={`${s.colCheckbox}`} type='checkbox' checked={specifyCheckedId(item.fileId)} onChange={(e) => selectCheckbox(e, item.fileId)} />
                    </td>}
                    <td className={`${s.colFileIcon}`} id='add' style={borderNone}><img onLoad={(e) => {showImg(e, item)}} src={`${filesUrl}${item.icon}`} alt={'file'}/></td>
                    <td className={`${s.colFileName}`} >{item.name}</td>
                    <td className={`${s.colModifiedTime}`} style={centerAlign}>{new Date(item.modifiedTime).toLocaleString('ru', options)}</td>
                    <td className={`${s.colSize}`} >{calcSize(item.size)}</td>
                    <td className={`${s.colType}`} style={borderNone}>{item.extension}</td>
                </tr> :
                <tr
                    onMouseDown={() => clickTime = Date.now()}
                    onMouseUp={(e) => calcClickTime(e, Date.now(), item)}
                    onContextMenu={(e) => showContextMenu(e, item)}
                    onClick={(e) => !openContextMenu && openFolder(e, item.name, item.folderId)}
                    key={item.folderId} id={item.folderId}
                >
                    <td className={`${s.colNumber}`} style={centerAlign}>{startNumber++}</td>
                    {openSelect && <td className={`${s.colSelect}`} style={centerAlign}>
                        <input className={`${s.colCheckbox}`} type='checkbox' checked={specifyCheckedId(item.folderId)} onChange={(e) => selectCheckbox(e, item.folderId)}/>
                    </td>}
                    <td className={`${s.colFileIcon}`} style={borderNone}><img src={folder_icon} alt={'folder'}/></td>
                    <td className={`${s.colFileName}`}>{item.name}</td>
                    <td className={`${s.colModifiedTime}`} style={centerAlign}>{new Date(item.modifiedTime).toLocaleString('ru', options)}</td>
                    <td className={`${s.colSize}`} >{calcSize(item.size)}</td>
                    <td className={`${s.colType}`} style={borderNone}>{localText.typeFolder}</td>
                </tr>

        ))}
        {load && <tr className={s.spinnerContainer}>
            <td><img src={loadingSVG} alt={'loading'}/></td>
        </tr>}
        </tbody>
    );
};

export default TableBody;


