import React, { useEffect, useState } from "react";
import { WrapManageStand } from "./manageStand.style";
import { filesUrl } from "../../api/generateDomenUrlsApp";
import { svg_icons } from "../../assets/imgs/svg_icons";
import avatarDefault from "../../components/MainPage/avatarDefaultCircle.svg";
import { herosModels } from "./../../assets/heros/herosModels";

const ManageStand = ({
  handlerActions,
  userData,
  selectStandModel,
  selectStandMedia,
  openMenu,
  sizeModels,
  modelImageData,
  isSceneStand,
  mediaCompany,
  typeMedia,
  companyNameKey,
  language
}) => {
  const { arrowBack, closeIcon, settingsIcon } = svg_icons;

  console.log(mediaCompany)

  const sizesModels = ["8x8", "8x16", "16x16", "Super"];

  return (
    <WrapManageStand openMenu={openMenu}>
      <div className="stand-current">
        <div className="stand-back">
          <button onClick={handlerActions("goBack")}>{arrowBack}</button>
          <h2>{"Конструктор стенда"}</h2>
        </div>
        <div className="profile-head">
          <img
            src={
              userData?.avatar ? `${filesUrl}${userData.avatar}` : avatarDefault
            }
            alt="user avatar"
          />
          <h2>
            {userData?.firstName} {userData?.lastName}
          </h2>
          <p>{userData?.position}</p>
        </div>
        <h3 className="hero_company-name">{userData?.company.name[language]}</h3>

        <div className="stand-current-view">
          <div className="view-head">
            <h3>{"Текущий стенд"}</h3>
            <button onClick={handlerActions("openSelectModel")}>
              {settingsIcon}
            </button>
          </div>
          {/* <img src={userData?.heroModel?.preview} alt="preview stand" /> */}
        </div>
      </div>

      {isSceneStand ? "" : <div className="stand__load"></div>}

      <div className="stand-select">
        <div className="stand-select-scroll">
          <div className="select-main-head">
            <h2>{"Стенды"}</h2>
            <button onClick={handlerActions("closeSelectModel")}>
              {closeIcon}
            </button>
          </div>
          <div className="stand-sizes">
            <h3 className="sizes__head">{"Размеры стенда"}</h3>
            {sizesModels.map((size) => (
              <div
                key={size}
                className={`size__${size} ${
                  size === sizeModels ? "active" : ""
                }`}
                onClick={handlerActions("changeSizeModels", size)}
              >
                {size}
              </div>
            ))}
          </div>

          <h3 className="select__head">{"Выбор стенда"}</h3>
          <p className="select__subhead">
            {
              "Обратите внимание, что стенды отличаются в зависимости от выбранной вами площади"
            }
          </p>

          {modelImageData?.map((img) => (
            <div
              key={img.name}
              className="model-preview"
              onClick={handlerActions("selectModel", img)}
            >
              <img src={img.url} alt="preview stand" />
              {selectStandModel?.name === img.name ? <p>{"Выбрано"}</p> : ""}
            </div>
          ))}
        </div>
        <div className="select-btns">
          <button
            className="btns__cancel"
            onClick={handlerActions("closeSelectModel")}
          >
            {"Отменить"}
          </button>
          <button
            className="btns__save"
            onClick={handlerActions("saveModel")}
            disabled={!selectStandModel}
          >
            {"Сохранить"}
          </button>
        </div>
      </div>

      <div className="stand-banner-media">
        <div className="banner-media-scroll">
          <div className="banner-media-head">
            <h2>{"Обложка"}</h2>
            <button onClick={handlerActions("closeSelectMedia")}>
              {closeIcon}
            </button>
          </div>
          <div className="media-types">
            <h3 className="types__head">{"Объекты для размещения"}</h3>
            <p className="types__subhead">
              {"Выбирите объект для размещения в данном элементе"}
            </p>
            <div className="toggle-types-btns">
              <button
                className={`toggle-types-btns__media ${
                  typeMedia === "media" ? "active" : ""
                }`}
                onClick={handlerActions("changeTypeMedia", "media")}
              >
                {`Галерея`}
              </button>
              <button
                className={`toggle-types-btns__3d ${
                  typeMedia === "_3d" ? "active" : ""
                }`}
                onClick={handlerActions("changeTypeMedia", "_3d")}
              >
                {`3D-объект`}
              </button>
            </div>
          </div>

          {mediaCompany[typeMedia === "media" ? "media" : "_3d"]?.map(
            (elem) => (
              <div
                key={elem.id}
                className="media-preview"
                onClick={handlerActions("selectMedia", elem)}
              >
                <img
                  src={
                    typeMedia === "media"
                      ? elem.type === "video" ? elem.tumb : filesUrl + elem.src
                      : `${filesUrl}/files/3dModels/${companyNameKey}/${elem.link}/0_0.jpg`
                  }
                  alt="preview stand"
                />
                {selectStandMedia?.id === elem.id ? <p>{"Выбрано"}</p> : ""}
              </div>
            )
          )}
        </div>
        <div className="media-btns">
          <button
            className="btns__cancel"
            onClick={handlerActions("closeSelectMedia")}
          >
            {"Отменить"}
          </button>
          <button
            className="btns__save"
            onClick={handlerActions("saveMedia")}
            disabled={!selectStandMedia}
          >
            {"Сохранить"}
          </button>
        </div>
      </div>
    </WrapManageStand>
  );
};

export default ManageStand;
