import Slider from "react-slick";
import { VideoSliderWrap } from "./VideoSlider.style";
import arrowRight from "../imgs/arrow-right.png";
import arrowLeft from "../imgs/arrow-left.png";
import ReactPlayer from "react-player/lazy";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"

export const VideoSlider = ({videos, mediaId}) => {

  if(!videos) return null;

  const settings = {
    initialSlide: videos.findIndex(vid => +vid.id === +mediaId),
  };

  return (
    <VideoSliderWrap arrR={arrowRight} arrL={arrowLeft}>
      <Slider {...settings}>
        {[...videos, ...videos].map((video, index) => {
          return (
            <div className="slider" key={index}>
              <div className="material">
                <ReactPlayer
                  url={video.src}
                  className="material__video"
                  alt="material_img"
                  controls={true}
                ></ReactPlayer>
              </div>
            </div>
          );
        })}
      </Slider>
    </VideoSliderWrap>
  );
};
