export let valid = {
    name: true,
    surname: true,
    patronymic: true,
    nickname: true,
    email: true,
    password: true,
    passwordEqual: true,
    userAgreement: true,
}

const allRegExp = {
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=\w+$).{8,32}/,
    name: /[A-Za-zА-Яа-яёа-зй-шы-яШЫІіЎў']{2,20}/,
    surname: /[A-Za-zА-Яа-яёа-зй-шы-яШЫІіЎў'-]{2,32}/,
    nickname: /[a-zA-ZА-Яа-яёа-зй-шы-яШЫІіЎў0-9_]{3,20}/,
    position: /^.{0,70}$/,
}


function testMatchRegExp(regExp, string) {
    return regExp.test(string) && string.match(regExp)[0] === string;
}


const validateInput = (input, value) => {
    switch (input) {
        case 'email': {
            return testMatchRegExp(allRegExp.email, String(value).toLowerCase().trim());
        }

        case 'password':
        case 'passwordEqual': {
            return testMatchRegExp(allRegExp.password, String(value).toLowerCase().trim());
        }

        case 'firstName': {
            return testMatchRegExp(allRegExp.name, String(value).toLowerCase().trim());
        }

        case 'nickname': {
            return testMatchRegExp(allRegExp.nickname, String(value).toLowerCase().trim());
        }

        case 'patronymic': {
            return String(value).toLowerCase().trim() === ''.trim() ? 
                true : testMatchRegExp(allRegExp.name, String(value).toLowerCase().trim());
        }

        case 'lastName': {
            return testMatchRegExp(allRegExp.surname, String(value).toLowerCase().trim());
        }

        case 'userAgreement': {
            return value;
        }
        case 'position': {
            return testMatchRegExp(allRegExp.position, String(value).toLowerCase().trim());
        }

        default :
            return true;
    }
}


export function validateField(input, value) {
    // console.log(input, valid[input])
    // if (input === 'passwordEqual' &&  input !== ) {
    //
    // }
    valid[input] = validateInput(input, value)
    return valid[input];
}

export function resetError() {
    for (let key in valid) {
        valid[key] = true;
    }
}