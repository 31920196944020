
export const registration_en = {
    'login': 'Login',
    'registration': 'Sign Up',
    'registerButton': 'Register now',
    'enterEmail': 'Enter Email to generate nickname',
    'registerSuccess': 'Registration was successful, a letter was sent to the specified mail.',

    'data': {
        'name': 'Your name',
        'surname': 'Your surname',
        'patronymic': 'Your patronymic',
        'nickname': 'Your displaying name',
        'email': 'E-mail',
        'password': 'Password',
        'passwordEqual': 'Repeat password',
    },
}