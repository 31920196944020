import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetAppChatState } from "../appChatSlice/appChatSlice";
import icons_svg from "../imgs/icons_svg";
import { WrapNewFileContent } from "./modalsChat.style";
import { getInfoFile } from "../../../utils/helpers";

export default function SendFile({ hanlderActions }) {

  const { sendFile } = useSelector((state) => state.appChat.openDialog);
  let { trash, closeModal, imgIcon, videoIcon, pdfIcon } = icons_svg;

  let file = {
    value: sendFile,
    desc: getInfoFile(sendFile),
  };

  const getElemByType = (type) => {
    console.log(type);
    let result = { img: null, icon: null };
    if (type.includes("image")) {
      result.img = (
        <>
          <div className="icon-elem">{imgIcon}</div>
          <img src={URL.createObjectURL(file.value)} alt="file" />
        </>
      );
    } else if (type.includes("video")) {
      result.img = (
        <>
          <div className="icon-elem">{videoIcon}</div>
          <video>
            <source src={URL.createObjectURL(file.value)} type="video/mp4" />
          </video>
        </>
      );
    } else if (type.includes("pdf")) {
      result.icon = pdfIcon;
    } else {

    }

    return result;
  };

  let preview = getElemByType(file.desc.type);

  return (
    <WrapNewFileContent>
      <button
        className="file__close-btn"
        onClick={hanlderActions("closeSendFile")}
      >
        {closeModal}
      </button>
      <h3>Прикрепить файл</h3>
      <div className="content__selected-file">
        {preview.img && <div className="file__preview">{preview.img}</div>}
        <div className="file-desc">
          {preview.icon && (
            <div className="file__preview icon">{preview.icon}</div>
          )}
          <p>
            <span className="desc__name">{file.desc.name}</span>
            <span className="desc__size">{file.desc.size}</span>
          </p>
          <button
            className="file__remove-btn"
            onClick={hanlderActions("closeSendFile")}
          >
            {trash}
          </button>
        </div>
      </div>
      <div className="base-btns">
        <button onClick={hanlderActions("closeSendFile")}>Отменить</button>
        <button onClick={hanlderActions("sendFile", sendFile)}>Отправить</button>
      </div>
    </WrapNewFileContent>
  );
}
