import React, {useState, useEffect} from 'react';
import manIcon from './img/man_icon.svg';
import manIconSelected from './img/man_icon_selected.svg';
import otherIcon from './img/other_gender_icon.svg';
import otherIconSelected from './img/other_gender_icon_selected.svg';
import womanIcon from './img/woman_icon.svg';
import womanIconSelected from './img/woman_icon_selected.svg';
import s from './EditingPerson.module.css';
import { useHttp } from '../../../../hooks/useHttp';


const EditingPerson = ({personId, setPersonId, localText}) => {
    const [selectedGender, setSelectedGender] = useState('other');
    const [selectedName, setSelectedName] = useState(localStorage.getItem('selectedPlayer') || 'Undefined');
    const [load, setLoad] = useState();
    let { initRequest } = useHttp();

    // const [selectOption, setSelectOption] = useState(true);
    // const [vision, setVision] = useState({
    //     hair: '',
    //     clothing: '',
    //     shoes: ''
    // });

    const language = localStorage.getItem('language');

    const genderArray = [
        {
            name: 'man', activeIcon: manIconSelected, icon: manIcon,
            en: 'Man', ru: 'Мужчина', by: 'Чалавек'
        },
        {
            name: 'other', activeIcon: otherIconSelected, icon: otherIcon,
            en: 'Other', ru: 'Другое', by: 'Іншае'
        },
        {
            name: 'woman', activeIcon: womanIconSelected, icon: womanIcon,
            en: 'Woman', ru: 'Женщина', by: 'Жанчына'
        },
    ];

    const manName = [
        {name: 'Nathan', ru: 'Кирилл', by: 'Кірыл', en: 'Kirill', id: 1},
        {name: 'Manuel', ru: 'Александр', by: 'Аляксандр', en: 'Alexander', id: 2},
        {name: 'Eric', ru: 'Андрей', by: 'Андрэй', en: 'Andrey', id: 3},
    ];

    const other = [
        {name: 'Undefined', ru: 'Неопределенный', by: 'Невызначаны', en: 'Undefined', id: 0},
        {name: 'Robot', ru: 'Робот', by: 'Робат', en: 'Robot', id: 7},
    ];

    const womanName = [
        {name: 'Sophia', ru: 'София', by: 'Сафія', en: 'Sophia', id: 6},
        {name: 'Claudia', ru: 'Клаудиа', by: 'Клаўдзія', en: 'Claudia', id: 4},
        {name: 'Carla', ru: 'Марина', by: 'Марына', en: 'Marina', id: 5}
    ];

    // const visionList = [
    //     {label: 'hair', en: 'Hair', ru: 'Волосы', by: 'Валасы'},
    //     {label: 'clothing', en: 'Clothing', ru: 'Одежда', by: 'Адзенне'},
    //     {label: 'shoes', en: 'Shoes', ru: 'Обувь', by: 'Абутак'},
    // ]
    //
    //
    // const visionInputChange = event => {
    //     setVision({
    //         ...vision,
    //         [event.target.name]: event.target.value
    //     })
    // };

    const setPersonGender = async (gender) => {
        await setSelectedGender(gender);
        if (gender === 'man') {
            setPersonId(manName[0].id);
            setSelectedName(manName[0].name);
        } else if (gender === 'woman') {
            setPersonId(womanName[0].id);
            setSelectedName(womanName[0].name);
        } else if (gender === 'other') {
            setPersonId(other[0].id);
            setSelectedName(other[0].name);
        }
    }

    const setPersonName = (id, name) => {
        setSelectedName(name);
        setPersonId(id);
    };

    const savePerson = () => {
        initRequest(`/simple_personalization?player=${selectedName}`, 'post')
        .then(() => {
            localStorage.setItem('selectedPlayer', selectedName);
            window.parent.postMessage(personId, '*');
            setLoad(selectedName);
            return load;
        })
    };

    useEffect(() => {
        if (selectedName === 'Nathan' ||
            selectedName === 'Manuel' ||
            selectedName === 'Eric') setSelectedGender('man');
        if (selectedName === 'Sophia' ||
            selectedName === 'Claudia' ||
            selectedName === 'Carla') setSelectedGender('woman');

    }, [selectedName])


    return (
        <div className={s.container}>
            <h1 className={s.pageTitle}>{localText.visualAppearance}</h1>
            <div className={s.decorLine}/>

            <div style={{marginBottom: '2em'}}>
                <h2 className={s.subtitlePage}>{localText.gender}</h2>

                <div className={s.genderList}>
                    {genderArray.map((gender, index) =>
                        <div key={index} onClick={() =>  setPersonGender(gender.name).then()}>
                            <p className={s.genderTitle}>{gender[language]}</p>
                            <div className={s.iconContainer}>
                                <img src={selectedGender === gender.name ? gender.activeIcon : gender.icon}
                                     alt={localText.gender.name}/>
                            </div>
                        </div>
                    )}
                </div>

                <div className={s.decorLine}/>

                {selectedGender === 'man' && <div className={s.namesContainer}>
                    {manName.map((item) =>
                        <p onClick={() => setPersonName(item.id, item.name)}
                           className={selectedName === item.name ? s.selectedName : s.name}>{item[language]}</p>
                    )}
                </div>}

                {selectedGender === 'other' && <div className={s.namesContainer}>
                    {other.map((item) =>
                        <p onClick={() => setPersonName(item.id, item.name)}
                           className={selectedName === item.name ? s.selectedName : s.name}>{item[language]}</p>
                    )}
                </div>}

                {selectedGender === 'woman' && <div className={s.namesContainer}>
                    {womanName.map((item) =>
                        <p onClick={() => setPersonName(item.id, item.name)}
                           className={selectedName === item.name ? s.selectedName : s.name}>{item[language]}</p>
                    )}
                </div>}
            </div>

            {localStorage.getItem('selectedPlayer') !== selectedName
                ? <button onClick={savePerson} className={`${s.saveBtn} btnGreen`}>{localText.save}</button>
                : <button onClick={savePerson} className={s.disabledBtn}>{localText.selected}</button>
            }
        </div>
    );
}



export default EditingPerson;
