import styled from "styled-components";

const WrapDialogs = styled.div`
    position: relative;
    flex: 1;
    border: 0.5px solid #D4D4D4;
    border-radius: 4px;
`;

const DialogsScroll = styled.div`
    position: absolute;
    top: 3px;
    left: 0;
    right: 3px;
    bottom: 3px;
    padding: 13px 9px 13px 16px;
    overflow: auto;
    overflow-x: hidden;
`;

const Dialog = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    height: 66px;
    background: #F5F5F5;
    border-radius: 4px;
    margin-bottom: 12px;
    &:last-child {
        margin-bottom: 0;
    }

    .wrap-dialog-img {
        position: relative;
        img, .dialog-default-img {
            width: 42px;
            height: 42px;
            border-radius: 50%;
        }
    
        .dialog-default-img {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #658f5d;
            color: #ffffff;
            text-transform: capitalize;
        }

        .dialog__unread {
            position: absolute;
            top: -3px;
            right: -3px;
            z-index: 1;
            width: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #FF5449;
            border: 1px solid #F5F5F5;
            border-radius: 15px;
            color: #FFFFFF;
            font-size: 10px;
        }

    }


    .dialog-info {
        padding: 0 35px 0 12px;
        flex: 1;
        min-width: 1px;
        .info__name {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #363636;
            padding-bottom: 2px;
        }
        .info__last-message {
            font-size: 12.5px;
            line-height: 108%;
            color: #484848;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
            display: -webkit-box;
        }
        }
    }
    .dialog__more-info {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background: #EFEFEF;
        border-radius: 8px;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    .wrap-context-menu-btn {
        transform: translate(-12px, 15px);
    }

`;

export {
    WrapDialogs,
    DialogsScroll,
    Dialog,
}