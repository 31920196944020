import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WrapAppContextMenu, ListContextMenu } from "./appContextMenu.style";
import ModalBackground from "./../ModalBackground/ModalBackground";
import { setAppState } from "../../store/slices/appSlice/appSlice";

// EXAMPLE         dispatch(setAppState({contextMenu: {
        //   currentTarget: e.currentTarget,
        //   listMenu: [
        //     { onClick: () => {}, icon: "", text: "" },
        //     { onClick: () => {}, icon: "", text: "", },
        //   ]
        // }}))

const AppContextMenu = () => {
  const { contextMenu, appText: {contextMenu: localText} } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const getCoordsForMenu = (currentTarget) => {
    let currentTargetRect = currentTarget?.getBoundingClientRect();
    return `top: ${
      currentTargetRect.y + currentTargetRect.height / 2 + 3
    }px; left: ${currentTargetRect.x + currentTargetRect.width / 2}px;`;
  };

  useEffect(() => {
    const closeContextMenu = () => {
      dispatch(setAppState({ contextMenu: null }));
    };
    contextMenu?.listMenu &&
      window.addEventListener("resize", closeContextMenu);
    return () => {
      window.removeEventListener("resize", closeContextMenu);
    };
  }, [dispatch, contextMenu?.listMenu]);

  const hanlderActions = (click) => (e) => {
    click && click();
    dispatch(setAppState({ contextMenu: null }));
  };

  const buildContextMenu = () => (
    <ListContextMenu>
      {contextMenu?.listMenu.map((item, i) => item ? (
        <div
          key={i}
          className={`list-item${item.class || ""}`}
          onClick={hanlderActions(item.onClick)}
        >
          {item.icon} <p>{localText[item.text] || item.text}</p>
        </div>
      ) : "")}
    </ListContextMenu>
  );

  if (!contextMenu?.listMenu) return null;

  return (
    <ModalBackground onClose={hanlderActions()}>
      <WrapAppContextMenu
        className="wrap-app-context-menu"
        coordsCss={
          contextMenu.currentTarget
            ? getCoordsForMenu(contextMenu.currentTarget)
            : null
        }
      >
        {buildContextMenu(contextMenu.listMenu)}
      </WrapAppContextMenu>
    </ModalBackground>
  );
};

export default AppContextMenu;

