
export const messages_ru = {
    'development': 'Данная страница находится на этапе разработки.',
    'confirm': 'Для подтверждения изменения данных необходимо перейти по ссылке, которая была выслана на вашу электронную почту.',
    'notFound': 'Чтобы изменить пароль, завершите изменение своего адреса электронной почты, проверив почту.',
    'wrongPasswordOld': 'Неверный старый пароль.',
    'wrongPassword': 'Неверный пароль.',
    'differentPasswords': 'Пароли не совпадают.',
    'logout': 'Выход из приложения.',
    'changeLanguageError': 'Ошибка перевода страницы на другой язык.',
    'changeCameraError': 'Значение свободной камеры не изменилось.',
    'fileSize': 'Размер файла не должен превышать 100 МБ..',
    'notFoundVideoCon': 'Встреча ещё не началась. Начало встречи запланировано на ',
    'logInAgain': "Повторите вход",
    thisPavilion: "Вы находитесь на данном павильоне",
    userNotFound: "Аккаунт не найден",
}