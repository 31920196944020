import React, { useEffect, useState } from 'react';
import Unity from 'react-unity-webgl';
import s from './CurrentStand.module.css';
import { getRequest } from '../../../../api/appService';
import { useHttp } from '../../../../hooks/useHttp';
import { useSelector } from 'react-redux';
import { filesUrl, serverUrl } from '../../../../api/generateDomenUrlsApp';

const CurrentStand = ({localState, localText,}) => {

    // new code
    let {userData} = useSelector(state => state.profile);

    //old code

    const [loadUnity, setLoadUnity] = useState(true);
    const [ignored, setDataStand] = useState({});
    const [totalClick, ignored_] = useState();
    let { initRequest } = useHttp();
    let { getDataStand } =  getRequest(initRequest);

    const getDataCurrentStand = async () => {

        getDataStand(localState, userData?.companyName || '').then((data) => {

            setDataStand({...data});

        });

    }

    window.standLoaded = () => {
        // createUnityContext("currentStand")?.unityInstance?.SendMessage("Stands", "AddShowStatisticsComponents")
        setLoadUnity(false);
    };

    // useEffect(function () {
    //     createUnityContext("currentStand").on("loaded", function () {
    //         setTimeout(() => {
    //             let unityInstance = createUnityContext("currentStand")?.unityInstance;
    //             unityInstance?.SendMessage("Stands", "SetServerData", JSON.stringify(
    //                 {serverRestAddress: serverUrl, serverRestProtocol: "http", serverFileAddress: filesUrl}
    //             ));
    //             unityInstance?.SendMessage("Stands", "SetStandName", `${userData?.companyName || ''}`);
    //         },2500);
    //       });

    //     return () => {
    //         createUnityContext("currentStand").removeAllEventListeners();
    //         createUnityContext("removeManageStandPage");
    //       };

    // }, []);  

    useEffect(function () {
        getDataCurrentStand().then();
    }, []);

    return (
        <div className={s.wrapCurrentStand}>
            <div className={s.wrapUnity}>
                {/* {loadUnity && <div className={s.wrapUnity_loading}><LoadingUnity typeLoad={'currentStand'}/></div>} */}
                {/* <Unity unityContext={createUnityContext("currentStand")} style={{height: '100%', width: '100%'}}/> */}
                {totalClick && <h3 className={s.wrapUnity_totalClick}>{`${localText.totalClick} ${totalClick}`}</h3>}
            </div>                        
        </div>
    );
}

export default CurrentStand;

