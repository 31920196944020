
export const statisticsPage_en = {

    head: "Statistics",
    head2: "General data",
    subhead: "Here you can get acquainted with the general statistics of stands and pages of your organization",
    timePeriod: "Time period",
    today: "Today",
    week: "Week",
    month: "Month",
    year: "Year",
    setPeriod: "Set the period",
    downloadReport: "Download the report",
    visitors3D: "3D Visitors", 
    visitors: "visitors",
    visitorsSite: "Site visitors",
    reqMeet: "Meeting requests",
    reqs: "requests",
    documents: "Documents",
    downedDocs: "downloaded documents",  
    listUsers: "List of users",
    listReqs: "List of requests",
    listDocs: "List of documents",


    'header': {
        'statistics': 'Statistics',
        'pageTitle': 'cleVR statistics laboratory',
        'pageTitle2': 'Company:',
        'registered': 'Registered: ',
        'lastUpdate': 'Last update: ',
    },
    'standsList': {
        'header': 'List of your stands',
        'noStands': 'You don\'t have any stands',
        'allStands': 'General statistics of all stands',
        'infoCompany': 'Company information page',
        'currentStand': "Statistics of the company's current stand",
        'error1': 'Unknown user',
        'error2': 'The user does not belong to any company',
        'error3': 'The company of the user has no stands',
    },
    'filter': {
        'selectIndicator': 'Select indicator: ',
        'uniqueVisitors': 'Number of unique visitors',
        'numberVisits': 'Number of visits',
        'numberFileDownloaded': 'Number of documentation downloads',
        'numberVisitsDev': 'Number of visits (in development)',
        'numberClicksDev': 'Number of clicks (in development)',
        'numberClicks': 'Number of clicks',
        'visitors': 'Number of visitors',
        'transitions': 'Number of clicks from external links (in development)',
        'selectPeriod': 'Select the period of interest: ',
        'from': 'From: ',
        'to': 'To: ',
        'labelStart': 'Start date',
        'labelEnd': 'End date',
    },
    'currentStand': {
        'totalClick': 'Number of clicks:',
        'Assistant': 'Assistant',
        'Tumba': "Rack",
        'Banner': "Banner",
    },
    'visitChart': {
        'header': 'General statistics of all your stands',
        'labelVisits': 'Number of sessions',
        'labelClicks': 'Number of clicks',
    },
    'downloadVisitors': {
        'paragraph': 'See customer data',
    },
    'development': 'In development',
}