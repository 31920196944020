import React, { Fragment } from "react";
import BaseTablePage from "../../components/BaseTablePage/BaseTablePage";
import { WrapRequests, WrapFilterBtns } from "./meetings.style";
import { filesUrl } from "../../api/generateDomenUrlsApp";
import { svg_icons } from "../../assets/imgs/svg_icons";
import AppSelect from "../../components/AppSelect/AppSelect";
import { dateToLocaleDateString, timeMoreDay } from "../../utils/helpers";

const Requests = ({
  handlerActions,
  table,
  settingsSelect,
  language,
  errorLogo,
}) => {
  const { pointsIcon, incomingIcon, outgoingIcon } = svg_icons;

  return (
    <BaseTablePage
      head={`Встречи ${table.data?.length ? `(${table.data.length})` : ""}`}
      selectFilter={
        <WrapFilterBtns activeBtn={table.filter.btn || "all"}>
          <AppSelect settings={settingsSelect} onChange={handlerActions("changeFilter")}/>
        </WrapFilterBtns>
      }
    >
      <WrapRequests>
        {table.data && table.data.length ? (
          table.data.map((meeting, i, array) => (
            <Fragment key={meeting.id}>
              {(timeMoreDay(meeting.time, array[i + 1]?.time) ||
                (array.length === i + 1 && timeMoreDay(array[i - 1]?.time, meeting.time))) &&
                (<div className="wrap-line-time">
                  <div/><p className="line__time">{dateToLocaleDateString(meeting.time)}</p><div/>
                </div>)}
              <div key={meeting.id} className="wrap-meeting">
                {meeting.logo && !errorLogo[i] ? (
                  <img
                    src={`${filesUrl}${meeting.logo}`}
                    alt="company logo"
                    className="meeting__logo"
                    onError={handlerActions("errorLogo", i)}
                  />
                ) : (
                  <div className="meeting__logo background">
                    {meeting.name[language][0] || ""}
                  </div>
                )}
                <div className="meeting-info">
                  <div>
                    <p className={`meeting__head${false ? " new" : ""}`}>
                      {meeting.subNameShort[language]} «{meeting.name[language]}
                      »
                      {false ? <span className="meeting__new">Новое</span> : ""}
                    </p>
                    <p className="meeting__date">
                      <strong>{"Время: "}</strong> {meeting.timeFrom}-
                      {meeting.timeTo}
                    </p>
                  </div>
                  <button className="info__more" onClick={handlerActions("contextMenu", meeting)}>{pointsIcon}</button>
                </div>
              </div>
            </Fragment>
          ))
        ) : (
          <p className="not-data">У вас нет встреч</p>
        )}
      </WrapRequests>
    </BaseTablePage>
  );
};

export default Requests;
