
export const contactPage_ru = {
    'headerPage': {
        'myContacts': 'Мои контакты',
        'search': 'Поиск',
        'back': 'Назад',
        'pageTitle': 'Список моих контактов',
        'myExhibition': 'Только на этом павильоне',
    },
    'noData': "У Вас нет контактов",
    'noDataFind': "Контактов не найдено",
    'headerTable': {
        'avatar': 'Аватар',
        'online': 'Онлайн',
        'name': 'Имя',
        'surname': 'Фамилия',
        'nick': 'Ник',
        'email': 'Электронная почта',
        'company': 'Компания',
        'delete': 'Удалить'
    },
    'userCard': {
        'email': 'Электронная почта',
        'position': 'Должность',
        'company': 'Предприятие',
        'addUser': 'Добавить в список контактов',
        'deleteUser': 'Удалить из списка контактов',
        'addSupport': 'Добавить в список поддержки',
        'unblockUser' : 'Разблокировать пользователя',
        'blockUser' : 'Заблокировать пользователя',
        'youBlock' : 'Вы заблокированы пользователем',
        'deleteSupport': 'Удалить из списка поддержки',
        'absent': 'отсутствует',
        'orderForm': 'Форма заказа / назначения видеоконференции',
        'selectDate': 'Дата, время и длительность встречи:',
        'enterTheme': 'Тема встречи:',
        'sendOrder': 'Отправить заявку',
        'minutes': 'минут',
        'hour': 'час',
        'hours': 'часа',

        'successSubmit': 'Встреча назначена',
        'createChat': 'Создать чат',
    },
    'modal': {
        'addUser': 'Добавить этого пользователя в список контактов?',
        'deleteUser': 'Удалить этого пользователя из списка контактов?',
        'yes': 'Да',
        'no': 'Нет',
        'addSupport': 'Добавить этого пользователя в список поддержки?',
        'deleteSupport': 'Удалить этого пользователя из списка поддержки?',
        'unblockUser' : 'Разблокировать пользователя?',
        'blockUser' : 'Заблокировать пользователя?',
    },
}