import React, { Fragment } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import RequestModal from "./RequestMeet";
import { FormFieldProps } from './../../../../utils/classTempates';
import { createRequestMeet } from "../../../../store/slices/unitySlice/unityExtraReducers"
import { setUnityState } from "../../../../store/slices/unitySlice/unitySlice";

const RequestsMeetContainer = () => {
  const dispatch = useDispatch();
  const companyId = useSelector(state => state.unity.companyIdForRequest);

  if(!companyId) return null;

  const initValues = {
    theme: "",
    time: "",
    timeFrom: "",
    timeTo: "",
    description: "",
  };

  const handlerActions = (type, data) => (e) => {
    switch (type) {
      case "close":
        console.log("close");
        dispatch(setUnityState({companyIdForRequest: null}));
        break;
      case "sendRequest":
        console.log("sendRequest", e);
        dispatch(createRequestMeet({...e, companyId, time: new Date(e.time).toISOString()}))
        break;

      default:
        break;
    }
  };

  const settingsFields = {
    theme: new FormFieldProps("theme", "Тема", "Введите текст...",),
    time: new FormFieldProps(
      "time",
      "Дата",
      "Выберите дату",
      "date"
    ),
    timeFrom: new FormFieldProps(
      "timeFrom",
      "Начало",
      "Введите время",
      "time"
    ),
    timeTo: new FormFieldProps(
      "timeTo",
      "Окончание",
      "Введите время",
      "time"
    ),
    description: new FormFieldProps(
      "description",
      "Сообщение",
      "Введите сообщение",
      "textarea",
      false
    ),
  };

  const settingsBtns = {
    cancelName: "Отменить",
    submitName: "Отправить заявку",
  };

  return (
    <RequestModal 
        handlerActions={handlerActions} 
        initValues={initValues}
        settingsBtns={settingsBtns}
        settingsFields={settingsFields}
        />
  );
};

export default RequestsMeetContainer;
