import React, {useEffect} from "react";
import RightBox from "./RightBox";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setAppChatState, setOpenDialogState } from "../appChatSlice/appChatSlice";
import { resetAppChatState } from "../../../store/slices/appChatSlice/appChatSlice";
import { openDialogById } from "../../../store/slices/appChatSlice/appChatExtraReducers";

const RightBoxContainer = ({mobileVersion}) => {


    const { dialogs, openDialog } = useSelector(state => state.appChat);
    const dispatch = useDispatch();
    const {chatId} = useParams();
    const navigate = useNavigate();

    // console.log(chatId)
   
    useEffect(() => {
        if(!openDialog.dialogId && dialogs) {          

            const openSavedDialog = () => {
                dispatch(openDialogById({id: chatId, navigate}));
                dispatch(setOpenDialogState({...savedDialog, messages: null}));
                dispatch(setAppChatState({key: "dialogs", value: dialogs?.map(dialog => 
                    dialog.dialogId === savedDialog.dialogId ? {...dialog, unread: 0} : dialog)})
                );
            }

            let savedDialog = dialogs.find(dialog => dialog.dialogId === +chatId);

            savedDialog && openSavedDialog();
    

        }
    }, [dialogs])

    useEffect(() => () => dispatch(resetAppChatState("openDialog")), [dispatch]);

    if(!openDialog.dialogId) return null;
      
    return <RightBox openDialog={openDialog} mobileVersion={mobileVersion}/>
}

export default RightBoxContainer