
export const login_by = {
    main: {
        'signUp': 'Зарэгістравацца',
        'login': 'Уваход',
        'loginBtn': 'Увайсці',
        'rememberBtn': 'Запомніць мяне',
        'forgotPassword': 'Не можаце атрымаць доступ да ўліковага запісу?',
        'sendPassword': 'Ліст з новым паролем дасланы вам на пошту',
        'withoutRegistration': 'Уваход без рэгістрацыі',
        'input': {
            'email': 'Электронная пошта',
            'password': 'Пароль',
        },
    },
    confirm: {
        'user': 'Карыстальнік: ',
        'pavilion': 'Павільён: ',
        'continue': 'Прадоўжыць',
        'changeUser': 'Змяніць карыстальніка',
        'changePavilion': 'Выбар іншага павільёна',
        'alreadyLoggedIn': 'Уваход у рахункам зараз ужо выкананы',
        'loading': 'Загрузка стэндаў...',
    },
}