import styled from "styled-components";

const WrapRegistration = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  z-index: 2;
  width: 550px;
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;
  color: #363636;

  .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    background: #f5f5f5;
    border-radius: 8.66667px;
    border: none;
    cursor: pointer;
  }

  & > p,
  h2 {
    text-align: center;
  }
  & > h2 {
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    padding-top: 8px;
  }
  & > p {
    font-size: 13px;
    line-height: 16px;
    padding: 14px 0;
  }

  .registration-form {
    .form-fields {
      .fields {
        display: flex;
        flex-wrap: wrap;
        margin-left: -16px;
      }

      .field-head {
        flex: 1;
        & > h3 {
          display: flex;
          align-items: center;
          font-weight: 600;
          font-size: 13px;
          line-height: 110%;
          &:not(&:first-child) {
            padding-top: 28px;
          }
          & > span {
            margin-right: 5px;
          }
        }
        &:after {
          content: "";
          height: 0.5px;
          background: #d4d4d4;
          border-radius: 2px;
          margin: 10px 0 12px;
          display: block;
        }
      }

      .field-wrap {
        padding: 0 0 16px 16px;

        .field__error-mes {
          bottom: 17px;
        }

        &.password {
          padding-bottom: 26px;
          & > .field__error-mes {
            bottom: 27px;
          }
        }

        &.privacyPolicy {
          flex-direction: row-reverse;
          justify-content: flex-end;
          align-items: center;
          padding: 24px 0 0 0;
          input {
            height: auto;
          }
          label {
            font-weight: 400;
            font-size: 13px;
            line-height: 120%;
            padding: 0 0 0 10px;
            color: #484848;
            span {
              color: #34c57f;
              cursor: pointer;
            }
          }
        }
      }

      & > .field-wrap.phone {
        padding: 0;
      }
    }
    .form-btns {
      gap: 8px;
      padding: 30px 0 14px;
    }
  }
`;

export { WrapRegistration };
