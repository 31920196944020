import React from 'react';
import s from './DirectoryModal.module.css'
import { DirectorySvg } from '../../../Directory/img/DirectorySvg';

const DirectoryModal = ({directoryFolder, getDocumentData, setMoveFolder, setActionsSelect, actionsSelect}) => {

    let directory = directoryFolder.current;
    let currentFolderName = directory[(directory.length - 1)].name;

    const goPrevFolder = () => {
        if(directory.length === 1){
            return '';
        } else {
            directoryFolder.current.pop();
            getDocumentData();
        }
    }

    const closeModalMoveFolder = () => {
        if(actionsSelect){
            setActionsSelect(false);
        } else {
            setMoveFolder({open: false, id: ''})
        }
    }

    return (
        <div className={s.wrapDirectory}>
            <div className={s.controlDirectory}>
                {directory.length === 1 ? '' : <div onClick={goPrevFolder}>{DirectorySvg.leftArrow}</div>}
            </div>
        <div className={s.currentDirectory}>
            <p>
                <span>{currentFolderName}</span>
            </p>
        </div>
        <div onClick={closeModalMoveFolder} className={s.crossModal}>{DirectorySvg.cross}</div>
        </div>
    )
}

export default DirectoryModal;