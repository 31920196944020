import React from "react";
import { WrapHero } from "./hero.style";
import { filesUrl } from "../../api/generateDomenUrlsApp";
import { svg_icons } from "../../assets/imgs/svg_icons";
import AppSelect from "../../components/AppSelect/AppSelect";
import avatarDefault from "../../components/MainPage/avatarDefaultCircle.svg";
import HeroModel from "./HeroModel";

const Hero = ({
  handlerActions,
  userData,
  selectHeroModel,
  herosModels,
  selectGender,
  openMenu,
  language
}) => {
  const { arrowBack, closeIcon, settingsIcon } = svg_icons;

  const modelsSelect = {
    1: [herosModels.Nathan, herosModels.Manuel, herosModels.Eric],
    // 1: [herosModels.Avatar, herosModels.Nathan, herosModels.Manuel, herosModels.Eric],
    2: [herosModels.Claudia, herosModels.Carla, herosModels.Sophia],
    3: [herosModels.Undefined, herosModels.Robot],
  };

  return (
    <WrapHero openMenu={openMenu}>
      <div className="hero-current">
        <div className="hero-back">
          <button onClick={handlerActions("goBack")}>{arrowBack}</button>
          <h2 title="dfsdfsdfsdf">{"Персонаж"}</h2>
        </div>
        <div className="profile-head">
          <img
            src={
              userData?.avatar ? `${filesUrl}${userData.avatar}` : avatarDefault
            }
            alt="user avatar"
          />
          <h2>
            {userData?.firstName} {userData?.lastName}
          </h2>
          <p>{userData?.position}</p>
        </div>
        <h3 className="hero_company-name">{userData?.company.name[language]}</h3>

        <div className="hero-current-view">
          <div className="view-head">
            <h3>Текущий персонаж</h3>
            <button onClick={handlerActions("openSelectModel")}>
              {settingsIcon}
            </button>
          </div>
          <img src={userData?.heroModel?.preview} alt="preview hero" />
        </div>
      </div>

      <HeroModel viewHeroModel={selectHeroModel || userData?.heroModel} />

      <div className="hero-select">
        <div className="hero-select-scroll">
          <div className="select-main-head">
            <h2>{"Персонажи"}</h2>
            <button onClick={handlerActions("closeSelectModel")}>
              {closeIcon}
            </button>
          </div>
          <h3 className="select__head">{"Выбор персонажа"}</h3>
          <p className="select__subhead">
            {
              "Выберите вашего персонажа, который будет отображаться на выставке"
            }
          </p>
          <p className="select__label">{"Пол"}</p>
          <AppSelect
            settings={{
              options: [
                { id: 1, value: "Мужской" },
                { id: 2, value: "Женский" },
                { id: 3, value: "Другое" },
              ],
            }}
            onChange={handlerActions("changeGender")}
          />

          {modelsSelect[selectGender].map((model) => (
            <div
              key={model.id}
              className="model-preview"
              onClick={handlerActions("selectModel", model)}
            >
              <img src={model?.preview} alt="preview hero" />
              {(selectHeroModel?.id || userData?.heroModel?.id) === model.id ? (
                <p>{"Выбрано"}</p>
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
        <div className="select-btns">
          <button
            className="btns__cancel"
            onClick={handlerActions("closeSelectModel")}
          >
            {"Отменить"}
          </button>
          <button
            className="btns__save"
            onClick={handlerActions("saveModel")}
            disabled={!selectHeroModel || (selectHeroModel?.name === userData?.player)}
          >
            {"Сохранить"}
          </button>
        </div>
      </div>
    </WrapHero>
  );
};

export default Hero;
