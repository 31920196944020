import React, { useRef, useEffect } from "react";
import s from "./TableMeetings.module.css";
import { appointmentSvg } from ".././img/AppointmentSvg";
import { useSelector, useDispatch } from "react-redux";
import { useLoading } from "../../../../hooks/checkLoading";
import passwordHidden from "../../../Login/img/password_hidden.png";
import passwordShown from "../../../Login/img/password_shown.png";
import Message from "materialize-css/dist/js/materialize.min.js";
import { useHttp } from "../../../../hooks/useHttp";
import {
  setAppointmentsState,
  setParticipantsState,
} from "../../../../store/slices/profileSlice/profileSlice";
import { checkPasswordAppointment, editThemeAppointment } from "../../../../store/slices/profileSlice/profileExtraReducers";

const TableMeetings = ({ localText}) => {
  //new code
  const dispatch = useDispatch();
  let { userData } = useSelector((state) => state.profile);
  const {
    loading: loadType,
    search,
    fromDate,
    toDate,
    listAppointments,
    listParticipants,
    password,
    theme,
  } = useSelector((state) => state.profile.appointments);

  //old code

  let { initRequest } = useHttp();
  let tableBody = useRef();
  let loading = useLoading("listAppointments", loadType);
  let hiddenPassword = password.hidden;
  let textHeader = localText.table.header;
  let tableHeaders = [
    { headName: textHeader.time, className: "cellData" },
    { headName: textHeader.status, className: "cellOnline" },
    { headName: textHeader.theme, className: "cellTheme" },
    { headName: textHeader.participants, className: "cellParticipants" },
    { headName: textHeader.duration, className: "cellDuration" },
  ];

  const checkTableWidth = () => {
    let width = tableBody.current
      ? tableBody.current.getBoundingClientRect().width
      : "100%";
    let thead = document.querySelector(`#theadMeeting`);
    if (thead) {
      return thead.getBoundingClientRect().width !== width
        ? (thead.style.width = `${width}px`)
        : "";
    }
  };

  useEffect(checkTableWidth, []);
  window.onresize = checkTableWidth;

  const getDurationMeeting = (time) => {
    let textBody = localText.table.body;
    let hour = +time / 3600000;
    let hours = Math.trunc(hour);
    let minutes = Math.round((hour * 60) % 60);

    return minutes === 0
      ? `${hours} ${textBody.durationHours}`
      : `${hours} ${textBody.durationHours} ${minutes} ${textBody.durationMin}`;
  };

  const getTimeMeeting = (time) => {
    let dateMeeting = new Date(time);

    let dd = dateMeeting.getDate();
    if (dd < 10) dd = "0" + dd;

    let mm = dateMeeting.getMonth() + 1;
    if (mm < 10) mm = "0" + mm;

    let yy = dateMeeting.getFullYear() % 100;
    if (yy < 10) yy = "0" + yy;

    let hour = dateMeeting.getHours();
    if (hour < 10) hour = "0" + hour;

    let min = dateMeeting.getMinutes();
    if (min < 10) min = "0" + min;

    return `${dd}.${mm}.${yy} / ${hour}:${min}`;
  };

  if (!listAppointments?.data?.length)
    return (
      <p style={{ textAlign: "center", padding: 30 }}>
        {localText.table.noAppointments}
      </p>
    );

  return (
    <div className={s.wrapTableCompanyMeetings}>
      <div className={s.overflow}>
        <table className={s.tableCompanyMeetings}>
          <thead className={s.tableHeader} id="theadMeeting">
            <tr>
              {tableHeaders.map((th) => (
                <th
                  key={th.className}
                  className={`${s.tableHeaderCell} ${s[th.className]}`}
                >
                  {th.headName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={s.tableBody} ref={tableBody}>
            {listAppointments.data.map((tr) => (
              <tr
                className={`${s.tableBodyRow} ${
                  tr.isVideoConference && s.activeVideo
                }`}
                key={tr.id}
                id={tr.id}
                onClick={(e) =>
                  tr.isVideoConference &&
                  !theme &&
                  Message.toast({ html: localText.videoConference.dev })
                }
              >
                <td className={`${s.cellData}`}>{getTimeMeeting(tr.time)}</td>
                <td
                  className={`${s.cellOnline} ${
                    tr.isVideoConference && s.online
                  }`}
                >
                  <div></div>
                </td>
                <td className={`${s.cellTheme}`}>
                  {theme && theme.id === tr.id ? (
                    <div className={s.btns_confirm}>
                      <input
                        type="text"
                        value={theme.name}
                        onChange={(e) =>
                          dispatch(
                            setAppointmentsState({
                              theme: { name: e.target.value, id: tr.id },
                            })
                          )
                        }
                      />
                      <button
                        className={`${s.themeApply}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatch(
                            editThemeAppointment({
                              id: tr.id,
                              name: theme.name,
                            })
                          );
                        }}
                      >
                        {appointmentSvg.ok}
                      </button>
                      <button
                        className={`${s.themeCancel}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatch(setAppointmentsState({ theme: false }));
                        }}
                      >
                        {appointmentSvg.cancel}
                      </button>
                    </div>
                  ) : (
                    <div>
                      <span>{tr.theme}</span>
                      {tr.isOwner && (
                        <button
                          className={`${s.cellTheme__edit}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            dispatch(
                              setAppointmentsState({
                                theme: { name: tr.theme, id: tr.id },
                              })
                            );
                          }}
                        >
                          {appointmentSvg.edit}
                        </button>
                      )}
                    </div>
                  )}
                </td>
                <td
                  className={`${s.cellParticipants} ${s.cellParticipants_decoration}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(
                      setParticipantsState({ dataAppointment: tr, open: true })
                    );
                  }}
                >
                  {localText.listParticipants.header}
                </td>
                <td className={`${s.cellDuration}`}>
                  {getDurationMeeting(tr.duration)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {loading && <div className="backgroundLoading"></div>}
      {password.open && (
        <div className={s.wrapEnterPassword}>
          <div className={s.enterPassword}>
            <form onSubmit={(e) => {
                e.preventDefault();
                dispatch(checkPasswordAppointment({password}));
            }}>
              <span
                className={s.enterPassword__close}
                onClick={() =>
                  dispatch(
                    setAppointmentsState({
                      password: { open: false, value: "", hidden: true },
                    })
                  )
                }
              >
                ×
              </span>
              <p>
                {`${localText.videoConference.text1}(${(
                  userData.nickname || "Ник"
                ).toLowerCase()})${localText.videoConference.text2}`}
                .
              </p>
              <label className={s.fieldPassword}>
                <img
                  className={s.fieldPassword__icon}
                  src={hiddenPassword ? passwordHidden : passwordShown}
                  alt={`${hiddenPassword ? "hidden" : "show"} password`}
                  onClick={() =>
                    dispatch(
                      setAppointmentsState({
                        password: { ...password, hidden: !hiddenPassword },
                      })
                    )
                  }
                />
                {hiddenPassword ? (
                  <input
                    type="password"
                    value={password.value}
                    onChange={(e) =>
                      dispatch(
                        setAppointmentsState({
                          password: { ...password, value: e.target.value },
                        })
                      )
                    }
                  />
                ) : (
                  <input
                    type="text"
                    value={password.value}
                    onChange={(e) =>
                      dispatch(
                        setAppointmentsState({
                          password: { ...password, value: e.target.value },
                        })
                      )
                    }
                  />
                )}
              </label>
              <button className="btnGreen">
                {localText.videoConference.btn}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};



export default TableMeetings;
