import styled from "styled-components";

const WrapAppSelect = styled.button`
  position: relative;
  min-width: 110px;
  height: 36px;
  background: #f5f5f5;
  border-radius: 4px;
  border: none;
  padding: 9px 42px 9px 12px;


  p, button {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #484848;
  }


  &:focus {
    .select__arrow {
      svg {
        transform: rotate(0deg);
      }
    }
    .select {
      display: flex;
    }
  }

  .select__arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    padding-right: 12px;
    svg {
      transform: rotate(180deg);
    }
  }

  .select__result {
    text-align: start;
  }

  .select__length {
    text-align: start;
    max-height: 0;
  }

  .select {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    transform: translateY(100%);
    z-index: 1;
    display: none;
    max-height: 164px;
    background: #ffffff;
    box-shadow: 0px 14px 30px rgba(0, 0, 0, 0.35);
    border-radius: 0px 0px 4px 4px;
    padding: 2px 2px 2px 0;
    .select__hide {
      position: absolute;
      left: 0;
      top: -36px;
      height: 36px;
      width: 100%;
    }

    .select-scroll {
      display: flex;
      flex-direction: column;
      gap: 10px;
      flex: 1;
      overflow: auto;
      overflow-x: hidden;
      padding: 10px 6px 10px 12px;
    }

    .option {
      background: #f5f5f5;
      text-align: start;
      padding: 12px;
      border: none;
      word-break: break-word;
      cursor: pointer;
      margin-right: ${({lengthSelect}) => lengthSelect > 3 ? "0px" : "4px"};
    }
  }
`;

const AppSelectLabel = styled.p`
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  color: #363636;
  padding-bottom: 6px;  
`;

export { WrapAppSelect, AppSelectLabel };
