
export const manageStand_en = {
    'header': {
        title: 'Stand Designer',
        owner: 'Owner',
        tariff: 'Tariff plan',
        sizeStand: 'The size of the stand depends on the tariff plan',
        currentStand: 'Current stand',
        defaultPosition: 'Default position',
        additionalText: 'Assistants and accessories of the stand are edited by clicking the selected areas on the floor. The content of the banners - by clicking on them.',
        manual: 'Instructions for working with the constructor',
        stand: 'Stand',
        dev: 'In development',
        noStand: 'It is necessary to save the stand',
        noSuperStand: "You don't have a super stand",
    },
    'footer': {
        title: 'Collection of standard stands',
        apply: 'Confirm changes',
        exit: 'Exit',
    },
    'message': {
        'error': 'Saving error',
        'ok': 'Changes confirmed',
    }
}