import {createAsyncThunk} from "@reduxjs/toolkit";
import { getRequest } from "../../../api/appService";
import downloadFile from './../../../services/downloadFile.service';


export const getPreviewUrlFiles = createAsyncThunk(
  "appChat/getPreviewUrlFiles",
  async ({messages, dialogId}, thunkAPI) => {
    let mutate = false;
    let mutateMessages =  await Promise.all(messages.map(async mes => {
      let files = mes.files;
      if(files && files.length) {
        mutate = true;
        files = await Promise.all(files.map(async file => ({
            ...file,
            previewUrl: await downloadFile({
              type: "previewDialogFile",
              data: {
                fileId: file.fileId,
                name: file.fileName,
                dialogId,
            }
            })
          }))
        )
      }
      return {...mes, files}
    })); 

    return {mutateMessages, mutate}
  }
);

export const createDialog = createAsyncThunk(
  "appChat/createDialog",
  async ({body, navigate}, thunkAPI) => {
    let { chatAPI } = getRequest();
    let newDialog = await chatAPI.createDialog(body);
    navigate && navigate(`/profile/chat/${newDialog.id}`);
    return {newDialog, navigate};
  }
);

export const sendMessage = createAsyncThunk(
  "appChat/sendMessage",
  async (data, thunkAPI) => {
    let { chatAPI } = getRequest();
    return await chatAPI.sendMessage(data);
  }
);

export const openDialogById = createAsyncThunk(
  "appChat/openDialogById",
  async ({id, navigate}, thunkAPI) => {
    let { chatAPI } = getRequest();
    navigate && navigate(`/profile/chat/${id}`);
    let dialog = await chatAPI.getDialogById(id);
    thunkAPI.dispatch(getPreviewUrlFiles(dialog))
    return {dialog, navigate};
  }
);


export const getDialogs = createAsyncThunk(
  "appChat/getDialogs",
  async (data, thunkAPI) => {
    let { chatAPI } = getRequest();
    return await chatAPI.getDialogs();
  }
);

export const deleteDialog = createAsyncThunk(
  "appChat/deleteDialog",
  async (id, thunkAPI) => {
    let { chatAPI } = getRequest();
    await chatAPI.deleteDialog(id);
    thunkAPI.dispatch(getDialogs());
    return id;
  }
);

const extraReducers = {

  [getPreviewUrlFiles.fulfilled]: (state, {payload}) => {
    if(payload.mutate) {
      state.openDialog.messages = payload.mutateMessages;
    }
  },

  [createDialog.fulfilled]: (state, {payload}) => {
    state.dialogs.unshift(payload.newDialog);
    state.openDialog = payload.newDialog;
    state.mobileVersion = !payload.navigate;
  },

  [openDialogById.fulfilled]: (state, {payload}) => {
    state.openDialog = payload.dialog;
    state.mobileVersion = !payload.navigate;
  },

  [getDialogs.fulfilled]: (state, {payload}) => {
    state.dialogs = payload
    // state.dialogs = payload.chats
    // state.totalUnread = payload.totalUnread
  },

  [deleteDialog.fulfilled]: (state, {payload}) => {
    state.dialogs = state.dialogs.filter(el => el.id !== payload);
  },

  
};

export default extraReducers;