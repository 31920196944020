import React from 'react';

export const statisticsSvg = {
    logo: <svg width="62" height="67" viewBox="0 0 62 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 29L21 12.2L34.3333 23.4L61 1" stroke="#5DB075" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.26318 46L6.26318 66H16.7895V46H6.26318Z" fill="white" stroke="#5DB075" strokeWidth="2" strokeLinecap="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M49.4211 25L49.4211 66H61.0001V25H49.4211Z" fill="white" stroke="#5DB075" strokeWidth="2" strokeLinecap="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M27.3157 35L27.3157 66H38.8946V35H27.3157Z" fill="white" stroke="#5DB075" strokeWidth="2" strokeLinecap="round"/>
    </svg>,
}
