import React from 'react';

export const svg = {
    uploadFile : <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="36" height="36" x="0" y="0"
    viewBox="0 0 443.294 443.294" className=""><g>
   <path xmlns="http://www.w3.org/2000/svg"
         d="m235.5 166.235h-27.706v69.265h-69.264v27.706h69.265v69.265h27.705v-69.265h69.265v-27.706h-69.265z"
         fill="#808080"  className=""/>
   <path xmlns="http://www.w3.org/2000/svg"
         d="m383.824 114.882-110.824-110.824c-2.597-2.597-6.114-4.058-9.794-4.058h-180.088c-15.274 0-27.706 12.432-27.706 27.706v387.882c0 15.273 12.432 27.706 27.706 27.706h277.059c15.273 0 27.706-12.432 27.706-27.706v-290.912c-.001-3.679-1.462-7.197-4.059-9.794zm-106.765-67.587 63.529 63.529h-63.529zm83.117 368.294h-277.058v-387.882h166.235v83.118c0 15.273 12.432 27.706 27.706 27.706h83.118v277.058z"
         fill="#808080"  className=""/></g>
</svg>,
    createFolder: <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnssvgjs="http://svgjs.com/svgjs" version="1.1" width="36" height="36" x="0" y="0" viewBox="0 0 511.99998 511"  xmlSpace="preserve"><path xmlns="http://www.w3.org/2000/svg" d="m352 224.5h-80v-80c0-8.835938-7.164062-16-16-16s-16 7.164062-16 16v80h-80c-8.835938 0-16 7.164062-16 16s7.164062 16 16 16h80v80c0 8.835938 7.164062 16 16 16s16-7.164062 16-16v-80h80c8.835938 0 16-7.164062 16-16s-7.164062-16-16-16zm0 0" fill="#808080"/><path xmlns="http://www.w3.org/2000/svg" d="m464 64.5h-199.441406c-.640625-.96875-1.386719-1.863281-2.222656-2.671875l-54.625-52.398437c-5.929688-5.734376-13.859376-8.933594-22.109376-8.929688h-137.601562c-26.511719 0-48 21.492188-48 48v320c0 26.511719 21.488281 48 48 48h416c26.511719 0 48-21.488281 48-48v-256c0-26.507812-21.492188-48-48-48zm16 304c0 8.835938-7.164062 16-16 16h-416c-8.835938 0-16-7.164062-16-16v-320c0-8.835938 7.164062-16 16-16h137.601562l53.550782 51.410156c6.046875 7.925782 15.4375 12.578125 25.40625 12.589844h199.441406c8.835938 0 16 7.164062 16 16zm0 0" fill="#808080"/></svg>
    ,
    select: <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnssvgjs="http://svgjs.com/svgjs" version="1.1" width="36" height="36" x="0" y="0" viewBox="0 0 148.961 148.961" xmlSpace="preserve">
    <g xmlns="http://www.w3.org/2000/svg">
        <path d="M146.764,17.379c-2.93-2.93-7.679-2.929-10.606,0.001L68.852,84.697L37.847,53.691c-2.93-2.929-7.679-2.93-10.606-0.001   c-2.93,2.929-2.93,7.678-0.001,10.606l36.309,36.311c1.407,1.407,3.314,2.197,5.304,2.197c1.989,0,3.897-0.79,5.304-2.197   l72.609-72.622C149.693,25.057,149.693,20.308,146.764,17.379z" fill="#7c7c7c"/>
        <path d="M130.57,65.445c-4.142,0-7.5,3.357-7.5,7.5v55.57H15V20.445h85.57c4.143,0,7.5-3.357,7.5-7.5c0-4.142-3.357-7.5-7.5-7.5   H7.5c-4.142,0-7.5,3.357-7.5,7.5v123.07c0,4.143,3.358,7.5,7.5,7.5h123.07c4.143,0,7.5-3.357,7.5-7.5v-63.07   C138.07,68.803,134.713,65.445,130.57,65.445z" fill="#7c7c7c"/>
    </g>
</svg>
}
