import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import DownloadReport from '../DownloadReport/DownloadReport';
import DateForm from "./DateForm/DateForm";
import s from './Filters.module.css';
import btn from './img/btn.svg';

const Filters = ({setLocalState, localText, indicatorsList, selectedIndicator, setSelectedIndicator}) => {

       //new code
       const {userData} = useSelector(state => state.profile);

       //old code


    const [showIndicators, setShowIndicators] = useState(false);
    const [startDate, setStartDate] = useState({
        label: localText.labelStart,
        selectedDate: new Date(new Date(new Date().setDate(new Date().getDate() - 7)).setHours(0,0,0,0)).toString()
    });

    const [endDate, setEndDate] = useState({
        label: localText.labelEnd,
        selectedDate: new Date(new Date().setHours(23,59,59,0)).toString()
    });

    const handleStartDateChange = (date) => {
        setStartDate({
            ...startDate,
            selectedDate: date
        })
    };

    const handleEndDateChange = (date) => {
        setEndDate({
            ...endDate,
            selectedDate: date
        })
    };

    const onChangeSelectedIndicator = (event, item) => {
        // console.log('CHANGED SELECTOR INDICATOR')
        setSelectedIndicator(item);
        setShowIndicators(!showIndicators);
    }

    useEffect(() => {
        setLocalState([Date.parse(startDate.selectedDate), Date.parse(endDate.selectedDate)])
    }, [startDate.selectedDate, endDate.selectedDate, setLocalState]);


    return (
        <div style={{}} className={s.container}>
            <div className={s.containerSelectIndicator}>
                <h1 className={s.pageTitle}>{localText.selectIndicator}</h1>
                <div className={s.wrapIndicatorsContainer}>
                    <div className={showIndicators ? s.backgroundList : s.backgroundListNone} onClick={() => setShowIndicators(false)}/>
                    <div className={showIndicators ? `${s.indicatorsContainer} ${s.indicatorsContainer_open}` : s.indicatorsContainer}>
                        <div className={s.btnContainer} onClick={()=>setShowIndicators(!showIndicators)}>
                            <img src={btn} alt={''}/>
                        </div>
                        <p className={`${s.selectedIndicator} ${s.active}`}>{selectedIndicator.text}</p>
                        {showIndicators && <div className={s.additionalFields}>
                            {indicatorsList.map(item => <p key={item.id} id={item.id} className={s.selectedIndicator}
                                   onClick={(event) => onChangeSelectedIndicator(event, item)}>
                                    {item.text}
                                </p>)}

                        </div>}
                    </div>
                </div>
                <DownloadReport companyUrl={userData?.companyName} typeReport='page'
                                selectedDateFrom={startDate.selectedDate}
                                selectedDateTo={endDate.selectedDate}/>
            </div>
            <div className={s.containerSelectDate}>
                <h1 className={s.pageTitle}>{localText.selectPeriod}</h1>
                <div className={s.dateContainer}>
                    <DateForm label={startDate.label} localText={localText.from} handleDateChange={handleStartDateChange}
                              selectedDate={startDate.selectedDate} maxDate={endDate.selectedDate} disableFuture={true}/>
                    <DateForm label={endDate.label} localText={localText.to} handleDateChange={handleEndDateChange}
                              selectedDate={endDate.selectedDate} minDate={startDate.selectedDate} disableFuture={true}/>
                </div>
            </div>
        </div>
    )
}

export default Filters;
