import React, { useState } from 'react';
import s from './SelectControl.module.css'
import { serverUrl } from '../../../../api/generateDomenUrlsApp';


const SelectControl = ({localText, checkboxesSelect, setActionsSelect, toggleSelectControl}) => {

    const count = (array, value) => {
        let count = 0;
        array.forEach(item => {
     if (item.checked === value && item.id !== 'all') {
         count++;
     }
        });
        return count;
    }

    const sortCheckboxes = (checkboxes, value) => {
      return checkboxes.filter((item) => {
            if (item.checked === value && item.id !== 'all') {
                return true;

            } else {
                return false;
            }});
    }

    const downloadFile = (file) => {
        console.log(file)
        let link_url = document.createElement("a");
        let url = `${serverUrl}/files/download?fileId=${file[0].id}&token=${localStorage.getItem('token')}`;
        
        link_url.target= '_blank';
        link_url.download = url.substring((url.lastIndexOf("/") + 1), url.length);
        link_url.href = url;
        document.body.appendChild(link_url);
        link_url.click();
        document.body.removeChild(link_url);
    }

    let [selectValue, setSelectValue] = useState('actions');
    let countSelect = count(checkboxesSelect, true);

    const applyAction = () => {
        if(selectValue === 'actions') {
            setActionsSelect(false)
        } 
        else if (selectValue === 'download') {
            downloadFile(sortCheckboxes(checkboxesSelect, true));
        }
         else {
            setActionsSelect({action: selectValue, checkboxes: sortCheckboxes(checkboxesSelect, true)})
        }
    }

    if(sortCheckboxes(checkboxesSelect, true).length > 1 && selectValue === 'download') setSelectValue('actions');

    return (
        <div className={s.wrapSelectControl}>
            <p>{`${localText.selectControl.quantity}: ${countSelect}`}</p>
            <select value={selectValue} onChange={(e) => setSelectValue(e.target.value)} className={s.selectActions}>
                <option value='actions' disabled>{localText.selectControl.actions}</option>
                <option value='move'>{localText.selectControl.moveContent}</option>
                <option value='delete'>{localText.selectControl.deleteContent}</option>
                {sortCheckboxes(checkboxesSelect, true).length === 1 &&  <option value='download'>{localText.table.contextMenu.downloadBtn}</option>}
            </select>
            <button className={s.wrapSelectControl__apply} onClick={applyAction}>{localText.selectControl.apply}</button>
            <p className={s.wrapSelectControl__close} onClick={toggleSelectControl}>×</p>
        </div>
    )
}

export default SelectControl;
