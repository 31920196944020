
export const mainNavigation_en = {
    'limitedVersion': 'Full functionality with chat and personal account will be available after ',
    'registration': ' registration.',
    'changePavilion': 'Choosing a different pavilion',
    'goToStand': 'Go to',
    'back': 'Back',
    'loading': 'Stands loading...',
    'communication': 'Communication',
    'endSession': 'The session is over. Log in again.',
}