import React from "react";
import BaseTablePage from "../../components/BaseTablePage/BaseTablePage";
import { WrapRequests, WrapFilterBtns } from "./requests.style";
import { filesUrl } from "../../api/generateDomenUrlsApp";
import { svg_icons } from "../../assets/imgs/svg_icons";
import AppSelect from "./../../components/AppSelect/AppSelect";
import { dateToLocaleDateString } from "../../utils/helpers";

const Requests = ({ handlerActions, table, settingSelects, language, errorLogo, localText}) => {
  const { pointsIcon, incomingIcon, outgoingIcon } = svg_icons;

  return (
    <BaseTablePage
      head={`${localText.requests} ${table.data?.length ? `(${table.data.length})` : ""}`}
      selectFilter={
        <WrapFilterBtns activeBtn={table.filter.btn || "all"}>
          <AppSelect settings={settingSelects.select1} onChange={handlerActions("changeFilter")}/>
          <AppSelect settings={settingSelects.select2} onChange={handlerActions("changeFilter")} />
        </WrapFilterBtns>
      }
    >
      <WrapRequests>
        {table.data && table.data.length
          ? table.data.map((request, i) => (
              <div key={request.id} className="wrap-request">
                {request.logo && !errorLogo[i] ? <img
                  src={`${filesUrl}${request.logo}`}
                  alt="company logo"
                  className="request__logo"
                  onError={handlerActions("errorLogo", i)}
                /> :
                <div className="request__logo background">{request.name[language][0] || ""}</div>}
                <div className="request-info">
                  <div>
                    <p className={`request__head${false  ? " new" : ""}`} >
                      {request.subNameShort[language]} «{request.name[language]}»
                      {request.online ? (
                        <span className="request__online" />
                      ) : (
                        ""
                      )}
                      {/* {i % 2  ? <span className="request__new">Новое</span> : ""} */}
                    </p>
                    <p className="request__date">
                      {request.time ? dateToLocaleDateString(request.time) : ""}
                      {request.incoming ? (
                        <span className="request__incoming">{incomingIcon} {localText.incoming}</span>
                      ) : (
                        <span className="request__outgoing">{outgoingIcon} {localText.outgoing}</span>
                      )}
                    </p>
                    <p className="request__name">
                      <strong>Тема: </strong> {request.theme}
                    </p>
                  </div>
                  <button className="info__more" onClick={handlerActions("contextMenu", request)}>{pointsIcon}</button>
                </div>
              </div>
            ))
          : <p className="not-data">{localText.noRequests}</p>}
      </WrapRequests>
    </BaseTablePage>
  );
};

export default Requests;
