import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Favorites from "./Favorites";
import { deleteFavoritesCompany, getFavoritesCompany } from "../../store/slices/profileSlice/profileExtraReducers";
import { setProfileTableState } from "../../store/slices/profileSlice/profileSlice";
import { setAppState } from "../../store/slices/appSlice/appSlice";
import { svg_icons } from './../../assets/imgs/svg_icons';
import { setUnityState } from "../../store/slices/unitySlice/unitySlice";

const FavoritesContainer = () => {
  const selectedTab = useSelector((state) => state.app.selectedTab);
  let { table } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const {requestsIcon, closeInCircleIcon} = svg_icons;


  const handlerActions = (type, data) => async (e) => {
    switch (type) {
      case "changeUserData":
        break;
      case "toggleFilterBtn":
        dispatch(setProfileTableState({filter: {...table.filter, btn: data}}))
        break;
      case "contextMenu":
        dispatch(setAppState({contextMenu: {
          currentTarget: e.currentTarget,
          listMenu: [
            { onClick: () => dispatch(setUnityState({companyIdForRequest: data.companyId})), icon: requestsIcon, text: "Заявка на встречу" },
            { onClick: () => dispatch(deleteFavoritesCompany(data.companyId)), icon: closeInCircleIcon, text: "Удалить из “Избранных”", },
          ]
        }}))
        break;
      default:
        break;
    }
  };

  const settingsSelect = {
    options: [{ id: 1, value: "Все встречи" }],
};

  useEffect(() => {
    dispatch(getFavoritesCompany(table.filter));
  }, [dispatch, table.filter]);

  return <Favorites handlerActions={handlerActions} table={table} settingsSelect={settingsSelect}/>;
};

export default FavoritesContainer;
