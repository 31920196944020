
export const documentPage_en = {
    'headerPage': {
        'myContacts': 'My documents',
        'search': 'Search',
        'pageTitle': 'List of my documents',
        'uploadFile': 'Upload file',
        'createFolder': 'Create folder',
    },
    'noData': "Folder is empty",
    'noDataFind': "Files not found",
    'modalLoadfile': 'Uploading a file',
    'modalMoveFolder': {
        'btnMove': 'Move',
        'noMoveFolderPart1': 'It is not possible to move the folder',
        'noMoveFolderPart2': 'to itself',
        'noSelectFile': 'Cannot be selected because it is not a folder',
    },
    'directory': {
        'myDisk': 'My disk',
        'newFolderModal': {
            'header': 'New Folder',
            'closeModal': 'Cancel',
            'createFolder': 'Create',
        },
        'contextMenu': {
            'createFolder': 'Create folder',
            'uploadFile': 'Upload file',
            'select': 'Select',
            'closeSelect': 'Close selection',
        }
    },
    'table' :{
        'headerTable': {
            'name': 'Name',
            'lastModified': 'Last modified',
            'sizeFile': 'Size',
            'type': 'Type',
        },
        'bodyTable': {
            'byte': 'b',
            'kilobyte': 'Kb',
            'megabyte': 'Mb',
            'gigabyte': 'Gb',
            'typeFolder': 'Folder',
        },
        'contextMenu': {
            'deleteBtn': 'Remove',
            'moveFolder': 'Move',
            'downloadBtn': 'Download',
            'renameBtn': 'Rename',
            'okBtn': 'Ok',
            'cancelBtn': 'Cancel',
            'select': 'Select',
            'closeSelect': 'Close selection',
        },
    },
    'selectControl': {
        'quantity': 'Quantity',
        'actions': 'Actions',
        'apply': 'Apply',
        'deleteContent': 'Remove',
        'moveContent': 'Move',
    },
    'modal': {
        'yes': 'Yes',
        'no': 'No',
        'piece': 'pc.',
        'confirmDeleteObjects': 'Do you really want to delete these objects',
        'confirmDeleteObject': 'Do you really want to delete this object',
        'confirmDeleteFile': 'The file you selected is being used elsewhere. Do you really want to delete it?',
        'confirmDeleteFolder': 'Do you really want to delete the folder',
    }
}