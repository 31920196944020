import React from "react";
import icons_svg from "../../imgs/icons_svg";
import { WrapHeaderLeftBox, WrapSearch } from "./headerLeftBox.style";
import { svg_icons } from "../../../../assets/imgs/svg_icons";

const HeaderLeftBox = ({
  dialogs,
  handlerActions,
  mobileVersion,
  localState: { openSearch },
}) => {
  const { arrowBack, searchIcon, selectArrow, closeIcon } = svg_icons;

  return (
    <WrapHeaderLeftBox>
      {!mobileVersion ? (
        <button className="chat__back" onClick={handlerActions("goBack")}>
          {arrowBack}
        </button>
      ) : (
        ""
      )}
      <h2>Чаты {dialogs?.length ? `(${dialogs?.length || ""})` : ""}</h2>
      <div className="head__margin"></div>
      {mobileVersion && (
        <button
          className="mobile-head__roll-up"
          onClick={handlerActions("closeMobileChat", true)}
        >
          {" "}
          Свернуть <div className="roll-up__icon">{selectArrow}</div>
        </button>
      )}
      <WrapSearch mobileVersion={mobileVersion} openSearch={openSearch}>
        {mobileVersion || openSearch ? (
          <div className="search-mobile">
            {searchIcon}
            <input
              type="text"
              name="search"
              placeholder="Поиск по контактам и чатам"
              onChange={handlerActions("searchDialogs")}
            />
            {openSearch ? (
              <button
                className="search__close"
                onClick={handlerActions("toggleSearch")}
              >
                {closeIcon}
              </button>
            ) : (
              ""
            )}
          </div>
        ) : (
          <button
            className="search__btn"
            onClick={handlerActions("toggleSearch")}
          >
            {searchIcon}
          </button>
        )}
      </WrapSearch>
    </WrapHeaderLeftBox>
  );
};

export default HeaderLeftBox;
