import React, { useEffect, useState } from "react";
import BaseTablePage from "../../components/BaseTablePage/BaseTablePage";
import { WrapFavorites, WrapFilterBtns } from "./favorites.style";
import { filesUrl, serverUrl } from "../../api/generateDomenUrlsApp";
import { svg_icons } from "../../assets/imgs/svg_icons";
import avatarDefault from "../../components/MainPage/avatarDefaultCircle.svg";
import { useSelector } from "react-redux";
import { calcFileSize } from "../../utils/helpers";
import AppSelect from "../../components/AppSelect/AppSelect";

const Favorites = ({ handlerActions, table, settingsSelect }) => {
  const { pointsIcon, standsIcon } = svg_icons;
  const lang = useSelector((state) => state.app.appText.language);

  return (
    <BaseTablePage
      head={`Избранные экспоненты ${
        table.data?.length ? `(${table.data.length})` : ""
      }`}
      selectFilter={
        <WrapFilterBtns>
          <AppSelect settings={settingsSelect} />
        </WrapFilterBtns>
      }
    >
      <WrapFavorites>
        {table.data && table.data.length ? (
          table.data.map((favorite) => (
            <div key={favorite.companyId} className="wrap-favorite">
              <div className="favorite-wrap-logo">
                <img
                  className="favorite__logo"
                  src={filesUrl + favorite.logo}
                  alt="logo company"
                />
              </div>
              <div className="favorite-info">
                <p className="favorite__name" title={favorite.name[lang]}>
                  {favorite.name[lang]}{" "}
                </p>
                <button
                  className="favorite__more"
                  onClick={handlerActions("contextMenu", favorite)}
                >
                  {pointsIcon}
                </button>
              </div>
              <div className="favorite-view">
                <button className="favorite__3d">
                  {standsIcon} Перейти на 3D стенд{" "}
                </button>
                {/* <p className="favorite__count-view">12</p> */}
              </div>
            </div>
          ))
        ) : (
          <p className="not-data">У вас нет избранных</p>
        )}
      </WrapFavorites>
    </BaseTablePage>
  );
};

export default Favorites;
