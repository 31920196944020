import styled from "styled-components";

const WrapRequests = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: -12px;
  padding-right: 8px;

  .wrap-request {
    display: flex;
    padding: 12px;
    min-width: 300px;
    flex: 1;
    min-width: 100%;
    min-height: 76px;
    background: #f5f5f5;
    border-radius: 4px;

    .request__logo {
      width: 53px;
      height: 53px;
      min-width: 53px;
      min-height: 53px;
      border-radius: 50%;
      &.background {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #34c57fcc;
        font-weight: 600;
        font-size: 25px;
        line-height: 110%;
        color: #FFFFFF;
      }
    }
    .request-info {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      padding-left: 12px;

      .request__head {
        font-weight: 500;
        font-size: 14px;
        line-height: 110%;        
        color: #363636;
        &.new {
          font-weight: 700;
        }

        .request__online {
          display: inline-block;
          width: 6px;
          height: 6px;
          background: #34C57F;
          border-radius: 50%;
          margin: 0 6px;
        }
        .request__new {
          font-weight: 600;
          font-size: 8px;
          line-height: 110%;
          letter-spacing: 0.02em;
          text-transform: uppercase;
          color: #FFFFFF;
          background: #34C57F;
          border-radius: 16px;
          padding: 1.5px 6px;
        }
      }
      .request__date {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 11px;
        line-height: 110%;
        color: #48484880;
        padding: 6px 0 4px;
        .request__incoming, .request__outgoing {
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 11px;
          line-height: 110%;
          padding-left: 8px;
          svg {
            margin-right: 3px;
          }

        }
        .request__incoming {
          color: #34C57F;
        }
        .request__outgoing {
          color: #6587FE;
        }
      }
    }

    .request__name {
      strong {
        font-weight: 600;
        font-size: 13px;
        line-height: 110%;
        color: #363636;
      }
      font-weight: 500;
      font-size: 12px;
      line-height: 110%;
      color: #484848;
    }

    .info__more {
      border: none;
      background-color: #efefef;
      border-radius: 8px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const WrapFilterBtns = styled.div`
    display: flex;
    gap: 12px;
    .app-select {
      height: 34px;
      min-width: 195px;
      &#select1 {
        min-width: 202px;
      }
    }
`;

export { WrapRequests, WrapFilterBtns };
