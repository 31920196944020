import React, { useState } from 'react';
import s from './DownloadReport.module.css';
import Message from 'materialize-css/dist/js/materialize.min.js';
import { useHttp } from '../../../../hooks/useHttp';
import { serverUrl } from '../../../../api/generateDomenUrlsApp';

const DownloadReport = ({ companyUrl, selectedDateFrom, selectedDateTo, typeReport, standInfoTypesRef = {current: null} }) => {

    let [loaderGenerateReport , setLoaderGenerateReport] = useState(false);
    const [report, setReport] = useState(false);
    let { initRequest } = useHttp();

    let token = localStorage.getItem('token');

    
    const getFileUrl = () => {

        let link_url = document.createElement("a");
        let url = `${serverUrl}/api/statistics/report/download?token=${token}`;
        
        link_url.download = url.substring((url.lastIndexOf("/") + 1), url.length);
        link_url.href = url;
        document.body.appendChild(link_url);
        link_url.click();
        document.body.removeChild(link_url);
    }

    const reportGeneration = async (type) => {

        if(!standInfoTypesRef?.current && typeReport === 'stand') {
            Message.toast({ html: 'Неизвестная ошибка'});
            return;
        }

        const getTime = date => new Date(date).getTime();

        setLoaderGenerateReport(true)
        try{

            let standInfoTypes = {};

            for(let i in standInfoTypesRef?.current) {
                standInfoTypes[i] = standInfoTypesRef?.current[i]['itemType'];
            }

            await initRequest(
                `/api/statistics/${typeReport}/report/${companyUrl}?reportDestination=${type}&dateFrom=${getTime(selectedDateFrom)}&dateTo=${getTime(selectedDateTo)}`
                ,typeReport === 'stand' ? 'post' : 'get', null, standInfoTypes)
            if(type === 'local') {
                Message.toast({ html: 'Отчет сформирован. Загрузка должна начаться'})
                getFileUrl();
            }
            if(type === 'server') {
                Message.toast({ html: 'Отчет сформирован и добавлен в личный кабинет'})
                setReport(false)
            }
        }
        catch(e) {
            Message.toast({ html: e.response?.data?.message || 'Error'})
        }
        setLoaderGenerateReport(false)
    }

    return (
        <>
            <button className={`${s.reportBtn} btnGreen`} onClick={() => setReport(true)}>Отчет</button>
            {report && 
            <div className={s.wrapDownloadReport}>
                <div className={s.downloadReport}>
                    <p className={s.downloadReport__title}>Отчет</p>
                    <button className='btnGreen' onClick={() => reportGeneration('server')}>Добавить в личный кабинет</button>
                    <button className='btnGreen' onClick={() => reportGeneration('local')}>Скачать</button>
                    <span  className={s.downloadReport__exit} onClick={() => setReport(false)}>×</span>
                    {loaderGenerateReport && <div className={s.downloadReport_loader}>
                        <p>Формирование отчета</p>
                        {/* <img alt={'elem.name'} src={loader}/> */}
                        </div>}
                </div> 
            </div>}
        </>
    );
}

export default DownloadReport;

