import { handlerData } from "../../../services/handlerData.service";
import { handlerLocalStorage } from "../../../services/handlerLocalStorage.service";
import { initialStateApp } from "./appSlice";

const { getAppText } = handlerData();
const {set} = handlerLocalStorage();

const reducers = {
    setAppTextLang(state, action) {
        set('language', action.payload);
        state.appText = getAppText(action.payload);
    },

    setAppState(state, {payload}) {
        console.log(payload)
        return {...state, ...payload}
    },

    resetAppState(state, {payload}) {
        switch (payload) {
            case 'modal':
                state.modal = initialStateApp.modal;
                break;
            case 'tab':
                state.selectedTab = initialStateApp.selectedTab;
                break;
        
            default:
                return initialStateApp;
        }
    }
        
};

export default reducers;