
export const login_en = {
    main: {
        'signUp': 'Sign Up',
        'login': 'Login',
        'loginBtn': 'Login',
        'rememberBtn': 'Remember me',
        'forgotPassword': 'Forgot your password?',
        'sendPassword': 'An email with a new password has been sent to your email',
        'withoutRegistration': 'Login without registration',
        'input': {
            'email': 'E-mail',
            'password': 'Password',
        },
    },
    confirm: {
        'user': 'User: ',
        'pavilion': 'Pavilion: ',
        'continue': 'Continue',
        'changeUser': 'Change user',
        'changePavilion': 'Choosing a different pavilion',
        'alreadyLoggedIn': 'This account is already signed in',
        'loading': 'Stands loading...',
    },
}