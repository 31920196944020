
export const mainNavigation_by = {
    'limitedVersion': 'Поўны функцыянал з чатам і асабістым кабінетам будзе даступны пасля ',
    'registration': ' рэгістрацыі.',
    'changePavilion': 'Выбар іншага павільёна',
    'goToStand': 'Перайсці',
    'back': 'Таму',
    'loading': 'Загрузка стэндаў...',
    'communication': 'Зносіны',
    'endSession': 'Сесія завершана. Паўтарыце ўваход.'
}