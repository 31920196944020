import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest } from "../../../api/appService";
import { herosModels } from "../../../assets/heros/herosModels";
import { setAppChatState } from "../appChatSlice/appChatSlice";

export const changeEmail = createAsyncThunk(
  "profile/changeEmail",
  async (data, thunkAPI) => {
    let { profileAPI } = getRequest();
    return await profileAPI.changeEmail(data);
 }
);

export const forgotPassword = createAsyncThunk(
  "profile/forgotPassword",
  async (data, thunkAPI) => {
    let { profileAPI } = getRequest();
    console.log(data)
    return await profileAPI.forgotPassword(data);
 }
);

export const changePassword = createAsyncThunk(
  "profile/changePassword",
  async (data, thunkAPI) => {
    let { profileAPI } = getRequest();
    return await profileAPI.changePassword(data);
 }
);

export const updateFullName = createAsyncThunk(
  "profile/updateFullName",
  async (data, thunkAPI) => {
    let { profileAPI } = getRequest();
    return await profileAPI.updateFullName(data);
 }
);

export const makeCompanyOwner = createAsyncThunk(
  "profile/makeCompanyOwner",
  async (data, thunkAPI) => {
    let { profileAPI } = getRequest();
    await profileAPI.makeCompanyOwner(data);
    data.updateContacts && thunkAPI.dispatch(getContactsUser());
    return "OK";
  }
);

export const getStatisticsCompany = createAsyncThunk(
  "profile/getStatisticsCompany",
  async (data) => {
    let { profileAPI } = getRequest();
    return await profileAPI.getStatisticsCompany(data);
    // response.clicksTotal = response.clicks.reduce((prev, current) => {
    //   return prev + current.clicks;
    // }, 0)
    // response.downloadsTotal = response.downloads.reduce((prev, current) => {
    //   return prev + current.downloaded;
    // }, 0)
    // response.visitsTotal = response.visits.reduce((prev, current) => {
    //   return prev + current.visits;
    // }, 0)
    // return response;
  }
);

export const deleteFileById = createAsyncThunk(
  "profile/deleteFileById",
  async (data, thunkAPI) => {
    let { profileAPI } = getRequest();
    return await profileAPI.deleteFileById(data);
  }
);

export const deleteFavoritesCompany = createAsyncThunk(
  "profile/deleteFavoritesCompany",
  async (companyId, thunkAPI) => {
    let { profileAPI } = getRequest();
    await profileAPI.deleteFavoritesCompany(companyId);
    return companyId;
  }
);

export const getFavoritesCompany = createAsyncThunk(
  "profile/getFavoritesCompany",
  async (data, thunkAPI) => {
    let { profileAPI } = getRequest();
    return await profileAPI.getFavoritesCompany(data);
  }
);

export const getDocumentsUser = createAsyncThunk(
  "profile/getDocumentsUser",
  async (data, thunkAPI) => {
    let { profileAPI } = getRequest();
    return await profileAPI.getDocumentsUser(data);
  }
);

export const getContactsUser = createAsyncThunk(
  "profile/getContactsUser",
  async (data = {}, thunkAPI) => {
    let { profileAPI } = getRequest();
    return await profileAPI.getContactsUser(data);
  }
);

export const deleteUserFromContacts = createAsyncThunk(
  "profile/deleteUserFromContacts",
  async (userId, thunkAPI) => {
    let { profileAPI } = getRequest();
    let res = await profileAPI.deleteUserFromContacts(userId);
    await thunkAPI.dispatch(getContactsUser());
    return res;
  }
);

export const setHeroModel = createAsyncThunk(
  "profile/setHeroModel",
  async (model, thunkAPI) => {
    let { profileAPI } = getRequest();
    console.log(model);
    await profileAPI.setHeroModel(model.name);
    localStorage.setItem("selectedPlayer", model.name);
    return model;
  }
);

export const getRequestsUser = createAsyncThunk(
  "profile/getRequestsUser",
  async (data, thunkAPI) => {
    let { profileAPI } = getRequest();
    let { language } = thunkAPI.getState().app.appText;
    return await profileAPI.getRequestsUser(language, data);
  }
);

export const deleteMeetings = createAsyncThunk(
  "profile/deleteMeetings",
  async ({id, consult}, thunkAPI) => {
    let { profileAPI } = getRequest();
    await profileAPI.deleteMeetings(id, consult);
    return id;
  }
);

export const getMeetingsUser = createAsyncThunk(
  "profile/getMeetingsUser",
  async (data, thunkAPI) => {
    let { profileAPI } = getRequest();
    let { language } = thunkAPI.getState().app.appText;
    return await profileAPI.getMeetingsUser(language, data);
  }
);

// old

export const getAllCompanies = createAsyncThunk(
  "profile/getAllCompanies",
  async (data, thunkAPI) => {
    let { profileAPI } = getRequest();
    return await profileAPI.getAllCompanies(data);
  }
);

export const getProfileData = createAsyncThunk(
  "profile/getProfileData",
  async (data, thunkAPI) => {
    let { authAPI} = getRequest();
    let userData = await authAPI.getProfileData(data);

    try {
    } catch (error) {}

    let heroModel = herosModels[userData.player];
    userData = { ...userData, heroModel };

    userData?.id && thunkAPI.dispatch(setAppChatState({key: "userId", value: userData.id}))

    if (userData.alreadyLoggedIn === "false") {
      return userData;
    } else {
      return userData;
    }
  }
);

export const getListAppointments = createAsyncThunk(
  "profile/getListAppointments",
  async (data, thunkAPI) => {
    let { profileAPI } = getRequest();
    let list = await profileAPI.getListAppointments(data);
    return list;
  }
);

export const getListParticipants = createAsyncThunk(
  "profile/getListParticipants",
  async (data, thunkAPI) => {
    let { profileAPI } = getRequest();
    let list = await profileAPI.getListParticipants(data);
    return list;
  }
);

export const getContactsListForAppoint = createAsyncThunk(
  "profile/getContactsListForAppoint",
  async (data, thunkAPI) => {
    let { profileAPI } = getRequest();
    let list = await profileAPI.getContactsListForAppoint(data);
    return list;
  }
);

export const leaveAppointment = createAsyncThunk(
  "profile/leaveAppointment",
  async (data, thunkAPI) => {
    let { profileAPI } = getRequest();
    return await profileAPI.leaveAppointment(data);
  }
);

export const confirmAppointment = createAsyncThunk(
  "profile/confirmAppointment",
  async (data, thunkAPI) => {
    let { profileAPI } = getRequest();
    return await profileAPI.confirmAppointment(data);
  }
);

export const toggleParticipant = createAsyncThunk(
  "profile/toggleParticipant",
  async (data, thunkAPI) => {
    let { profileAPI } = getRequest();
    return await profileAPI.toggleParticipant(data);
  }
);

export const editThemeAppointment = createAsyncThunk(
  "profile/editThemeAppointment",
  async (data, thunkAPI) => {
    let { profileAPI } = getRequest();
    return await profileAPI.editThemeAppointment(data);
  }
);

export const checkPasswordAppointment = createAsyncThunk(
  "profile/checkPasswordAppointment",
  async (data, thunkAPI) => {
    let { profileAPI } = getRequest();
    let catchInfo = {
      type: "notFoundVideoCon",
      data: data,
    };
    let result = await profileAPI.editThemeAppointment({
      password: data.value,
      catchInfo,
    });
    let link_url = document.createElement("a");
    let url = `https://vcs.nevozmozhnogo.net.by/c/${data.id}`;
    link_url.target = "_blank";
    link_url.download = url.substring(url.lastIndexOf("/") + 1, url.length);
    link_url.href = url;
    document.body.appendChild(link_url);
    link_url.click();
    document.body.removeChild(link_url);
    return;
  }
);

const extraReducers = {

  [changeEmail.fulfilled]: (state, { payload }) => {
    state.modal = "repeatLinkEmail";
  },

  [forgotPassword.fulfilled]: (state, { payload }) => {
    state.modal = "repeatLinkPassword";
  },

  [changePassword.fulfilled]: (state, { payload }) => {
    state.modal = "successChangePassword";
  },

  [updateFullName.fulfilled]: (state, { payload }) => {
    state.userData = {...state.userData, ...payload};
  },

  [deleteFavoritesCompany.fulfilled]: (state, { payload }) => {
    if(state.table.data) {
      state.table.data = state.table.data.filter(elem => !(elem.companyId === payload))
    }
  },
  [deleteFileById.fulfilled]: (state, { payload }) => {
    if(payload.fileId) {
      state.table.data = state.table.data.filter(elem => elem.fileId !== payload.fileId);
    }
  },

  [deleteMeetings.fulfilled]: (state, { payload }) => {
      state.table.data = state.table.data.filter(elem => elem.id !== payload);
  },

  [setHeroModel.fulfilled]: (state, { payload }) => {
    state.userData.heroModel = payload;
    state.userData.player = payload.name;
  },

  [getStatisticsCompany.fulfilled]: (state, { payload }) => {
    state.statistics = payload;
  },

  [getFavoritesCompany.fulfilled]: (state, { payload }) => {
    state.table.data = payload;
  },

  [getDocumentsUser.fulfilled]: (state, { payload }) => {
    state.table.data = payload;
  },
  [getContactsUser.fulfilled]: (state, { payload }) => {
    state.table.data = payload.contacts;
  },

  [getRequestsUser.fulfilled]: (state, { payload }) => {
    state.table.data = payload;
  },

  [getMeetingsUser.fulfilled]: (state, { payload }) => {
    state.table.data = payload;
  },

  //old

  [getAllCompanies.fulfilled]: (state, action) => {
    state.listCompanies = action.payload;
  },

  [editThemeAppointment.fulfilled]: (state, action) => {
    state.appointments.theme = false;
  },

  [getProfileData.fulfilled]: (state, action) => {
    state.userData = action.payload;
  },

  [getListAppointments.pending]: (state, action) => {
    state.appointments.loading = "listAppointments";
  },
  [getListAppointments.rejected]: (state, action) => {
    state.appointments.loading = false;
  },

  [getListAppointments.fulfilled]: (state, action) => {
    state.appointments.listAppointments = action.payload;
    state.appointments.loading = false;
  },

  [getContactsListForAppoint.fulfilled]: (state, action) => {
    state.appointments.listContacts = {
      ...state.appointments.listContacts,
      ...action.payload,
    };
    state.appointments.loading = false;
  },

  [getListParticipants.pending]: (state, action) => {
    if (action.payload !== "offLoad") {
      state.appointments.loading = "listParcitipants";
    }
  },
  [getListParticipants.rejected]: (state, action) => {
    state.appointments.loading = false;
  },

  [getListParticipants.fulfilled]: (state, action) => {
    if (0) {
      state.appointments.listParticipantsAdd = action.payload;
    } else {
      state.appointments.listParticipants = {
        ...state.appointments.listParticipants,
        ...action.list,
      };
    }
    state.appointments.loading = false;
  },
};

export default extraReducers;
