import React, { useEffect } from "react";
import { TabsContext, WrapTabs, Tab } from "./appTabs.style";
import { useDispatch, useSelector } from 'react-redux';
import { getParamFromUrl } from "../../utils/helpers";
import { useLocation, useNavigate } from "react-router-dom";
import { resetAppState, setAppState } from "../../store/slices/appSlice/appSlice";

export const AppTabs = ({ settings }) => {

  const selectedTab = useSelector(state => state.app.selectedTab);
  const dispatch = useDispatch();
  const navigate =  useNavigate();
  const {pathname} = useLocation();

  useEffect(() => {
    if(!settings.offNav) {
    const setTabByUrl = (tab) => {
      console.log(pathname)
      switch (pathname) {
        case "/profile":
        case "/profile/":
          dispatch(setAppState({selectedTab: "main"}));
          navigate(`?tab=main`)
          break;
      
        default:
          dispatch(setAppState({selectedTab:  tab}));
          navigate(`?tab=${tab}`)
          break;
      }
    };

    (getParamFromUrl("tab") && !selectedTab) && dispatch(setAppState({selectedTab:  getParamFromUrl("tab")}));
    ((!getParamFromUrl("tab") && selectedTab) || (getParamFromUrl("tab") !== selectedTab)) && setTabByUrl(selectedTab);
    (!getParamFromUrl("tab") && !selectedTab) && setTabByUrl();
  }
  }, [dispatch, selectedTab,])


  useEffect(() => () => dispatch(resetAppState("tab")), [dispatch])

  if (!settings) return null;

  return (
      <WrapTabs className="wrap-app-tabs">
        {settings.tabs?.map(tab => (
        <Tab 
        className="app-tabs__tab"
        key={tab.name} 
        active={selectedTab === tab.name}
        onClick={() => {
          dispatch(setAppState({selectedTab:  tab.name}));
          !settings.offNav && navigate(`?tab=${tab.name}`)
        }}
        >{tab.content}
        </Tab>))}
      </WrapTabs>
  );
};
