import React, {useCallback, useEffect, useState} from 'react';
import s from './StandsList.module.css';
import { useSelector } from 'react-redux';

import {useNavigate} from "react-router-dom";
import { useSendUnity } from '../../../../hooks/useSendUnity';


const StandsList = ({localText, selectedStand, setSelectedStand, setStandId, setError, }) => {

    //new code
    let {standExists} = useSelector(state => state.profile);
    const sendUnity = useSendUnity();

    //old code

    // const [allStands, setAllStands] = useState([]);
    const [btnStyle, setBtnStyle] = useState({all: `${s.btnAllStand}`, page: `${s.btnAllStand} ${s.selected}`, currentStand: `${s.btnAllStand}`});
    let navigate = useNavigate();

    useEffect(() => {
        switch(selectedStand) {
            case 'all':
                setBtnStyle({all: `${s.btnAllStand} ${s.selected}`, page: `${s.btnAllStand}`, currentStand: `${s.btnAllStand}`});
                break;
            case 'page':
                setBtnStyle({all: `${s.btnAllStand}`, page: `${s.btnAllStand} ${s.selected}`, currentStand: `${s.btnAllStand}`});
                break;
            case 'currentStand':
                setBtnStyle({all: `${s.btnAllStand}`, page: `${s.btnAllStand}`, currentStand: `${s.btnAllStand} ${s.selected}`});
                break;
            default:
                setBtnStyle({all: `${s.btnAllStand}`, page: `${s.btnAllStand}`, currentStand: `${s.btnAllStand}`});
                break;
        }
    }, [selectedStand]);

    const processingErrors = useCallback((error) => {

        switch(error) {
            case 'Unknown user':
                setError({type: 'unknown user', value: localText.error1});
                break;
            case 'The user does not belong to any company':
                setError({type: 'no company', value: localText.error2});
                break;
            case 'The company of the user has no stands':
                setError({type: 'no stands', value: localText.error3});
                break;
            default: setError({type: 'unknown error', value: error});
                 break;
        }
    },[setError, localText])

    const openStandStatistics = () => {
        sendUnity("Set3dViewerActive", "true")
        setTimeout(() => {
            sendUnity("SetStandName", localStorage.getItem('companyUrl'));
            navigate('/standStatistics');
        }, 300);
    }

    return (
        <div className={s.container}>
            <h1 className={s.pageTitle}>{localText.header}:</h1>
            <div className={s.containerStandList}>
                <p onClick={() => {setSelectedStand('all'); setStandId('all')}} className={btnStyle.all}>{localText.allStands}</p>
                <p onClick={() => {setSelectedStand('page'); setStandId('page')}} className={btnStyle.page}>{localText.infoCompany}</p>

                {/* only for admins while it is not finished */}
                {((localStorage.getItem('role') === 'ROLE_ADMIN' || localStorage.getItem('role') === 'ROLE_VENDOR'))
                && localStorage.getItem('companyUrl') && standExists &&
                <p onClick={openStandStatistics} className={btnStyle.currentStand}>{localText.currentStand}</p>
                }
            </div>
        </div>
    );
}


export default StandsList;
