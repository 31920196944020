import styled from "styled-components";

const WrapFirstStart = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.2s ease-out;

    .lottie {
        transition: opacity 1s ease-out;
        width: 180px;
    }
`;

export {
    WrapFirstStart
}