import {getRequest} from "../api/appService";

const SET_PAVILIONS = 'SET_PAVILIONS';
const SET_STANDS = 'SET_STANDS';
const SET_SELECTED_PAVILIONS = 'SET_SELECTED_PAVILIONS';
const SET_SELECTED_STAND = 'SET_SELECTED_STAND';
const CHOOSE_ANOTHER_PAVILION = 'CHOOSE_ANOTHER_PAVILION';
const TOGGLE_IS_LOADING = 'TOGGLE_IS_FETCHING';
const TOGGLE_PAVILION_LOADING = 'TOGGLE_PAVILION_LOADING';



let initialState = {
    pavilions: [],
    stands: null,
    selectedPavilion: localStorage.getItem('nameKey'),
    selectedPavilionId: localStorage.getItem('pavilionId'),
    selectedPavilionName: localStorage.getItem('selectedPavilion'),
    shortName: localStorage.getItem('shortName'),
    selectedSand: null,
    isLoading: false,
    isPavilionLoading: false,
};

const PavilionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PAVILIONS: {
            return {
                ...state,
                pavilions: action.pavilions
            }
        }

        case SET_STANDS: {
            return {
                ...state,
                stands: action.stands
            }
        }

        case SET_SELECTED_PAVILIONS: {
            return {
                ...state,
                selectedPavilionId: action.pavilion.id,
                selectedPavilion: action.pavilion.key,
                selectedPavilionName: action.pavilion.name,
                shortName: action.pavilion.shortName,
            }
        }
        case CHOOSE_ANOTHER_PAVILION: {
            return {
                ...state,
                selectedPavilionId: null,
                selectedPavilion: '',
                selectedPavilionName: null,
            }
        }

        case TOGGLE_IS_LOADING: {
            return { ...state, isLoading: action.isLoading}
        }

        case TOGGLE_PAVILION_LOADING: {
            return { ...state, isPavilionLoading: action.boolean}
        }

        case SET_SELECTED_STAND: {
            return {
                ...state,
                selectedSand: action.stand
            }
        }

        default:
            return state;
    }
};


export const setPavilions = (pavilions) => ({type: SET_PAVILIONS, pavilions});
export const setStands = (stands) => ({type: SET_STANDS , stands});
export const setSelectedPavilion = (pavilion) => ({type: SET_SELECTED_PAVILIONS, pavilion});
export const setSelectedStand = (stand) => ({type: SET_SELECTED_STAND, stand});
export const chooseAnotherPavilion = (stand) => ({type: CHOOSE_ANOTHER_PAVILION, stand});
export const toggleIsLoading = (isLoading) => ({type: TOGGLE_IS_LOADING, isLoading});
export const togglePavilionLoading = (boolean) => ({type: TOGGLE_PAVILION_LOADING, boolean});

export const getPavilions = (initRequest) => {
    return (dispatch) => {
        let { pavilionsAPI } =  getRequest(initRequest);
        dispatch(toggleIsLoading(true));
        dispatch(setPavilions([]));

        pavilionsAPI.getPavilions().then(data => {
            // console.log(data)
            dispatch(setPavilions(data));
            dispatch(toggleIsLoading(false));
        });
    };
};

export const getStands = (id, initRequest) => {
    return (dispatch) => {
        let { pavilionsAPI } =  getRequest(initRequest);
        dispatch(toggleIsLoading(true));

        pavilionsAPI.getStands(id).then( data => {
            // console.log(data.data.companies)
            dispatch(setStands(data.companies));
            dispatch(toggleIsLoading(false));
            if(data.companies.length > 0) {
                dispatch(setSelectedStand(data.companies[0].url));
            }
        });
    };
};


export const selectStand = (stand) => {
    return (dispatch) => {
        dispatch(setSelectedStand(stand));
    };
};

export const changePavilion = () => {
    return (dispatch) => {
        localStorage.setItem('nameKey', '')
        dispatch(chooseAnotherPavilion());
        dispatch(setStands([]));
    };
}

export default PavilionsReducer;