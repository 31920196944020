import styled, { css } from "styled-components";


const WrapUnity = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 100vh;

  ${({wrapCss}) => wrapCss && css`${wrapCss}`}
`;

export {
  WrapUnity,
}