import styled, {keyframes} from "styled-components";

const gradient = keyframes`
  0% {background-position:0% 47%}
  50% {background-position:100% 54%}
  100% {background-position:0% 47%}
`;

const WrapManageStand = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  .stand-current {
    display: ${({ openMenu }) => (openMenu === "current" ? "block" : "none")};
    width: 283px;
    height: 100%;
    background: #ffffff;
    border-right: 0.5px solid rgba(72, 72, 72, 0.2);
    padding: 16px;
    margin-right: auto;
    z-index: 1;
    .stand-back {
      display: flex;
      align-items: center;
      gap: 10px;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 26px;
        height: 26px;
        background: #f6f6f6;
        border-radius: 10px;
        border: none;
        cursor: pointer;
      }
      h2 {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #363636;
      }
    }
    .profile-head {
      padding-top: 30px;
      text-align: center;
      & > img {
        width: 68px;
        height: 68px;
        border-radius: 50%;
      }

      & > h2 {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #363636;
      }

      & > p {
        font-size: 12px;
        line-height: 14px;
        color: #484848;
        padding-bottom: 24px;
      }
    }

    .hero_company-name {
      display: flex;
      flex-direction: column;
      font-weight: 600;
      font-size: 13px;
      line-height: 110%;
      color: #363636;
      text-align: center;
      &:after,
      &:before {
        content: "";
        height: 0.5px;
        background: #d4d4d4;
        border-radius: 2px;
      }
      &:after {
        margin-top: 16px;
      }
      &:before {
        margin-bottom: 16px;
      }
    }

    .stand-current-view {
      padding-top: 20px;
      .view-head {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        h3 {
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          color: #363636;
        }
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          background: #f4f4f4;
          border-radius: 8px;
          border: none;
          cursor: pointer;
          svg {
            width: 14.18px;
            height: 14.18px;
          }
        }
      }
      img {
        min-width: 250.2px;
        min-height: 250.2px;
        width: 250.2px;
        height: 250.2px;
      }
    }
  }

  .stand__load {
    flex: 1;
    z-index: 1;
    background: linear-gradient(249deg, #e1e2e1, #9a9a9a);
    background-size: 400% 400%;
    -webkit-animation: ${gradient} 3s ease infinite;
    -moz-animation: ${gradient} 3s ease infinite;
    -o-animation: ${gradient} 3s ease infinite;
    animation: ${gradient} 3s ease infinite;
  }

  .stand-select {
    display: ${({ openMenu }) => (openMenu === "select" ? "flex" : "none")};
    flex-direction: column;
    width: 283px;
    height: 100%;
    background: #ffffff;
    border-left: 0.5px solid #d4d4d4;
    overflow: hidden;
    padding: 4px 4px 4px 0;
    color: #363636;
    margin-left: auto;
    .stand-select-scroll {
      flex: 1;
      overflow: scroll;
      overflow-x: hidden;
      padding: 12px 10px 12px 16px;

      .select-main-head {
        display: flex;
        justify-content: space-between;
        padding-bottom: 19px;
        h2 {
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
        }

        button {
          width: 26px;
          height: 26px;
          background: #f5f5f5;
          border-radius: 8.66667px;
          border: none;
          cursor: pointer;
        }
      }

      .stand-sizes {
        display: grid;
        grid-auto-columns: 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto 1fr 1fr;
        gap: 8px;
        padding-bottom: 28px;

        & > div {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 34px;
          background: #f5f5f5;
          border-radius: 4px;
          font-size: 13px;
          line-height: 16px;
          cursor: pointer;
          &.active {
            background: #34c57f;
            color: #ffffff;
          }
        }

        .sizes__head {
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          grid-area: 1 / 1 / 2 / 3;
        }
        .size__8x8 {
          grid-area: 2 / 1 / 3 / 2;
        }
        .size__8x16 {
          grid-area: 2 / 2 / 3 / 3;
        }

        .size__16x16 {
          grid-area: 3 / 1 / 4 / 2;
        }
        .size__Super {
          grid-area: 3 / 2 / 4 / 3;
        }
      }

      .select__head {
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
      }

      .select__subhead {
        font-size: 11.9px;
        line-height: 14px;
        padding: 6px 0 10px;
      }

      .model-preview {
        position: relative;
        padding-bottom: 10px;
        cursor: pointer;
        img {
          min-width: 246px;
          min-height: 141px;
          width: 100%;
          height: 141px;
        }
        p {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 10px;

          text-align: center;
          font-weight: 500;
          font-size: 12px;
          line-height: 110%;
          padding: 5px 8px 4px;
          background: #34c57f;
          color: #ffffff;
        }
      }
    }

    .select-btns {
      display: flex;
      gap: 10px;
      padding: 12px 12px 12px 16px;
      button {
        height: 36px;
        padding: 0 24px;
        font-weight: 500;
        font-size: 13px;
        line-height: 140%;
        border-radius: 8px;
        flex: 1;
      }

      .btns__cancel {
        background: transparent;
        border: 1px solid #34c57f;
        color: #34c57f;
      }
      .btns__save {
        background: #34c57f;
        color: #ffffff;
      }
    }
  }


  .stand-banner-media {
    display: ${({ openMenu }) => (openMenu === "banner" ? "flex" : "none")};
    flex-direction: column;
    width: 283px;
    height: 100%;
    background: #ffffff;
    border-left: 0.5px solid #d4d4d4;
    overflow: hidden;
    padding: 4px 4px 4px 0;
    color: #363636;
    margin-left: auto;
    .banner-media-scroll {
      flex: 1;
      overflow: scroll;
      overflow-x: hidden;
      padding: 12px 10px 12px 16px;

      .banner-media-head {
        display: flex;
        justify-content: space-between;
        padding-bottom: 16px;
        h2 {
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
        }

        button {
          width: 26px;
          height: 26px;
          background: #f5f5f5;
          border-radius: 8.66667px;
          border: none;
          cursor: pointer;
        }
      }

      .media-types {
        display: flex;
        flex-direction: column;
        padding-bottom: 18px;

        .types__head {
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
        }
        .types__subhead {
          font-size: 12px;
          line-height: 14px;
          padding: 6px 0 12px
        }
      }

      .toggle-types-btns {
        display: flex;
        height: 30px;
        background: #f5f5f5;
        border-radius: 10px;
        overflow: hidden;
        .toggle-types-btns__media,
        .toggle-types-btns__3d {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 30px;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          border-radius: 10px;
          background: transparent;
          border: none;
          cursor: pointer;
          flex: 1;
        }
        .toggle-types-btns__media {
          &.active {
            background: #34c57f;
            color: #ffffff;
          }
        }
        .toggle-types-btns__3d {
          &.active {
            background: #34c57f;
            color: #ffffff;
          }
        }
      }
      
      .media-preview {
        position: relative;
        padding-bottom: 10px;
        cursor: pointer;
        img {
          min-width: 246px;
          min-height: 141px;
          width: 100%;
          height: 141px;
        }
        p {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 10px;

          text-align: center;
          font-weight: 500;
          font-size: 12px;
          line-height: 110%;
          padding: 5px 8px 4px;
          background: #34c57f;
          color: #ffffff;
        }
      }
    }

    .media-btns {
      display: flex;
      gap: 10px;
      padding: 12px 12px 12px 16px;
      button {
        height: 36px;
        padding: 0 24px;
        font-weight: 500;
        font-size: 13px;
        line-height: 140%;
        border-radius: 8px;
        flex: 1;
      }

      .btns__cancel {
        background: transparent;
        border: 1px solid #34c57f;
        color: #34c57f;
      }
      .btns__save {
        background: #34c57f;
        color: #ffffff;
      }
    }
  }
`;

export { WrapManageStand };
