import styled from "styled-components";


const WrapSendMessage = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-column-gap: 6px;
    height: 36px;
    margin-top: 16px;

    .enter-file {
        .enter-file__input-hidden {
          position: absolute;
          visibility: hidden;
          max-width: 0;
          max-height: 0;
        }
      }

    .btn-new-mes {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 36px;
        height: 36px;
        background: #F5F5F5;
        border-radius: 10px;
        border: none;
        cursor: pointer;
    }
    .new-message {
        min-height: 36px;
        padding: 10px;
        background: #F5F5F5;
        border-radius: 8px;
        resize: none;
        border: none;
        outline: none;
    }
    .send-mes-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 16px 8px 15px;
        background: #34C57F;
        border-radius: 10px;
        color: #FFF;
        &:disabled {
            background: #F5F5F5;
        }
        span {
            padding-left: 7.6px;
        }
        &.mobile {
            min-width: 36px;
            padding: 2px 1px 0 0;
            border-radius: 50%;
            span {
                display: none;
            }
        }
    }
`;
export {
    WrapSendMessage,
}