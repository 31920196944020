import React from 'react';
import s from './Header.module.css';
import MainLogoPage from '../../MainLogoPage/MainLogoPage';
import { statisticsSvg } from './img/StatisticsSvg';
import { useSelector } from 'react-redux';

const Header = ({selectedStand, localText, date,}) => {

      //new code
  const {userData} = useSelector(state => state.profile);

  //old code

    let conditonSelectedStand = ((selectedStand !== 'all' 
        && selectedStand !== 'page' 
        && (selectedStand === 'page' && date.modifiedTime !== '')));

    return (
        <div className={s.container}>
            <MainLogoPage localText={localText.statistics} logo={statisticsSvg.logo}/>
            <div className={s.info}>
                <h1 className={s.pageTitle}>{localText.pageTitle}</h1>
                <h2 className={s.pageTitle2}>{`${localText.pageTitle2} ${userData?.companyFullName || 'No'}`}</h2>
                {conditonSelectedStand && <div className={s.dateContainer}>
                    <p className={s.selectedItem}>{selectedStand.name}</p>
                    <div>
                        <div className={s.dateTitle} style={{padding: '0 0 10px'}}>
                            <span style={{color: '#A4A3A3'}}>{localText.registered}</span>
                            <span className={s.date}>{date.modifiedTime}</span>
                        </div>
                        <div className={s.dateTitle}>
                            <span style={{color: '#A4A3A3'}}>{localText.lastUpdate}</span>
                            <span className={s.date}>{date.registrationDate}</span>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    );
}

export default Header;
