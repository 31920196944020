
export const profile_en = {
    'header': {
        'language': {
            'ru': 'Russian language',
            'en': 'English',
            'by': 'Belarusian language'
        },
        'manageStands': 'Manage my stand',
        'changeData': 'Change user data',
        'modalLoadfile': 'Uploading a file',
        'editCompanyModal':{
            'header': 'Changing the company will lead to loss of access to statistics, are you sure?',
            'yes': 'Yes',
            'no': 'No',
        },
        'avatarLoader':{
            'upload': 'Upload',
            'scale': 'Scale',
            'save': 'Save',
            'back': 'Back',
            'change': 'Change',
        },
    },
    'profileInfo': {
        'firstName': 'First Name',
        'lastName': 'Last Name',
        'nick': 'Nick',
        'patronymic': 'Patronymic',
        'email': 'E-mail',
        'position': 'Position',
        'company': 'Company',
        'noSuchCompany': 'The name of the company must exactly match its name in the system',
    },
    'passwordFields': {
        'changePassword': 'Change Password',
        'oldPassword': 'Old password',
        'newPassword': 'New password',
        'repeatPassword': 'Repeat password',
        'savePassword': 'Save password',
        'noMatch': 'The password must contain at least one Latin letter of any case and one number, must' +
            ' be at least 8 characters and no more than 32, and must not contain other characters except "_".'
    },
    'navLinks': {
        'documents': 'Documents',
        'meetings': 'Appointments',
        'search': 'Search',
        'back': 'Back',
        'myContacts': 'Contacts',
        'dressingRoom': 'Dressing',
        'support': 'Support',
        'statistics': 'Statistics',
        'pavilions': 'Pavilions',
        'exitBtn': 'Leave the exhibition',
        'freeCameraTitle': 'Free camera',
        'freeCameraDescription': 'Turning on the free camera allows you to see your character not only ' +
            'from the back, but also from any angle convenient for you'
    },
    'back': 'Back',
}