export const companyStandInfo_by = {
    inFavorite: "У выбраныя",
    deleteFavorite: "Выдаліць выбранае",
    requestMeet: "Заяўка на сустрэчу",
    pageCompany: "Старонка экспанента",
    desc: "Апісанне",
    representatives: "Прадстаўнікі",
    notRepresentatives: "Няма прадстаўнікоў",
    representative: "Прадстаўнік",
    documents: "Дакументы",
    notDocuments: "Няма дакументаў",
    document: "Дакумент",
    showMore: "Паказаць больш",
    showLess: "Паказаць менш",
}