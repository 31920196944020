import {createSlice} from '@reduxjs/toolkit';
import extraReducers from './appChatExtraReducers';
import reducers from './appChatReducers';


export const initialState = {
  modal: null,
  contextMenu: null,
  dialogs: null,
  wsClient: null,
  openDialog: {
    messages: null,
    sendFile: null,
  },

  toast: false
};

const appChatSlice = createSlice({
  name: 'appChat',
  initialState,
  reducers,
  extraReducers,
})

const appChatReducer = appChatSlice.reducer;

export const {
  setAppChatState,
  setOpenDialogState,
  resetAppChatState,
  setNewMessage,
  setDialogsChat
} = appChatSlice.actions;

export default appChatReducer;