import React, { useEffect, useState } from "react";
import { WrapProfile } from "./profile.style";
import { AppTabs } from "./../../components/AppTabs/AppTabs";
import { icons_svg } from "../../components/StandContent/icons-svg";
import avatarDefault from "../../components/MainPage/avatarDefaultCircle.svg";
import { filesUrl } from "../../api/generateDomenUrlsApp";
import ProfileMain from "./TabsContent/ProfileMain";
import ProfileData from "./TabsContent/ProfileData";
import ProfileSecurity from "./TabsContent/ProfileSecurity";
import ProfileModals from "./ProfileModals/ProfileModals";

const Profile = ({ userData, selectedTab, handlerActions }) => {
  const { homeIcon, securityIcon, userIcon } = icons_svg;

  const tabSettings = {
    tabs: [
      {
        name: "main",
        content: (
          <>
            {homeIcon} <p>Профиль</p>
          </>
        ),
      },
      {
        name: "data",
        content: (
          <>
            {userIcon} <p>Личные данные</p>
          </>
        ),
      },
      {
        name: "security",
        content: (
          <>
            {securityIcon} <p>Безопасность</p>
          </>
        ),
      },
    ],
  };

  return (
    <>
    <WrapProfile>
      <div className="profile-head">
        <div className="head-background">
          <img
            src={
              userData?.avatar ? `${filesUrl}${userData.avatar}` : avatarDefault
            }
            alt="user avatar"
          />
        </div>
        <h2>
          {userData?.firstName} {userData?.lastName}
        </h2>
        <p>{userData?.position}</p>
      </div>
      <AppTabs settings={tabSettings} />
      <div className="divider"></div>
      {selectedTab === "main" ? (
        <ProfileMain handlerActions={handlerActions} userData={userData} />
      ) : (
        ""
      )}
      {selectedTab === "data" ? (
        <ProfileData handlerActions={handlerActions} userData={userData} />
      ) : (
        ""
      )}
      {selectedTab === "security" ? (
        <ProfileSecurity handlerActions={handlerActions} userData={userData} />
      ) : (
        ""
      )}
    </WrapProfile>
    <ProfileModals/>
    </>
  );
};

export default Profile;
