import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSendUnity } from "../../hooks/useSendUnity";

import Login2 from "./Login2";
import { validateField } from "../../validation/validation";
import { useLocation, useNavigate } from "react-router-dom";
import { authUser } from "../../store/slices/authSlice/authExtraReducers";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { resetAuthState, setAuthState } from "../../store/slices/authSlice/authSlice";
import { FormFieldProps } from "../../utils/classTempates";
import { resetUnityState } from "../../store/slices/unitySlice/unitySlice";
import { resetProfileState } from "../../store/slices/profileSlice/profileSlice";

const LoginContainer = () => {
  //new code
  const dispatch = useDispatch();
  const { token, login, withoutRegistration } = useSelector((state) => state.auth);
  const {
    login: { main: localText },
    errors: errorsText,
  } = useSelector((state) => state.app.appText);
  const sendUnity = useSendUnity();
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const isScene = useSelector(state => state.unity.isScene);

  const settingsFields = {
    email: new FormFieldProps("email", "Email", "Введите email", "email"),
    password: new FormFieldProps(
      "password",
      "Пароль",
      "Введите пароль",
      "password"
    ),
  };

  const settingsBtns= {
    cancelName: "Зарегистрироваться",
    submitName: "Войти",
  };

  const validate = (values) => {
    const errors = {};
    if (!validateField("email", values.email)) {
      errors.email = errorsText.validation.email;
    } else if(!validateField("password", values.password)) {
      errors.password = errorsText.validation.password
    } else {   
    }
    return errors;
  };

  const handlerActions = (type, data) => async (e) => {
    switch (type) {
      case "login":
        let captcha =  await executeRecaptcha("login");
        await dispatch(authUser({loginData: {...e, captcha}, navigate}));
        break;
      case "registration":
        console.log("registration");
        navigate("/registration");
        break;
      case "closeLogin":
      case "loginWithoutReg":
        console.log(withoutRegistration , isScene)
        !withoutRegistration && dispatch(setAuthState({withoutRegistration: true}));
        navigate(withoutRegistration && isScene ? "/" : "/pavilions");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if(!withoutRegistration) {
      dispatch(resetAuthState("login"));
      dispatch(resetUnityState("selectedPavilion"));
      dispatch(resetProfileState());
    }
  }, [dispatch])

  return (
    <Login2
      handlerActions={handlerActions}
      initValues={login}
      validate={validate}
      settingsFields={settingsFields}
      settingsBtns={settingsBtns}
    />
  );
};

export default LoginContainer;
