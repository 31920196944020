import styled from "styled-components";

const WrapHero = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  .hero-current {
    display: ${({openMenu}) => openMenu === "current" ? "block" : "none"};
    width: 283px;
    height: 100%;
    background: #ffffff;
    border-right: 0.5px solid rgba(72, 72, 72, 0.2);
    padding: 16px;
    .hero-back {
      display: flex;
      align-items: center;
      gap: 10px;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 26px;
        height: 26px;
        background: #f6f6f6;
        border-radius: 10px;
        border: none;
        cursor: pointer;
      }
      h2 {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #363636;
      }
    }
    .profile-head {
      padding-top: 30px;
      text-align: center;
      & > img {
        width: 68px;
        height: 68px;
        border-radius: 50%;
      }

      & > h2 {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #363636;
      }

      & > p {
        font-size: 12px;
        line-height: 14px;
        color: #484848;
        padding-bottom: 24px;
      }
    }

    .hero_company-name {
      display: flex;
      flex-direction: column;
      font-weight: 600;
      font-size: 13px;
      line-height: 110%;
      color: #363636;
      text-align: center;
      &:after,
      &:before {
        content: "";
        height: 0.5px;
        background: #d4d4d4;
        border-radius: 2px;
      }
      &:after {
        margin-top: 16px;
      }
      &:before {
        margin-bottom: 16px;
      }
    }

    .hero-current-view {
      padding-top: 20px;
      .view-head {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        h3 {
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          color: #363636;
        }
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          background: #f4f4f4;
          border-radius: 8px;
          border: none;
          cursor: pointer;
          svg {
            width: 14.18px;
            height: 14.18px;
          }
        }
      }
      img {
        min-width: 250.2px;
        min-height: 250.2px;
        width: 250.2px;
        height: 250.2px;
      }
    }
  }

  .hero-select {
    display: ${({openMenu}) => openMenu === "select" ? "flex" : "none"};
    flex-direction: column;
    width: 283px;
    height: 100%;
    background: #ffffff;
    border-left: 0.5px solid #d4d4d4;
    overflow: hidden;
    padding: 4px 4px 4px 0;
    color: #363636;
    .hero-select-scroll {
      flex: 1;
      overflow: scroll;
      overflow-x: hidden;
      padding: 12px 10px 12px 16px;

      .select-main-head {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        h2 {
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
        }

        button {
          width: 26px;
          height: 26px;
          background: #f5f5f5;
          border-radius: 8.66667px;
          border: none;
          cursor: pointer;
        }
      }

      .select__head {
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
      }

      .select__subhead {
        font-size: 12px;
        line-height: 14px;
        padding-top: 6px;
      }

      .select__label {
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        padding: 24px 0 5px;
      }

      .app-select {
        width: 100%;
        margin-bottom: 28px;
      }

      .model-preview {
        position: relative;
        padding-bottom: 10px;
        cursor: pointer;
        img {
          min-width: 246px;
          min-height: 246px;
          width: 246px;
          height: 246px;
        }
        p {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 10px;

          text-align: center;
          font-weight: 500;
          font-size: 12px;
          line-height: 110%;
          padding: 5px 8px 4px;
          background: #34c57f;
          color: #ffffff;
        }
      }
    }

    .select-btns {
      display: flex;
      gap: 10px;
      padding: 12px 12px 12px 16px;
      button {
        height: 36px;
        padding: 0 24px;
        font-weight: 500;
        font-size: 13px;
        line-height: 140%;
        border-radius: 8px;
        flex: 1;
      }

      .btns__cancel {
        background: transparent;
        border: 1px solid #34C57F;
        color: #34C57F;
      }
      .btns__save {
        background: #34C57F;
        color: #FFFFFF;
      }
    }
  }
`;

const WrapHeroModel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background: #d8d8d8;
  max-width: calc(100% - 283px);
  .hero-model {
    width: 100%;
    height: 100%;
    background: #d8d8d8;
    overflow: hidden;
  }
`;

export { WrapHero, WrapHeroModel };
