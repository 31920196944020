import React, { useState }  from 'react';
import s from './DressingRoom.module.css';
import EditingPerson from "./EditingPerson/EditingPerson";
import ViewPerson from "./ViewPerson/ViewPerson";
import BackButton from "../BackButton/BackButton";
import MainLogoPage from '../MainLogoPage/MainLogoPage';
import { dressingSvg } from './img/DressingSvg';
import { connect, useSelector } from 'react-redux';

const DressingDoom = () => {

      //new code
      const {dressingRoom: localText} = useSelector(state => state.app.appText);
  



      //old code

    let id;
    switch (localStorage.getItem('selectedPlayer')) {
        case 'Undefined': id = 0;
            break;
        case 'Nathan': id = 1;
            break;
        case 'Manuel': id = 2;
            break;
        case 'Eric': id = 3;
            break;
        case 'Claudia': id = 4;
            break;
        case 'Carla': id = 5;
            break;
        case 'Sophia': id = 6;
            break;
        case 'Robot': id = 7;
            break;
        default : id = 0;
    }

    const [personId, setPersonId] = useState(id);

    return (<div className={s.mainPage}>
        <BackButton path={'/profile'}/>
        <MainLogoPage localText={localText.dressingRoom} logo={dressingSvg.logo}/>
        <div className={s.dressingPage}>
            <EditingPerson personId={personId} setPersonId={setPersonId} localText={localText}/>
            <ViewPerson personId={personId}/>
        </div>
    </div>);
};

const mapStateToProps = function (state) {
    return {
    };
}

export default connect(mapStateToProps, {})(DressingDoom);
