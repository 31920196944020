import React, {useEffect, useState} from "react";
import s from './DownloadVisitor.module.css'
import { serverUrl } from "../../../../api/generateDomenUrlsApp";

const DownloadVisitors = ({localState, localText, isDownloadFile, standId, language}) => {
    const [btnStyle, setBtnStyle] = useState(`${s.button}`);

    const buttons = [
        {type: 'pdf', title: 'PDF'},
        {type: 'excel', title: 'Excel'}
    ]

    let token = localStorage.getItem('token');


    const baseUrl = `${serverUrl}/userAction/statistics/`;

    useEffect(() => {
        if (!isDownloadFile) {
            setBtnStyle(`${s.button} ${s.disabled}`);
        } else if (isDownloadFile) {
            setBtnStyle(`${s.button}`);
        }
    }, [isDownloadFile]);


    return (
        localState && <div className={s.container}>
            <p className={s.title}>{localText.paragraph}</p>

            <div className={s.buttons}>
                {buttons.map((item, i) => <button key={i} className={`${btnStyle} btnGreen`} disabled={!isDownloadFile}>
                    <a href={baseUrl + `${standId}?type=${item.type}&dateFrom=${localState[0]}&dateTo=${localState[1]}&language=${language}&token=${token}`}
                       download target='_blank' rel="noreferrer">
                        {item.title}
                    </a>
                </button>)}
            </div>
        </div>
    )
}


export default DownloadVisitors;
