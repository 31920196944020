
import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import close from "./close.svg";
import s from "./MediaFiles.module.css";
import Iframe from "./Iframe/Iframe";
import { getRequest } from "../../api/appService";
import Preloader from "../common/Preloader/Preloader";
import ChangeMedia from "./ChangeMedia/ChangeMedia";
import closingSound from "../../sounds/4_closing_menu.mp3";
import {
  setLoadProgressUnity,
  setMediaFromPath,
} from "../../reducers/mainReducer";
import { useHttp } from "../../hooks/useHttp.js";
import { CompanyStandMaterialsContainer } from "../StandContent/CompanyStandMaterials/CompanyStandMaterialsContainer.jsx";
import { useSendUnity } from "../../hooks/useSendUnity";
import { siteUrl } from "../../api/generateDomenUrlsApp";
import { useKeyboardUnity } from "../../hooks/useKeyboardUnity";

function MediaFiles({
  loadProgressUnity,
  setLoadProgressUnity,
  mediaFromPath,
  setMediaFromPath,
  user,
}) {

  const object = useSelector(state => state.unity.copmanyStandFull.object);
  const {media: localText} = useSelector(state => state.app.appText);
  const sendUnity = useSendUnity(); 
  let { initRequest } = useHttp();
  let [isLoading, setIsLoading] = useState(true);
  let [typeId, setTypeId] = useState(null);
  let [path, setPath] = useState(null);
  let [notFound, setNotFound] = useState(null);
  let [isEdit, setIsEdit] = useState(false);
  let [isChangeMedia, setIsChangeMedia] = useState(false);
  let typeId3D = useRef(typeId === 11 ? true : false);
  let _3dViewerActive = useRef(false);
  let { mediaApi, unityAPI } = getRequest(initRequest);
  let userRole = localStorage.getItem("role");
  let userData = useSelector(state => state.profile.userData);
  const onKeyboardUnity = useKeyboardUnity();

  const dispatch = useDispatch();

  // let [threeDLoadingProgress, setThreeDLoadingProgress] = useState(null);
  // let [filesList, setFilesList] = useState(null);
  let navigate = useNavigate();
  let token = localStorage.getItem("token");
  

  let [closeSound, setCloseSound] = useState();

  if (typeId === 11) typeId3D.current = true;

  useEffect(() => {
    if (!closeSound) {
      setCloseSound(new Audio(closingSound));
    }
  }, []);

  // useEffect(() => {
  //     console.log(history.location.pathname)
  // })

  useEffect(() => {
    //
    // console.log('my  ', path, typeId)

    // sometimes keyboard does not work. this log can help us to see why.

    sendUnity("AllowCaptureAllKeyboardInput", "false");

    setIsLoading(true);
    // setPath(null);
    setNotFound(false);
    setTypeId(null);
    // setIsChangeMedia(false);

    async function fetchInfoAPI() {
      console.log(object)
      await unityAPI
        .getCompany({companyKey: object.owner, noStatistics: true})
        .then((data) => {
          if (data.files.length > 0) {
            setTypeId(9);
            // setFilesList(response.data.files)
            setPath(`/company/${object.owner}?onlyInfo=true&infoType=files`);
          } else {
            setTypeId(10);
            setPath(
              `/company/${object.owner}?onlyInfo=true&infoType=textWithLogo`
            );
          }

          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setNotFound(true);
          setIsLoading(false);
        });
    }

    const statisticsHandler = async () =>
      await mediaApi.setStandStatistics(
        object.owner,
        object.id,
        userData ? undefined : "NoHeader"
      );

    async function fetchMyAPI() {
      console.log(object)
      object.owner &&
        (await mediaApi
          .getStandInfo(object.owner, object.id)
          .then(async (data) => {
            statisticsHandler();
            let info = data.info;
            setPath(info);
            let typeId = data["typeId"];
            setTypeId(typeId);
            if (typeId === 11) {
              sendUnity("Set3dViewerActive", "true");
              _3dViewerActive.current = true;
            } else if (typeId === 6) {
              localStorage.setItem("3dViewFolder", info)
            }
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setNotFound(true);
            setIsLoading(false);
          }));
    }

    if (object.name === "Tumba") {
      fetchInfoAPI().then();
      statisticsHandler();
      return;
    }
    if (object.name === "Assistant") {
      setTypeId(10);
      setPath(`/company/${object.owner}?onlyInfo=true&infoType=textWithLogo`);
      statisticsHandler();
    } else {
      fetchMyAPI().then();
    }
  }, [isChangeMedia]);

  const onLoaded = () => {
    setIsLoading(false);
  };

  window._3dModelClosed = () => {
    let checkPath = ["/manageStand", "/standStatistics"].includes(
      mediaFromPath
    );
    closeSound["play"]().then();
    setPath(null);
    setTypeId(null);
    setNotFound(null);
    if (loadProgressUnity?.open)
      setLoadProgressUnity({ open: false, progress: 0 });
    // sometimes keyboard does not work. this log can help us to see why.
    if (_3dViewerActive.current) {
      sendUnity("SetObjectTypeEmptyAndDestroyModel");
      sendUnity("Set3dViewerActive", "false");
      _3dViewerActive.current = false;
    }
    if (checkPath) {
      sendUnity("Set3dViewerActive", "true");
      _3dViewerActive.current = true;
      typeId3D.current && sendUnity("SetStandName", localStorage.getItem("companyUrl"));
    } else {
      onKeyboardUnity();
    }
    onKeyboardUnity();
    navigate(mediaFromPath || "/");
    setMediaFromPath(false);
    typeId3D.current = false;
  };

  useEffect(() => {
     sendUnity("PauseMenu");
    return () => sendUnity("ResumeMenu");
  }, [])

  // 6 - model imgs 
  // 7 - img
  // 8 - video 
  // 9 files
  // 10 - text without logo
  // 11 - model unity

  if (typeId === 6 || typeId === 7 || typeId === 8)
    return (<>
      <CompanyStandMaterialsContainer
        mediaId={Number(path) ? path : new URLSearchParams(path).get("mediaId")}
        typeContentId={typeId}
        companyKey={object.owner}
        setIsLoading={setIsLoading}
      />
      {isLoading && <Preloader/>}
      </>
    );

  if (!typeId) return <Preloader />;

  console.log(typeId)

  return (
    <div
      className={s.mediaPage}
      style={{ height: isEdit || typeId !== 11 ? "100vh" : "70px" }}
    >
      {isLoading && typeId !== 11 && <Preloader/>}

      <div
        className={s.closeWrapper}
        onClick={() => {
          window._3dModelClosed();
        }}
      >
        <img src={close} className={s.closeImg} alt={"close"} />
      </div>

      {(userRole === "ROLE_ADMIN" ||
        (userRole === "ROLE_VENDOR" && user?.companyName === object.owner)) &&
        object.name !== "Tumba" &&
        object.name !== "Assistant" && (
          <button className={s.editingButton} onClick={() => setIsEdit(true)}>
            {localText.editing}
          </button>
        )}

      {typeId === 11 && (
        <button
          className={s.editingButton}
          onClick={() => {
            sendUnity("GoToDefaultPosition", "true")
          }}
        >
          {localText.defaultPosition}
        </button>
      )}

      {isEdit && (
        <ChangeMedia
          setIsEdit={setIsEdit}
          objectStandId={object.id}
          _3dViewerActive={_3dViewerActive}
          setTypeId={setTypeId}
          setIsChangeMedia={setIsChangeMedia}
          isChangeMedia={isChangeMedia}
          companyName={object.owner}
          localText={localText.changeModal}
        />
      )}

      {/* {typeId === 10 && <Communication companyName={companyName} text={text.communication}/>} */}

      {/*/!* company info *!/*/}
      {/*{typeId === 0 && <Iframe src={'https://' + siteAddress + path + "&language=" + localStorage.getItem('language')}*/}
      {/*                         onLoaded={onLoaded}/>}*/}
      {/*/!* image *!/*/}
      {/*{typeId === 1 && <Image src={filesUrl + path} onLoaded={onLoaded}/>}*/}
      {/*/!* video *!/*/}
      {/*{typeId === 2 && <Video src={filesUrl + path} onLoaded={onLoaded}/>}*/}
      {/*/!* -- *!/*/}
      {/*{typeId === 4 && window.open(path, '_blank')}*/}
      {/*/!* youTube video *!/*/}
      {/*{typeId === 5 && <VideoIframe src={path} onLoaded={onLoaded}/>}*/}

      {/* new window materails for three types 6, 7, 8*/}

      {/* 360 model viewer (72 images) */}
      {/* {(typeId === 6 ) && <Iframe src={_3dViewAddress + '/3dView/'} onLoaded={onLoaded} setIsLoading={setIsLoading}/>} */}

      {/* image */}
      {/* {(typeId === 7 ) && <Iframe src={interfaceProtocol + '://' + siteAddress + path} onLoaded={onLoaded} setIsLoading={setIsLoading}/>} */}

      {/* video */}
      {/* {(typeId === 8 ) && <Iframe src={interfaceProtocol + '://' + siteAddress + path} onLoaded={onLoaded} setIsLoading={setIsLoading}/>} */}

      {/* files */}
      {/*{(typeId === 9) && filesList && <File files={filesList} text={text}/>}*/}
      {typeId === 9 && (
        <Iframe
          src={
            token
              ? siteUrl + path + "&token=" + token
              : siteUrl + path
          }
          onLoaded={onLoaded}
          setIsLoading={setIsLoading}
        />
      )}

      {/* company info */}
      {/* {(typeId === 10 ) && <Iframe src={'https://' + siteAddress + path} onLoaded={onLoaded} setIsLoading={setIsLoading}/>} */}

      {notFound && (
        <div className={s.errorWrapper}>
          <p className={s.errorText}>404 Not found</p>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    mediaFromPath: state.main?.mediaFromPath,
    loadProgressUnity: state.main?.loadProgressUnity,
    user: state.auth?.user,
  };
};

export default connect(mapStateToProps, {
  setMediaFromPath,
  setLoadProgressUnity,
})(MediaFiles);
