import React, { useEffect, useState } from "react";
import Profile from "./Profile";
import { useSelector, useDispatch } from "react-redux";
import { setAppState } from "../../store/slices/appSlice/appSlice";
import { setProfileState } from "../../store/slices/profileSlice/profileSlice";
import { useNavigate } from "react-router-dom";
import { resetAuthState } from "../../store/slices/authSlice/authSlice";
import { setPavilionsState } from "../../store/slices/unitySlice/unitySlice";
import { useResetIframeUnity } from "../../hooks/useResetIframeUnity";
import { updateFullName } from "../../store/slices/profileSlice/profileExtraReducers";

const ProfileContainer = () => {
  const selectedTab = useSelector((state) => state.app.selectedTab);
  let { standExists, superStandExists, userData } = useSelector(
    (state) => state.profile
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const resetUnity = useResetIframeUnity();

  const handlerActions = (type, data) => async (e) => {
    switch (type) {
      case "cancel":
        dispatch(setAppState({selectedTab:  "main"}));
        break;
      case "changeUserData":
        console.log("changeUserData", e);
        dispatch(updateFullName(e))
        break;
      case "registration":
        break;
      case "changePassword":
      case "changeEmail":
      case "forgotPassword":
        dispatch(setProfileState({ modal: type }));
        break;
      case "goUnity":
        navigate("/");
        break;
      case "exitProfile":
        dispatch(resetAuthState("login"));
        navigate("/login");
        resetUnity();
        dispatch(setPavilionsState({ selectedPavilion: {} }));
        break;
      default:
        break;
    }
  };

  return (
    <Profile
      userData={userData}
      selectedTab={selectedTab}
      handlerActions={handlerActions}
    />
  );
};

export default ProfileContainer;
