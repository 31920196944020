import React, { useEffect, useRef } from "react";
import { WrapHeroModel } from "./hero.style";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";

const HeroModel = ({
  viewHeroModel
}) => {
  const heroRef = useRef();
  const onWindowResizeRef = useRef();


  const updateCanvas = (viewHeroModel) => {
    heroRef.current.innerHTML = "";

    const node = heroRef.current;

    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xd8d8d8);

    const light = new THREE.PointLight();
    light.position.set(0.8, 1.4, 1.0);
    scene.add(light);

    const ambientLight = new THREE.AmbientLight();
    scene.add(ambientLight);

    const camera = new THREE.PerspectiveCamera(
      87,
      (window.innerWidth * 0.3) / window.innerHeight,
      0.8,
      1000
    );
    camera.position.set(45, 135, 120);

    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(heroRef.current.clientWidth, heroRef.current.clientHeight);
    // let img = document.createElement('img');
    // img.src = spinner;
    // node.appendChild(img);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.enableZoom = false;
    controls.minPolarAngle = Math.PI / 2 - 0.7;
    controls.maxPolarAngle = Math.PI / 2;
    controls.target.set(0, 60, 0);

    const updateNodeChild = () => {
      // node.removeChild(node.firstChild)
      node.appendChild(renderer.domElement);
    };

    const fbxLoader = new FBXLoader();
    console.log("start loading");

    fbxLoader.load(
      viewHeroModel.model,
      (object) => {
        object.traverse(function (child) {
          if (child.isMesh) {
            if (viewHeroModel.id === 7) {
              let load = 0;
              child.material = [
                new THREE.MeshBasicMaterial({
                  map: new THREE.TextureLoader().load(
                    viewHeroModel.textureBody,
                    () => {
                      load++;
                      if (load === 3) updateNodeChild();
                    }
                  ),
                }),
                new THREE.MeshBasicMaterial({
                  map: new THREE.TextureLoader().load(
                    viewHeroModel.textureArm,
                    () => {
                      load++;
                      if (load === 3) updateNodeChild();
                    }
                  ),
                }),
                new THREE.MeshBasicMaterial({
                  map: new THREE.TextureLoader().load(
                    viewHeroModel.textureLegs,
                    () => {
                      load++;
                      if (load === 3) updateNodeChild();
                    }
                  ),
                }),
              ];
            }
            else if(viewHeroModel.id === 8) {
              let load = 0;
              child.material = [
                new THREE.MeshBasicMaterial({
                  map: new THREE.TextureLoader().load(
                    viewHeroModel.avatar_textureBody1,
                    () => {
                      load++;
                      if (load === 7) updateNodeChild();
                    }
                  ),
                }),
                // new THREE.MeshBasicMaterial({
                //   map: new THREE.TextureLoader().load(
                //     viewHeroModel.avatar_textureBody2,
                //     () => {
                //       load++;
                //       if (load === 7) updateNodeChild();
                //     }
                //   ),
                // }),
                // new THREE.MeshBasicMaterial({
                //   map: new THREE.TextureLoader().load(
                //     viewHeroModel.avatar_textureBody3,
                //     () => {
                //       load++;
                //       if (load === 7) updateNodeChild();
                //     }
                //   ),
                // }),
                new THREE.MeshBasicMaterial({
                  map: new THREE.TextureLoader().load(
                    viewHeroModel.avatar_textureEyes1,
                    () => {
                      load++;
                      if (load === 7) updateNodeChild();
                    }
                  ),
                }),
                // new THREE.MeshBasicMaterial({
                //   map: new THREE.TextureLoader().load(
                //     viewHeroModel.avatar_textureEyes2,
                //     () => {
                //       load++;
                //       if (load === 7) updateNodeChild();
                //     }
                //   ),
                // }),
                // new THREE.MeshBasicMaterial({
                //   map: new THREE.TextureLoader().load(
                //     viewHeroModel.avatar_textureEyes3,
                //     () => {
                //       load++;
                //       if (load === 7) updateNodeChild();
                //     }
                //   ),
                // }),
                new THREE.MeshBasicMaterial({
                  map: new THREE.TextureLoader().load(
                    viewHeroModel.avatar_textureHead1,
                    () => {
                      load++;
                      if (load === 7) updateNodeChild();
                    }
                  ),
                }),
                // new THREE.MeshBasicMaterial({
                //   map: new THREE.TextureLoader().load(
                //     viewHeroModel.avatar_textureHead2,
                //     () => {
                //       load++;
                //       if (load === 7) updateNodeChild();
                //     }
                //   ),
                // }),
                // new THREE.MeshBasicMaterial({
                //   map: new THREE.TextureLoader().load(
                //     viewHeroModel.avatar_textureHead3,
                //     () => {
                //       load++;
                //       if (load === 7) updateNodeChild();
                //     }
                //   ),
                // }),
                new THREE.MeshBasicMaterial({
                  map: new THREE.TextureLoader().load(
                    viewHeroModel.avatar_textureLeft,
                    () => {
                      load++;
                      if (load === 7) updateNodeChild();
                    }
                  ),
                }),
                                new THREE.MeshBasicMaterial({
                  map: new THREE.TextureLoader().load(
                    viewHeroModel.avatar_textureRight,
                    () => {
                      load++;
                      if (load === 7) updateNodeChild();
                    }
                  ),
                }),

                new THREE.MeshBasicMaterial({
                  map: new THREE.TextureLoader().load(
                    viewHeroModel.avatar_textureTeeth1,
                    () => {
                      load++;
                      if (load === 7) updateNodeChild();
                    }
                  ),
                }),
                // new THREE.MeshBasicMaterial({
                //   map: new THREE.TextureLoader().load(
                //     viewHeroModel.avatar_textureTeeth2,
                //     () => {
                //       load++;
                //       if (load === 7) updateNodeChild();
                //     }
                //   ),
                // }),
                // new THREE.MeshBasicMaterial({
                //   map: new THREE.TextureLoader().load(
                //     viewHeroModel.avatar_textureTeeth3,
                //     () => {
                //       load++;
                //       if (load === 7) updateNodeChild();
                //     }
                //   ),
                // }),
                new THREE.MeshBasicMaterial({
                  map: new THREE.TextureLoader().load(
                    viewHeroModel.avatar_textureColor,
                    () => {
                      load++;
                      if (load === 7) updateNodeChild();
                    }
                  ),
                }),
                // new THREE.MeshBasicMaterial({
                //   map: new THREE.TextureLoader().load(
                //     viewHeroModel.avatar_textureNormal,
                //     () => {
                //       load++;
                //       if (load === 7) updateNodeChild();
                //     }
                //   ),
                // }),


                // new THREE.MeshBasicMaterial({
                //   map: new THREE.TextureLoader().load(
                //     viewHeroModel.avatar_textureRoughness,
                //     () => {
                //       console.log(viewHeroModel, load)
                //       load++;
                //       if (load === 7) updateNodeChild();
                //     }
                //   ),
                // }),
              ];
            }
            else {
              const texture = new THREE.TextureLoader().load(
                viewHeroModel.texture,
                function () {
                  updateNodeChild();
                }
              );
              child.material = new THREE.MeshBasicMaterial({ map: texture });
            }
          }
        });
        object.position.set(0, -40, 0);
        scene.add(object);
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },
      (error) => {
        console.log(error);
      }
    );

    // ZOOM
    // document.addEventListener( 'mousewheel', (event) => {
    //     camera.position.z +=event.deltaY/100;
    // })


    function onWindowResize() {
      console.log(heroRef.current.offsetWidth);
      camera.aspect =
        heroRef.current.offsetWidth / heroRef.current.offsetHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(
        heroRef.current.offsetWidth,
        heroRef.current.offsetHeight
      );
      render();
    }

    onWindowResizeRef.current = onWindowResize;

    onWindowResizeRef.current();

    window.addEventListener("resize", onWindowResizeRef.current, false);

    function animate() {
      requestAnimationFrame(animate);
      controls.update();
      render();
    }

    function render() {
      renderer.render(scene, camera);
    }

    animate();
  };

  useEffect(() => () => window.removeEventListener("resize", onWindowResizeRef.current,), [])

  useEffect(() => {
    viewHeroModel && updateCanvas(viewHeroModel);
  }, [viewHeroModel]);


  if(!viewHeroModel) return null;

  return (
    <WrapHeroModel>
      <div id={"canvas"} ref={heroRef} className={"hero-model"} />
    </WrapHeroModel>
  );
};

export default HeroModel;
