
export const profile_by = {
    'header': {
        'language': {
            'ru': 'Руская мова',
            'en': 'Англійская мова',
            'by': 'Беларуская мова'
        },
        'changeData': 'Змяніць дадзеныя',
        'manageStands': 'Упраўленне маім стэндам',
        'modalLoadfile': 'Загрузка файла',
        'editCompanyModal':{
            'header': 'Змена кампаніі прывядзе да страты доступу да статыстыкі, вы ўпэўненыя?',
            'yes': 'Так',
            'no': 'Не',
        },
        'avatarLoader':{
            'upload': 'Загрузіць',
            'scale': 'Маштаб',
            'save': 'Захаваць',
            'back': 'Назад',
            'change': 'Змяніць',
        },
    },
    'profileInfo': {
        'firstName': 'Імя',
        'lastName': 'Прозвішча',
        'patronymic': 'Па бацьку',
        'nick': 'Нік',
        'email': 'Электронная пошта',
        'position': 'Пасаду',
        'company': 'Кампанія',
        'aboutMe': 'Пра мяне',
        'noSuchCompany': 'Назва кампаніі павінна ў дакладнасці супадаць з яе назвай у сістэме',
    },
    'passwordFields': {
        'changePassword': 'Змяніць пароль',
        'oldPassword': 'Стары пароль',
        'newPassword': 'Новы пароль',
        'repeatPassword': 'Паўтарыце пароль',
        'savePassword': 'Захаваць пароль',
        'noMatch': 'Пароль павінен змяшчаць як мінімум адну лацінскую літару любога рэгістра і адну лічбу, быць ' +
            'не менш за 8 знакаў і не больш за 32 і не ўтрымліваць іншых сімвалаў, акрамя "_".'
    },
    'navLinks': {
        'documents': 'Дакументы',
        'meetings': 'Сустрэчы',
        'search': 'Пошук',
        'back': 'Назад',
        'myContacts': 'Кантакты',
        'dressingRoom': 'Персанаж',
        'support': 'Дапамога',
        'statistics': 'Статыстыка',
        'pavilions': 'Павільёны',
        'exitBtn': 'Пакінуць выставу',
        'freeCameraTitle': 'Свабодная камера',
        'freeCameraDescription': 'Ўключэнне свабоднай камеры дазваляе бачыць Вашага персанажа не толькі ' +
            'са спіны, але і з любога зручнага для Вас ракурсу'
    },
    'back': 'Назад',
}