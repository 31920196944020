
export const login_ru = {
    main: {
        'signUp': 'Зарегистрироваться',
        'login': 'Вход',
        'loginBtn': 'Войти',
        'rememberBtn': 'Запомнить меня',
        'forgotPassword': 'Не можете получить доступ к своей учетной записи?',
        'sendPassword': 'Письмо с новым паролем отправлено вам на почту',
        'withoutRegistration': 'Войти без регистрации',
        'input': {
            'email': 'Электронная почта',
            'password': 'Пароль',
        },
    },
    confirm: {
        'user': 'Пользователь: ',
        'pavilion': 'Павильон: ',
        'continue': 'Продолжить',
        'changeUser': 'Сменить пользователя',
        'changePavilion': 'Выбор другого павильона',
        'alreadyLoggedIn': 'Вход в данный аккаунт уже выполнен',
        'loading': 'Загрузка стендов...',
    },
}