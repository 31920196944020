import { useSendUnity } from './useSendUnity';

export const useKeyboardUnity = () => {

    const sendUnity = useSendUnity();
    
    return (value) => {
        setTimeout(() => {
            try {
                document.querySelector("#unityIframe").focus()
                sendUnity("AllowCaptureAllKeyboardInput", value || "true");
            } catch (error) {}
          }, 200);
    }
}


