import styled from "styled-components";

const WrapRequests = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: -12px;
  padding-right: 8px;

  .wrap-line-time {
    display: flex;
    align-items: center;
    flex: 1;
    & > div {
      height: 0.5px;
      flex: 1;
      background: #d4d4d4;
    }

    .line__time {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 28px;
      background: #eef5ff;
      border-radius: 16px;
      font-size: 12px;
      line-height: 110%;
      color: #363636;
      margin: 0 12px;
    }
  }

  .wrap-meeting {
    display: flex;
    padding: 12px;
    min-width: 300px;
    flex: 1;
    min-width: 100%;
    min-height: 76px;
    background: #f5f5f5;
    border-radius: 4px;

    .meeting__logo {
      width: 53px;
      height: 53px;
      min-width: 53px;
      min-height: 53px;
      border-radius: 50%;
      &.background {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #34c57fcc;
        font-weight: 600;
        font-size: 25px;
        line-height: 110%;
        color: #ffffff;
      }
    }
    .meeting-info {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      padding-left: 12px;

      .meeting__head {
        font-weight: 500;
        font-size: 14px;
        line-height: 110%;
        color: #363636;
        &.new {
          font-weight: 700;
        }

        .meeting__new {
          font-weight: 600;
          font-size: 8px;
          line-height: 110%;
          letter-spacing: 0.02em;
          text-transform: uppercase;
          color: #ffffff;
          background: #34c57f;
          border-radius: 16px;
          padding: 1.5px 6px;
          margin-left: 6px;
        }
      }
      .meeting__date {
        font-size: 13px;
        line-height: 110%;
        color: #363636;
        padding: 6px 0 4px;
        strong {
          font-weight: 600;
          font-size: 13px;
          line-height: 110%;
          color: #363636;
        }
      }
    }
    .info__more {
      border: none;
      background-color: #efefef;
      border-radius: 8px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const WrapFilterBtns = styled.div`
  display: flex;
  gap: 12px;
  .app-select {
    height: 34px;
    min-width: 195px;
  }
`;

export { WrapRequests, WrapFilterBtns };
