import React from "react";
import { WrapProfileModals } from "./profileModals.style";
import { svg_icons } from "../../../assets/imgs/svg_icons";
import ChangePassword from "./ChangePassword";
import EmailForm from "./EmailForm";
import { useDispatch, useSelector } from "react-redux";
import { setProfileState } from "../../../store/slices/profileSlice/profileSlice";
import { changeEmail, changePassword, forgotPassword } from "../../../store/slices/profileSlice/profileExtraReducers";
import ResetLinkEmail from "./ResetLinkEmail";

const ProfileModals = ({}) => {
  const { closeIcon } = svg_icons;
  const modal = useSelector((state) => state.profile.modal);
  const dispatch = useDispatch();

  const handlerActions = (type, data) => async (e) => {
    switch (type) {
      case "closeModal":
        dispatch(setProfileState({ modal: false }));
        break;
      case "sendChangedPassword":
        console.log("sendChangedPassword", e);
        dispatch(changePassword(e));
      case `submit_${modal}`:
        let sendFunc = {forgotPassword,  changeEmail};  
        console.log(`submit_${modal}`, e);
        dispatch(sendFunc[modal](e));
        break;
      default:
        break;
    }
  };

  const getModalByType = (key) => {
    switch (key) {
      case "changePassword":
        return <ChangePassword handlerActions={handlerActions} />;
      case "changeEmail":
      case "forgotPassword":
        return <EmailForm handlerActions={handlerActions} sendType={key}/>;
      case "successChangePassword":
        return (
          <button
            className="modal__submit-btn"
            onClick={handlerActions("closeModal")}
          >
            {"Понятно"}
          </button>
        );
      case "repeatLinkPassword":
      case "repeatLinkEmail":
      // return <ResetLinkEmail handlerActions={handlerActions}/>
      return (
        <button
          className="modal__submit-btn"
          onClick={handlerActions("closeModal")}
        >
          {"Понятно"}
        </button>
      );
      default:
        return "";
    }
  };

  const text = {
    changePassword: {
      head: "Новый пароль",
      subhead: "Для изменения введите текущий пароль <br/> и добавьте новый",
    },
    changeEmail: {
      head: "Новый email",
      subhead:
        "Введите новый email и перейдите по ссылке <br/> в почте для подтверждения",
    },
    forgotPassword: {
      head: "Email для смены пароля",
      subhead:
        "Введите email, на который мы отправим временный <br/> пароль для вашего аккаунта",
    },
    repeatLinkPassword: {
      head: "Email для смены пароля!",
      subhead: " На указанный вами адрес электронной почты был <br/> отправлен временный пароль",
    },
    repeatLinkEmail: { 
      head: "Подтвердите email",
      subhead: "На адрес вашей электронной почты <br/> была отправлена ссылка",
    },
  };

  if (!modal) return null;

  return (
    <WrapProfileModals>
      <div
        className="background-modal"
        onClick={handlerActions("closeModal")}
      />
      <div className="profile-modal">
        <button className="close-btn" onClick={handlerActions("closeModal")}>
          {closeIcon}
        </button>
        <h2 className="modal__head">{text[modal]?.head}</h2>
        <p
          className="modal__subhead"
          dangerouslySetInnerHTML={{ __html: text[modal]?.subhead || "" }}
        />
        {getModalByType(modal)}
      </div>
    </WrapProfileModals>
  );
};

export default ProfileModals;
