import React from 'react';
import folderIcon from './img/folder.png';
import s from './FolderItem.module.css';
import { Link } from 'react-router-dom';


const FolderItem = (props) => {
    let size = 0;
    const oneKb = 1024;
    const oneMb = 1048576;
    const oneGb = 1073741824;

    if (props.size >= oneKb && props.size < oneMb) {
        size = (props.size / oneKb).toFixed(1) + ' Кб'
    } else if (props.size >= oneMb && props.size < oneGb) {
        size = (props.size / oneMb).toFixed(1) + ' Мб'
    } else if (props.size >= oneGb) {
        size = (props.size / oneGb).toFixed(1) + ' Гб'
    }



    return (
        <>
            <hr/>
            <Link className={s.container} to={`/profile/documents?search=${props.name}`}>
                <img src={folderIcon} className={s.image} alt={''}/>
                <p className={s.name}>{props.name}</p>
                <p>{props.modifiedTime}</p>
                <p className={s.size}>{size}</p>
            </Link>

        </>
    )
}

export default FolderItem;
