
export const documentPage_by = {
    'headerPage': {
        'myContacts': 'Мае дакументы',
        'search': 'Пошук',
        'pageTitle': 'Спіс маіх дакументаў',
        'uploadFile': 'Загрузіць файл',
        'createFolder': 'Стварыць папку',
    },
    'noData': "Папка пустая",
    'noDataFind': "Файлы не знойдзеныя",
    'modalLoadfile': 'Загрузка файла',
    'modalMoveFolder': {
        'btnMove': 'Перамясціць',
        'noMoveFolderPart1': 'Немагчыма перамясціць папку',
        'noMoveFolderPart2': 'у саму сябе',
        'noSelectFile': 'Немагчыма выбраць, так як гэта не папка',
    },
    'directory': {
        'myDisk': 'Мой дыск',
        'newFolderModal': {
            'header': 'Новая папка',
            'closeModal': 'Адмена',
            'createFolder': 'Стварыць',
        },
        'contextMenu': {
            'createFolder': 'Стварыць папку',
            'uploadFile': 'Загрузіць файл',
            'select': 'Выдзеліць',
            'closeSelect': 'Закрыць выдзяленне',
        }
    },
    'table' :{
        'headerTable': {
            'name': 'Имя',
            'lastModified': 'Апошняе змяненне',
            'sizeFile': 'Памер',
            'type': 'Тып',
        },
        'bodyTable': {
            'byte': 'б',
            'kilobyte': 'Кб',
            'megabyte': 'Мб',
            'gigabyte': 'Гб',
            'typeFolder': 'Папка',
        },
        'contextMenu': {
            'deleteBtn': 'Выдаліць',
            'moveFolder': 'Перамясціць',
            'downloadBtn': 'Скачаць',
            'renameBtn': 'Перайменаваць',
            'okBtn': 'Ок',
            'cancelBtn': 'Адмена',
            'select': 'Выдзеліць',
            'closeSelect': 'Закрыць выдзяленне',
        },
    },
    'selectControl': {
        'quantity': 'Колькасць',
        'actions': 'Дзеянне',
        'apply': 'Прымяніць', 
        'deleteContent': 'Выдаліць',
        'moveContent': 'Перамясціць',
    },
    'modal': {
        'yes': 'Так',
        'no': 'Не',
        'piece': 'шт.',
        'confirmDeleteObjects': "Вы сапраўды хочаце выдаліць гэтыя аб'екты",
        'confirmDeleteObject': "Вы сапраўды хочаце выдаліць гэты аб'ект",
        'confirmDeleteFile': 'Абраны вамі файл выкарыстоўваецца ў іншым месцы. Вы сапраўды жадаеце яго выдаліць?',
        'confirmDeleteFolder': 'Вы сапраўды жадаеце выдаліць папку',
    }
}