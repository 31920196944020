import {createSlice} from '@reduxjs/toolkit';
import reducers from './unityReducers';
import extraReducers from './unityExtraReducers';
import { handlerLocalStorage } from '../../../services/handlerLocalStorage.service';
import { getParamFromUrl } from '../../../utils/helpers';

const {get} = handlerLocalStorage();

export const initialStateUnity = {
  //unity state
  provider: null,
  providerId: null,
  builds: null,
  progress: 0,
  isProgress: false,
  downloadBuild: false,
  isScene: false,
  isSceneStand: false,
  wsClient: null,
  wrapCss: null,
  networkUser: null,
  companyIdForRequest: null,
  //pavilions state
  pavilions: {
    list: [],
    selectedPavilion: {
      id: getParamFromUrl("pavilionId") || get("pavilionId"),
      shortName: getParamFromUrl("pavilionId") === get("pavilionId") ? get('shortName') : null,
    }
  },
  copmanyStandFull: {
    object: {
      name: null,
      id: null,
      owner: null,
      info: null,
      typeId: null,
    },
    company: null,
    openInfoStand: null,
  },
  manageStand: {
    loader: 'imgStands',
    sizeStand: '8x8',
    selectedStand: 'current',
    open: false,
  },
};

const unitySlice = createSlice({
  name: 'unity',
  initialState: initialStateUnity,
  reducers,
  extraReducers,
})

const unityReducer = unitySlice.reducer;

export const {setUnityState, setPavilionsState, setSelectedPavState, setManageStandState, setCopmanyStandFull, resetUnityState} = unitySlice.actions;

export default unityReducer;