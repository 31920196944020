import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CompanyStandInfo from './CompanyStandInfo';
import { useHttp } from '../../../hooks/useHttp';
import { useSendUnity } from '../../../hooks/useSendUnity';
import { getCopmanyStandFull, toggleFavoritesCompany } from '../../../store/slices/unitySlice/unityExtraReducers';
import { resetUnityState, setCopmanyStandFull, setUnityState } from '../../../store/slices/unitySlice/unitySlice';
import { useKeyboardUnity } from '../../../hooks/useKeyboardUnity';
import { setAppState } from '../../../store/slices/appSlice/appSlice';
import downloadFile from '../../../services/downloadFile.service';
import { filesUrl, serverUrl } from '../../../api/generateDomenUrlsApp';
import { svg_icons } from './../../../assets/imgs/svg_icons';
import { useOpenDialogNotFromChat } from '../../../hooks/useOpenDialogNotFromChat';
import { setAppChatState } from '../../../store/slices/appChatSlice/appChatSlice';
import { getRequest } from '../../../api/appService';


const CompanyStandInfoContainer = () => {

    const [localState, setLocalState] = useState({
        moreInfo: false,
      });
    const sendUnity = useSendUnity(); 
    const {company, openInfoStand, } = useSelector((state) =>  state.unity.copmanyStandFull,);
    const selectedTab = useSelector(state => state.app.selectedTab);
    const {companyStandInfo: localText} = useSelector(state => state.app.appText);
    const { downloadIcon, messageIcon, blockIcon, plusInCircle, minusInCircle } = svg_icons;
    const onKeyboardUnity = useKeyboardUnity();
    const dispatch = useDispatch()
    let { initRequest } = useHttp();
    const openDialogNotFromChat = useOpenDialogNotFromChat();

    const handlerActions = (type, data) => async (e) => {
        switch (type) {
            case "contextUser":
                let block = data.block ===  0 || data.block ===  2;
                let contact = data.contact;
                dispatch(
                    setAppState({
                      contextMenu: {
                        currentTarget: e.currentTarget,
                        listMenu: [
                            {
                                onClick: () => openDialogNotFromChat({mobile: true, dialogUser: data})   ,
                                icon: messageIcon,
                                text: "writeMes",
                            },
                            {
                                onClick: () =>  
                                dispatch(setAppChatState({
                                    key: "modal", 
                                    value: {type: block ? "blockUser" : "unblockUser", 
                                    content: data
                                }})),
                                icon: blockIcon,
                                text: block ? "block" : "unblock",
                            },
                            {
                                onClick: () =>  
                                dispatch(setAppChatState({
                                    key: "modal", 
                                    value: {type: contact ? "removeContact" : "addToContact", 
                                    content: data
                                }})),
                                icon: contact ? minusInCircle : plusInCircle,
                                text: contact ? "removeFromContact" : "addFromContact",
                            },
                        ],
                      },
                    })
                  );
                break;
            case "contextDocumnet":
                dispatch(
                    setAppState({
                      contextMenu: {
                        currentTarget: e.currentTarget,
                        listMenu: [
                          {
                            onClick: () =>
                              downloadFile({type: "base", data: {
                                url: `${filesUrl}${data.src}`,
                                fileName: data.name,
                              }}),
                            icon: downloadIcon,
                            text: "downloadFile",
                          },
                        ],
                      },
                    })
                  );
                break;
            case "moreInfo":
                setLocalState((state) => ({ ...state, moreInfo: !state.moreInfo }));
                break;
            case "close":
                dispatch(resetUnityState("copmanyStandFull"));
                onKeyboardUnity()
                break;
            case "sendRequest":
                dispatch(setUnityState({companyIdForRequest: company.id}));
                break;
            case "toggleFavorite":
                dispatch(toggleFavoritesCompany({method: data.isFavorite ? "delete" : "post", id: data.id}));
                break;
        
            default:
                break;
        }
    };

    const tabSettings = {
        offNav: true,
        tabs: [
          {
            name: "desc",
            content: (
                "Описание"
            ),
          },
          {
            name: "repr",
            content: (
                "Представители"
            ),
          },
          {
            name: "docs",
            content: (
                "Документы"
            ),
          },
        ],
      };


      useEffect(() => {
        dispatch(getCopmanyStandFull({companyKey: openInfoStand}));
      }, [dispatch]);

    return <CompanyStandInfo 
    handlerActions={handlerActions}
    localState={{...localState, info: company}}
    tabSettings={tabSettings}
    selectedTab={selectedTab}
    localText={localText}
    setLocalState={setLocalState}/>;
}

export default CompanyStandInfoContainer;