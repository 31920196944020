import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';

import 'date-fns';
import ru from "date-fns/locale/ru";
import en from "date-fns/locale/en-US";
import by from "date-fns/locale/be";
import Grid from '@material-ui/core/Grid';
import {ThemeProvider} from "@material-ui/styles";
import {connect} from 'react-redux';
import {createTheme } from '@material-ui/core';

const DateForm = ({selectedDate, handleDateChange, localText, type = '', language, minDate, maxDate, disableFuture}) => {

    const spanStyle = {
        padding: '5px 10px 0 10px',
        color: '#5DB075',
        fontSize: '16px'
    }

    const materialTheme = createTheme ({
        overrides: {
            MuiPickersDay: {
                day: {
                    color: '#5DB075',
                },
                daySelected: {
                    backgroundColor: '#5DB075',
                },
            },
        },
    });

    let locale = {ru, en, by}[language];

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
            <span style={spanStyle}>{localText}</span>
            <Grid container justifyContent='space-around'>
                <ThemeProvider theme={materialTheme}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant='inline'
                        format='dd/MM/yyy'
                        margin='normal'
                        id='date-picker'
                        minDate={minDate}
                        maxDate={maxDate}
                        disableFuture={disableFuture}
                        value={selectedDate}
                        onChange={(date) => handleDateChange(date, type)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date'
                        }}
                    />
                </ThemeProvider>
            </Grid>
        </MuiPickersUtilsProvider>
    );
}

const mapStateToProps = function (state) {
    return {
        language: state.auth?.language || localStorage.getItem('language'),
    };
}

export default connect(mapStateToProps)(DateForm);
