import React, { useEffect, useReducer, useRef, useState } from 'react';
import s from './DocumentsPage.module.css';
import Header from './Header/Header';
import Table from './Table/Table';
import BackButton from "../BackButton/BackButton";
import Directory from './Directory/Directory';
import SelectControl from './SelectControl/SelectControl';
import PaginationContainer from './../Pagination/PaginationContainer';
import { init, reducer } from './Table/storeTableDoc';
import { getRequest } from '../../../api/appService';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useHttp } from '../../../hooks/useHttp';


let noDataFind = false;
let pagination = {
    currentPage: 1,
    totalPage: 0,
    changeCurrentPage: '',
}

const DocumentsPage = () => {



      //new code
    const {document: localText} = useSelector(state => state.app.appText);
  



    //old code

    const [documentData, setDocumentData] = useState();
    const [createFolderModal, setCreateFolderModal] = useState(false);
    const [nameNewfolder, setNameNewfolder] = useState('');
    const [downloadPercentage, setDownloadPercentage] = useState(0);
    const [load, setLoad] = useState();
    const [loadFile, setLoadFile] = useState();
    const [openSelect, setOpenSelect] = useState(false);
    const [actionsSelect, setActionsSelect] = useState(false);
    const [checkboxesSelect, setCheckboxesSelect] = useReducer(reducer, documentData, init);
    let history = useLocation();
    let searchParamUrl = useRef(history.search.replace('?search=', '') || '')
    let { initRequest } = useHttp();
    let { sendFileInDocument } =  getRequest(initRequest);

    const directoryFolder = useRef([{name: localText.directory.myDisk, id: ''}]);

    pagination.changeCurrentPage = numPage => {
        pagination.currentPage = numPage;
        getDocumentData(pagination.currentPage).then();
    };

    const getDocumentData = async (page = pagination.currentPage, search = searchParamUrl.current, sortType = '', sortParam = '', folderId = 125) => {
        folderId = directoryFolder.current[(directoryFolder.current.length - 1)].id;
        noDataFind = search
        if (!localStorage.getItem('token')) {
            setLoad({})
            return ''
        }
        if (documentData) {
            setLoad(true);
        }
        await initRequest(`/files/api/pageable/?page=${page}&size=${25}&searchParam=${search}&sortType=${sortType}&sortParam=${sortParam}&folderId=${folderId}`, 'get')
            .then(data => {
                pagination.totalPage = data.totalPage;
                setDocumentData(data);
                setLoad(false);
            })
            searchParamUrl.current = '';
    };

    const addFile = async (event) => {
        let file = event.target.files;
        uploadFilesToServer(file);
    };

    const createFolder = async (e) => {
        
        if(e.currentTarget.id === 'openModalCreateFolder_menu' || e.currentTarget.id === 'openModalCreateFolder_header' ){
            setCreateFolderModal(true);
        }
        if(e.currentTarget.id === 'closeModalCreateFolder'){
            setCreateFolderModal(false);
        }
        if(e.currentTarget.id === 'createFolder'){
            let folderId = directoryFolder.current[(directoryFolder.current.length - 1)].id;
            await initRequest(`/files/api/createFolder/?name=${nameNewfolder}&folderId=${folderId}`, 'post')
            .then(() => {
                setNameNewfolder('')
                setCreateFolderModal(false);
                getDocumentData()
            })
        }
    }

    const progressLoadFile = (progressEvent) => {
        let percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        setDownloadPercentage(percentCompleted);
}

    let uploadFilesToServer = async (filesList) => {

        let folderId = directoryFolder.current[(directoryFolder.current.length - 1)].id;

        filesList = [...filesList]
        filesList.forEach(async(file) => {
            if(file instanceof File) {
                const formData = new FormData();
                formData.append(
                    "file",
                    file,
                    file.name
                );

                for (let pair of formData.entries()) {
                    const fileName = pair[0];
                    const type = (pair[1].type).replace('/', ' ').split(' ')[0];
                    let fileType;

                    if (type === 'image') {
                        fileType = 'image'
                    } else if (type === 'video') {
                        fileType = 'video'
                    } else {
                        fileType = 'file'
                    }
                    setLoadFile(true);
                    await sendFileInDocument(fileName, fileType, formData, progressLoadFile, folderId)
                        .then(() => {
                            getDocumentData(pagination.currentPage).then()
                        })
                    setLoadFile(false);
                }
            }
        })

    };

    const toggleSelectControl = () => {
       if(openSelect) setCheckboxesSelect({type: 'cancelAll', cancelAll: false});
       setOpenSelect(!openSelect)
    }

    if(documentData) {
        let idCheckboxsCurrent = documentData.foldersAndFiles.reduce((prevValue, item) => {
            let id = item.fileId || item.folderId;
                return [...prevValue, id];
        }, ['all'])
        let idCheckboxsPrev = checkboxesSelect.reduce((prevValue, item) => {
                return [...prevValue, item.id];
        }, [])
        if(idCheckboxsCurrent.join('') !== idCheckboxsPrev.join('')){
            setCheckboxesSelect({type: 'updateCheckboxs', update: init(documentData)})
        } 
    }

    useEffect(() => {
        if (!documentData) {
            getDocumentData(pagination.currentPage).then();
        }
    },[]);

    if (!documentData) return <p className='LOAD_DATA'>Загрузка данных</p>;

    if (!documentData.foldersAndFiles.length && noDataFind) return (
        <div className={s.documentsPage}>
            <BackButton path={'/profile'}/>
            <Header search={getDocumentData} load={load} 
                localText={localText.headerPage} addFile={addFile} 
                createFolder={createFolder} searchParamUrl={searchParamUrl}/>
            <h2 className={s.headerNoFiles}>{localText.noDataFind}</h2>
        </div>
    );

    if (!documentData.foldersAndFiles.length) return (
        <div className={s.documentsPage}>
            <BackButton path={'/profile'}/>
            <Header search={getDocumentData} load={load} 
                localText={localText.headerPage} addFile={addFile} 
                createFolder={createFolder} searchParamUrl={searchParamUrl}/>
            <div>
                <Directory 
                    directoryFolder={directoryFolder}
                    getDocumentData={getDocumentData}
                    localText={localText}
                    addFile={addFile}
                    createFolder={createFolder}
                    createFolderModal={createFolderModal}
                    nameNewfolder={nameNewfolder}
                    setNameNewfolder={setNameNewfolder}
                    toggleSelectControl={toggleSelectControl}
                    openSelect={openSelect}
                    />
                <h2 className={s.headerNoFiles}>{localText.noData}</h2>
            </div>

        </div>
    );

    return (
        <div className={s.documentsPage}>
            <BackButton path={'/profile'}/>
            <Header
                search={getDocumentData}
                load={load}
                localText={localText.headerPage}
                addFile={addFile}
                createFolder={createFolder}
                searchParamUrl={searchParamUrl}
                />
            <div>
                <Directory 
                    directoryFolder={directoryFolder}
                    getDocumentData={getDocumentData}
                    localText={localText}
                    addFile={addFile}
                    createFolder={createFolder}
                    createFolderModal={createFolderModal}
                    nameNewfolder={nameNewfolder}
                    setNameNewfolder={setNameNewfolder}
                    toggleSelectControl={toggleSelectControl}
                    openSelect={openSelect}
                    />
                {openSelect &&<SelectControl 
                    localText={localText}
                    checkboxesSelect={checkboxesSelect}
                    setActionsSelect={setActionsSelect}
                    toggleSelectControl={toggleSelectControl}
                    />}
                <Table
                    documentData={documentData}
                    load={load}
                    getDocumentData={getDocumentData}
                    localText={localText}
                    currentPage={pagination.currentPage}
                    directoryFolder={directoryFolder}
                    openSelect={openSelect}
                    actionsSelect={actionsSelect}
                    setActionsSelect={setActionsSelect}
                    checkboxesSelect={checkboxesSelect}
                    setCheckboxesSelect={setCheckboxesSelect}
                    setOpenSelect={setOpenSelect}
                    toggleSelectControl={toggleSelectControl}
                />
            </div>
            <PaginationContainer paginationData={pagination}/>
            {loadFile && 
                <div className={s.backgroundProgress}>
                    <div className={s.wrapProgress}>
                        <h1 className={s.count}>{`${localText.modalLoadfile}: ${downloadPercentage}%`}</h1>
                        <div className={s.progress_bar} style={{width: `${downloadPercentage}%`}}/>
                    </div>
                </div>}
        </div>
    );
};

export default DocumentsPage;
