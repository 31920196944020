import React, { useState } from "react";
import { FieldWrap } from "./baseComponentsForm.style";
import { icons_svg } from "../StandContent/icons-svg";
import { Field, ErrorMessage } from "formik";

const BaseFormField = ({ settings, grow, helpElem }) => {
  const { showPasswordIcon, hidePassword } = icons_svg;
  const [{ showPassword }, setLocalState] = useState({ showPassword: false });

  if (!settings) return null;

  return settings.type === "select" ? (
    <FieldWrap className={`field-wrap _select ${settings.name}`} grow={grow}>
      <label htmlFor={settings.name} required={settings.required}>
        {settings.label}
      </label>
      <Field name={settings.name} component="select">
        {settings.options?.map(option => (
          <option key={option.value} value={option.value}>{option.name}</option>
        ))}
      </Field>
      <ErrorMessage
        name={settings.name}
        component="span"
        className="field__error-mes"
      />
    </FieldWrap>
  ) : settings.type === "password" ? (
    <FieldWrap className={`field-wrap ${settings.name}`} grow={grow}>
      {helpElem || ""}
      <label htmlFor={settings.name} required={settings.required}>
        {settings.label}
      </label>
      <div className="field_password">
        {showPassword ? (
          <>
            <Field
              name={settings.name}
              type={"text"}
              placeholder={settings.placeholder}
              autoComplete="true"
              required={settings.required}
            />
            <button
              type="button"
              className="password__show"
              onClick={() =>
                setLocalState((state) => ({
                  ...state,
                  showPassword: !state.showPassword,
                }))
              }
            >
              {hidePassword}
            </button>
          </>
        ) : (
          <>
            <Field
              name={settings.name}
              type={settings.type}
              placeholder={settings.placeholder}
              autoComplete="true"
              required={settings.required}
            />
            <button
              type="button"
              className="password__show"
              onClick={() =>
                setLocalState((state) => ({
                  ...state,
                  showPassword: !state.showPassword,
                }))
              }
            >
              {showPasswordIcon}
            </button>
          </>
        )}
      </div>
      <ErrorMessage
        name={settings.name}
        component="span"
        className="field__error-mes"
      />
    </FieldWrap>
  ) : (
    <FieldWrap className={`field-wrap ${settings.name}`} grow={grow}>
      {helpElem || ""}
      <label htmlFor={settings.name} required={settings.required}>
        {settings.label}
      </label>
      <Field
        name={settings.name}
        type={settings.type}
        placeholder={settings.placeholder}
        as={settings.type === "textarea" ?  settings.type : ""}
        required={settings.required}
      />
      <ErrorMessage
        name={settings.name}
        component="span"
        className="field__error-mes"
      />
    </FieldWrap>
  );
};

export default BaseFormField;
