import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import universalSound from "../../sounds/3_universal.mp3";
import UnityContainer from "../UnityContainer/UnityContainer";
import { getRequest } from "../../api/appService";
import { useLocation, useNavigate } from "react-router-dom";
import { useHttp } from "../../hooks/useHttp";
import { useUploadBuildsForUnityContext } from "../../hooks/useUploadUnityBuilds";
import { useResetIframeUnity } from "../../hooks/useResetIframeUnity";
import {
  setPavilionsState,
  setSelectedPavState,
  setUnityState,
} from "../../store/slices/unitySlice/unitySlice";
import {
  getPavilions,
  getStandsByPavId,
} from "../../store/slices/unitySlice/unityExtraReducers";
import { setAuthState } from "../../store/slices/authSlice/authSlice";
import Message from "materialize-css/dist/js/materialize.min.js";
import { closeChangingPavilion } from "../../reducers/mainReducer";
import { WrapPavilions } from "./pavilions.style";

import testImgPav from "./testImgPav.png";
import { useSendUnity } from "../../hooks/useSendUnity";
import { icons_svg } from "../StandContent/icons-svg";
import Pavilions from "./Pavilions";

const PavilionsContainer = () => {
  //new code
  const dispatch = useDispatch();
  const {
    pavilions: { selectedPavilion, list },
  } = useSelector((state) => state.unity);
  const { token } = useSelector((state) => state.auth);
  const {
    mainNavigation: localText,
    messages: messagesText,
    language,
  } = useSelector((state) => state.app.appText);
  const sendUnity = useSendUnity();
  let userData = useSelector((state) => state.profile.userData);
  const {state: locationState} = useLocation();

  const continueSelectPavilion = (pavilion) => {
    let dataUnity = {};
    console.log("PAV")
    loadUnityByPavilion(pavilion);

    // console.log(dataUnity);
    // console.log(dataUnity.pavilionId)
    // console.log('2 window.navigator.deviceMemory = ', window.navigator['deviceMemory']);
    if (
      !window.navigator["deviceMemory"] ||
      window.navigator["deviceMemory"] < 8
    ) {
      sendUnity("SetMaxShownOtherPlayers", 10);
    }
    // sendUnity("Login", JSON.stringify(dataUnity));
    setTimeout(() => {
      // sendUnity("LoadExhibition", parseInt(localStorage.getItem("pavilionId")));
    }, 500);
  };

  useEffect(() => {
    dispatch(getPavilions());
  }, [dispatch]);

  //old code

  // let language = localStorage.getItem("language");
  let navigate = useNavigate();

  let [mainSound, setMainSound] = useState();
  let { initRequest } = useHttp();
  let { authAPI } = getRequest(initRequest);

  useEffect(() => {
    if (!mainSound) {
      setMainSound(new Audio(universalSound));
    }
  }, []);

  const loadUnityByPavilion = (pavilion) => {
    // console.log(id)
    changeUnity(pavilion);
    onBack("selectPavilion");
    dispatch(getStandsByPavId({pavilionId: pavilion.id}));
  };

  const resetUnity = useResetIframeUnity();

  const changeUnity = async (pavilion) => {
    // document.querySelector(".unity-iframe")?.remove();

    let id = pavilion.id;
    let key = pavilion.nameKey;
    let name = pavilion[language];
    let shortName =
      pavilion[
        `shortName${language === "ru" ? "Ru" : language === "en" ? "En" : "By"}`
      ];
    localStorage.setItem("pavilionId", id);
    localStorage.setItem("nameKey", key);
    localStorage.setItem("selectedPavilion", name);
    shortName && localStorage.setItem("shortName", shortName);
    dispatch(setSelectedPavState({ ...pavilion, id, key, name, shortName }));
    dispatch(setUnityState({ progress: 0 }));

    await resetUnity();

    console.log("ISRELOAD", window.isReloadIframeUnity);
  };

  const handlerActions = (key, elem) => async (e) => {
    switch (key) {
      case "selectPavilion":
        if (selectedPavilion.id === elem.id) {
          Message.toast({ html: messagesText.thisPavilion });
          return;
        }
        // sendUnity("LoadLogInScene");
        console.log("PAV")

        // if (userData) {
        //   await authAPI.checkLogin().then((data) => {
        //     console.log(data)
        //     if (data.alreadyLoggedIn === "true") {
        //     } else {
        //       continueSelectPavilion(elem);
        //       mainSound["play"]();
        //     }
        //   });
        // } else {
          continueSelectPavilion(elem);
          mainSound["play"]();
        // }
        break;
       case "goBack":
          navigate(-1, {state: {goBack: false}});
        break;

      default:
        break;
    }
  };

  const onBack = (type) => {
    if (type === "selectPavilion") {
      navigate("/");
      return;
    }

    if (token) {
      let nextPath = "/login";
      let prevPath = sessionStorage.getItem("prevPath");

      if (prevPath) {
        nextPath = prevPath;
        sessionStorage.removeItem("prevPath");
        prevPath = null;
      } else {
        resetUnity();
        dispatch(setPavilionsState({ selectedPavilion: {} }));
      }
      navigate(nextPath);
    }
  };

  console.log(language);
  // navigate(location.pathname, { replace: true });
  return (
    <Pavilions
      language={language}
      list={list}
      handlerActions={handlerActions}
      locationState={locationState}
    />
  );
};

export default PavilionsContainer;
