import styled from "styled-components";

const WrapRequestMeet = styled.div`
    position: relative;
    width: 600px;
    padding: 16px;
    background: #FFFFFF;
    border-radius: 8px;
    z-index: 1;
    color: #363636;


    .close-btn {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 26px;
        height: 26px;
        background: #F5F5F5;
        border-radius: 8.66667px;
    }

    h2 {
        font-weight: 700;
        font-size: 15px;
        line-height: 17px;
        text-align: center;
        padding-top: 8px;
    }

    .form-fields {
        display: flex;
        flex-wrap: wrap;
        margin-left: -16px;
        .field-wrap {
            padding: 0 0 24px 16px;
            &.description textarea {
                height: 89px;
            }
        }
        .fields {
            display: flex;
            align-items: center;
            padding: 0 0 24px 16px;
            width: 100%;
            .field-wrap {
                padding: 0;
                &.time {
                    padding-right: 28px;
                }
            }
            .fields__separator {
                height: 1px;
                padding: 0 4px;
            }
        }

    }

    .form-btns {
        gap: 10px;
    }
`;


export {
    WrapRequestMeet,
}