import {createSlice} from '@reduxjs/toolkit';
import reducers from './chatReducers';


const initialStateChat = {
  open: false
};

const chatSlice = createSlice({
  name: 'chat',
  initialState: initialStateChat,
  reducers,
})

const chatReducer = chatSlice.reducer;

export const {setChatState} = chatSlice.actions;

export default chatReducer;