import React from 'react';

const optionsSort = {
    typeCol: 'name',
    typeSort: 'asc',
    arrow: ' ↓',
}

const TableHeader = ({localText, getDocumentData, s, widthTbody, openSelect, checkboxesSelect, setCheckboxesSelect}) => {

    let width = widthTbody.current ? widthTbody.current.getBoundingClientRect().width : '99%';

    const sortColumn = (e) => {

        let arrows = {
            down: ' ↓',
            up: ' ↑',
            remove: '',
        }

        switch (e.target.id) {
            case 'name':
                if (optionsSort.arrow === arrows.down) {
                    selectColumn(e.target.id, 'desc', arrows.up)
                } else if (optionsSort.arrow === arrows.up) {
                    selectColumn(e.target.id, '', arrows.remove)
                } else {
                    selectColumn(e.target.id, 'asc', arrows.down)
                }
                break;

            case 'modifiedTime':
                if (optionsSort.arrow === arrows.down) {
                    selectColumn(e.target.id, 'desc', arrows.up)
                } else if (optionsSort.arrow === arrows.up) {
                    selectColumn(e.target.id, '', arrows.remove)
                } else {
                    selectColumn(e.target.id, 'asc', arrows.down)
                }
                break;

            case 'size':
                if (optionsSort.arrow === arrows.down) {
                    selectColumn(e.target.id, 'desc', arrows.up)
                } else if (optionsSort.arrow === arrows.up) {
                    selectColumn(e.target.id, '', arrows.remove)
                } else {
                    selectColumn(e.target.id, 'asc', arrows.down)
                }
                break;

            case 'type':
                if (optionsSort.arrow === arrows.down) {
                    selectColumn(e.target.id, 'type-desc', arrows.up)
                } else if (optionsSort.arrow=== arrows.up) {
                    selectColumn(e.target.id, '', arrows.remove)
                } else {
                    selectColumn(e.target.id, 'type-asc', arrows.down)
                }
                break;
            default:
                break;
        }
    }

    const selectAll = (e) => setCheckboxesSelect({type: 'selectAll', selectAll: e.target.checked})

    const selectColumn = async (typeCol, typeSort, arrow) => {
        optionsSort.typeCol = typeCol;
        optionsSort.typeSort = typeSort;
        optionsSort.arrow = arrow;
        await getDocumentData(undefined, undefined, optionsSort.typeSort, optionsSort.typeCol)
    }

    const getArrowSort = (typeCol) => {
        switch (typeCol) {
            case 'name':
                return typeCol === optionsSort.typeCol ? optionsSort.arrow : '';
            case 'modifiedTime':
                return typeCol === optionsSort.typeCol ? optionsSort.arrow : '';
            case 'size':
                return typeCol === optionsSort.typeCol ? optionsSort.arrow : '';
            case 'type':
                return typeCol === optionsSort.typeCol ? optionsSort.arrow : '';
            default:
                return ;
        }
    }

    return (
        <thead style={{position: "absolute", top: '0', backgroundColor: 'white', width: width}} className={s.header_thead}>
        <tr className={s.header_docs} onClick={sortColumn}>
            <th className={`${s.colNumber}`}>№</th>
            {openSelect &&<th className={`${s.colSelect}`}>
                <input className={`${s.colCheckbox}`} type='checkbox' id='checkboxSelectAll' checked={checkboxesSelect.selectAll} onChange={selectAll}/>
            </th>}
            <th className={`${s.colName}`} colSpan='2' ><p id='name'>{localText.name + getArrowSort('name')}</p></th>
            <th className={`${s.colModifiedTime}`}><p id='modifiedTime'>{localText.lastModified + getArrowSort('modifiedTime')}</p></th>
            <th className={`${s.colSize}`} ><p id='size'>{localText.sizeFile + getArrowSort('size')}</p></th>
            <th className={`${s.colType}`} style={{borderRight: 'none'}}><p id='type'>{localText.type + getArrowSort('type')}</p></th>
        </tr>
        </thead>
    )
}

export default TableHeader;