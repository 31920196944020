import React from "react";
import { useImgs3DModels } from "./useImgs3DModels";


const Preview3DModels = ({company, models, mediaId}) => {

    useImgs3DModels(company.companyNameKey, mediaId || models[0] ? models[0].id : 1)

  return (
    <>
            <div id="KeyShotXR" style={{flex: 1}}></div>
    </>
  );
};

export default Preview3DModels;
