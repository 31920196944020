
export const companyStandInfo_ru = {
    inFavorite: "В избранные",
    deleteFavorite: "Удалить избранное",
    requestMeet: "Заявка на встречу",
    pageCompany: "Страница экспонента",
    desc: "Описание",
    representatives: "Представители",
    notRepresentatives: "Нет представителей",
    representative: "Представитель",
    documents: "Документы",
    notDocuments: "Нет документов",
    document: "Документ",
    showMore: "Показать больше",
    showLess: "Показать меньше",
}