import React, {useRef, useEffect} from 'react';
import s from './ContactsList.module.css'
import s_l from '.././ListParticipants.module.css'
import s_a from '../../AppointmentsPage.module.css'
import { connect, useDispatch, useSelector } from 'react-redux';
import search_load_icon from "../../img/search_load_icon.png";
import PaginationContainer from '../../../Pagination/PaginationContainer';
import { useLoading } from '../../../../../hooks/checkLoading';
import { useHttp } from '../../../../../hooks/useHttp';
import { getContactsListForAppoint, getListParticipants, toggleParticipant } from '../../../../../store/slices/profileSlice/profileExtraReducers';
import { setAppointmentsState } from '../../../../../store/slices/profileSlice/profileSlice';


const ContactsList = ({localText, closeList}) => {

    //new code 
    const dispatch = useDispatch();
    const {
        userData: {id: userId},
        appointments: {
            loading: loadType,
            listParticipants,
            listParticipantsAdd,
            listContacts,
            showConrolBtns,
            contactsSearch,
        }
      } = useSelector((state) => state.profile);

    const handlerToggleParticipant = async ({userId, method}) => {
        dispatch(toggleParticipant({id: "test", userId, method}));
    
    
        //   if(type === 'addParticipants') getContactsList(paginationContacts.currentPage, paginationContacts)(dispatch, getState);
        //   getListParticipants(initRequest, pagination.currentPage, pagination, type)(dispatch, getState);
      }


    //old code

    let {initRequest} = useHttp();
    let dataAppointment = listParticipants.dataAppointment;
    let contacts = listContacts.data || [];
    let isOwner = listParticipants.isOwner;
    let participants = listParticipantsAdd.data || [];
    let loading = useLoading(['listsAddParticipant', 'addParticipants'], loadType);
    let pagination = useRef({
        currentPage: 1,
        totalPage: listContacts.totalPage || 0,
        changeCurrentPage: (numPage) => {
            dispatch(getContactsListForAppoint({page: numPage}))
        },
    })
    let currentText = localText.listAddContacts;
    let paginationParticipants = useRef({
        currentPage: 1,
        totalPage: listParticipantsAdd.totalPage || 0,
        changeCurrentPage: (numPage) => {
            dispatch(getListParticipants({
                page: numPage,
                type: "addParticipants",
            }))
        },
    })

    const getTimeMeeting = (time) => {
        let dateMeeting = new Date(time);

        let dd = dateMeeting.getDate();
        if (dd < 10) dd = '0' + dd;
      
        let mm = dateMeeting.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;
      
        let yy = dateMeeting.getFullYear() % 100;
        if (yy < 10) yy = '0' + yy;  

        let hour = dateMeeting.getHours();
        if (hour < 10) hour = '0' + hour;

        let min = dateMeeting.getMinutes();
        if (min < 10) min = '0' + min;

        return `${dd}.${mm}.${yy} / ${hour}:${min}`
    }

    useEffect(() => {
        dispatch(getContactsListForAppoint({page: 1}))
    },[contactsSearch]);
    useEffect(() => {
        dispatch(getListParticipants({
            page: 1,
            type: "addParticipants",
            load: "offLoad",
        }))
    },[]);

    return (
        <div className={s.wrapLists}>
            <span className={s.wrapLists__close} id='closeAddList' onClick={closeList}>×</span>
            <div className={s.wrapListsHeader}>
                <p className={s.wrapListsHeaderText}>
                    <span className={s.wrapLists_date}>{`${localText.table.header.time}: ${getTimeMeeting(dataAppointment.time)}`}</span>
                    <span className={s.wrapLists_theme}>{`${dataAppointment.theme}`}</span>
                </p>
                <div>
                    <label className={s.search}>
                        <p>{`${localText.header.search}:`}</p>
                        <input type="text" name="search"
                            value={contactsSearch} onChange={(e) => dispatch(setAppointmentsState({contactsSearch: e.target.value}))}
                        />
                        <img 
                        className={`${s.search__loader} ${loading ? s_a.load : s_a.hidden}`} 
                        src={search_load_icon} width={'20px'} height={'20px'}
                            alt="search_load_icon"/>
                    </label>
                </div>
            </div>
            {(contacts && participants) ?  <div className={s.userLists}>
                <div className={s.wrapList}>
                    <div className={`${s.list__title} ${s.list__elem}`}>
                        <p>{currentText.headerCon}</p>
                    </div>
                    <div className={s.list}> 
                    {contacts.map(user => (
                            <div key={user.id} className={`${s.list__contact} ${s.list__elem} ${user.participant && s.disabled}`} 
                            onClick={() => !user.participant && handlerToggleParticipant({userId: user.id, method: "post"})}>
                                <p className={s.user__name}>{`${user.firstName} ${user.lastName}`}</p>
                            </div>
                        ))}
                    </div>
                <PaginationContainer 
                    classNameBtn={s_l.paginationBtns} 
                    paginationData={pagination.current}/>
                </div>
                <span className={s.userLists__line}></span>
                <div className={s.wrapList}>
                    <div className={`${s.list__title} ${s.list__elem}`}>
                        <p>{currentText.headerPar}</p>
                    </div> 
                    <div className={s.list}>   
                      {participants.map(user => (
                            <div key={user.id} className={`${s.list__parcitipant} ${s.list__elem}`}>
                                <p className={s.user__name}>{`${user.firstName} ${user.lastName}`}{(isOwner && +userId === +user.id) && <span>*</span>}</p>
                                {isOwner && (+userId !== +user.id) && <button className={s_l.participant__deleteBtn} 
                                onClick={() => handlerToggleParticipant({userId: user.id, method: "delete"})}
                                >
                                    <span>×</span>
                                </button>}
                            </div>
                        ))}   
                    </div>     
                    <PaginationContainer 
                    classNameBtn={s_l.paginationBtns} 
                    paginationData={paginationParticipants.current}/>
                </div>
                {loading && <div className='backgroundLoading'></div>}
            </div> : <p>Загрузка</p>}
        </div>
    );
}


export default ContactsList;
