import React from 'react';
import { svg } from '../Table/ContextMenu/img/directory/svg';
import s from './Header.module.css'
import { documentsSvg } from './img/DocumentsSvg';
import search_load_icon from "./img/search_load_icon.png";
import MainLogoPage from './../../MainLogoPage/MainLogoPage';
import { useState } from 'react';



const Header = ({addFile, search, load, localText, createFolder, searchParamUrl}) => {

    console.log('searchParamUrl', searchParamUrl?.current)

    const [searchDoc, setSearchDoc] = useState(searchParamUrl?.current || '')

    return (
        <div className={s.wrapper}>
            <MainLogoPage localText={localText.myContacts} logo={documentsSvg.logo}/>
            <div className={s.wrap_search}>
                <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                    <h1 className={s.page_title}>{localText.pageTitle}</h1>
                    <div>
                        <label className={s.labelFile}>
                            {svg.uploadFile}
                            <span className={s.title}>{localText.uploadFile}</span>
                            <input type={'file'} name="file" multiple id="file" onChange={addFile}/>
                        </label>
                        <div id='openModalCreateFolder_header' className={s.labelFile} onClick={createFolder}>
                            {svg.createFolder}
                            <span className={s.title}>{localText.createFolder}</span>
                        </div>
                    </div>
                </div>
                <form className={s.search_form} onSubmit={e => e.preventDefault()}>
                    <label>{localText.search}</label>
                    <input type="text" name="search" value={searchDoc} onChange={(e) => {
                        setSearchDoc(e.target.value)
                        search(undefined, e.target.value)                       
                        }}/>
                    <img className={load ? s.load : s.hidden} src={search_load_icon} width={'20px'} height={'20px'}
                         alt="search_load_icon"/>
                </form>
            </div>
        </div>

    )
}

export default Header;
