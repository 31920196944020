import React, { useEffect, useRef, useState } from 'react';
import DirectoryModal from './DirectoryModal/DirectoryModal';
import s from './ModalMoveFolder.module.css'
import folder_icon from '../img/folder.png';
import loadingSVG from '../img/spinner.svg';
import { useHttp } from '../../../../../hooks/useHttp';
import { filesUrl, serverUrl } from '../../../../../api/generateDomenUrlsApp';

const ModalMoveFolder = ({moveFolderOption, setMoveFolder, mainDirectoryFolder, updateTable, actionsSelect, setActionsSelect, checkboxesSelect, localText}) => {

    const [documentData, setDocumentData] = useState();
    const [load, setLoad] = useState();

    const directoryFolder = useRef([{name: localText.directory.myDisk, id: ''}]);
    let currentFolderId = useRef(directoryFolder.current[(directoryFolder.current.length - 1)].id);
    let [disabledBtnMove, setDisabledBtnMove] = useState(false);
    let parentFolderMoveId = mainDirectoryFolder[(mainDirectoryFolder.length - 1)].id
    let { initRequest } = useHttp();
    
    const getDocumentData = async (page = 1, search = '', sortType = '', sortParam = '', folderId = '') => {
        folderId = directoryFolder.current[(directoryFolder.current.length - 1)].id;
        if (!localStorage.getItem('token')) {
            setLoad({})
            return ''
        }
        if (documentData) {
            setLoad(true);
        }
        await initRequest(`/files/api/pageable/?page=${page}&size=${100}&searchParam=${search}&sortType=${sortType}&sortParam=${sortParam}&folderId=${folderId}`, 'get')
            .then(data => {
                checkFolder(moveFolderOption.type, folderId);
                setLoad(false);
                setDocumentData(data);
            })
    };

    const showImg = (e, item) => {
        if(e.target.src === `${serverUrl}/files/download?fileId=${item.fileId}&token=${localStorage.getItem('token')}`) return
        if((item.fileType === 'transparent_image' || item.fileType === 'image') && (item.name.includes('.jpg') || item.name.includes('.png')))  {
            e.target.src = `${serverUrl}/files/download?fileId=${item.fileId}&token=${localStorage.getItem('token')}`;
        }
    }

    const openFolder = (folderName, folderId) => {
        directoryFolder.current.push({name: folderName, id: folderId})
        getDocumentData();
    }

    const moveElem = async (type = false, id = false) => {

        let typeContent = type ? type : moveFolderOption.type;
        let idContent = id ? id : moveFolderOption.id;

            let url, method;
            switch (typeContent.toLocaleLowerCase()) {
                case 'folder':
                    url = `/files/api/addFolder?parentFolderId=${currentFolderId.current}&folderId=${idContent}`;
                    method = 'put';
                    break;
                case 'file':
                    url = `/files/api/add?folderId=${currentFolderId.current}&fileId=${idContent}`;
                    method = 'post';
                    break;
                default: break;
            }
            await initRequest(url, method)
            .then(() => {
                actionsSelect ? setActionsSelect(false) : setMoveFolder(false);
                updateTable();
            })
        
    }

    const moveMultiple = () => {
        if(actionsSelect.checkboxes) {
            actionsSelect.checkboxes.forEach((item) => {
                    moveElem(item.type, item.id);
            })} 
    }

    const methodMoveContent = () => {
        actionsSelect ? moveMultiple() : moveElem();
    }

    const checkFolder = (type, folderId) => {

        actionsSelect && (type = 'multipleMove')
        currentFolderId.current = folderId;

        switch (type) {
            case 'folder':
                if(+currentFolderId.current === +moveFolderOption.id) {
                    setDisabledBtnMove(true);
                }
                else if(+currentFolderId.current === +parentFolderMoveId) {
                    setDisabledBtnMove(true)
                }
                else {
                    setDisabledBtnMove(false);
                }
                break;
            case 'file':
                if(+currentFolderId.current === +parentFolderMoveId) {
                    setDisabledBtnMove(true)
                }
                else {
                    setDisabledBtnMove(false);
                }
                break;
            case 'multipleMove':
                for(let checkbox of actionsSelect.checkboxes){
                    if(+checkbox.id === +currentFolderId.current) {
                        setDisabledBtnMove(true)
                        return '';
                     }
                }
                if(+currentFolderId.current === +parentFolderMoveId) {
                    setDisabledBtnMove(true)
                }
                else {
                    setDisabledBtnMove(false);
                }
                break;
            default:
                break;
        }
    }

    const selectFolder = (e, folderId) => {
        let rows = document.querySelectorAll(`.${s.body_row}`);
        rows.forEach(row => {
            row.style.background = 'initial';
            row.lastChild.style.color = '#7C7C7C';
        })
        e.currentTarget.style.background = '#5DB075';
        e.currentTarget.lastChild.style.color = 'white';
        checkFolder(moveFolderOption.type, folderId);
    }

    useEffect(() => {
        if (!documentData) {
            getDocumentData().then();
        }
    });

    const sortMoveContent = (item, i) => {

        const findId = (id) => {
            return actionsSelect.checkboxes.find(item => +item.id === +id ? true : false);    
        }

      let condition = actionsSelect ? findId(+item.folderId) : +item.folderId === +moveFolderOption.id;
        return condition 
        ? 
        <div className={s.body_row + ' ' + s.row_file} key={i} 
            data-title={`${localText.modalMoveFolder.noMoveFolderPart1} "${item.name}" ${localText.modalMoveFolder.noMoveFolderPart2}`}>
            <img className={s.row_icon} src={folder_icon} alt='folder_icon'/>
            <p>{item.name}</p>
        </div>
        :
        <div className={s.body_row} key={i} 
            onClick={(e) => selectFolder(e, item.folderId)}
            onDoubleClick={() => openFolder(item.name, item.folderId)}
            >
            <img className={s.row_icon} src={folder_icon} alt='folder_icon'/>
            <p>{item.name}</p>
        </div>
    }

    const buildBody = () => {

        if(documentData && !documentData.foldersAndFiles.length) {
            return (documentData && !load 
                ? 
            <div className={s.modal_body + ' ' + s.noData}><h2>{localText.noDataFind}</h2></div>
            :
            <div className={s.modal_body + ' ' + s.spinner}><img src={loadingSVG} alt={'loading'}/></div>
            )
        } else {
            return (
                documentData && !load ? 
                    <div className={s.modal_body}>
                        {documentData.foldersAndFiles.map((item, i) => (
                    item.type === 'FILE' 
                    ? 
                        <div className={s.body_row + ' ' + s.row_file} key={i} data-title={localText.modalMoveFolder.noSelectFile}>
                            <img className={s.row_icon} onLoad={(e) => {showImg(e, item)}} src={`${filesUrl}${item.icon}`} alt={'file'}/>
                            <p>{item.name}</p>
                        </div>
                    :
                    sortMoveContent(item, i)
                ))}
                    </div>
                    : 
                    <div className={s.modal_body + ' ' + s.spinner}><img src={loadingSVG} alt={'loading'}/></div>
            )
        }
    }

        return (<div className={s.wrapModalMoveFolder}>
            <div className={s.modalMoveFolder}>
                <div className={s.modal_header}>
                    <DirectoryModal 
                        actionsSelect={actionsSelect} 
                        directoryFolder={directoryFolder}
                        getDocumentData={getDocumentData}
                        setActionsSelect={setActionsSelect} 
                        setMoveFolder={setMoveFolder}/>
                </div>
                {buildBody()}
                <div className={s.modal_footer}>
                    <button id='btnMoveFolder' onClick={methodMoveContent} className={s.modal_footer_btnMove} disabled={disabledBtnMove}>{localText.modalMoveFolder.btnMove}</button>
                </div>
            </div>
        </div>)

}

export default ModalMoveFolder;
