import React from 'react';
import s from './../ChangeMedia.module.css';
import { siteUrl } from '../../../../api/generateDomenUrlsApp';


function ImageVideo({companyName}) {

    return (
        <div className={s.container}>
            <p>Введите файла, который будет отображен на банере:</p>
            <br/>
            <iframe id={'ImageVideoIframe'} src={siteUrl + '/company/' + companyName + '?onlyInfo=true&showMediaId=true&infoType=image&mediaId=0'}
                className={s.iframeIV}/>
        </div>
    );
}


export default ImageVideo;
