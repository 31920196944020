import React from 'react';
import s from './PaginationContainer.module.css';
import Pagination from 'react-paginating';

const PaginationContainer = ({paginationData, classNameBtn = ''}) => {
    if(paginationData.totalPage === 0 || paginationData.totalPage === 1) return '';

    return (
        <div className={s.wrapPagination}>
        <Pagination
          total={paginationData.totalPage}
          limit={1}
          pageCount={3}
          currentPage={paginationData.currentPage}
        >
          {({
            pages,
            currentPage,
            hasNextPage,
            hasPreviousPage,
            previousPage,
            nextPage,
            totalPages,
            getPageItemProps
          }) => (
            <div className={`${s.wrapBtns} ${classNameBtn}`}>

              
              {hasPreviousPage ? 
                <div>
                    <button 
                    {...getPageItemProps({
                        pageValue: 1,
                        onPageChange: paginationData.changeCurrentPage
                    })}
                >
                    {'<<'}
                    </button>
                    <button
                    {...getPageItemProps({
                        pageValue: previousPage,
                        onPageChange: paginationData.changeCurrentPage
                    })}
                    >
                    {'<'}
                    </button>
                </div>
               : 
                <div>
                    <button disabled
                    {...getPageItemProps({
                        pageValue: 1,
                        style: {color: 'grey', cursor: 'not-allowed'},
                        onPageChange: paginationData.changeCurrentPage
                    })}
                >
                    {'<<'}
                    </button>
                    <button disabled
                    {...getPageItemProps({
                        pageValue: previousPage,
                        style: {color: 'grey', cursor: 'not-allowed'},
                        onPageChange: paginationData.changeCurrentPage
                    })}
                    >
                    {'<'}
                    </button>
                </div>
                }

              {pages.map(page => {
                let activePage = null;
                if (currentPage === page) {
                  activePage = { backgroundColor: '#5db276', color: 'white' };
                }
                return (
                  <button
                    {...getPageItemProps({
                      pageValue: page,
                      key: page,
                      style: activePage,
                      onPageChange: paginationData.changeCurrentPage
                    })}
                  >
                    {page}
                  </button>
                );
              })}

              {hasNextPage ? 
                <div>
                    <button
                    {...getPageItemProps({
                        pageValue: nextPage,
                        onPageChange: paginationData.changeCurrentPage
                    })}
                    >
                    {'>'}
                    </button>
                    <button
                    {...getPageItemProps({
                        pageValue: totalPages,
                        onPageChange: paginationData.changeCurrentPage
                    })}
                    >
                    {'>>'}
                    </button>
                </div>
              : 
                  <div>
                <button disabled
                  {...getPageItemProps({
                    pageValue: nextPage,
                    style: {color: 'grey', cursor: 'not-allowed'},
                    onPageChange: paginationData.changeCurrentPage
                  })}
                >
                  {'>'}
                </button>
                <button disabled
                  {...getPageItemProps({
                    pageValue: totalPages,
                    style: {color: 'grey', cursor: 'not-allowed'},
                    onPageChange: paginationData.changeCurrentPage
                  })}
                >
                  {'>>'}
                </button>
                           
                </div>
              }


            </div>
          )}
        </Pagination>
        </div>
    )
}

export default PaginationContainer;
