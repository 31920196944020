import React, { useState} from 'react';
import s from './ChangeMedia.module.css';
import close from './../close.svg';
import ThreeDModel from "./ThreeDModel/ThreeDModel";
import ImageVideo from "./ImageVideo/ImageVideo";
import {getRequest} from "../../../api/appService";
import Preloader from "../../common/Preloader/Preloader";
import { connect, useSelector } from 'react-redux';
import { useHttp } from '../../../hooks/useHttp';
import { useSendUnity } from '../../../hooks/useSendUnity';



function ChangeMedia({ setIsEdit, companyName, objectStandId, setTypeId, setIsChangeMedia, isChangeMedia, mediaFromPath, _3dViewerActive }) {


            //new code
            const {media: localText} = useSelector(state => state.app.appText);
            const sendUnity = useSendUnity(); 

            //old code

    let { initRequest } = useHttp();
    const [isLoading, setIsLoading] = useState(false);
    let [selectedTypeId, setSelectedTypeId] = useState('7/8');
    let [valueForUpdate, setValueForUpdate] = useState('');
    let [threeD_modelImage, setThreeD_modelImage] = useState(null);
    let [assetBundles, setAssetBundles] = useState(null);
    let { mediaApi } =  getRequest(initRequest);

    window.messageHandler = event => {
        // console.log('onMessage event.data = ', event.data);
        if(event.data['currentSlideId'] && event.data['selectedTypeId']) {
            setValueForUpdate(event.data['currentSlideId']);
            setSelectedTypeId(event.data['selectedTypeId']);
        }
    }

    let _10mb = 10485760;
    let _50mb = 52428800;

    // useEffect(()=> {
    //     console.log(threeD_modelImage)
    // }, [threeD_modelImage])
    //
    // useEffect(()=> {
    //     console.log(assetBundles)
    // }, [assetBundles])

    const changeSelectValue = (event) => {
        setSelectedTypeId(event.target.value);
    };

    const uploadFile = async (file, uploadFunction) => {
        // console.log(file)

        const formData = new FormData();

        formData.append(
            "file",
            file,
            file.name
        );

        for (let pair of formData.entries()) {
            const fileName = pair[0];

            try {
                await uploadFunction(fileName, companyName, objectStandId, formData)
                    .then(() => {
                        // console.log(fileName + ' UPLOADED')
                    })
            } catch (e) {
                // console.log(fileName + ' NOT UPLOADED')
                console.log(e.response)
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const handleResponse = (updateFile = false) => {

            let checkPath = ['/manageStand','/standStatistics'].includes(mediaFromPath);
            setIsEdit(false);
            setTypeId(selectedTypeId);
            setIsChangeMedia(!isChangeMedia);
            setIsLoading(false);
            sendUnity("UpdateStandInfo", `${companyName}:${objectStandId}`);
            checkPath && sendUnity("UpdateStandInfo_3d", `${companyName}:${objectStandId}`);
            if(updateFile){
                !_3dViewerActive.current && sendUnity("Set3dViewerActive", "true");
                _3dViewerActive.current = true;
                sendUnity("Set3dModelName", `${objectStandId}`);
            }
        }

        if (Number(selectedTypeId) === 10 || Number(selectedTypeId) === 9) {
            setIsLoading(true);
            mediaApi.updateFileOrCompany(companyName, objectStandId, selectedTypeId)
                .then(() => {handleResponse();})
        }

        if (Number(selectedTypeId) === 6 && (assetBundles.size < _50mb && threeD_modelImage.size < _10mb)) {
            setIsLoading(true);
            uploadFile(assetBundles,  mediaApi.uploadAssetBundle)
                .then(()=> {
                    // console.log('assetBundles UPLOADED')
                    uploadFile(threeD_modelImage,  mediaApi.uploadImage3DModel)
                        .then(() => {
                            mediaApi.updateFileOrCompany(companyName, objectStandId, 11).then(()=> {handleResponse(true);})
                            .catch((e)=> {
                                console.log(e)
                                setIsLoading(false);
                            })
                        })
                        .catch((e)=> {
                            // console.log('threeD_modelImage DONT UPLOADED')
                            console.log(e)
                            setIsLoading(false);
                        })
                })
                .catch((e)=> {
                    // console.log('assetBundles DONT UPLOADED')
                    console.log(e)
                    setIsLoading(false);
                })
        }

        if (Number(selectedTypeId) === 7 || Number(selectedTypeId) === 8) {
            setIsLoading(true);
            mediaApi.updateMedia(companyName, objectStandId, selectedTypeId, valueForUpdate)
                .then(() => {handleResponse();});
        }
    };

    return (
        <div className={s.modalPage}>
            <div  className={s.modalContainer}>
                <div className={s.closeWrapper} onClick={()=>setIsEdit(false)} >
                    <img src={close} className={s.closeImage} alt={'close'}/>
                </div>

                <div className={s.form}>
                    {isLoading && <Preloader/>}

                    <label className={s.whatDownloadText}>
                        {localText.whatDownloadText}
                        <select className={s.selectMedia} onChange={changeSelectValue}>
                            <option value={'7/8'}>{localText.imageVideo}</option>
                            <option value={6}>{localText._3d}</option>
                        </select>
                    </label>

                    {Number(selectedTypeId) === 6 && <ThreeDModel threeD_modelImage={threeD_modelImage}
                                                                  setThreeD_modelImage={setThreeD_modelImage}
                                                                  assetBundles={assetBundles}
                                                                  setAssetBundles={setAssetBundles}
                                                                  localText={localText.threeDModel}/>}

                    {(Number(selectedTypeId) === 7 || Number(selectedTypeId) === 8 || selectedTypeId === '7/8') &&
                    <ImageVideo companyName={companyName}/>}

                    <input className={s.submitInput}  onClick={handleSubmit} type="submit" value={'OK'}/>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = function (state) {
    return {
        mediaFromPath: state.main.mediaFromPath,
    };
}

export default connect(mapStateToProps, )(ChangeMedia);

