
export const contactPage_by = {
    'headerPage': {
        'myContacts': 'Мае кантакты',
        'search': 'Пошук',
        'back': 'Назад',
        'pageTitle': 'Спіс маіх кантактаў',
        'myExhibition': 'Толькі на гэтым павільёне',
    },
    'noData': "У вас няма кантактаў",
    'noDataFind': "Кантактаў не знойдзена",
    'headerTable': {
        'avatar': 'Аватар',
        'online': 'Анлайн',
        'name': 'Імя',
        'surname': 'Прозвішча',
        'nick': 'Нік',
        'email': 'Электронная пошта',
        'company': 'Кампанія',
        'delete': 'Выдаліць'
    },
    'userCard': {
        'email': 'Электронная пошта',
        'position': 'Пасада',
        'company': 'Прадпрыемства',
        'addUser': 'Дадаць у спіс кантактаў',
        'deleteUser': 'Выдаліць з спісу кантактаў',
        'addSupport': 'Дадаць у спіс падтрымкі',
        'unblockUser' : 'Разблакаваць карыстальніка',
        'blockUser' : 'Заблакаваць карыстальніка',
        'youBlock' : 'Вы заблакаваныя карыстальнікам',
        'deleteSupport': 'Выдаліць з спісу падтрымкі',
        'absent': 'адсутнічае',
        'orderForm': 'Форма замовы / прызначэння відэаканферэнцыі',
        'selectDate': 'Дата, час і працягласць сустрэчы:',
        'enterTheme': 'Тэма сустрэчы:',
        'sendOrder': 'Адправіць заяўку',
        'minutes': 'хвілін',
        'hour': 'гадзіна',
        'hours': 'гадзіны',

        'successSubmit': 'Сустрэча прызначаная',
        'createChat': 'Стварыць чат',
    },
    'modal': {
        'addUser': 'Дадаць гэтага карыстальніка ў спіс кантактаў?',
        'deleteUser': 'Выдаліць гэтага карыстальніка з спісу кантактаў?',
        'addSupport': 'Дадаць гэтага карыстальніка ў спіс падтрымкі?',
        'deleteSupport': 'Выдаліць гэтага карыстальніка з спісу падтрымкі?',
        'unblockUser' : 'Разблакаваць карыстальніка?',
        'blockUser' : 'Заблакаваць карыстальніка?',
        'yes': 'Так',
        'no': 'Не',
    },
}