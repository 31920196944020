import React, { useEffect, useRef } from 'react';
import s from './AppointmentsPage.module.css'
import BackButton from "../BackButton/BackButton";
import TableMeetings from './TableMeetings/TableMeetings';
import MainLogoPage from '../MainLogoPage/MainLogoPage';
import { appointmentSvg } from './img/AppointmentSvg';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import search_load_icon from "./img/search_load_icon.png";
import PaginationContainer from '../Pagination/PaginationContainer';
import ListParticipants from './ListParticipants/ListParticipants';
import DateForm from '../StatisticsPage/Filters/DateForm/DateForm';
import { useLoading } from '../../../hooks/checkLoading';
import { useHttp } from '../../../hooks/useHttp';
import {setAppointmentsState} from "../../../store/slices/profileSlice/profileSlice"
import { unwrapResult } from '@reduxjs/toolkit';
import { getListAppointments } from '../../../store/slices/profileSlice/profileExtraReducers';

const AppointmentsPage = () => {



    // new code
    const dispatch = useDispatch();
    const {appointment: localText} = useSelector(state => state.app.appText);
    const {loading: loadType, search, fromDate, toDate, listAppointments, listParticipants} = useSelector(state => state.profile.appointments);

    //old code

    let {initRequest} = useHttp();
        
    let loading = useLoading('listAppointments', loadType);

        let pagination = useRef({
            currentPage: 1,
            totalPage: 0,
            changeCurrentPage: async (numPage) => {
                let response = unwrapResult(await dispatch(getListAppointments(
                    {
                        page: numPage,
                        search,
                        fromDate,
                        toDate
                    }
                )));
                pagination.current.currentPage = numPage;
                pagination.current.totalPage = response.totalPage;
            },
        })

    const handleDateChange = (date, typeDate) => {
        dispatch(setAppointmentsState({[typeDate]: date.getTime()}));
    };

    useEffect(() => {
        const fetch = async () => {
            let response = unwrapResult(await dispatch(getListAppointments(
                {
                    page: 1,
                    search,
                    fromDate,
                    toDate
                }
            )));
            pagination.current.currentPage = 1;
            pagination.current.totalPage = response.totalPage;
        }
        fetch();
    },[search, fromDate, toDate]);

    if (!listAppointments) return <p className='LOAD_DATA'>Загрузка данных</p>;

    return (
        <div className={s.wrapAppointmentsPage}>
            <div className={s.headerContainer}>
                <MainLogoPage text={localText.header?.appointments} logo={appointmentSvg.logo}/>
                <div className={s.headerContent}>
                    <div className={s.headerContentTitle}>
                        <BackButton path={'/profile'}/>
                        <h1 className={s.headerContainer__text}>{localText.header?.title}</h1>
                    </div>
                    <div className={s.headerContentEnter}>
                        <label>
                            {`${localText.header?.search}:`}
                            <input type="text" name="search" 
                            value={search} onChange={(e) => dispatch(setAppointmentsState({search: e.target.value}))}/>
                        <img 
                        className={`${s.headerContentEnter__loader} ${loading ? s.load : s.hidden}`} 
                        src={search_load_icon} width={'20px'} height={'20px'}
                            alt="search_load_icon"/>
                        </label>
                        <DateForm text={localText.header?.data.from} type='fromDate' maxDate={toDate}
                        handleDateChange={handleDateChange} selectedDate={fromDate}/>
                        <DateForm text={localText.header?.data.to} type='toDate' minDate={fromDate}
                        handleDateChange={handleDateChange} selectedDate={toDate}/>
                    </div>
                </div>
                <Link className={s.headerContainer__close} to={'/profile'}>×</Link>
            </div>
            <TableMeetings localText={localText} paginationList={pagination.current}/>
            {listParticipants.open && <ListParticipants localText={localText} paginationList={pagination.current}/>}
            <PaginationContainer paginationData={pagination.current}/>
        </div>
    );
}


export default AppointmentsPage;
