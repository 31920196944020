import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Registration2 from "./Registration2";
import { validateField } from "../../validation/validation";
import { FormFieldProps } from "./../../utils/classTempates";
import { useNavigate } from "react-router-dom";
import { registration } from "../../store/slices/authSlice/authExtraReducers";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSendUnity } from "../../hooks/useSendUnity";
import { resetAuthState } from "../../store/slices/authSlice/authSlice";
import { resetUnityState } from "../../store/slices/unitySlice/unitySlice";
import { resetProfileState } from "../../store/slices/profileSlice/profileSlice";

function RegistrationContainer({ isLoading }) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {registration: localText, errors: errorsText} = useSelector(state => state.app.appText);
  const modal = useSelector(state => state.app.modal);
  const isScene = useSelector(state => state.unity.isScene);
  const { withoutRegistration } = useSelector((state) => state.auth);
  const sendUnity = useSendUnity();

  const settingsFields = {
    lastName: new FormFieldProps("lastName", "Фамилия", "Введите фамилию"),
    firstName: new FormFieldProps("firstName", "Имя", "Введите имя"),
    patronymic: new FormFieldProps("patronymic", "Отчетво", "Введите отчество"),
    email: new FormFieldProps("email", "Email", "Введите email"),
    password: new FormFieldProps(
      "password",
      "Пароль",
      "Введите пароль",
      "password"
    ),
    equalPass: new FormFieldProps(
      "equalPass",
      "Повторите пароль",
      "Введите пароль повторно",
      "password"
    ),
    phone: new FormFieldProps(
      "phone",
      "Телефон",
      "+ (__) ___ __ __",
      "tel",
      false
    ),
    privacyPolicy: new FormFieldProps(
      "privacyPolicy",
      (
        <p>
          Я соглашаюсь с правилами пользования и <span>политикой конфиденциальности</span> 
        </p>
      ),
      "",
      "checkbox",
      false
    ),
  };

  const settingsBtns= {
    cancelName: "Отменить",
    submitName: "Зарегистрироваться",
  };

  const initialValues = {
    lastName: "",
    firstName: "",
    patronymic: "",
    password: "",
    email: "",
    equalPass: "",
    phone: "",
    privacyPolicy: false,
  };

  const validate = (values) => {
    const errors = {};
    if (!validateField("lastName", values.lastName)) {
      errors.lastName = errorsText.validation.surname;
    } 
    else if (!validateField("firstName", values.firstName)) {
      errors.firstName = errorsText.validation.name;
    } 
    else if (!validateField("patronymic", values.patronymic)) {
      errors.patronymic = errorsText.validation.patronymic;
    } 
    else if (!validateField("email", values.email)) {
      errors.email = errorsText.validation.email;
    } 
    else if (!validateField("password", values.password)) {
      errors.password = errorsText.validation.password;
    } 
    else if (values.equalPass !== values.password) {
      errors.equalPass = errorsText.validation.passwordEqual;
    } 
    else if (!values.privacyPolicy) {
      errors.privacyPolicy = errorsText.validation.userAgreement;
    } 
    // else if (!values.phone) {
    //   errors.phone = "";
    // }
    else {
    }
    console.log(values)
    return errors;
  };

  const handlerActions = (type, data) => async (e) => {
    
    switch (type) {
      case "login":
        break;
      case "registration":
      //   const SendValues = {
      //     password: value.password,
      //     firstName: value.name,
      //     lastName: value.surname,
      //     phone: value.tel,
      //     email: value.email,
      //     patronymic: value.patronymic ,
      //     language: localText.language
      // }
        await dispatch(registration({...e, language: 'ru'}));
        console.log("registration", e);
        break;
      case "close":
      case "cancel":
        navigate(withoutRegistration && isScene ? -1 : "/login");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if(!withoutRegistration) {
      dispatch(resetAuthState("login"));
      dispatch(resetUnityState("selectedPavilion"));
      dispatch(resetProfileState());
    }
  }, [dispatch])


  if(modal.type === "registrSuccess") return null;

  return (
    <Registration2
      validate={validate}
      handlerActions={handlerActions}
      initValues={initialValues}
      settingsFields={settingsFields}
      settingsBtns={settingsBtns}
    />
  );
}

export default RegistrationContainer;
