import { handlerLocalStorage } from "./handlerLocalStorage.service";
import { langs } from './../Languages/languages';

export const handlerData = () => {
  
  const initLanguage = handlerLocalStorage().get("language") || "ru";

  const getAppText = (lang = initLanguage) => {
    let _lang = lang;
    if(_lang !== "en" && _lang !== "ru" && _lang !== "by") {
      handlerLocalStorage().set("language", "ru")
      _lang = "ru" 
    };
    return langs[_lang]
  };

  return {
    getAppText,
  };
};
