
export const profile_ru = {
    'header': {
        'language': {
            'ru': 'Русский язык',
            'en': 'Английский язык',
            'by': 'Белорусский язык'
        },
        'manageStands': 'Управление моим стендом',
        'changeData': 'Изменить данные',
        'modalLoadfile': 'Загрузка файла',
        'editCompanyModal':{
            'header': 'Изменение компании приведёт к потере доступа к статистике, вы уверены?',
            'yes': 'Да',
            'no': 'Нет',
        },
        'avatarLoader':{
            'upload': 'Загрузить',
            'scale': 'Масштаб',
            'save': 'Сохранить',
            'back': 'Назад',
            'change': 'Изменить',
        },
    },
    'profileInfo': {
        'firstName': 'Имя',
        'lastName': 'Фамилия',
        'patronymic': 'Отчество',
        'nick': 'Никнейм',
        'email': 'Электронная почта',
        'position': 'Должность',
        'company': 'Компания',
        'aboutMe': 'Обо мне',
        'noSuchCompany': 'Название компании должно в точности совпадать с её названием в системе',
    },
    'passwordFields': {
        'changePassword': 'Изменить пароль',
        'oldPassword': 'Старый пароль',
        'newPassword': 'Новый пароль',
        'repeatPassword': 'Повторите пароль',
        'savePassword': 'Сохранить пароль',
        'noMatch': 'Пароль должен содержать как минимум одну латинскую букву любого регистра и одну цифру, быть ' +
            'не менее 8 символов и не более 32 и не содержать других символов, кроме "_".'
    },
    'navLinks': {
        'documents': 'Документы',
        'meetings': 'Встречи',
        'search': 'Поиск',
        'back': 'Назад',
        'myContacts': 'Контакты',
        'dressingRoom': 'Персонаж',
        'support': 'Поддержка',
        'statistics': 'Статистика',
        'pavilions': 'Павильоны',
        'exitBtn': 'Покинуть выставку',
        'freeCameraTitle': 'Свободная камера',
        'freeCameraDescription': 'Включение свободной камеры позволяет видеть Вашего персонажа не только' +
            ' со спины, но и с любого удобного для Вас ракурса'
    },
    'back': 'Назад',
}