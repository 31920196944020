export const useSendUnity = () => {
  return (key, value) => {

    const send = (type, action, value) => {
      try {
        window.unityProviderForIFrame.send(type, action, value);
      } catch (error) {
        console.log("NOT UNITY")
      }
    }

    let mutateKey;
    switch (key) {
      case "ShowMap":
      case "HideMap":
      case "LoadLogInScene":
      case "AllowCaptureAllKeyboardInput":
      case "ShowStandByCode":
      case "TeleportToStand":
      case "OnTextMessage":
        send("GameManager", key, value);
        break;
      case "UpdateStandInfo":
        send("Stands", key, value);
        break;
      case "UpdateStandInfo_3d":
        send("3dViewer/Stands", "UpdateStandInfo", value);
        break;
      case "SetMaxShownOtherPlayers":
      case "Login":
      case "LoadExhibition":
      case "SetLanguage":
      case "SetQuality":
      case "ResumeMenu":
      case "PauseMenu":
        send("=== Scripts ===", key, value);
        break;
      case "MoveLeft":
      case "MoveUp":
      case "MoveRight":
      case "MoveDown":
      case "GoToDefaultPosition":
        send("3dViewer/CameraRotator", key, value);
        break;
      case "GoToDefaultPosition_cam":
        send(
          "CameraRotator",
          "GoToDefaultPosition",
          value
        );
        break;
        case "Set3dModelName":
        case "SetStandName":
        case "SetCompanyName":
        case "GetInformationAboutStand":
        case "SetObjectTypeEmptyAndDestroyModel":
          send("3dViewer/Stands", key, value);
          break;
        case "Set3dViewerActive":
          send("=== Player UI ===", key, value);
          break;
      case "RemovePointsFromStands":
      case "AddShowStatisticsComponents":
      case "SetStandName_ins":
      case "SetCompanyName_ins":
        mutateKey =
          key === "SetStandName_ins"
            ? "SetStandName"
            : key === "SetCompanyName_ins"
            ? "SetCompanyName"
            : key;
        window.unityProviderForIFrame?.unityInstance?.SendMessage("3dViewer/Stands", mutateKey, value);
        break;
      case "GetInformationAboutStand_ins":
        window.unityProviderForIFrame?.unityInstance?.SendMessage("3dViewer/Stands", "GetInformationAboutStand");
        break;
      default:
        break;
    }
  };
};
