import React from "react";
import s from './Agreement.module.css';
import {useNavigate} from "react-router-dom";
// import logo from './../../LoadingUnity/cleVR_logo_grey 1_big.png';
import { useSelector } from 'react-redux';
import { siteUrl } from "../../../api/generateDomenUrlsApp";

const Agreement = () => {


    //new code
    let navigate = useNavigate();
    const {mainNavigation: localText} = useSelector(state => state.app.appText);

    // old code



    const onBack = () => {
        navigate('/registration');
    }

    return (<div className={s.agreementContainer}>
        <div className={s.header}>
            <div onClick={onBack} className={s.exitContainer}>
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="20" height="20" x="0" y="0"
                     viewBox="0 0 443.52 443.52" style={{enableBackground: '0 0 512 512'}}>
                    <g><g xmlns="http://www.w3.org/2000/svg"><g><path d="M143.492,221.863L336.226,29.129c6.663-6.664,6.663-17.468,0-24.132c-6.665-6.662-17.468-6.662-24.132,0l-204.8,204.8    c-6.662,6.664-6.662,17.468,0,24.132l204.8,204.8c6.78,6.548,17.584,6.36,24.132-0.42c6.387-6.614,6.387-17.099,0-23.712    L143.492,221.863z"
                                                                      fill="#808080" data-original="#000000"/></g></g></g>
                </svg>
                <p>{localText.back}</p>
            </div>
            {/* <img src={logo} alt="logo"/> */}
        </div>

        <div className={s.textWrapper}>
            <p className={s.text}>
                Пользовательское соглашение cleVR<br/>
                Настоящая Политика конфиденциальности (далее – Политика конфиденциальности) действует в отношении всей информации, включая персональные данные которую УП "ЦНИИТУ-ИТ", может получить о Пользователе во время использования официальных сайтов cleVR.by, clevr-expo.com и online.clevr-expo.com, расположенных на доменных именах cleVR.by, clevr-expo.com и online.clevr-expo.com<br/>
                <p>1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</p>
                1.1. В настоящей Политике конфиденциальности используются следующие термины:<br/>

                1.1.1. «Администрация сайтов (далее – Администрация сайтов)» – сотрудники, уполномоченные на управление сайтом, действующие от имени организации УП "ЦНИИТУ-ИТ", которые организуют и (или) осуществляет обработку персональных данных, а также определяют цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.<br/>
                1.1.2. «Персональные данные» - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).<br/>
                1.1.3. «Обработка персональных данных» - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.<br/>
                1.1.4. «Конфиденциальность персональных данных» - обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.<br/>
                1.1.5. «Пользователь сайтов cleVR (далее Пользователь)» – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайты cleVR<br/>
                1.1.6. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.<br/>
                1.1.7. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.<br/>
                <p>2. ОБЩИЕ ПОЛОЖЕНИЯ</p>
                2.1. Использование Пользователем сайтов cleVR означает согласие с настоящей Политикой конфиденциальности, согласие на обработку персональных данных и согласие с условиями обработки персональных данных Пользователя.<br/>
                2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование сайтов cleVR<br/>
                2.3. Настоящая Политика конфиденциальности применяется только к сайтам cleVR.by, clevr-expo.com и online.clevr-expo.com. Сайт не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на сайтах cleVR.<br/>
                2.4. Администрация сайтов не проверяет достоверность персональных данных, предоставляемых Пользователем сайтов cleVR<br/>
                <p>3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</p>
                3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайтов по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации сайта при регистрации на сайте.<br/>
                3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путём заполнения форм обратной связи на сайтах cleVR включают в себя следующую информацию:<br/>
                3.2.1. фамилию, имя, отчество Пользователя;<br/>
                3.2.2. контактный телефон Пользователя;<br/>
                3.2.3. адрес электронной почты (e-mail);<br/>
                3.2.4. место жительства Пользователя.<br/>
                3.3. Сайт защищает Данные, которые автоматически передаются в процессе просмотра рекламных блоков и при посещении страниц, на которых установлен статистический скрипт системы ("пиксель"):<br/>
                ·	IP адрес;<br/>
                ·	информация из cookies;<br/>
                ·	информация о браузере (или иной программе, которая осуществляет доступ к показу рекламы);<br/>
                ·	время доступа;<br/>
                ·	адрес страницы, на которой расположен рекламный блок;<br/>
                ·	реферер (адрес предыдущей страницы).<br/>
                3.3.1. Отключение cookies может повлечь невозможность доступа к частям сайта, требующим авторизации.<br/>
                3.3.2. Сайт осуществляет сбор статистики об IP-адресах своих посетителей. Данная информация используется с целью выявления и решения технических проблем, для контроля законности проводимых финансовых платежей.<br/>
                3.4. Любая иная персональная информация неоговоренная выше (история просмотра, используемые браузеры и операционные системы и т.д.) подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных в п.п. 5.2. и 5.3. настоящей Политики конфиденциальности.<br/>
                <p>4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</p>
                4.1. Персональные данные Пользователя Администрация сайтов может использовать в целях:<br/>
                4.1.1. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайтов, оказания услуг, обработка запросов и заявок от Пользователя.<br/>
                4.1.2. Определения местонахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.<br/>
                4.1.3. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.<br/>
                4.1.4. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем связанных с использованием Сайта.<br/>
                4.1.5. Предоставления Пользователю с его согласия, обновлений продукции, специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени Сайта или от имени партнеров Сайтов<br/>
                4.1.6. Осуществления рекламной деятельности с согласия Пользователя.<br/>
                <p>5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</p>
                5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, но с учетом сроков хранения персональных данных, предусмотренных действующим законодательством, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств. Обрабатываемые персональные данные подлежат уничтожению либо обезличиванию по достижении целей обработки или в случае утраты необходимости в достижении этих целей.<br/>
                5.2. Пользователь соглашается с тем, что УП "ЦНИИТУ-ИТ"  вправе передавать персональные данные третьим лицам, в частности, исполнителям работ по гарантийному и постгарантийному обслуживанию, курьерским службам, транспортным компаниям, организациями почтовой связи, операторам электросвязи в целях предоставления Пользователю возможности воспользоваться услугами гарантийного сервиса и иными, предоставляемыми услугами. УП "ЦНИИТУ-ИТ" передаёт указанным лицам и/или получает от указанных лиц лишь ту персональную информацию Пользователя, которая необходима для указанных целей.<br/>
                5.3. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Республики Беларусь только по основаниям и в порядке, установленным законодательством Республики Беларусь.<br/>
                5.4. При утрате или разглашении персональных данных Администрация сайтов информирует Пользователя об утрате или разглашении персональных данных.<br/>
                5.5. Администрация сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.<br/>
                5.6. Администрация сайтов совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.<br/>
                <p>6. ОБЯЗАТЕЛЬСТВА СТОРОН</p>
                6.1. Пользователь обязан:<br/>
                6.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтами.<br/>
                6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.<br/>
                6.2. Администрация сайтов обязана:<br/>
                6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.<br/>
                6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 5.2. и 5.3. настоящей Политики Конфиденциальности.<br/>
                6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.<br/>
                6.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.<br/>
                <p>7. ОТВЕТСТВЕННОСТЬ СТОРОН</p>
                7.1. Администрация сайтов, не исполнившая свои обязательства, несёт ответственность за убытки, понесённые Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Республики Беларусь, за исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики Конфиденциальности.<br/>
                7.2. В случае утраты или разглашения Конфиденциальной информации Администрация сайтов не несёт ответственность, если данная конфиденциальная информация:<br/>
                7.2.1. Стала публичным достоянием до её утраты или разглашения.<br/>
                7.2.2. Была получена от третьей стороны до момента её получения Администрацией сайтов.<br/>
                7.2.3. Была разглашена с согласия Пользователя.<br/>
                <p>8. РАЗРЕШЕНИЕ СПОРОВ</p>
                8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем Сайта и Администрацией сайтов, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).<br/>
                8.2. Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.<br/>
                8.3. При не достижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с действующим законодательством Республики Беларусь.<br/>
                8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией сайтов применяется действующее законодательство Республики Беларусь<br/>
                9.1. Администрация сайтов вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.<br/>
                9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики конфиденциальности.<br/>
                9.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать по адресу info@clevr.by<br/>
                9.4. Действующая Политика конфиденциальности размещена на странице по адресу <a style={{color: 'green'}} target="_blank" rel="noreferrer" href={siteUrl + '/agreement'}>{siteUrl}/agreement</a><br/>
            </p>
        </div>
    </div>)
}


export default Agreement;
