import React from "react";
import { WrapPavilions } from "./pavilions.style";
import { icons_svg } from "./../StandContent/icons-svg";
import { filesUrl } from "../../api/generateDomenUrlsApp";

const Pavilions = ({ language, list, handlerActions, locationState }) => {
  const { arrowBack } = icons_svg;

  return (
    <WrapPavilions>
      {locationState?.goBack ? (
        <button
          className="pavilions__back-btn"
          onClick={handlerActions("goBack")}
        >
          <div>{arrowBack}</div>
          <span>{"Назад"}</span>
        </button>
      ) : (
        ""
      )}
      <svg className="clevr__logo" width="105" height="49" viewBox="0 0 125 49" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M100.108 7.27053C100.409 7.68208 100.773 7.96701 101.09 8.29942C108.435 15.6441 115.795 23.0046 123.14 30.3493C123.915 31.125 124.533 31.9797 124.564 33.1353C124.596 34.639 123.725 36.032 122.364 36.6018C120.876 37.2033 119.499 36.9184 118.344 35.8262C117.236 34.7656 116.191 33.6576 115.114 32.5654C108.672 26.1071 102.245 19.6647 95.803 13.2064C95.5497 12.9532 95.2964 12.7316 94.9957 12.4466C94.964 12.969 94.9165 13.4597 94.9165 13.9346C94.9165 20.2662 94.9165 26.5978 94.9165 32.9295C94.9165 33.7684 94.8057 34.5915 94.3309 35.3197C93.207 37.0609 90.5794 37.3933 89.0281 35.9528C87.5402 34.5599 86.0997 33.0878 84.6593 31.6473C76.254 23.2579 67.8646 14.8685 59.4594 6.47907C58.6521 5.67179 58.0981 4.7537 58.0823 3.59818C58.0664 2.0311 58.8895 0.843917 60.3458 0.258241C61.6755 -0.279948 63.1476 0.0366337 64.2081 1.08135C71.901 8.79012 79.6098 16.4989 87.3027 24.2076C87.3977 24.3026 87.4927 24.4293 87.6352 24.6034C87.7143 24.176 87.7618 23.8436 87.7618 23.5112C87.7618 18.1451 87.746 12.779 87.746 7.41299C87.746 6.22581 87.6985 5.03862 87.6985 3.85144C87.6985 1.60371 89.1547 0.131608 91.4025 0.131608C101.169 0.131608 110.92 0.131608 120.686 0.131608C121.858 0.131608 122.934 0.400703 123.678 1.36628C125.388 3.56652 124.216 6.4949 121.873 7.06475C121.304 7.20721 120.718 7.19138 120.148 7.19138C114.181 7.19138 108.197 7.19138 102.23 7.19138C101.517 7.17555 100.837 7.19138 100.108 7.27053Z" fill="#3DC381"/>
<path d="M69.133 29.6235C68.3257 28.2305 67.3127 27.0592 65.8248 26.3785C63.672 25.3971 61.4559 25.128 59.2557 26.1886C56.8022 27.3758 55.1401 29.2594 54.6494 32.0295C53.9055 36.1451 57.0238 40.3398 61.1552 40.8305C61.8517 40.9096 62.5481 40.8938 63.2288 41.0838C65.0333 41.6219 66.0939 43.189 65.8406 44.946C65.5715 46.798 64.0994 48.0327 62.1208 48.0169C59.2399 48.0011 56.5806 47.1938 54.1904 45.595C50.8821 43.379 48.6819 40.3714 47.8587 36.4458C46.1334 28.278 51.2303 20.4901 59.3823 18.8122C65.9039 17.4668 72.5363 20.87 75.4647 26.7584C76.3669 28.5788 76.826 30.5099 76.9526 32.536C77.0001 33.4066 77.0317 34.2456 76.6202 35.037C75.9712 36.3034 74.9265 36.984 73.5177 37.0157C69.7029 37.0948 65.8881 37.079 62.0891 37.0157C60.1263 36.984 58.6067 35.4486 58.575 33.5174C58.5434 31.4913 60.0313 29.9084 62.0099 29.8134C63.5929 29.7343 65.1758 29.7501 66.7587 29.8134C67.5343 29.8293 68.3099 29.7185 69.133 29.6235Z" fill="#363636"/>
<path d="M0 33.5017C0 26.0779 5.36606 19.8096 12.4733 18.749C17.6969 17.9734 22.1132 19.5247 25.7697 23.3078C26.4346 23.9885 26.9094 24.7483 26.9886 25.7297C27.1152 27.1543 26.5612 28.2781 25.3899 29.0854C24.2343 29.8611 22.9838 29.8769 21.7492 29.2437C21.1477 28.9271 20.657 28.4681 20.1979 27.9774C18.5517 26.2362 16.5097 25.4131 14.1195 25.6663C10.6371 26.0462 7.97786 28.5631 7.31304 32.0138C6.50575 36.2085 9.51328 40.4032 13.7555 41.0047C15.3542 41.2263 16.9055 41.1314 18.3617 40.3716C19.1057 39.9758 19.7389 39.4535 20.2929 38.8203C20.9894 38.0289 21.7492 37.3324 22.8572 37.1424C24.3451 36.8892 25.8172 37.6015 26.5929 38.9628C27.3368 40.2608 27.1785 41.7645 26.1496 42.92C22.8414 46.6241 18.6941 48.3811 13.7238 48.0803C6.94897 47.6688 1.26633 42.4452 0.205778 35.7495C0.0791453 34.9422 0.0316582 34.1349 0 33.5017Z" fill="#363636"/>
<path d="M36.7791 25.6935C36.7791 30.648 36.7791 35.6183 36.7791 40.5728C36.7791 40.8894 36.8425 40.9844 37.1749 40.9844C39.4226 40.9685 41.6862 40.9685 43.9339 40.9844C45.501 41.0002 46.8939 42.0449 47.2896 43.517C47.717 45.0683 47.0997 46.6987 45.7701 47.5534C45.1844 47.9175 44.5354 48.0758 43.8547 48.0758C40.3407 48.0758 36.8424 48.0916 33.3284 48.0758C31.1281 48.06 29.6877 46.572 29.6719 44.3718C29.6719 33.2598 29.6719 22.1636 29.6719 11.0516C29.6719 8.15484 32.4736 6.41364 34.9746 7.75911C36.2409 8.43976 36.7633 9.61112 36.7633 11.0199C36.7791 15.9111 36.7791 20.8023 36.7791 25.6935Z" fill="#363636"/>
</svg>

      <h2>Все павильоны</h2>
      <p>Выберите павильон чтобы посетить выставку</p>
      <div className="pavilions-list">
        {list?.map((pav) => (
          <div key={pav.id} className="wrap-pavilion">
            <div
              className="wrap-pav-logo"
              // dangerouslySetInnerHTML={{ __html: getHtmlSvg(pav.nameKey) }}
            >
              <img className={`pavilion__logo ${pav.nameKey}`} src={`${filesUrl}/svg/${pav.nameKey}.svg`} alt="logo-pav" onClick={handlerActions("selectPavilion", pav)}/>
            </div>
            <div className="pavilion-full-info">
              <h3 className="pavilion__head">
                {pav[
                  `shortName${
                    language === "ru" ? "Ru" : language === "en" ? "En" : "By"
                  }`
                ] || pav[language]}
              </h3>
              {/* <p className="pavilion__stands">
                <span>Cтенды в павильоне:</span>
                <span>124 стенда</span>
              </p> */}
              <div className="pavilion-desc">
                <h4 className="desc__head">Описание</h4>
                <p className="desc__text">
                  {pav[
                    `description${
                      language === "ru" ? "Ru" : language === "en" ? "En" : "By"
                    }`
                  ] || ""}
                </p>
              </div>
              <button
                className="pavilion__go"
                onClick={handlerActions("selectPavilion", pav)}
              >
                Перейти в павильон
              </button>
            </div>
          </div>
        ))}
      </div>
    </WrapPavilions>
  );
};

export default Pavilions;
