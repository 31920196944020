
export const mainNavigation_ru = {
    'limitedVersion': 'Полный функционал с чатом и личным кабинетом будет доступен после ',
    'registration': ' регистрации.',
    'changePavilion': 'Выбор другого павильона',
    'goToStand': 'Перейти',
    'back': 'Назад',
    'loading': 'Загрузка стендов...',
    'communication': 'Общение',
    'endSession': 'Сессия завершена. Повторите вход.',
}