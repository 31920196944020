import React from "react";
import videoFile from './../../background_app/background-clevr.mp4';
import { WrapBackgroundVideo } from "./backgroundVideo.style";
import { includesHref } from "../../utils/helpers";


const BackgroundVideo = () => {

    return (includesHref("/login") || includesHref("/registration")) ? (
        <WrapBackgroundVideo>
            <video  autoPlay muted loop>
                <source type="video/mp4" src={videoFile}/>
            </video>
        </WrapBackgroundVideo>
    ) : ""
}

export default BackgroundVideo;