
export const manageStand_ru = {
    'header': {
        title: 'Конструктор стенда',
        owner: 'Владелец',
        tariff: 'Тарифный план',
        sizeStand: 'Размер стенда в зависимости от тарифного плана',
        currentStand: 'Текущий стенд',
        defaultPosition: 'Позиция по умолчания',
        additionalText: 'Ассистенты и фурнитура стенда редактируется кликом выделенных областей на полу. Содержимое баннеров — кликом по ним.',
        manual: 'Инструкция по работе с конструктором',
        stand: 'Стенд',
        dev: 'В разработке',
        noStand: 'Необходимо сохранить стенд',
        noSuperStand: "У вас нет супер стенда",
    },
    'footer': {
        title: 'Коллекция типовых стендов',
        apply: 'Подтвердить изменения',
        exit: 'Выход',
    },
    'message': {
        'error': 'Ошибка сохранения',
        'ok': 'Изменения подтверждены',
    }
}