
export const errors_en = {
    'validation': {
        'name': 'Name can contain only letters and should have from 2 to 20 symbols.',
        'surname': 'Surname can contain only letters and should have from 2 to 32 symbols.',
        'patronymic': 'Patronymic can contain only letters and should have from 2 to 20 symbols.',
        'nickname': 'Nickname can only consist of letters and numbers and must contain from 3 to 32 characters.',
        'email': 'Doesn\'t match the E-mail format.',
        'password': 'Password must contain only latin letters and numbers and be not shorter than 8 symbols.',
        'passwordEqual': 'Password must be equal.',
        'company': 'enter error',
        'position': 'enter error',
        'userAgreement': 'The user agreement',
    },
    'captchaError': 'ReCapthca Error. Refresh the page.',
    'loginFail': 'enter error',
    'incorrectData': 'Login or password entered incorrectly',
    'userEmailAlreadyExist': 'User with such email exists',
    'userNicknameAlreadyExist': 'User with such nickname exists',
    'userAgreementNotChecked': 'Accept the user agreement',
    'emptyFields': 'All fields must be filled',
    'unknownError': 'Unknown error',
    'unknownErrorReg': 'Unknown error. Try refreshing the page and re-registering.',
    'alreadyLoggedIn': 'This account is already signed in',
    'internetError': 'Internet problems.',
    'userBlock': 'It is impossible to create a chat, the user is blocked',
    'youBlock': 'It is impossible to create a chat, you are blocked by the user',
    'addDate': {
        'companyNotWorking': 'Company doesn\'t work at this time',
        'unknownError': 'Unknown error',
        'noUserMeetingError': 'The company has no users to make an appointment',
        'noUsersAtThisTime': 'The company does not have free users at the time you selected.',
        'noSuchCompanyError': 'Company not found',
        'companyBusy': 'Company is busy in that time',
        'timeNotFound': 'All sessions are busy',
        'chooseDifferentTime': 'Choose another time',
        'error': 'Error',

        'userNotAvailable': 'User is not available',
        'alreadyAssigment': 'You already assigned appointment at this time',
    },
    'addMessage': {
        'noUser': 'The company has no designated users for correspondence',
        'noSuchCompany': 'Company not found',
        'unknownError': 'Unknown error',
    }
}