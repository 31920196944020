import styled from "styled-components";


const WrapBackgroundVideo = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 1;
    background: #fffffff7;
    video {
        min-width: 100%;
        min-height: 100%;
    }
`;

export {
    WrapBackgroundVideo,
}