import React from 'react';
import s from './BackButton.module.css';
import {Link} from "react-router-dom";

import { connect, useSelector } from 'react-redux';

const BackButton = ({language, path}) => {

//new code
    const {profile: localText} = useSelector(state => state.app.appText);
//old code


    return (
        <Link className={s.exitContainer} to={path}>
            <svg width="16" height="10" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659727 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM14 3.5L1 3.5L1 4.5L14 4.5L14 3.5Z" fill="currentColor"/>
            </svg>
            <p>{localText.back}</p>
        </Link>
    )
}

const mapStateToProps = function (state) {
    return {
        language: state.auth?.language || localStorage.getItem('language'),
    };
}

export default connect(mapStateToProps, {})(BackButton);
