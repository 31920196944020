import React, {useEffect, useRef, useState} from 'react';
import TableBody from './TableBody';
import TableHeader from './TableHeader';
import s from './Table.module.css'
import ContextMenu from './ContextMenu/ContextMenu';
import ModalMoveFolder from './ModalMoveFolder/ModalMoveFolder';
import { useHttp } from '../../../../hooks/useHttp';

let contextMenu = {};
let topLoad = 0 + 'px';

const Table = ({documentData, load, localText, getDocumentData,
     currentPage, directoryFolder, openSelect, actionsSelect, 
     setActionsSelect, checkboxesSelect, setCheckboxesSelect,
     toggleSelectControl}) => {

    const [openSpecify, setOpenSpecify] = useState(false);
    const [openContextMenu, setOpenContextMenu] = useState(false);
    const [moveFolder, setMoveFolder] = useState({open: false, id: ''})
    let { initRequest } = useHttp();

    const showContextMenu = (e, eInfo) => {
        e.preventDefault()
        // let coordTable = document.querySelector(`.${s.wrapper}`).getBoundingClientRect();
        contextMenu.left = `${e.pageX - 0}px`;
        contextMenu.top = `${e.pageY - window.pageYOffset}px`;
        contextMenu.deleteFile = deleteFile;
        contextMenu.setOpenContextMenu = setOpenContextMenu;
        contextMenu.openEdit = openEdit;
        contextMenu.setMoveFolder = setMoveFolder;
        contextMenu.setCheckboxesSelect = setCheckboxesSelect;
        contextMenu.openSelect = openSelect;
        contextMenu.openRename = false;
        contextMenu.eInfo = eInfo;
        contextMenu.currentFolderId = directoryFolder.current[(directoryFolder.current.length - 1)].id;
        contextMenu.type = 'table';
        contextMenu.event = e;
        contextMenu.openSelect = openSelect;
        contextMenu.toggleSelectControl = toggleSelectControl;
        contextMenu.checkboxesSelect = checkboxesSelect;

        switch(eInfo.type) {
            case 'FOLDER':
                contextMenu.folderId = e.currentTarget.id;
                delete contextMenu.fileId;
                break;
            case 'FILE':
                contextMenu.fileId = e.currentTarget.id;
                delete contextMenu.folderId;
                break;
            default: break;
        }  
        setOpenContextMenu({})
    };

    const openEdit = async (newName, edit = false) => {
        let idContent = (contextMenu.folderId || contextMenu.fileId);

        if (edit) {
            let typeContent = contextMenu.fileId ? 'file' : 'folder';
            await initRequest(`/files/api/rename/?type=${typeContent}&fileOrFolderId=${idContent}&name=${newName}`, 'put')
                .then(() => {
                    getDocumentData()
                })
            contextMenu.openRename = !contextMenu.openRename;
            setOpenContextMenu(false);
            return "";
        }
        contextMenu.openRename = !contextMenu.openRename;
        setOpenContextMenu({});
    };

    const deleteConfirmationElem = (id = false, type = false) => {
        let typeContent = type ? type : contextMenu.eInfo.type;
        let idContent = id ? id : (contextMenu.folderId || contextMenu.fileId);
        if(typeContent === 'FOLDER' || 'FILE') {
            let url = typeContent === 'FOLDER' ? `/files/api/folder/delete/?folderId=${idContent}` : `/files/delete?fileId=${idContent}&force=true`;
            initRequest(url, 'delete')
            .then(() => {
                getDocumentData();
                actionsSelect ? setActionsSelect(false) : setOpenSpecify(false);
            }); 
        }
    };

    const deleteConfirmationMultiple = () => {
        if(actionsSelect && actionsSelect.action === 'delete'){
            actionsSelect.checkboxes.forEach((item) => {
                deleteConfirmationElem(item.id, item.type);
            })
        }
    }

    const methodDeleteConfirmation = () => actionsSelect ? deleteConfirmationMultiple() : deleteConfirmationElem();
    const methodOpenSpecify = () => actionsSelect ? setActionsSelect(false) : setOpenSpecify(false);

    const deleteFile = () => {      
        switch(contextMenu.eInfo.type) {
            case 'FOLDER':
                setOpenContextMenu(false);
                setOpenSpecify(true);
                break;
            case 'FILE':
                initRequest(`/files/delete?fileId=${contextMenu.fileId}&force=false`, 'delete')
                .then((data) => {
                    setOpenContextMenu(false);
                    if (data.status === 202) {
                        setOpenSpecify(true);
                    } else {
                        getDocumentData();
                    }
                })    
                break;
            default: break;
        }
    };

    const closeContextMenu = (e) => {
        console.log(e?.target?.parentElement)
        if(e && (e?.target?.parentElement?.id === 'renameElem' || e?.target?.id === 'renameElem')){
            return;
        }
        if(openContextMenu) setOpenContextMenu(false);
        document.removeEventListener('click', closeContextMenu)
    }

    useEffect(() => {
        document.querySelector(`.${s.wrapper}`).addEventListener('scroll', () => {
            // topLoad = e.target.scrollTop + 'px';
            setOpenContextMenu(false);
            setOpenSpecify(false);
        })
    });

    useEffect(() => {
        document.addEventListener('click', closeContextMenu)
        return () => {
            document.removeEventListener('click', closeContextMenu)
        }
    })

    let widthTbody = useRef(null);
    
    const defineTextDeleteModal = () => {
        if(actionsSelect?.action === 'delete'){
            return actionsSelect.checkboxes.length < 2 ? `${localText.modal.confirmDeleteObject}?`
             : `${localText.modal.confirmDeleteObjects} (${actionsSelect.checkboxes.length} ${localText.modal.piece})?`;
        } else {
            return contextMenu.fileId ? localText.modal.confirmDeleteFile : `${localText.modal.confirmDeleteFolder} (${contextMenu.eInfo.name})?`;
        } 
    }

    return (
        <div style={{position: "relative", height: '54vh'}}>
            <div className={s.wrapper}>
                <table className={s.documents}>
                    <TableHeader 
                        localText={localText.table.headerTable}
                        getDocumentData={getDocumentData}
                        s={s}
                        widthTbody={widthTbody}
                        openSelect={openSelect}
                        checkboxesSelect={checkboxesSelect}
                        setCheckboxesSelect={setCheckboxesSelect}
                    />
                    <TableBody
                        localText={localText.table.bodyTable} documentData={documentData}
                        getDocumentData={getDocumentData}
                        openContextMenu={openContextMenu}
                        setOpenContextMenu={setOpenContextMenu}
                        showContextMenu={showContextMenu}
                        currentPage={currentPage}
                        load={load}
                        s={s}
                        widthTbody={widthTbody}
                        directoryFolder={directoryFolder}
                        openSelect={openSelect}
                        checkboxesSelect={checkboxesSelect}
                        setCheckboxesSelect={setCheckboxesSelect}
                    />
                </table>

                {load ? <div style={{top: topLoad}} className={s.load}/> : ''}
                {openContextMenu ? <ContextMenu contextMenu={contextMenu} localText={localText.table.contextMenu}/> : ''}
                {(openSpecify || actionsSelect?.action === 'delete') && <div className={s.specifyContainer}>
                    <div className={s.deleteConfContainer}>
                        <p>{defineTextDeleteModal()}</p>
                        <button onClick={methodDeleteConfirmation} className={s.yesBtn}>{localText.modal.yes}</button>
                        <button onClick={methodOpenSpecify} className={s.noBtn}>{localText.modal.no}</button>
                    </div>
                </div>}
                {moveFolder.open || (actionsSelect?.action === 'move') ? <ModalMoveFolder
                    actionsSelect={actionsSelect} 
                    setActionsSelect={setActionsSelect}
                    checkboxesSelect={checkboxesSelect} 
                    moveFolderOption={moveFolder} 
                    setMoveFolder={setMoveFolder}
                    updateTable={getDocumentData}
                    mainDirectoryFolder={directoryFolder.current}
                    localText={localText}
                    /> : ""}
            </div>
        </div>
    )
}

export default Table;

