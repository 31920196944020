import styled, { keyframes } from "styled-components";

const opacityPoint = keyframes`
  0%   { content: ''; }
  25%  { content: '.'; }
  50%  { content: '..'; }
  75%  { content: '...'; }
  100% { content: ''; }
`;

const WrapLoadingUnity = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: #FFFFFF;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .load__line-progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 5px;
    background: #34c57f;
    transition: width 0.3s ease-out;
    width: ${({ progress }) => progress}%;
  }

  .load-content {
    width: 330px;
    text-align: center;
    h2 {
      font-weight: 700;
      font-size: 20px;
      line-height: 17px;
      color: #363636;
      padding-top: 26px;
    }
    & > p {
      font-size: 18px;
      line-height: 130%;
      color: #363636;
      padding: 10px 0 10px;
    }

    .content-progress {
      display: flex;
      justify-content: center;
      color: #34c57f;
      font-size: 21px;

      .progress__points {
        width: 11px;
        text-align: start;
        span::after {
          display: inline-block;
          animation: ${opacityPoint} 1s linear infinite;
          content: "";
        }
      }

      .progress__result {
        padding-left: 6px;
      }
    }
  }
`;

export { WrapLoadingUnity };
