
export const contactPage_en = {
    'headerPage': {
        'myContacts': 'My contacts',
        'search': 'Search',
        'back': 'Back',
        'pageTitle': 'List of my contacts',
        'myExhibition': 'Only at this exhibition',
    },
    'noData': "No contacts",
    'noDataFind': "Contacts not found",
    'headerTable': {
        'avatar': 'Avatar',
        'online': 'Online',
        'name': 'Name',
        'surname': 'Surname',
        'nick': 'Nickname',
        'email': 'E-mail',
        'company': 'Company',
        'delete': 'delete'
    },
    'userCard': {
        'email': 'E-mail',
        'position': 'Position',
        'company': 'Company',
        'addUser': 'Add to Contact list',
        'deleteUser': 'Remove from contact list',
        'addSupport': 'Add to the support list',
        'unblockUser' : 'Unblock a user',
        'blockUser' : 'Block a user',
        'youBlock' : 'You are blocked by the user',
        'deleteSupport': 'Remove from the support list',
        'absent': 'absent',
        'orderForm': 'Videoconference order / appointment form',
        'selectDate': 'Date, time and duration of the meeting:',
        'enterTheme': 'Topic of the meeting:',
        'sendOrder': 'Send a request',
        'minutes': 'minutes',
        'hour': 'hour',
        'hours': 'hours',

        'successSubmit': 'The meeting is scheduled',
        'createChat': 'Create a chat',
    },
    'modal': {
        'addUser': 'Add this user to your contact list?',
        'deleteUser': 'Remove this user from your contact list?',
        'yes': 'Yes',
        'no': 'No',
        'addSupport': 'Add this user to your the support list?',
        'deleteSupport': 'Remove this user from your the support list?',
        'unblockUser' : 'Unblock a user?',
        'blockUser' : 'Block a user?'
    },
}