import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContextMenuBtn from "../../AppChat/components/ContextMenuBtn/ContextMenuBtn";
import { icons_svg } from "../icons-svg";
import { WrapCompanyStandInfo, CompanyList } from "./companyStandInfo.style";
import { filesUrl, serverUrl, siteUrl } from "../../../api/generateDomenUrlsApp";
import { RequestModal } from "./RequestsModal/RequestMeet";
import RequestsMeetContainer from "./RequestsModal/RequestsMeetContainer";
import { AppTabs } from "./../../AppTabs/AppTabs";
import { getInfoFile } from "../../../utils/helpers";
import { svg_icons } from "../../../assets/imgs/svg_icons";

const CompanyStandInfo = ({
  handlerActions,
  localState,
  tabSettings,
  selectedTab,
  localText,
}) => {
  let isRepr = selectedTab === "repr";
  let listElems = isRepr
    ? localState.info?.representatives
    : localState.info?.files;
  let listElemsLength = listElems?.length;
  let headBlock =
    listElemsLength > 1
      ? `${
          isRepr ? localText.representatives : localText.documents
        } (${listElemsLength})`
      : listElemsLength === 1
      ? `${isRepr ? localText.representative : localText.document}`
      : `${isRepr ? localText.notRepresentatives : localText.notDocuments}`;

  const { close, selectArrow, ellipsis } = icons_svg;

  if (!localState.info) return null;  

  const buildListItem = (item) => {
    let fileDesc = isRepr ? {} : getInfoFile(item);
    let icon = isRepr ? item.avatar : fileDesc.previewImg;
    let head = isRepr ? `${item.firstName} ${item.lastName}` : fileDesc.name;
    let subhead = isRepr ? item.position : fileDesc.size;
    return (
      <div className="item" key={item.id || head}>
        <div className="item-preview-img">
          {isRepr ? <img src={`${filesUrl}${icon}`} alt="icon" /> : icon}
        </div>
        <p className="item-info">
          <span>{head}</span>
          <span>{subhead}</span>
        </p>
        <button
          className="item__context-menu"
          onClick={handlerActions(
            isRepr ? "contextUser" : "contextDocumnet",
            item
          )}
        >
          {ellipsis}
        </button>
      </div>
    );
  };

  return (
    <>
      <WrapCompanyStandInfo moreInfo={localState.moreInfo}>
        <button className="close-btn" onClick={handlerActions("close")}>
          {close}
        </button>
        <div className="company-head">
          <img src={`${filesUrl}${localState.info.logo}`} alt="logo" />
          <h2>{localState.info.name}</h2>
        </div>

        <div className="company-btns">
          <button
            className="company__favorite"
            onClick={handlerActions("toggleFavorite", {
              id: localState.info.id,
              isFavorite: localState.info.isFavorite,
            })}
          >
            {localState.info.isFavorite
              ? localText.deleteFavorite
              : localText.inFavorite}
          </button>
          <button
            className="company__request"
            onClick={handlerActions("sendRequest")}
          >
            {localText.requestMeet}
          </button>
          <div className="company__page">
            <button>
              <a
                href={`${siteUrl}/company/${localState.info.companyNameKey}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {localText.pageCompany}
              </a>
            </button>
          </div>
        </div>

        <AppTabs settings={tabSettings} />

        {selectedTab === "desc" ? (
          <div className="company-desc">
            <h3 className="head-block">{localText.desc}</h3>
            <p dangerouslySetInnerHTML={{ __html: localState.info.text }} />
            <button className="desc__full" onClick={handlerActions("moreInfo")}>
              <span>
                {localState.moreInfo ? localText.showLess : localText.showMore}
              </span>
              {selectArrow}
            </button>
          </div>
        ) : (
          ""
        )}

        {listElems && selectedTab !== "desc" ? (
          <CompanyList
            oneUser={listElemsLength === 1 || !listElemsLength}
            moreInfo={localState.moreInfo}
          >
            <h3 className="head-block">{headBlock}</h3>
            <div className="wrap-users">
              <div className="scroll-users">
                {listElems.map((item) => buildListItem(item))}
              </div>
            </div>
          </CompanyList>
        ) : (
          ""
        )}
      </WrapCompanyStandInfo>
      {/* <ContactPage/> */}
    </>
  );
};

export default CompanyStandInfo;
