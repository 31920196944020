
export const errors_by = {
    'validation': {
        'name': 'Імя можа ўтрымліваць толькі літары і павінна мець ад 2 да 20 сімвалаў.',
        'surname': 'Прозвішча можа ўтрымліваць толькі літары і павінна мець ад 2 да 32 сімвалаў.',
        'patronymic': 'Па бацьку можа ўтрымліваць толькі літары і павінна мець ад 2 да 20 сімвалаў.',
        'nickname': 'Нік можа складацца толькі з літар і лікаў і павінен утрымоўваць ад 3 да 32 знакаў.',
        'email': 'Не адпавядае фармату электроннай пошты.',
        'password': 'Пароль павінен утрымліваць толькі лацінскія літары і лічбы і не менш за 8 сімвалаў.',
        'passwordEqual': 'Паролі павінны быць роўнымі.',
        'company': 'enter error',
        'position': 'enter error',
        'userAgreement': 'Карыстальніцкае пагадненне',
    },
    'captchaError': 'ReCapthca Error. Абнавіце старонку.',
    'loginFail': 'enter error',
    'incorrectData': 'Няправільна ўведзены лагін ці пароль',
    'userEmailAlreadyExist': 'Карыстальнік з такім адрасам электроннай пошты існуе',
    'userNicknameAlreadyExist': 'Карыстальнік з такім нікам існуе',
    'userAgreementNotChecked': 'Прыміце карыстацкую дамову',
    'emptyFields': 'Усе палі павінны быць запоўнены',
    'unknownError': 'Невядомая памылка',
    'unknownErrorReg': 'Невядомая памылка. Паспрабуйце абнавіць старонку і паўтарыць рэгістрацыю.',
    'alreadyLoggedIn': 'Уваход у рахункам зараз ужо выкананы',
    'internetError': 'Праблемы з інтэрнэтам.',
    'userBlock': 'Немагчыма стварыць чат, карыстальнік заблакаваны',
    'youBlock': 'Немагчыма стварыць чат, вы заблакаваныя карыстальнікам',
    'addDate': {
        'companyNotWorking': 'Кампанія ў гэты час не працуе',
        'unknownError': 'Невядомая памылка',
        'noUserMeetingError': '',
        'noUsersAtThisTime': 'У кампаніі няма свабодных карыстальнікаў на абраны вамі час.',
        'noSuchCompanyError': '',
        'companyBusy': '',
        'timeNotFound': 'Усе сеансы занятасці',
        'chooseDifferentTime': 'Выберыце іншы час',
        'error': 'Памылка',

        'userNotAvailable': 'Карыстальнік недаступны',
        'alreadyAssigment': 'Вы ўжо прызначылі сустрэчу ў гэты час',
    },
    'addMessage': {
        'noUser': 'У кампаніі няма прызначаных карыстальнікаў для перапіскі',
        'noSuchCompany': 'Кампанія не знойдзена',
        'unknownError': 'Невядомая памылка',
    }
}