import otherPerson from "./undefined.fbx";
import alexander from "./alexander.fbx";
import marina from "./marina.fbx";
import claudia from "./claudia2.fbx";
import andrey from "./andrey.fbx";
import kirill from "./kirill.fbx";
import sophia from "./sophia2.fbx";
import robot from "./robot.fbx";
import avatarFbx from "./Avatar/Avatar.fbx";
   import avatar_textureHead1 from "./Avatar/AvatarHeadMale_Color_2K.jpg"
   import avatar_textureHead2 from "./Avatar/AvatarHeadMale_Normal1_2K.jpg"
   import avatar_textureHead3 from "./Avatar/AvatarHeadMale_Roughness_2K.jpg"
   import avatar_textureBody1 from "./Avatar/AvatarBodyMale_Color_2K.jpg"
   import avatar_textureBody2 from "./Avatar/AvatarBodyMale_Normal1_2K.jpg"
   import avatar_textureBody3 from "./Avatar/AvatarBodyMale_Roughness_2K.jpg"
   import avatar_textureEyes1 from "./Avatar/AvatarEyes_Color_512.jpg"
   import avatar_textureEyes2 from "./Avatar/AvatarEyes_Normal_512.jpg"
   import avatar_textureEyes3 from "./Avatar/AvatarEyes_Roughness_512.jpg"
   import avatar_textureTeeth1 from "./Avatar/AvatarTeeth_Color_1K.jpg"
   import avatar_textureTeeth2 from "./Avatar/AvatarTeeth_Normal1_1K.jpg"
   import avatar_textureTeeth3 from "./Avatar/AvatarTeeth_Roughness_1K.jpg"
   import avatar_textureColor from "./Avatar/ARARAT_Color_2K.jpg"
   import avatar_textureNormal from "./Avatar/ARARAT_Normal_2K.jpg"
   import avatar_textureLeft from "./Avatar/AvatarLeftCornea_Color_512.jpg"
   import avatar_textureRight from "./Avatar/AvatarRightCornea_Color_512.jpg"
   import avatar_textureRoughness from "./Avatar/ARARAT_Roughness_2K.jpg"


import otherPerson_texture from "./undefined.jpg";
import alexander_texture from "./rp_alexander.jpg";
import kirill_texture from "./rp_kirill.jpg";
import marina_texture from "./rp_marina.jpg";
import claudia_texture from "./rp_claudia.jpg";
import andrey_texture from "./rp_andrey.jpg";
import sophia_texture from "./rp_sophia.jpg";
import robot_arm_texture from "./Armature_Arms_AlbedoTransparency.png";
import robot_body_texture from "./Armature_Body_AlbedoTransparency.png";
import robot_legs_texture from "./Armature_Legs_AlbedoTransparency.png";
import otherPersonPreview from "./imgPreview/otherPerson-preview.png";
import kirillPreview from "./imgPreview/kirill-preview.png";
import alexanderPreview from "./imgPreview/alexander-preview.png";
import andreyPreview from "./imgPreview/andrey-preview.png";
import claudiaPreview from "./imgPreview/claudia-preview.png";
import marinaPreview from "./imgPreview/marina-preview.png";
import sophiaPreview from "./imgPreview/sophia-preview.png";
import robotPreview from "./imgPreview/robot-preview.png";

export const herosModels = {
  Undefined: {
    id: 0,
    name: "Undefined",
    model: otherPerson,
    texture: otherPerson_texture,
    preview: otherPersonPreview,
  },
  Nathan: {
    id: 1,
    name: "Nathan",
    model: kirill,
    texture: kirill_texture,
    preview: kirillPreview,
  },
  Manuel: {
    id: 2,
    name: "Manuel",
    model: alexander,
    texture: alexander_texture,
    preview: alexanderPreview,
  },
  Eric: {
    id: 3,
    name: "Eric",
    model: andrey,
    texture: andrey_texture,
    preview: andreyPreview,
  },
  Claudia: {
    id: 4,
    name: "Claudia",
    model: claudia,
    texture: claudia_texture,
    preview: claudiaPreview,
  },
  Carla: {
    id: 5,
    name: "Carla",
    model: marina,
    texture: marina_texture,
    preview: marinaPreview,
  },
  Sophia: {
    id: 6,
    name: "Sophia",
    model: sophia,
    texture: sophia_texture,
    preview: sophiaPreview,
  },
  Robot: {
    id: 7,
    name: "Robot",
    model: robot,
    textureArm: robot_arm_texture,
    textureBody: robot_body_texture,
    textureLegs: robot_legs_texture,
    preview: robotPreview,
  },
  Avatar: {
    id: 8,
    name: "Avatar",
    model: avatarFbx,
    avatar_textureHead1, 
    // avatar_textureHead2, 
    // avatar_textureHead3, 
    avatar_textureBody1, 
    // avatar_textureBody2, 
    // avatar_textureBody3, 
    avatar_textureEyes1,
    // avatar_textureEyes2,
    // avatar_textureEyes3,
    avatar_textureTeeth1, 
    // avatar_textureTeeth2, 
    // avatar_textureTeeth3, 
    avatar_textureColor, 
    // avatar_textureNormal,
    avatar_textureLeft,
    avatar_textureRight,
    // avatar_textureRoughness,
    preview: robotPreview,
  },
};
