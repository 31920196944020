import React from "react";
import AppChat from "./AppChat";
import useWebSocket from "./hooks/useWebSocket";
import {  useSelector } from "react-redux";

const AppChatContainer = () => {
  let mobileVersion = useSelector((state) => state.appChat.mobileVersion);
  // useWebSocket();

  return <AppChat mobileVersion={mobileVersion} />;
};

export default AppChatContainer;
