import React, { useEffect } from "react";
import { WrapBaseTablePage } from "./baseTablePage.style";
import { svg_icons } from "../../assets/imgs/svg_icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  resetProfileState,
  setProfileTableState,
} from "../../store/slices/profileSlice/profileSlice";

const BaseTablePage = ({ head, type, children, selectFilter, styles }) => {
  const { arrowBack, searchIcon } = svg_icons;
  const navigate = useNavigate();
  let {
    table: { filter },
  } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const location = useLocation();

  const handlerActions = (key, data) => async (e) => {
    switch (key) {
      case "goBack":
        navigate(location.pathname.includes("profile") ? "/profile?tab=main" : -1);
        break;
      case "search":
        dispatch(
          setProfileTableState({
            filter: { ...filter, search: e.target.value },
          })
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => () => dispatch(resetProfileState("table")), [dispatch]);

  return (
    <WrapBaseTablePage styles={styles}>
      <div className="table-head">
        <div className="table-back">
          <button onClick={handlerActions("goBack")}>{arrowBack}</button>
          <h2>{head || "Назад"}</h2>
        </div>
        <div className="table-filter">
          {selectFilter ? (
            <div className="filter-select-btns">{selectFilter}</div>
          ) : (
            ""
          )}
          <div className="filter-search">
            {searchIcon}
            <input
              type="text"
              placeholder="Что вы ищите?"
              value={filter.search}
              onChange={handlerActions("search")}
            />
          </div>
        </div>
      </div>

      <div className="table-wrap-content">{children}</div>
    </WrapBaseTablePage>
  );
};

export default BaseTablePage;
