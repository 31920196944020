import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ManageStand from "./ManageStand";
import { useNavigate } from "react-router-dom";
import { filesUrl } from "../../api/generateDomenUrlsApp";
import { useSendUnity } from "../../hooks/useSendUnity";
import { resetUnityState, setUnityState } from "../../store/slices/unitySlice/unitySlice";
import { useHttp } from "../../hooks/useHttp";
import { getRequest } from "../../api/appService";
import { getCopmanyStandFull } from "../../store/slices/unitySlice/unityExtraReducers";

const ManageStandContainer = () => {
  let { userData, table } = useSelector((state) => state.profile);
  const { isScene, isSceneStand, copmanyStandFull } = useSelector((state) => ({
    isScene: state.unity.isScene,
    isSceneStand: state.unity.isSceneStand,
    copmanyStandFull: state.unity.copmanyStandFull,
  }));
  let { language } = useSelector((state) => state.app.appText);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { initRequest } = useHttp();
  let { mediaApi } = getRequest(initRequest);
  const [
    { sizeModels, selectStandModel, openMenu, selectStandMedia, typeMedia },
    setLocalState,
  ] = useState({
    sizeModels: "8x8",
    selectStandModel: null,
    selectStandMedia: null,
    typeMedia: "media",
    openMenu: "current",
  });
  let countStands =
    sizeModels === "8x8"
      ? ["1", "2", "3"]
      : sizeModels === "Super"
      ? []
      : ["1", "2", "3", "4"];
  let modelImageData = countStands.map((elem) => ({
    url: `${filesUrl}/files/unity_images/stands/typical/${sizeModels}/${elem}.jpg`,
    name: `${sizeModels}_0${elem}`,
  }));
  const sendUnity = useSendUnity();

  console.log("company", copmanyStandFull);
  console.log("companyStand", copmanyStandFull.company);

  const handlerActions = (type, data) => async (e) => {
    switch (type) {
      case "closeSelectModel":
        setLocalState((state) => ({
          ...state,
          selectStandMedia: null,
          typeMedia: "media",
          openMenu: "current",
        }));
        dispatch(setUnityState({ wrapCss: "left: 283px" }));
        break;
      case "closeSelectMedia":
        setLocalState((state) => ({
          ...state,
          selectStandModel: null,
          openMenu: "current",
        }));
        dispatch(setUnityState({ wrapCss: "left: 283px" }));
        break;
      case "openSelectModel":
        setLocalState((state) => ({ ...state, openMenu: "select" }));
        dispatch(setUnityState({ wrapCss: "right: 283px" }));
        break;
      case "selectModel":
        dispatch(setUnityState({ isSceneStand: false }));
        setLocalState((state) => ({ ...state, selectStandModel: data }));
        sendUnity("SetStandName_ins", `typical/${data.name}`);
        break;
      case "selectMedia":
        setLocalState((state) => ({ ...state, selectStandMedia: data }));
        break;
      case "saveMedia":
        let object = copmanyStandFull.object;
        let newTypeId = selectStandMedia.type === "image" ? 7 : selectStandMedia.type === "video" ? 8 : 6;
        console.log(object.owner, object.id, object.typeId, selectStandMedia.id, newTypeId)
        await mediaApi.updateMedia(object.owner, object.id, newTypeId, selectStandMedia.id);
        sendUnity("UpdateStandInfo", `${object.owner}:${object.id}`);
        sendUnity("UpdateStandInfo_3d", `${object.owner}:${object.id}`);
        console.log(selectStandMedia);
        break;
      case "changeSizeModels":
        setLocalState((state) => ({ ...state, sizeModels: data }));
        break;
      case "changeTypeMedia":
        setLocalState((state) => ({ ...state, typeMedia: data }));
        break;
      case "goBack":
        navigate("/profile?tab=main");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const fetch = async () => {
      await dispatch(
        getCopmanyStandFull({
          companyKey: copmanyStandFull.object.owner,
          objectId: copmanyStandFull.object.id,
          noStatistics: true,
        })
      );
      setLocalState((state) => ({ ...state, openMenu: "banner" }));
      dispatch(setUnityState({ wrapCss: "right: 283px" }));
    };
    copmanyStandFull.object.id && fetch();
  }, [dispatch, copmanyStandFull.object.id]);

  useEffect(() => {
    dispatch(setUnityState({ wrapCss: "left: 283px" }));
    return () => {
      dispatch(setUnityState({ isSceneStand: false, wrapCss: null }));
      dispatch(resetUnityState("copmanyStandFull"));
      sendUnity("Set3dViewerActive", "false");
    };
  }, [dispatch]);

  useEffect(() => {
    let timeout;
    if (isScene) {
      timeout = setTimeout(() => {
        sendUnity("Set3dViewerActive", "true");
        sendUnity("SetStandName", userData?.company.companyNameKey);
      }, 300);
    }
    return () => {
      isScene && clearTimeout(timeout);
    };
  }, [isScene]);

  return (
    <ManageStand
      handlerActions={handlerActions}
      table={table}
      userData={userData}
      openMenu={openMenu}
      selectStandModel={selectStandModel}
      sizeModels={sizeModels}
      modelImageData={modelImageData}
      isSceneStand={isSceneStand}
      mediaCompany={{
        media: [
          ...(copmanyStandFull.company?.mediaByTypes?.images || []),
          ...(copmanyStandFull.company?.mediaByTypes?.videos || []),
        ],
        _3d: copmanyStandFull.company?.mediaByTypes?.models || [],
      }}
      selectStandMedia={selectStandMedia}
      typeMedia={typeMedia}
      companyNameKey={copmanyStandFull.company?.companyNameKey}
      language={language}
    />
  );
};

export default ManageStandContainer;
