import React from 'react';
import { Link } from 'react-router-dom';
import { filesUrl } from '../../../../api/generateDomenUrlsApp';

const FileItem = (props) => {
    const token = localStorage.getItem('token');

    let size = 0;
    const oneKb = 1024;
    const oneMb = 1048576;
    const oneGb = 1073741824;

    const divStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        margin: '0 auto',
        padding: '0 15px',
        cursor: 'pointer'
    }

    const imgStyle = {
        width: '45px',
        height: '45px',
        margin: '5px 30px 5px 0'
    }

    const nameStyle = {
        width: '66%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'start'
    }

    const sizeStyle = {
        width: '15%'
    }

    if (props.size >= oneKb && props.size < oneMb) {
        size = (props.size / oneKb).toFixed(1) + ' Кб'
    } else if (props.size >= oneMb && props.size < oneGb) {
        size = (props.size / oneMb).toFixed(1) + ' Мб'
    } else if (props.size >= oneGb) {
        size = (props.size / oneGb).toFixed(1) + ' Гб'
    }

    // const downloadFile = () => {
    //     axios.get(serverUrl + `/files/download?fileId=${props.id}&token=${token}`,
    //     //     {
    //     //     headers: {
    //     //         'Authorization': `Bearer ` + token
    //     //     }
    //     // }
    //         )
    //         // .then()
    // }


    return (
        <>
            <hr/>
                <Link  style={divStyle}  to={`/profile/documents?search=${props.name}`}>
                    <img src={filesUrl + props.icon} style={imgStyle} alt={''}/>
                    <p style={nameStyle}>{props.name}</p>
                    <p>{props.modifiedTime}</p>
                    <p style={sizeStyle}>{size}</p>
                </Link>
        </>
    )
}

export default FileItem;
