import { filesUrl } from "../api/generateDomenUrlsApp";
import { svg_icons } from "../assets/imgs/svg_icons";

export const getInfoFile = (file) => {
  if (!file) return file;

  // const { get } = handlerLocalStorage();
  // let lang = get("language");
  // let text = (lang && langs[lang])?.baseWords;

  // test var

  let infoFile = file;

  let text = {
    'byte': 'b',
    'kilobyte': 'Kb',
    'megabyte': 'Mb',
    'gigabyte': 'Gb',
  }

  let sizeInformation = {
    kilobyte: 2 ** 10,
    megabyte: 2 ** 20,
    gigabyte: 2 ** 30,
  };

  const { pdfIcon, defaultFile } = svg_icons;

  const getDocIcon = (type) => {
    switch (type) {
      case "pdf":
        return pdfIcon;

      default:
        return defaultFile;
    }
  };

  const calcSize = (sizeFile) => {
    if (
      sizeFile >= sizeInformation.kilobyte &&
      sizeFile < sizeInformation.megabyte
    ) {
      return `${(sizeFile / sizeInformation.kilobyte).toFixed(1)} ${
        text.kilobyte
      }`;
    } else if (
      sizeFile >= sizeInformation.megabyte &&
      sizeFile < sizeInformation.gigabyte
    ) {
      return `${(sizeFile / sizeInformation.megabyte).toFixed(1)} ${
        text.megabyte
      }`;
    } else if (sizeFile >= sizeInformation.gigabyte) {
      return `${(sizeFile / sizeInformation.gigabyte).toFixed(1)} ${
        text.gigabyte
      }`;
    } else {
      return `${(+sizeFile).toFixed(1)} ${text.byte}`;
    }
  };

  infoFile = {
    ...file,
      lastModified: file.lastModified,
      shortName: (file.name || "file").substring(0, file.name?.lastIndexOf(".")),
      name: file.name,
      size: calcSize(file.size),
      type: file.type,
      format: file.name.slice(file.name.indexOf(".") + 1, file.name.length)
    }

    infoFile.previewImg = getDocIcon(infoFile.format);
  

  return infoFile;
};

export const generateUrlsBuild = (idPavilion = 1, localHost) => {
  let urlOrigin = filesUrl;
  // return {
  //   data: `https://online.clevr-expo.com/Build/prodBuild.data.unityweb?16717052200001`,
  //   framework: `https://online.clevr-expo.com/Build/prodBuild.framework.js.unityweb?16717052200001`,
  //   wasm: `https://online.clevr-expo.com/Build/prodBuild.wasm.unityweb?16717052200001`,
  //   loader: `https://online.clevr-expo.com/Build/prodBuild.loader.js?16651335770001`,
  // };
  return {
    data: `${urlOrigin}/Build/${idPavilion}/prodBuild.data.unityweb`,
    framework: `${urlOrigin}/Build/${idPavilion}/prodBuild.framework.js.unityweb`,
    wasm: `${urlOrigin}/Build/${idPavilion}/prodBuild.wasm.unityweb`,
    loader: `${urlOrigin}/Build/${idPavilion}/prodBuild.loader.js`,
  };
};

export const checkRegexp = ({ key, value }) => {
  // regularExpress.lastIndex = 0
  // withoutSpecialChars.lastIndex = 0

  switch (key) {
    case "changePassword":
      return (
        /^(?=.*\d)(?=.*[a-zA-Z]).{8,32}$/gm.test(value) &&
        /^[^-$%^&#*.,'":;() /@!]*$/gm.test(value)
      );

    default:
      return true;
  }
};

export const includesHref = (path) => window.location.href.includes(path);

export const getParamFromUrl = (param) =>
  new URLSearchParams(document.location.search).get(param);

export const timeMoreDay = (prev, next) =>
  { 
    let difference = +new Date(next) - +new Date(prev);
    return (difference < 0 ? difference * -1 : difference) >= 86400000
  };

export const dateToLocaleDateString = (date) =>
  date ? new Date(date).toLocaleDateString() : "-";

  export const getDateAgo = ({number = 1, type = "days", date = new Date()} = {}) => {
    let dateCopy = new Date(date);
    dateCopy.setHours(0, 0, 0);
    switch (type) {
      case "month":
        dateCopy.setMonth(date.getMonth() - number);
        break;
      case "year":
        dateCopy.setFullYear(date.getFullYear() - number);
        break;
      default:
        dateCopy.setDate(date.getDate() - number);
        break;
    }
    return dateCopy;
  };

export const viewDatePeriod = (from, to) =>
  `${dateToLocaleDateString(from)} - ${dateToLocaleDateString(to)}`;

export const calcFileSize = (sizeFile, text) => {

  let sizeInformation = {
    kilobyte: 2 ** 10,
    megabyte: 2 ** 20,
    gigabyte: 2 ** 30,
  };

  if (
    sizeFile >= sizeInformation.kilobyte &&
    sizeFile < sizeInformation.megabyte
  ) {
    return `${(sizeFile / sizeInformation.kilobyte).toFixed(1)} ${
      text.kilobyte
    }`;
  } else if (
    sizeFile >= sizeInformation.megabyte &&
    sizeFile < sizeInformation.gigabyte
  ) {
    return `${(sizeFile / sizeInformation.megabyte).toFixed(1)} ${
      text.megabyte
    }`;
  } else if (sizeFile >= sizeInformation.gigabyte) {
    return `${(sizeFile / sizeInformation.gigabyte).toFixed(1)} ${
      text.gigabyte
    }`;
  } else {
    return `${(+sizeFile).toFixed(1)} ${text.byte}`;
  }
};
