import React, { useEffect, useState } from "react";
import { ImgSlider } from "./ImgSlider/ImgSlider";
import { VideoSlider } from "./VideoSlider/VideoSlider";
import { WrapMaterials } from "./companyStandMaterials.style";
import icons_svg from "../../AppChat/imgs/icons_svg";
import { _3dViewUrl } from "../../../api/generateDomenUrlsApp";
import Iframe from './../../MediaFiles/Iframe/Iframe';
import Preview3DModels from "./Preview3DModels/Preview3DModels";

export const CompanyStandMaterials = ({
  type,
  handlerActions,
  mediaByTypes,
  mediaId,
  company,
}) => {
  let { img, videoPlay, cude3D, closeBtn } = icons_svg;

  console.log(mediaByTypes);
  console.log(type);
  console.log(mediaId);

  return (
    <>
      <div className="backgroud-modal"></div>
      <WrapMaterials>
        <div className="materials-header">
          <div className="header-text">
            {/* <h3>Павильон:</h3>
            {companyPavilions.map((pavilion, index) => {
                return (
                    <p key={index}>{pavilion.id === PavilionId ? pavilion[language.language] : ''}</p>
                )
            })} */}
          </div>
          <div className="header-btns">
            <button
              onClick={handlerActions("changeType", "image")}
              className={`btns__imgs${type === "image" ? "_active" : ""}`}
            >
              {img} Фото ({mediaByTypes.images.length})
            </button>
            <button
              onClick={handlerActions("changeType", "video")}
              className={`btns__imgs${type === "video" ? "_active" : ""}`}
            >
              {videoPlay} Видео ({mediaByTypes.videos.length})
            </button>
            {mediaByTypes.models?.length ? <button
              onClick={handlerActions("changeType", "3dModel")}
              className={`btns__imgs${type === "3dModel" ? "_active" : ""}`}
            >
              {cude3D} 3D модели ({mediaByTypes.models?.length})
            </button> : ""}
          </div>
          <div className="header__close">
            Закрыть{" "}
            <div className="close__icon" onClick={handlerActions("close")}>
              {closeBtn}
            </div>
          </div>
        </div>

        <div className="wrap-materials">
          {type === "3dModel" ? (
            <Preview3DModels company={company} models={mediaByTypes.models} mediaId={mediaId}/>
          ) : type === "image" ? (
            <ImgSlider images={mediaByTypes.images} mediaId={mediaId} />
          ) : (
            <VideoSlider videos={mediaByTypes.videos} mediaId={mediaId} />
          )}
        </div>
        {/*Content for slider*/}
      </WrapMaterials>
    </>
  );
};
