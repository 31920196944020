import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Statistics from "./Statistics";
import {
  getDocumentsUser,
  getStatisticsCompany,
} from "../../store/slices/profileSlice/profileExtraReducers";
import { setProfileTableState } from "../../store/slices/profileSlice/profileSlice";
import { setAppState } from "../../store/slices/appSlice/appSlice";
import { useNavigate } from "react-router-dom";
import { getDateAgo, viewDatePeriod } from "../../utils/helpers";
import downloadFile from "../../services/downloadFile.service";
import { serverUrl } from "../../api/generateDomenUrlsApp";

const StatisticsContainer = () => {
  let { statistics } = useSelector((state) => state.profile);
  let localText = useSelector((state) => state.app.appText.statistics);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let todayDate = new Date().toISOString();
  let {
    current: { defDate },
  } = useRef({
    defDate: {
      dateFrom: getDateAgo({ number: 1 }).toISOString(),
      dateTo: todayDate,
    },
  });
  let datePickerRef = useRef({});

  const settingsSelect = {
    label: localText.timePeriod,
    options: [
      {
        id: 1,
        value: localText.today,
        date: defDate,
      },
      {
        id: 2,
        value: localText.week,
        date: {
          dateFrom: getDateAgo({ number: 7 }).toISOString(),
          dateTo: todayDate,
        },
      },
      {
        id: 3,
        value: localText.month,
        date: {
          dateFrom: getDateAgo({ type: "month" }).toISOString(),
          dateTo: todayDate,
        },
      },
      {
        id: 4,
        value: localText.year,
        date: {
          dateFrom: getDateAgo({ type: "year" }).toISOString(),
          dateTo: todayDate,
        },
      },
      {
        id: 5,
        value: localText.setPeriod,
      },
    ],
  };

  const [{ selectDate }, setLocalState] = useState({
    selectDate: {
      id: 1,
      dateFrom: defDate.dateFrom,
      dateTo: defDate.dateTo,
      view: viewDatePeriod(defDate.dateFrom, defDate.dateTo),
    },
  });

  const handlerActions = (type, data) => async (e) => {
    let dateFrom, dateTo, id, view;
    switch (type) {
      case "changePeriod":
        if (e.id === 5) {
          dispatch(
            setAppState({
              modal: {
                type: "periodStatistics",
                withChildren: "registrSuccess",
                head: "Выбор периода",
                submitText: "Применить",
                cancel: e.setPrevSelect,
                submit: handlerActions("applySelectedPeriod", e.setSelectValue),
              },
            })
          );
          return;
        }
        let selectPeriod = settingsSelect.options.find((elem) => elem.id === e.id);
        dateFrom = selectPeriod.date.dateFrom;
        dateTo = selectPeriod.date.dateTo;
        id = selectPeriod.id;
        if (id === selectDate.id) return;
        view = viewDatePeriod(dateFrom, dateTo);
        setLocalState((state) => ({
          ...state,
          selectDate: {
            id,
            dateFrom,
            dateTo,
            view,
          },
        }));
        break;
      case "applySelectedPeriod":
        dateFrom = datePickerRef.current.startDate;
        dateTo = datePickerRef.current.endDate;
        id = 5;
        view = viewDatePeriod(dateFrom, dateTo);
        setLocalState((state) => ({
          ...state,
          selectDate: {
            id,
            dateFrom,
            dateTo,
            view,
          },
        }));
        data(view);
        break;
      case "changeUserData":
        break;
      case "goBack":
        navigate("/profile?tab=main");
        break;
      case "toPage":
        navigate(data);
        break;
      case "downloadReport":
        downloadFile({
          type: "excel",
          data: {
            dateFrom: selectDate.dateFrom,
            dateTo: selectDate.dateTo,
            name: `${selectDate.view}.xlsx`,
          },
        });
        //
        break;
      default:
        break;
    }
  };

  // console.log(statistics);

  const statisticsList = {
    date: selectDate.view,
    list: [
      {
        head: localText.visitors3D,
        total: statistics?.allVisitors3d,
        new: statistics?.allByPeriodVisitors3d
          ? `+${statistics?.allByPeriodVisitors3d} ${localText.visitors}`
          : "-",
        to: "/profile/contacts",
        toText: localText.listUsers,
      },
      {
        head: localText.visitorsSite,
        total: statistics?.allVisitorsSite,
        new: statistics?.allByPeriodVisitorsSite
          ? `+${statistics?.allByPeriodVisitorsSite} ${localText.visitors}`
          : "-",
        to: "/profile/contacts",
        toText: localText.listUsers,
      },
      {
        head: localText.reqMeet,
        total: statistics?.allMeetings,
        new: statistics?.allByPeriodMeetings
          ? `+${statistics?.allByPeriodMeetings} ${localText.reqs}`
          : "-",
        to: "/profile/requests",
        toText: localText.listReqs,
      },
      {
        head: localText.documents,
        total: statistics?.allDocuments,
        new: statistics?.allByPeriodDocuments
          ? `+${statistics?.allByPeriodDocuments} ${localText.downedDocs}`
          : "-",
        to: "/profile/documents",
        toText: localText.listDocs,
      },
    ],
  };

  // console.log(statisticsList);

  useEffect(() => {
    console.log("CALLL");
    dispatch(getStatisticsCompany(selectDate));
  }, [dispatch, selectDate]);

  return (
    <Statistics
      handlerActions={handlerActions}
      settingsSelect={settingsSelect}
      statisticsList={statisticsList}
      localText={localText}
      datePickerRef={datePickerRef}
    />
  );
};

export default StatisticsContainer;
