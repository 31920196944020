import React, { useEffect, useState, useRef } from "react";
import "./App.css";
import { connect } from "react-redux";

import {
  Route,
  useNavigate,
  useLocation,
  Routes,
  Navigate,
  useSearchParams,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { makeLogout,  } from "./reducers/authReducer";
import DocumentsPage from "./components/Profile/DocumentsPage/DocumentsPage";
import AppointmentsPage from "./components/Profile/Appointments/AppointmentsPage";
import StatisticsPage from "./components/Profile/StatisticsPage/StatisticsPage";
import DressingRoom from "./components/Profile/DressingRoom/DressingRoom";
import SearchPage from "./components/Profile/SearchPage/SearchPage";
import UnityContainer from "./components/UnityContainer/UnityContainer";
import Agreement from "./components/Registration/Agreement/Agreement";
// import StandStatistics from "./components/StandStatistics/StandStatistics";
import MediaFiles from "./components/MediaFiles/MediaFiles";
import { getRequest } from "./api/appService";
import { setSelectedPavilion } from "./reducers/pavilionsReducer";
import { resetState } from "./reducers/mainReducer";
import { useHttp } from "./hooks/useHttp";
import UnityIframe from "./components/UnityContainer/UnityIframe/UnityIframe";
import { useSelector } from "react-redux";
import AppChatContainer from "./components/AppChat/AppChatContainer";
import RightBoxContainer from "./components/AppChat/RightBox/RightBoxContainer";
// import ProfileContainer from "./components/Profile/ProfileContainer";
import ModalsChat from "./components/AppChat/ModalsChat/ModalsChat";
import NavMainBtnsContainer from "./components/MainPage/NavMainBtnsContainer";
import Pavilions from "./components/Pavilions/Pavilions";
import LoginContainer from "./components/Login/LoginContainer";
import RegistrationContainer from "./components/Registration/RegistrationContainer";
import FirstStart from "./components/FirstStart/FirstStart";
import BackgroundVideo from "./components/BackgroundVideo/BackgroundVideo";
import { includesHref } from "./utils/helpers";
import AppModals from './components/AppModals/AppModals';
import { useCheckAuth } from './hooks/useCheckAuth';
import PavilionsContainer from './components/Pavilions/PavilionsContainer';
import ProfileRoute from "./pages/Profile/ProfileRoute";
import ProfileContainer from './pages/Profile/ProfileContainer';
import BaseTablePage from "./components/BaseTablePage/BaseTablePage";
import ContactsContainer from './pages/Contacts/ContactsContainer';
import RequestsContainer from "./pages/Requests/RequestsContainer";
import MeetingsContainer from "./pages/Meetings/MeetingsContainer";
import HeroContainer from "./pages/Hero/HeroContainer";
import ManageStandContainer from "./pages/ManageStand/ManageStandContainer";
import DocumentsContainer from "./pages/Documents/DocumentsContainer";
import FavoritesContainer from './pages/Favorites/FavoritesContainer';
import AppContextMenu from './components/AppContextMenu/AppContextMenu';
import StatisticsContainer from './pages/Statistics/StatisticsContainer';
import useWebSocket from "./components/AppChat/hooks/useWebSocket";
import RequestsMeetContainer from "./components/StandContent/CompanyStandInfo/RequestsModal/RequestsMeetContainer";
import { setAuthState } from "./store/slices/authSlice/authSlice";

window._3dModelClosed = () => {
  console.log("window._3dModelClosed()");
};

window.messageHandler = (event) => {
  // console.log('window.messageHandler default');
  // console.log('event = ', event);
};



window.addEventListener("message", (event) => {
  window.messageHandler(event);
});

function App({
  isAuth,
  isAutoLogin,

  setSelectedPavilion,
  makeLogout,
  resetState,
  dispatch,
}) {
  //new code
  const {
    isScene,
    isProgress,
    pavilions: { selectedPavilion },
  } = useSelector((state) => state.unity);
  const user = useSelector((state) => state.auth.user);

  let { initRequest } = useHttp();
  window.useHttp = { initRequest };
  useCheckAuth();


  //old code

  // console.log("packageJson.version = ", packageJson.version);
  let navigate = useNavigate();
  let location = useLocation();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const standInfoTypesRef = useRef(null);
  const [unityVersion, setUnityVersion] = useState(null);
  let { configAppAPI, pavilionsAPI } = getRequest(initRequest);

  let pavilionNameKey = params.pavilionNameKey;

  if (pavilionNameKey) {
    pavilionsAPI.getPavilions().then((data) => {
      let pavilions = data;

      console.log("pavilions = ", pavilions);

      for (let i in pavilions) {
        // check if name if right
        let item = pavilions[i];
        if (pavilionNameKey === item["nameKey"]) {
          let language = localStorage.getItem("language");
          let id = item["id"];
          let key = item["nameKey"];
          let name = item[language ? language : navigator.language];

          console.log("item = ", item);

          // save to local storage
          localStorage.setItem("nameKey", key);
          localStorage.setItem("pavilionId", id);

          localStorage.setItem("selectedPavilion", name);

          let companyNameKey = params.companyNameKey;

          if (companyNameKey) {
            localStorage.setItem("companyNameKey", companyNameKey);
          }

          if (
            selectedPavilion.key !== key ||
            +selectedPavilion.id !== +id ||
            selectedPavilion.name !== name
          ) {
            // setSelectedPavilion({ id, key, name });
          }
        }
      }
    });
  }

  // const checkSessionTime = () => {
  //   let startSessionTime =
  //     localStorage.getItem("tokenTime") && localStorage.getItem("token")
  //       ? localStorage.getItem("tokenTime")
  //       : Date.now();
  //   let currentTime = Date.now();
  //   let sessionDuration = currentTime - startSessionTime;
  //   let endSessionTime = +startSessionTime + 23400000;
  //   let sessionClosingTime = endSessionTime - currentTime;

  //   const endSession = () => {
  //     Message.toast({ html: localText.endSession || "Session End" });
  //     // if (!isLoadedUnity) {
  //     //   makeLogout();
  //     //   // setTimeout(() => window.location.reload(), 3000)
  //     //   return;
  //     // }
  //     resetState();
  //     // makeLogout();
  //     history.push("/login");
  //     window.webSocket?.close(1000, "CloseSocket");
  //     createUnityContext().send("GameManager", "LoadLogInScene");
  //   };

  //   if (+sessionDuration >= 23400000 || startSessionTime > currentTime)
  //     endSession();

  //   setTimeout(endSession, sessionClosingTime);
  // };

  //   useEffect(checkSessionTime, []);

  useEffect(() => {
    configAppAPI.checkVersion("UNITY").then((data) => {
      // setUnityVersion(data.message);
      window.unityVersion = data.message;
      let unityVersion = localStorage.getItem("unityVersion");
      if (unityVersion !== data.message) {
        let reloaded = sessionStorage.getItem("reloaded");
        console.log("unityVersion !== response.data.message");
        console.log("reloaded = ", reloaded);
        if (!reloaded) {
          localStorage.setItem("newUnityVersion", data.message);
          sessionStorage.setItem("reloaded", "true");
          // window.location.reload(true);
        }
      }
    });

    configAppAPI.checkVersion("INTERFACE").then((data) => {
      if (localStorage.getItem("interfaceVersion")) {
        if (localStorage.getItem("interfaceVersion") !== data.message) {
          localStorage.setItem("interfaceVersion", data.message);
          // window.location.reload(true);
        }
      } else {
        localStorage.setItem("interfaceVersion", data.message);
      }
    });
  }, [
    localStorage.getItem("unityVersion"),
    localStorage.getItem("interfaceVersion"),
  ]);

  useWebSocket();

  

  // console.log(!isScene, isProgress);
  // console.log("selectedPavilion", selectedPavilion)

  return (
    <div className="wrap-app">
      <FirstStart/>
      <RequestsMeetContainer/>
      <AppContextMenu/>
      <Toaster
        containerStyle={{
          position: "fixed",
          top: "0px",
          right: "0px",
          overflow: "hidden",
        }}
      />
      <ModalsChat />
      <AppModals/>
      <div className="app-pages">
      {!includesHref("/iframe/unity") &&
        selectedPavilion.id && <UnityContainer />}
        <BackgroundVideo />
        <Routes>
          <Route path="/iframe/unity" element={<UnityIframe />} />

          {/* <Route path="/registration" element={<Registration />} /> */}
          <Route path="/registration" element={<RegistrationContainer />} />
          <Route path="/login" element={<LoginContainer isScene={isScene} />} />
          <Route path="/pavilions" element={<PavilionsContainer />} />

          <Route
            path="/"
            element={<NavMainBtnsContainer />}
          />

          <Route path="/media" element={<MediaFiles />} />

          <Route
            path="/profile/manage-stand"
            element={<ManageStandContainer />}
          />
{/* 
          <Route
            path="/standStatistics"
            element={<StandStatistics standInfoTypesRef={standInfoTypesRef} />}
          /> */}

          <Route path="/agreement" element={<Agreement />} />

          <Route path="/profile" element={<ProfileRoute />}>
            <Route path="" element={<ProfileContainer />} />
            <Route path="chat" element={<AppChatContainer />}>
              <Route path=":chatId" element={<RightBoxContainer />} />
            </Route>
            <Route path="statistics" element={<StatisticsContainer />} />
            <Route path="favorites" element={<FavoritesContainer />} />
            <Route path="documents" element={<DocumentsContainer />} />
            <Route path="hero" element={<HeroContainer />} />
            <Route path="requests" element={<RequestsContainer />} />
            <Route path="meetings" element={<MeetingsContainer />} />
            <Route path="documents" element={<DocumentsPage />} />
            <Route path="contacts" element={<ContactsContainer />} />
            <Route path="appointments" element={<AppointmentsPage />} />
            <Route path="statistics" element={<StatisticsPage user={user} />} />
            <Route path="dressing_room" element={<DressingRoom />} />
            <Route path="search" element={<SearchPage />} />
            {/* <Route path="*" element={<Navigate to="/profile" />} /> */}
          </Route>
        </Routes>

          {/* <Route path="/profile" element={<ProfileContainer />}>
            <Route path="" element={<Profile />} />
            <Route path="chat" element={<AppChatContainer />}>
              <Route path=":chatId" element={<RightBoxContainer />} />
            </Route>
            <Route path="documents" element={<DocumentsPage />} />
            <Route path="contacts" element={<ContactPage />} />
            <Route path="appointments" element={<AppointmentsPage />} />
            <Route path="statistics" element={<StatisticsPage user={user} />} />
            <Route path="dressing_room" element={<DressingRoom />} />
            <Route path="search" element={<SearchPage />} />
            <Route path="*" element={<Navigate to="/profile" />} />
          </Route>
        </Routes> */}
      </div>

    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    isAuth: state.auth.isAuth,
    isAutoLogin: state.auth.isAutoLogin,
  };
};

export default connect(mapStateToProps, {
  setSelectedPavilion,
  makeLogout,
  resetState,
  dispatch: (dispatch) => dispatch,
})(App);
