
export const appointmentPage_ru = {
    'header': {
        'appointments': 'Встречи',
        'title': 'Список назначенных встреч',
        'search': 'Поиск',
        'data': {
            'from': 'От',
            'to': 'До',
        }
    },
    'table': {
        'header': {
            'theme' : 'Тема',
            'duration' : 'Длительность',
            'status' : 'Онлайн',
            'time' : 'Дата / Время',
            'participants': 'Участники',
        },
        'body': {
            'durationHours': 'ч.',
            'durationMin': 'мин.',
        },
        'leaveModal':{
            'header': 'Вы уверены, что хотите удалить эту встречу?',
            'yes': 'Да',
            'no': 'Нет',
        },
        'editModal':{
            'header': 'Редактирование встречи',
            'confirmed': 'Подтвердить встречу',
            'theme': 'Тема встречи',
            'yes': 'Редактировать',
            'no': 'Отмена',
        },
        'noAppointments': 'Список встреч в данный момент пуст',
    },
    'listParticipants': {
        'header': 'Список участников',
        'confirm': {
            'yes': 'Подтвердить участние',
            'no': 'Отменить подтверждение участния',
        },
        'addContact': 'Добавить пользователя',
        'appointment': {
            'leave': 'Покинуть встречу',
            'remove': 'Удалить встречу',
        },      
    },
    'listAddContacts': {
        'headerCon': 'Контакты',
        'headerPar': 'Участники',
    },
    'videoConference': {
        'text1': 'Ваш ник ',
        'text2': ' является логином (ID) для входа в видеоконференцию. Введите, пожалуйста, пароль подтверждающий, что Вы - это Вы',
        'btn': 'Продолжить',
        'dev': 'Видеоконференцсвязь в разработке',
    },
}