import styled from "styled-components";

const WrapLogin = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  z-index: 2;
  width: 372px;
  height: 486px;
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;
  color: #363636;
  .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    background: #f5f5f5;
    border-radius: 8.66667px;
    border: none;
    cursor: pointer;
  }

  & > p,
  h2 {
    text-align: center;
  }
  & > h2 {
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    padding-top: 8px;
  }
  & > p {
    font-size: 13px;
    line-height: 16px;
    padding: 14px 0;
  }
  .login-form {
    .field-wrap {
      margin-bottom: 25px;
    }

    .form-btns {
      flex-direction: column-reverse;
      gap: 12px;
      padding: 5px 0 25px;
    }
  }
  .login-or {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 0 26px;
    & > div {
      height: 0.5px;
      background: #D4D4D4;
      border-radius: 2px;
      flex: 1;
    }
    & > span {
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      padding: 0 10px;
    }
  }

  .login__without-reg {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    height: 40px;
    background: #f5f5f5;
    border-radius: 8px;
    color: #34c57f;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    cursor: pointer;
    & > svg {
      margin-right: 10px;
    }
  }
`;

const FieldWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  flex-basis: ${({ grow }) => (grow ? `calc(100% / ${grow} - 8px)` : "100%")};

  label {
    font-weight: 700;
    font-size: 13px;
    line-height: 110%;
    color: #363636;
    padding-bottom: 5px;
  }

  .field_password {
    position: relative;
    & > input {
      width: 100%;
      padding-right: 36px;
    }
    .password__show {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      border: none;
      cursor: pointer;
      background: transparent;
    }
  }

  input {
    height: 36px;
    background: #f5f5f5;
    border-radius: 4px;
    border: none;
    outline: none;
    padding: 10px;
    font-size: 14px;
    line-height: 110%;
    color: #363636;
  }

  .field__error-mes {
    position: absolute;
    bottom: -1px;
    transform: translateY(100%);
    font-size: 10px;
    color: #ff7575;
  }

  label[required]::after {
    content: "*";
  }

  .field__help-elem {
    position: absolute;
    right: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 110%;
    color: #34c57f;
    cursor: pointer;
  }
`;

export { WrapLogin, FieldWrap };
