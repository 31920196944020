import React from "react";
import { WrapChangePassword } from "./profileModals.style";
import { svg_icons } from "../../../assets/imgs/svg_icons";
import { FormFieldProps } from "../../../utils/classTempates";
import { Formik, Form } from "formik";
import BaseFormField from "../../../components/BaseComponentsForm/BaseFormField";
import BaseFormBtns from './../../../components/BaseComponentsForm/BaseFormBtns';

const ChangePassword = ({handlerActions}) => {

    const { closeIcon } = svg_icons

    const settingsFields = {
      oldPassword: new FormFieldProps(
          "oldPassword",
          "Пароль",
          "Введите пароль",
          "password"
        ),
        newPassword: new FormFieldProps(
          "newPassword",
          "Новый пароль",
          "Введите пароль",
          "password"
        ),
        newPasswordEqual: new FormFieldProps(
          "newPasswordEqual",
          "Повторите новый пароль",
          "Введите пароль",
          "password"
        ),
      };
    
      const settingsBtns= {
        cancelName: "Отменить",
        submitName: "Сохранить изменения",
      };

  return (
    <WrapChangePassword>
          <Formik
            initialValues={{oldPassword: "", newPassword: "", newPasswordEqual: ""}}
            // validate={validate}
            onSubmit={handlerActions("sendChangedPassword")}
          >
            {({ dirty, isValid}) => {
              return (
                <Form>
                  <div className="form-fields">
                    <BaseFormField
                      settings={settingsFields.oldPassword}
                      helpElem={<span className="field__help-elem">{"Забыли пароль?"}</span>}
                    />
                    <BaseFormField
                      settings={settingsFields.newPassword}
                    />
                    <BaseFormField
                      settings={settingsFields.newPasswordEqual}
                    />
                  </div>
                  <BaseFormBtns 
                    settings={settingsBtns} 
                    cancel={handlerActions("closeModal")} 
                    disabled={!dirty || !isValid}
                  />
                </Form>
              );
            }}
          </Formik>
    </WrapChangePassword>
  );
};

export default ChangePassword;
