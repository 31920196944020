import axios from "axios";
import { handlerLocalStorage } from "./handlerLocalStorage.service";
import { serverUrl } from "../api/generateDomenUrlsApp";


const downloadFile = async ({type, data}) => {
    const { get } = handlerLocalStorage();

    const createLink = (href, fileName) => {
        const link = document.createElement('a');
        link.href = href;
        link.target = "_blanket";
        link.setAttribute('download', fileName); 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    
    const downloadByAxios = async (url, fileName, preview) => {
        let fileUrl;
        await axios({
            url,
            method: 'GET',
            headers: {Authorization: `Bearer ${get("token")}`},
            responseType: 'blob', // important
          }).then((response) => {
            let url = window.URL.createObjectURL(new Blob([response.data]));
            if(preview) {
                fileUrl = url;
            } else {
                createLink(url, fileName);
            }
          });
        return fileUrl;
    }
 
    switch (type) {
        case 'axios':
            await downloadByAxios(`${serverUrl}/reports/cheques/${data.type}/${data.id}`, `${data.name}.pdf`);
            break;
        case 'excel':
            await downloadByAxios(
                `${serverUrl}/api/statistics/v2/company/report?timeFrom=${data.dateFrom}&timeTo=${data.dateTo}`, 
                `${data.name}`);
            break;
        case 'previewDialogFile':
            return await downloadByAxios(`${serverUrl}/api/v2/dialogs/file/download?fileId=${data.fileId}&dialogId=${data.dialogId}`, `${data.name}`, true);
        case 'base':
            console.log(data)
            createLink(data.url, data.fileName);
        break;
        default:
            break;
    }

}

export default downloadFile;