
export const mediaPage_en = {
    'company': 'Company',
    'editing': 'Edit',
    'loading': 'Loading',
    'await': 'Drawing a loaded model...',
    'defaultPosition': 'Default position',
    'files': 'Files',
    'update': 'Update',
    'communication': {
        'communication': 'Agenda',
        'chatWithRepresentative': 'Chat with a representative',
        'contactRequest': 'Appointment request',
        'addDate': {
            'chooseDate': 'Date, time and duration of the meeting:',
            'enterTheme': 'The subject of the meeting:',
            'submit': 'Submit',
            'successSubmit': 'The application has been sent for consideration to the representative of the enterprise',
            'availableUsers': 'A list of users:',
            'select': 'Select',
            'minutes': 'minutes',
            'hour': 'hour',
            'hours': 'hours',
            'isVideoConference': 'Online by video',
            'meetingScheduled': 'The meeting is scheduled',
            'alreadyAppointmentAtThisTime': 'You already assigned appointment at this time',
            'userIsNotAvailable': 'User is not available',
            'toMeetingList': 'To the list of meetings',
        }
    },
    'changeModal': {
        'imageVideo': 'Image/Video',
        '_3d': '3D model',
        'video': 'video',
        'file': 'file',
        'company': 'company information',
        'whatDownloadText': 'What do you want to download?',
        'send': 'Send',
        'threeDModel': {
            'chooseImage': 'Select the image to be displayed on the banner:',
            'chooseAssetBundles': 'Select the asset bundle 3D Model File:',
            'uploadPhoto': 'Upload photo',
            'uploadAssetBundles': 'Upload asset bundle',
            'uploaded': 'Uploaded',
            'from': 'from',
            'allLoaded': 'All photos uploaded',
            'selected': 'Selected:',
            'images': 'images',
            'lincToAssetBundles': 'A link on how to create an asset bundle: ',
            'contactUs': '. Also, to convert your model to the required format, you can contact us:',
            'allowedSize': 'The allowed image size is 10MB. Your size is ',
            'allowedFileSize': 'The allowed file size is 50MB. Your size is ',
            'mb': 'MB'
        }
    }
}