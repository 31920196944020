// dev.clevr.nevozmozhnogo.net.by -> dev.clevr.by
// dev.rest.expo.nevozmozhnogo.net.by -> dev.rest.clevr.by
// dev.expo.nevozmozhnogo.net.by -> dev.expo.clevr.by
// dev.websocket.expo.nevozmozhnogo.net.by -> dev.websocket.clevr.by
// dev.chat.nevozmozhnogo.net.by -> dev.chat.clevr.by

// test.clevr.nevozmozhnogo.net.by -> test.clevr.by
// test.rest.expo.nevozmozhnogo.net.by -> test.rest.clevr.by
// test.expo.nevozmozhnogo.net.by -> test.expo.clevr.by
// test.websocket.expo.nevozmozhnogo.net.by -> test.websocket.clevr.by
// test.chat.nevozmozhnogo.net.by -> test.chat.clevr.by

let serverUrl,
filesUrl,
socketChatUrl,
socketUnityUrl,
siteUrl,
_3dViewUrl;



const generateDomenUrlsApp = () => {
    let hostname = window.location.hostname;

    let domens = {
        "dev.expo.clevr.by": {
            serverUrl: "https://dev.rest.clevr.by",
            filesUrl: "https://dev.files.clevr.by",
            socketChatUrl: "wss://dev.chat.clevr.by",
            socketUnityUrl: "wss://dev.websocket.clevr.by",
            siteUrl: "https://dev.clevr.by",
            _3dViewUrl: "https://dev.expo.clevr.by"
        },
        "test.expo.clevr.by": {
            serverUrl: "https://test.rest.clevr.by",
            filesUrl: "https://test.files.clevr.by",
            socketChatUrl: "wss://test.chat.clevr.by",
            socketUnityUrl: "wss://test.websocket.clevr.by",
            siteUrl: "https://test.clevr.by",
            _3dViewUrl: "https://test.expo.clevr.by"
        },
        "online.clevr-expo.com": {
            serverUrl: "https://rest.online.clevr-expo.com",
            filesUrl: "https://files.clevr-expo.com",
            socketChatUrl: "wss://chat.clevr.by",
            socketUnityUrl: "wss://websocket.online.clevr-expo.com",
            siteUrl: "https://clevr.by",
            _3dViewUrl: "https://online.clevr-expo.com"
        },
    }

    switch (hostname) {
        case "dev.expo.clevr.by":
        case "test.expo.clevr.by":
        case "online.clevr-expo.com":
            serverUrl = domens[hostname].serverUrl;
            filesUrl = domens[hostname].filesUrl;
            socketChatUrl = domens[hostname].socketChatUrl;
            socketUnityUrl = domens[hostname].socketUnityUrl;
            siteUrl = domens[hostname].siteUrl;
            _3dViewUrl = domens[hostname]._3dViewUrl;
            break;
        default:
            serverUrl = domens["dev.expo.clevr.by"].serverUrl;
            filesUrl = domens["dev.expo.clevr.by"].filesUrl;
            socketChatUrl = domens["dev.expo.clevr.by"].socketChatUrl;
            socketUnityUrl = domens["dev.expo.clevr.by"].socketUnityUrl;
            siteUrl = domens["dev.expo.clevr.by"].siteUrl;
            _3dViewUrl = domens["dev.expo.clevr.by"]._3dViewUrl;
            break;
    }

    console.log(serverUrl)
} 

export {
    serverUrl,
    filesUrl,
    socketChatUrl,
    socketUnityUrl,
    siteUrl,
    _3dViewUrl,
    generateDomenUrlsApp
}
