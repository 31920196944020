import styled from "styled-components";


const WrapModalBackground = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10;

    .background {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #00000080;
    }

    .close-modal-btn {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 26px;
        height: 26px;
        background: #F5F5F5;
        border-radius: 8.66667px;
        border: none;
        cursor: pointer;
    }
`;

export {
    WrapModalBackground
}