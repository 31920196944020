import React from "react";
import { WrapRegistration } from "./registration.style";
import { icons_svg } from "./../StandContent/icons-svg";
import { Formik, Form } from "formik";
import BaseFormField from "../BaseComponentsForm/BaseFormField";
import BaseFormBtns from "../BaseComponentsForm/BaseFormBtns";

function Registration2({
  handlerActions,
  initValues,
  validate,
  settingsFields,
  settingsBtns
}) {
  const { close, userIcon, securityIcon, phoneNumberIcon } = icons_svg;

  return (
      <WrapRegistration>
        <button className="close-btn" onClick={handlerActions("close")}>
          {close}
        </button>
        <h2>{"Регистрация"}</h2>
        <p>
          Заполните все обязательные поля <br />
          чтобы создать свой аккаунт пользователя CleVR
        </p>
        <div className="registration-form">
          <Formik
            initialValues={initValues}
            validate={validate}
            // validationSchema={validateSchema}
            onSubmit={handlerActions("registration")}
          >
            {({ dirty, isValid }) => {
              return (
                <Form>
                  <div className="form-fields">
                    <div className="field-head">
                      <h3>
                        <span>{userIcon}</span>
                        {"Пользователь"}
                      </h3>
                    </div>
                    <div className="fields">
                      <BaseFormField settings={settingsFields.lastName} />
                      <BaseFormField
                        settings={settingsFields.firstName}
                        grow={2}
                      />
                      <BaseFormField
                        settings={settingsFields.patronymic}
                        grow={2}
                      />
                    </div>
                    <div className="field-head">
                      <h3>
                        <span>{securityIcon}</span>
                        {"Безопасность и вход"}
                      </h3>
                    </div>
                    <div className="fields">
                      <BaseFormField settings={settingsFields.email} />
                      <BaseFormField
                        settings={settingsFields.password}
                        grow={2}
                      />
                      <BaseFormField
                        settings={settingsFields.equalPass}
                        grow={2}
                      />
                    </div>
                    <div className="field-head">
                      <h3>
                        <span>{phoneNumberIcon}</span>
                        {"Дополнительные контакты"}
                      </h3>
                    </div>
                    <BaseFormField settings={settingsFields.phone} />
                    <BaseFormField settings={settingsFields.privacyPolicy} />
                  </div>
                  <BaseFormBtns 
                    settings={settingsBtns} 
                    cancel={handlerActions("cancel")} 
                    disabled={!dirty || !isValid}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </WrapRegistration>
  );
}

export default Registration2;
