
export const appointmentPage_en = {
    'header': {
        'appointments': 'Appointments',
        'title': 'List of appointments',
        'search': 'Search',
        'data': {
            'from': 'from',
            'to': 'to',
        }
    },
    'table': {
        'header': {
            'theme' : 'Theme',
            'duration' : 'Duration',
            'status' : 'Online',
            'time' : 'Date / Time',
            'participants': 'Participants',
        },
        'body': {
            'durationHours': 'h.',
            'durationMin': 'min.',
        },
        'leaveModal':{
            'header': 'Are you sure you want to delete this meeting?',
            'yes': 'Yes',
            'no': 'No',
        },
        'editModal':{
            'header': 'Editing a meeting',
            'confirmed': 'Confirm a meeting',
            'theme': 'Meeting theme',
            'yes': 'Edit',
            'no': 'Cancel',
        },
        'noAppointments': 'The list of meetings is currently empty',
    },
    'listParticipants': {
        'header': 'List of participants',
        'confirm': {
            'yes': 'Confirm participation',
            'no': 'Cancel confirmation of participation',
        },
        'addContact': 'Add a user',
        'appointment': {
            'leave': 'Leave the appointment',
            'remove': 'Delete an appointment',
        },     
    },
    'listAddContacts': {
        'headerCon': 'Contacts',
        'headerPar': 'Participants',
    },
    'videoConference': {
        'text1': 'Your nickname ',
        'text2': ' is the login (ID) to log in to the video conference. Please enter the password confirming that you are you',
        'btn': 'Continue',
        'dev': 'Video conferencing in development',
    },
}