import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Contacts from "./Documents";
import {
  deleteFileById,
  getDocumentsUser,
} from "../../store/slices/profileSlice/profileExtraReducers";
import { setProfileTableState } from "../../store/slices/profileSlice/profileSlice";
import { setAppState } from "../../store/slices/appSlice/appSlice";
import downloadFile from "../../services/downloadFile.service";
import { serverUrl } from "../../api/generateDomenUrlsApp";
import { svg_icons } from "../../assets/imgs/svg_icons";

const DocumentsContainer = () => {
  const selectedTab = useSelector((state) => state.app.selectedTab);
  const token = useSelector((state) => state.auth.token);
  let { table } = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const {  trashIcon, downloadIcon } = svg_icons;

  const handlerActions = (type, data) => async (e) => {
    switch (type) {
      case "changeUserData":
        break;
      case "toggleFilterBtn":
        dispatch(
          setProfileTableState({ filter: { ...table.filter, btn: data } })
        );
        break;
      case "contextMenu":
        dispatch(
          setAppState({
            contextMenu: {
              currentTarget: e.currentTarget,
              listMenu: [
                {
                  onClick: () =>
                    downloadFile({type: "base", data: {
                      url: `${serverUrl}/files/download?fileId=${data.fileId}&token=${token}`,
                      fileName: data.name,
                    }}),
                  icon: downloadIcon,
                  text: "Скачать файл",
                },
                {
                  onClick: () => dispatch(deleteFileById(data.fileId)),
                  icon: trashIcon,
                  text: "Удалить",
                  class: " error",
                },
              ],
            },
          })
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(getDocumentsUser(table.filter));
  }, [dispatch, table.filter]);

  return <Contacts handlerActions={handlerActions} table={table} />;
};

export default DocumentsContainer;
