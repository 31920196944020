
export const messages_en = {
    'development': 'This page is still under development.',
    'confirm': 'Please go to the mail to confirm the changes to the data in your profile.',
    'notFound': 'To change your password, complete the change to your email address by checking your mail.',
    'wrongPasswordOld': 'Invalid old password.',
    'wrongPassword': 'Invalid password.',
    'differentPasswords': 'Password mismatch.',
    'logout': 'Logout.',
    'changeLanguageError': 'Error in translating the page into another language.',
    'changeCameraError': 'Free camera value not changed.',
    'fileSize': 'The file must be up to 100 MB in size.',
    'notFoundVideoCon': "The meeting hasn't started yet. The meeting is scheduled to start on",
    'logInAgain': "Log in again",
    thisPavilion: "You are on this pavilion",
    userNotFound: "Account not found",
}