import React from 'react';

export const appointmentSvg = {
    logo: 
    <svg width="120" height="100" viewBox="0 0 121 113" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="18" y="25" width="84" height="51" rx="5" fill="white" stroke="#5DB075" strokeWidth="2"/>
    <path d="M72 65V76H48V65C48 58.3726 53.3726 53 60 53C66.6274 53 72 58.3726 72 65Z" fill="white" stroke="#5DB075" strokeWidth="2"/>
    <path d="M67 42C67 46.4729 63.5891 50 59.5 50C55.4109 50 52 46.4729 52 42C52 37.5271 55.4109 34 59.5 34C63.5891 34 67 37.5271 67 42Z" fill="white" stroke="#5DB075" strokeWidth="2"/>
    <rect x="28" y="64" width="23" height="32" rx="11.5" fill="white" stroke="#5DB075" strokeWidth="2"/>
    <rect x="70" y="64" width="24" height="32" rx="12" fill="white" stroke="#5DB075" strokeWidth="2"/>
    <circle cx="38.5" cy="52.5" r="7.5" fill="white" stroke="#5DB075" strokeWidth="2"/>
    <circle cx="81.5" cy="52.5" r="7.5" fill="white" stroke="#5DB075" strokeWidth="2"/>
    </svg>,
    arrows: {
        online: <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 7.5L0.500001 0.138784L0.5 14.8612L14 7.5Z" fill="#5DB075"/>
        </svg>,
        offline: <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 7.5L0.500001 0.138784L0.5 14.8612L14 7.5Z" fill="#E9F1EE"/>
        </svg>,
        back: <svg width="16" height="10" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659727 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM14 3.5L1 3.5L1 4.5L14 4.5L14 3.5Z" fill="currentColor"/>
        </svg>,
    },
    ellipsis: 
    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis-v" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="white" d="M96 184c39.8 0 72 32.2 72 72s-32.2 72-72 72-72-32.2-72-72 32.2-72 72-72zM24 80c0 39.8 32.2 72 72 72s72-32.2 72-72S135.8 8 96 8 24 40.2 24 80zm0 352c0 39.8 32.2 72 72 72s72-32.2 72-72-32.2-72-72-72-72 32.2-72 72z"></path></svg>,
    edit: <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 3L16 7" stroke="#5DB075"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.5858 1.41421C14.3668 0.633166 15.6332 0.633165 16.4142 1.41421L17.5858 2.58579C18.3668 3.36684 18.3668 4.63316 17.5858 5.41421L6.58579 16.4142C6.21071 16.7893 5.70201 17 5.17157 17L2 17L2 13.8284C2 13.298 2.21071 12.7893 2.58579 12.4142L13.5858 1.41421Z" stroke="#5DB075"/>
    </svg>,
    ok: <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 9.5L9 19L19 1" stroke="#5DB075" strokeWidth="2"/>
    </svg>,
    cancel: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L19 19" stroke="#FF0000" strokeWidth="2"/>
    <path d="M19 1L1 19" stroke="#FF0000" strokeWidth="2"/>
    </svg>,
}
