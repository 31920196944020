import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAppChatState, setOpenDialogState } from "../../appChatSlice/appChatSlice";
import Dialogs from "./Dialogs";
import { openDialogById } from "../../../../store/slices/appChatSlice/appChatExtraReducers";




const DialogsContainer = ({mobileVersion}) => {

    const {dialogs, openDialog} = useSelector(state => state.appChat);
    const dispatch = useDispatch();
    const [localState, setLocalState] = useState({indClickDialog: null});
    const navigate = useNavigate();


    const handlerActions = (key, data) => (e) => {
        switch (key) {
            case "contextMenu":
                e.stopPropagation();
                localState.indClickDialog !== data && setLocalState(state => ({...state, indClickDialog: data}));
                break;
            case "openDialog":
                if(openDialog.dialogId === data.dialogId) return ''; 
                dispatch(setOpenDialogState({...data, messages: null}))
                dispatch(openDialogById({id: data.dialogId, navigate: mobileVersion ? false : navigate}));
                dispatch(setAppChatState({key: "dialogs", value: dialogs?.map(dialog => 
                    dialog.dialogId === data.dialogId ? {...dialog, unread: 0} : dialog)})
                );                        
                break;
            default:
                break;
        }

    }


    return <Dialogs 
        handlerActions={handlerActions} 
        dialogs={dialogs} 
        localState={localState}/>
}

export default DialogsContainer;