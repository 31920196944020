import {createAsyncThunk} from "@reduxjs/toolkit";
import { getRequest } from "../../../api/appService";
import { setAppState } from "../appSlice/appSlice";


export const authUser = createAsyncThunk(
  'auth/authUser',
  async(props, thunkAPI) => {
    let { authAPI } =  getRequest();
    let withoutRegistration = thunkAPI.getState().auth.withoutRegistration;
    let isScene = thunkAPI.getState().unity.isScene;
    let authData = await authAPI.getAuthData(props.loginData);

    localStorage.setItem('userId', authData.id);
    localStorage.setItem('selectedPlayer', authData.player);
    localStorage.setItem('token', authData.token);
    localStorage.setItem('tokenTime', Date.now());
    localStorage.setItem('role', authData.role);
    localStorage.setItem('nickName', authData.nickName);
    props.navigate(withoutRegistration && isScene ? "/" : "/pavilions");
    return authData;

  });

export const registration = createAsyncThunk(
  'auth/registration',
  async(data, thunkAPI) => {
    let { authAPI } =  getRequest();
    try {
      let res = await authAPI.registration(data);
      thunkAPI.dispatch(setAppState({modal: {type: "registrSuccess", head: "Успешно!", subhead: "Регистрация прошла успешно, на указанную почту выслано письмо."} }))
      return res;
    } catch (error) {
      return thunkAPI.rejectWithValue({message: error.data?.message, status: error.response?.status})
    }
  });


const extraReducers = {
  [authUser.fulfilled]: (state, action) => ({...state, ...action.payload, withoutRegistration: false}),

  [registration.rejected]: (state, action) => {
    if (action.payload.message === 'User with such email exists') {
      state.error = "userEmailAlreadyExist";
    } else if (action.payload.message === 'User with such nickname exists') {
      state.error = "userNicknameAlreadyExist";

    } else if (action.payload.status === 401) {
        state.error = "incorrectData";
    } else {
      state.error = "unknownErrorReg";
    }
  },
  
  [registration.fulfilled]: (state, action) => {
    if (action.payload?.message === 'User is not valid') {
      state.error = "incorrectData";
    } else {
      state.registration.success = true;
    }
  },

};

export default extraReducers;