
export const errors_ru = {
    'validation': {
        'name': 'Имя может состоять только из букв и должно содержать от 2 до 20 символов.',
        'surname': 'Фамилия может состоять только из букв и должна содержать от 2 до 32 символов.',
        'patronymic': 'Отчество может состоять только из букв и должно содержать от 2 до 20 символов.',
        'nickname': 'Ник может состоять только из букв и должен содержать от 3 до 32 символов.',
        'email': 'Не соответствует формату электронной почты.',
        'password': 'Пароль должен состоять только из латинских букв и цифр и быть не короче 8 символов.',
        'passwordEqual': 'Пароли должны быть одинаковыми.',
        'company': 'enter error',
        'position': 'enter error',
        'userAgreement': 'Пользовательское соглашение',
    },
    'captchaError': 'ReCapthca Error. Обновите страницу.',
    'loginFail': 'enter error',
    'incorrectData': 'Неверно введен логин или пароль',
    'userEmailAlreadyExist': 'Пользователь с таким адресом электронной почты существует',
    'userNicknameAlreadyExist': 'Пользователь с таким ником существует',
    'userAgreementNotChecked': 'Примите пользовательское соглашение',
    'emptyFields': 'Все поля должны быть заполнены',
    'unknownError': 'Неизвестная ошибка',
    'unknownErrorReg': 'Неизвестная ошибка. Попробуйте обновить страницу и повторить регистрацию.',
    'alreadyLoggedIn': 'Вход в данный аккаунт уже выполнен',
    'internetError': 'Проблемы с интернетом.',
    'userBlock': 'Невозможно создать чат, пользователь заблокирован',
    'youBlock': 'Невозможно создать чат, вы заблокированы пользователем',
    'addDate': {
        'companyNotWorking': 'Компания в это время не работает',
        'unknownError': 'Неизвестная ошибка',
        'noUserMeetingError': 'У компании нет пользователей для встречи',
        'noUsersAtThisTime': 'У компании нет свободных пользователей на выбранное вами время.',
        'noSuchCompanyError': 'Компания не найдена',
        'companyBusy': 'Компания занята в это время',
        'timeNotFound': 'Все сеансы заняты',
        'chooseDifferentTime': 'Выберите другое время',
        'error': 'Ошибка',

        'userNotAvailable': 'Пользователь недоступен',
        'alreadyAssigment': 'Вы уже назначили встречу в это время',
    },
    'addMessage': {
        'noUser': 'У компании нет назначенных пользователей для переписки',
        'noSuchCompany': 'Компания не найдена',
        'unknownError': 'Неизвестная ошибка',
    }
}