
export const statisticsPage_ru = {

    head: "Статистика",
    head2: "Общие данные",
    subhead: "Здесь вы можете ознакомиться с общей статистикой стендов и страницы вашей организации",
    timePeriod: "Временной период",
    today: "Сегодня",
    week: "Неделя",
    month: "Месяц",
    year: "Год",
    setPeriod: "Задать период",
    downloadReport: "Скачать отчёт",
    visitors3D: "Посетители 3D", 
    visitors: "посетителей",
    visitorsSite: "Посетители сайта",
    reqMeet: "Заявки на встречу",
    reqs: "заявок",
    documents: "Документы", 
    downedDocs: "скачаных документа", 
    listUsers: "Список пользователей",
    listReqs: "Список заявок",
    listDocs: "Список документов",



    'header': {
        'statistics': 'Статистика',
        'pageTitle': 'Лаборатория статистики cleVR',
        'pageTitle2': 'Компания:',
        'registered': 'Зарегистрирован: ',
        'lastUpdate': 'Последнее обновление: ',
    },
    'standsList': {
        'header': 'Список Ваших стендов',
        'noStands': 'У Вас нет стендов',
        'allStands': 'Общая статистика всех стендов',
        'infoCompany': 'Страница с информацией о компании',
        'currentStand': 'Статистика текущего стенда компании',
        'error1': 'Неизвестный пользователь',
        'error2': 'Пользователь не принадлежит ни к какой компании',
        'error3': 'У компании пользователя нет стендов',
    },
    'filter': {
        'selectIndicator': 'Выбрать показатель: ',
        'uniqueVisitors': 'Количество уникальных посетителей',
        'numberVisits': 'Количество посещений',
        'numberFileDownloaded': 'Количество загрузок документации',
        'numberVisitsDev': 'Количество посещений (в разработке)',
        'numberClicks': 'Количество кликов',
        'numberClicksDev': 'Количество кликов (в разработке)',
        'visitors': 'Количество посетителей',
        'transitions': 'Количество переходов с внешних ссылок (в разработке)',
        'selectPeriod': 'Выбрать интересующий период: ',
        'from': 'От: ',
        'to': 'До: '
    },
    'currentStand': {
        'totalClick': 'Количество кликов:',
        'Assistant': 'Ассистент',
        'Tumba': "Стойка",
        'Banner': "Баннер",
    },
    'visitChart': {
        'header': 'Общая статистика всех Ваших стендов',
        'labelVisits': 'Количество посещений',
        'labelClicks': 'Количество кликов',
    },
    'downloadVisitors': {
        'paragraph': 'Смотрите данные о посетителях',
    },
    'development': 'В разработке',
}