import React from 'react';
import s from './CompanyItem.module.css';
import { filesUrl } from '../../../../api/generateDomenUrlsApp';


const CompanyItem = (props) => {

    const logoStyle = {
        width: '50px',
        height: '50px',
        display: 'flex',
        margin: '5px 30px 5px 0',
        background: `url(${filesUrl + props.photo})`,
        backgroundSize: '45px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    }

    return (
        <>
            <hr/>
            <a className={s.container} href={props.link} target='_blank'>
                <div style={logoStyle}/>
                {/*<img src={filesURL + props.photo} className={s.image} alt={'company logo'}/>*/}
                <p>{props.name}</p>
            </a>

        </>
    )
}

export default CompanyItem;
