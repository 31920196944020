import styled, { css } from "styled-components";

const WrapBaseTablePage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 16px;

  button {
    cursor: pointer;
    border: none;
  }

  .table-head {
    display: flex;
    flex-direction: column;
    .table-back {
      display: flex;
      align-items: center;
      gap: 10px;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 26px;
        height: 26px;
        background: #f6f6f6;
        border-radius: 10px;
      }
      h2 {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #363636;
      }
    }

    .table-filter {
      display: flex;
      padding: 18px 0;

      .filter-select-btns {
        display: flex;
        align-items: center;
        margin-right: auto;
        padding-right: 10px;
      }

      .filter-search {
        display: flex;
        align-items: center;
        max-width: 606px;
        height: 34px;
        background: #f5f5f5;
        border-radius: 10px;
        padding: 0 10px;
        flex: 1;
        input {
          padding: 0 6px;
          background: transparent;
          border: none;
          outline: none;
        }
      }
    }
  }

  .table-wrap-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    border: 0.5px solid #d4d4d4;
    border-radius: 4px;
    overflow: hidden;
    padding: 16px;
    .not-data {
      text-align: center;
      margin: auto;
    }
  }

  ${({styles}) => styles && css`${styles}`}
`;

export { WrapBaseTablePage };
