import React from "react";
import icons_svg from "../../imgs/icons_svg";
import { WrapDialogs, DialogsScroll, Dialog } from "./dialogs.style";
// import phortUserTest from "../../imgs/Ellipse.png";
import ContextMenuBtn from "../../components/ContextMenuBtn/ContextMenuBtn";
import { filesUrl } from "../../../../api/generateDomenUrlsApp";


const Dialogs = ({ handlerActions, localState, dialogs, }) => {
  const { points } = icons_svg;



  if(!dialogs) return null;

  return (
    <WrapDialogs>
      <DialogsScroll>
        {dialogs.map(
          (dialog, i) => (
            <Dialog key={dialog.dialogId} onClick={handlerActions("openDialog", dialog)}>
              <div className="wrap-dialog-img">
                {dialog.user.avatar ? <img src={`${filesUrl}${dialog.user.avatar}`} alt="user_photo" /> :
                <div className="dialog-default-img">
                  {dialog.user.firstName[0]}
                </div>
                }
                {dialog.unread ? <div className="dialog__unread">{dialog.unread}</div>: null}
              </div>

              <div className="dialog-info">
                <p className="info__name">{dialog.user.firstName} {dialog.user.lastName}</p>
                {/* <p className="info__last-message">...</p> */}
              </div>
              <button
                className="dialog__more-info"
                onClick={handlerActions("contextMenu", i)}
              >
                {points}
                {i === localState.indClickDialog && (
                  <ContextMenuBtn data={{type: "dialog", dialog }} onClose={handlerActions("contextMenu", null)}/>
                )}
              </button>
            </Dialog>
          )
        )}
      </DialogsScroll>
    </WrapDialogs>
  );
};

export default Dialogs;
