import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfileData } from "../store/slices/profileSlice/profileExtraReducers";
import { useNavigate, useSearchParams } from "react-router-dom";
import { includesHref } from "../utils/helpers";
import { setAuthState } from "../store/slices/authSlice/authSlice";
import { resetUnityState } from "../store/slices/unitySlice/unitySlice";
import { getPavilionById } from "../store/slices/unitySlice/unityExtraReducers";
import { useSendUnity } from "./useSendUnity";

export const useCheckAuth = () => {
  const { id, shortName } = useSelector(
    (state) => state.unity.pavilions.selectedPavilion
  );
  const { token, withoutRegistration } = useSelector((state) => state.auth);
  let userData = useSelector((state) => state.profile.userData);
  const { isScene, isProgress } = useSelector((state) => state.unity);
  let [searchParams, setSearchParams] = useSearchParams();
  let companyNameKeyRef = useRef(searchParams.get("companyNameKey"));
  let tokenRef = useRef(searchParams.get("t"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sendUnity = useSendUnity();

  if (tokenRef.current) localStorage.setItem("token", token);

  useEffect(() => {
    const teleportToStand = (key) => {
      setTimeout(() => sendUnity("TeleportToStand", key), 10);
      companyNameKeyRef.current = null;
    }
    (companyNameKeyRef.current && isScene && !isProgress) && teleportToStand(companyNameKeyRef.current);
  }, [isScene, isProgress]);

  useEffect(() => {
    if (tokenRef.current) {
      !token && dispatch(setAuthState({ token: tokenRef.current }));
      tokenRef.current = null;
      setSearchParams();
    }
  }, [dispatch]);

  useEffect(() => {
    if (!shortName && userData?.id && !id) {
      dispatch(resetUnityState("selectedPavilion"));
    }
    if(!shortName && id && userData?.id) {
      dispatch(getPavilionById(id));
    }
  }, [dispatch, userData?.id]);

  useEffect(() => {
    if (!id && !includesHref("/login") && !includesHref("/registration") && token) {
      navigate("/pavilions");
    }
  }, [id]);

  useEffect(() => {
    !includesHref("/login") &&
      !includesHref("/registration") &&
      !includesHref("/iframe/unity") &&
      !withoutRegistration &&
      dispatch(getProfileData());
  }, [withoutRegistration, token]);
};
