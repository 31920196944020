export let reCAPTCHA_siteKey = "6Lcaaj8bAAAAAON_tM52JS9WZgoY-0aqu1HXROu-";

// TODO make several variables for headers and use it.

export const getRequest = () => {
  let initRequest = window.useHttp?.initRequest;

  let intervalId =
    !initRequest &&
    setInterval((e) => {
      console.log("INTERVAL", this);
      initRequest = window.useHttp?.initRequest;
      initRequest && clearInterval(intervalId);
    }, 100);

  const changeLanguage = (language) =>
    initRequest(`/users/changeLanguage?language=${language}`, "put");

  const sendNewUserPhoto = (fileName, fileType, formData, progressLoadFile) =>
    initRequest(
      `/files/api/upload?file=${fileName}&type=${fileType}&isGeneralAccess=true&isAvatar=true`,
      "post",
      null,
      formData,
      null,
      progressLoadFile
    );

  const changeUserPhoto = (avatarId) =>
    initRequest(`/users/change_avatar?avatarId=${avatarId}`, "post");

  const changeUserData = (user) =>
    initRequest(`/users/profile/data/change/main`, "put", null, user);

  const changePassword = (email, oldPassword, newPassword) =>
    initRequest(
      `/changePassword/${email}?oldPassword=${oldPassword}&newPassword=${newPassword}`,
      "post"
    );

  const makeAppointment = (
    dateTime,
    userId,
    duration,
    theme,
    language,
    isVideoConference
  ) =>
    initRequest(
      `/api/v2/make-appointment?dateTime=${dateTime}&userId=${userId}&duration=${duration}&theme=${theme}&language=${language}&isVideoConference=${isVideoConference}`,
      "post"
    );

  const sendFileInDocument = (
    fileName,
    fileType,
    formData,
    progressLoadFile,
    folderId = ""
  ) =>
    initRequest(
      `/files/api/upload?file=${fileName}&type=${fileType}&folderId=${folderId}&isGeneralAccess=false`,
      "post",
      null,
      formData,
      null,
      progressLoadFile
    );

  const configAppAPI = {
    checkVersion(interfaceName) {
      return initRequest(
        `/version?interfaceName=${interfaceName}`,
        "get",
        "NoHeader"
      );
    },
  };

  const unityAPI = {
    getNetworkUser({ id, language }) {
      return initRequest(
        `/users/otherUserInfo?otherUserId=${id}&language=${language || "ru"}`,
        "get"
      );
    },
    getStandElemInfo(params = {}) {
      return initRequest(
        `/standInfo?companyName=${params.companyKey}&objectId=${params.objectId}`,
        "get",
        "application/json"
      );
    },
    getCompany(params = {}) {
      return initRequest(
        `/api/company/${params.companyKey}?noStatistics=${params.noStatistics}`,
        "get",
        params.noHead ? "NoHeader" : ""
      );
    },
    getCompanyUsers(params) {
      return initRequest(
        `/api/representatives?companyId=${params.companyId}`,
        "get",
        params.noHead ? "NoHeader" : ""
      );
    },
    createRequestMeet(body) {
      return initRequest(`/api/meetings`, "post", null, body);
    },
  };

  const authAPI = {
    //new code
    getProfileData() {
      return initRequest(`/users/profile/data`, "get");
    },

    getAuthData(body) {
      return initRequest(`/api/v2/auth/login`, "post", "NoHeader", body);
    },

    registration(body) {
      return initRequest(`/api/v2/auth/register`, "post", "NoHeader", body);
    },

    //old code

    getAuth() {
      return initRequest(`/profile/data`, "get");
    },

    checkLogin() {
      return initRequest(`/users/check-login`, "get");
    },

    getUser(body) {
      console.log(initRequest);
      return initRequest(`/auth`, "post", "NoHeader", body);
    },

    generateNick(email) {
      return initRequest(
        `/users/generate-nickname?email=${email}`,
        "get",
        "NoHeader"
      );
    },

    restorePassword(body) {
      return initRequest(`/restorePassword`, "post", "NoHeader", body);
    },

    // registration(body) {
    //     return initRequest(`/register`, 'post', 'application/json', {...body, captcha: ''});
    // },
  };

  const pavilionsAPI = {
    getPavilions() {
      return initRequest(`/api/pavilion`, "get", "application/json");
    },

    getPavilionById({ id }) {
      return initRequest(`/api/pavilion/${id}`, "get");
    },

    getStands(params) {
      return initRequest(
        `/api/company/getAllByPavilionId?pavilionId=${
          params.pavilionId || ""
        }&language=${localStorage.getItem("language") || "ru"}&searchParam=${
          params.search || ""
        }`,
        "get",
        "NoHeader"
      );
    },
  };

  const chatAPI = {
    createDialog(body) {
      return initRequest(`/api/v2/dialogs`, "post", null, body);
    },

    sendMessage(data) {
      return initRequest(
        `/api/v2/dialogs/${data.id}/messages`,
        "post",
        null,
        data.body
      );
    },

    getDialogById(id) {
      return initRequest(`/api/v2/dialogs/${id}`, "get");
    },

    getDialogs() {
      return initRequest(`/api/v2/dialogs`, "get");
    },

    deleteDialog(id) {
      return initRequest(`/api/v2/dialogs/${id}`, "delete");
    },
  };

  const profileAPI = {
    deleteMeetings(id, consult) {
      return initRequest(`${consult ? "/api/product-consultation" : "/api/meetings"}/${id}`, "delete");
    },

    changeEmail(data) {
      return initRequest(
        `/api/user-email/client-area/email/change?newEmail=${data.email}`,
        "post"
      );
    },

    forgotPassword(data) {
      return initRequest(`/users/password/restore`, "post", "noHeader", data);
    },

    changePassword(params) {
      return initRequest(
        `/users/password/change?oldPassword=${params.oldPassword}&newPassword=${params.newPassword}`,
        "post"
      );
    },

    updateFullName(data) {
      return initRequest(`/users/profile/update`, "put", null, data);
    },

    deleteFavoritesCompany(companyId) {
      return initRequest(
        `/users/favorites-company?companyId=${companyId}`,
        "delete"
      );
    },

    makeCompanyOwner(params) {
      let url = `/users/user-${
        params.post ? "to-company" : "from-company"
      }?contactId=${params.contactId}`;
      let method = params.post ? "post" : "delete";
      return initRequest(url, method);
    },

    deleteUserFromContacts(userId) {
      return initRequest(
        `/users/contacts/delete?contactId=${userId}`,
        "delete"
      );
    },

    deleteFileById(fileId) {
      return initRequest(`/files/delete?fileId=${fileId}&force=true`, "delete");
    },

    getStatisticsCompany(params) {
      return initRequest(
        `/api/statistics/v2/company?timeFrom=${params.dateFrom}&timeTo=${params.dateTo}`,
        "get"
      );
    },

    getFavoritesCompany(params = {}) {
      return initRequest(
        `/users/favorites-company?language=${params.language || "ru"}&search=${
          params.search
        }`,
        "get"
      );
    },

    toggleFavoritesCompany({id, method}) {
      return initRequest(`/users/favorites-company?companyId=${id}`, method);
    },

    getDocumentsUser(params = {}) {
      return initRequest(
        `/users/documents?search=${params.search || ""}`,
        "get"
      );
    },

    setHeroModel(name) {
      return initRequest(
        `/users/simple_personalization?player=${name}`,
        "post"
      );
    },

    getMeetingsUser(language, params) {
      return initRequest(
        `/api/meetings/approved?language=${language}&search=${params.search}&incoming=${
          params.incoming || ""
        }`,
        "get"
      );
    },

    getRequestsUser(language, params) {
      return initRequest(
        `${
          params.consult
            ? "/api/product-consultation"
            : "/api/meetings/requests"
        }?language=${language}&search=${params.search}&incoming=${
          params.incoming || ""
        }`,
        "get"
      );
    },
    getContactsUser(params) {
      return initRequest(
        `/users/contacts/pageable/list?language=${
          params.language || "ru"
        }&page=${1}&size=${100}&searchParam=${params.search || ""}`,
        "get"
      );
    },

    getAllCompanies(params) {
      return initRequest(
        `/api/company/search?language=${params.language}&searchParam=${params.search}`,
        "get"
      );
    },

    changeLanguage(language) {
      return initRequest(
        `/users/changeLanguage?language=${language}`,
        "put",
        "NoHeader"
      );
    },

    getListAppointments(params) {
      return initRequest(
        `/api/v2/appointments?page=${params.page}&size=${10}&search=${
          params.search
        }&dateFrom=${params.fromDate}&dateTo=${params.toDate}`,
        "get"
      );
    },

    getListParticipants(params) {
      return initRequest(
        `/appointment/${params.id}/user-list?page=${
          params.page
        }&size=${10}&language=${params.lang}`,
        "get"
      );
    },

    getContactsListForAppoint(params) {
      return initRequest(
        `users/contacts/appointment/list?searchParam=${
          params.contactsSearch
        }&page=${params.page}&size=${10}&appointmentId=${params.id}`,
        "get"
      );
    },

    leaveAppointment(data) {
      return initRequest(
        data.type
          ? `/appointment/${data.id}`
          : `/appointment/${data.id}/user-list`,
        "delete"
      );
    },

    confirmAppointment(data) {
      return initRequest(
        `/appointment/${data.id}/confirmation?confirmed=${!data.confirm}`,
        "put"
      );
    },

    toggleParticipant(data) {
      return initRequest(
        `/appointment/${data.id}/user-list?userId=${data.userId}`,
        data.method
      );
    },

    editThemeAppointment(data) {
      return initRequest(`/appointment/${data.id}?theme=${data.name}`, "put");
    },

    checkPasswordAppointment(data) {
      return initRequest(
        `/api/conference-user-login`,
        "post",
        null,
        { password: data.password },
        data.catchInfo
      );
    },
  };

  const mediaApi = {
    getStandInfo(companyName, id) {
      return initRequest(
        `/standInfo?companyName=${companyName}&objectId=${id}`,
        "get",
        "application/json"
      );
    },
    setStandStatistics(companyName, id, header) {
      return initRequest(
        `/stand-info/statistics?companyName=${companyName}&objectId=${id}`,
        "post",
        header
      );
    },
    updateMedia(companyName, idFromUnity, uploadId, valueForUpdate) {
      return initRequest(
        `/updateStandInfo?companyName=${companyName}&objectId=${idFromUnity}&typeId=${uploadId}&value=${valueForUpdate}`,
        "put"
      );
    },
    updateFileOrCompany(companyName, idFromUnity, uploadId) {
      return initRequest(
        `/updateStandInfo?companyName=${companyName}&objectId=${idFromUnity}&typeId=${uploadId}`,
        "put"
      );
    },
    uploadFile(filesList, valueForUpdate, formData) {
      return initRequest(
        `/files/api/upload?file=${filesList}&type=image&isGeneralAccess=true&threeD_modelName=${valueForUpdate}`,
        "post",
        null,
        { formData }
      );
    },
    addDate(seconds, companyName, meetingTheme) {
      return initRequest(
        `/makeAppointment?dateTime=${seconds}&companyShortName=${companyName}&theme=${meetingTheme}&duration=15&language=${localStorage.getItem(
          "language"
        )}`,
        "post"
      );
    },

    getCompanyUserList(seconds, companyName, duration) {
      return initRequest(
        `/get-company-user-list-available?dateTime=${seconds}&companyShortName=${companyName}&duration=${duration}`,
        "get"
      );
    },

    addMessage(companyName) {
      return initRequest(
        `/chats/getFreeSupportIdByCompanyName?companyName=${companyName}`,
        "get"
      );
    },

    uploadImage3DModel(fileName, companyName, idFromUnity, formData) {
      return initRequest(
        `/files/api/upload/3d-model?file=${fileName}&companyName=${companyName}&objectId=${idFromUnity}&isBundle=false`,
        "post",
        null,
        formData
      );
    },
    uploadAssetBundle(fileName, companyName, idFromUnity, formData) {
      return initRequest(
        `/files/api/upload/3d-model?file=${fileName}&companyName=${companyName}&objectId=${idFromUnity}&isBundle=true`,
        "post",
        null,
        formData
      );
    },
  };

  const searchInProfile = (
    search,
    language,
    isCompany,
    isContact,
    isFolder,
    isFile,
    page
  ) => {
    return initRequest(
      `/search/pageable?searchParam=${search}&language=${language}&company=${isCompany}&contact=${isContact}&folder=${isFolder}&file=${isFile}&page=${page}&size=${10}`,
      "get"
    );
  };

  const getVisitData = (state, standId) => {
    return initRequest(
      `/api/statistics/${standId}?dateFrom=${state[0]}&dateTo=${
        +state[1] + 86400000
      }`,
      "get"
    );
  };

  const getUniqueVisitors = (state) => {
    return initRequest(
      `/api/statistics/unique-visitors?dateFrom=${state[0]}&dateTo=${
        +state[1] + 86400000
      }`,
      "get"
    );
  };

  const getNumberFilesDownload = (state) => {
    return initRequest(
      `/api/statistics/downloaded?dateFrom=${state[0]}&dateTo=${
        +state[1] + 86400000
      }`,
      "get"
    );
  };

  const getDataStand = (state, standId) => {
    return initRequest(
      `/stand/${standId}?dateFrom=${state[0]}&dateTo=${+state[1] + 86400000}`,
      "get"
    );
  };

  return {
    changeLanguage,
    sendNewUserPhoto,
    changeUserPhoto,
    changeUserData,
    changePassword,
    makeAppointment,
    sendFileInDocument,
    configAppAPI,
    authAPI,
    pavilionsAPI,
    profileAPI,
    mediaApi,
    searchInProfile,
    getVisitData,
    getUniqueVisitors,
    getNumberFilesDownload,
    getDataStand,
    unityAPI,
    chatAPI,
  };
};

// API MOVE USER_PROFILE !!!!!!!!!!!!!!!!
