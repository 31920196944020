import axios from "axios";
import Message from "materialize-css/dist/js/materialize.min.js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { serverUrl } from "../api/generateDomenUrlsApp";
import { useEffect, useRef } from "react";
import { setAuthState } from "../store/slices/authSlice/authSlice";

export const useHttp = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const { messages: localText } = useSelector((state) => state.app.appText);
  const wsClientChat = useSelector((state) => state.unity.wsClient);
  const wsClientUnity = useSelector((state) => state.appChat.wsClient);

  const token = useSelector((state) => state.auth.token);

  const endSession = () => {
    navigate("/login");
    wsClientChat?.close(1000, "CloseSocket");
    wsClientUnity?.close(1000, "CloseSocket");
  };

  const initRequest = async (
    url,
    method = "GET",
    header,
    body = null,
    catchInfo = {},
    progress
  ) => {
    let response;
    let data;
    let headers =
      header === "application/json"
        ? { "Content-Type": "application/json" }
        : header === "application/json+token"
        ? {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            }
        : header === "NoHeader"
        ? undefined
        : { Authorization: `Bearer ${token}` };
    try {
      response = await axios.request({
        url: serverUrl + url,
        method,
        headers,
        data: body,
        onUploadProgress: progress,
      });

      data = response?.data;
    } catch (error) {
      let response = error?.response;
      data = response?.data;

      let responseData = { response, data };

      let message =
        responseData.data?.message ||
        responseData.data?.error ||
        "Unknown error";
      switch (message) {
        case "Unauthorized":
          // message = localText.logInAgain;
          message = "";
          !window.location.href.includes("/iframe/unity") && endSession();
          break;
        case "Forbidden":
          message = "";
          break;
        case "Company not found":
        case "Pavilion not found":
          break;
        case "File with such name already exists":
          break;
        case "Unknown error":
          break;
        case "Not Found":
          message = catchInfo.type = "notFoundVideoCon"
            ? `${localText.notFoundVideoCon} ${catchInfo.password?.date}`
            : "";
          break;
        case "wrong password":
          message = localText.wrongPassword;
          break;
        case "User not found":
          message = localText.userNotFound;
          break;
        default:
          break;
      }
      message && Message.toast({ html: message });

      throw error;
    }

    return data;
  };

  return { initRequest };
};
