
export const manageStand_by = {
    'header': {
        title: 'Канструктар стэнда',
        owner: 'Уладальнік',
        tariff: 'Тарыфны план',
        sizeStand: 'Памер стэнда ў залежнасці ад тарыфнага плана',
        currentStand: 'Цяперашні стэнд',
        defaultPosition: 'Пазіцыя па змаўчанні',
        additionalText: 'Асістэнты і фурнітура стэнда рэдагуецца клікам выдзеленых абласцей на падлозе. Змесціва банэраў-клікам па іх.',
        manual: 'Інструкцыя па рабоце з канструктарам',
        stand: 'Стэнд',
        dev: 'У распрацоўцы',
        noStand: 'Неабходна захаваць стэнд',
        noSuperStand: "У вас няма супер стэнда",
    },
    'footer': {
        title: 'Калекцыя тыпавых стэндаў',
        apply: 'Пацвердзіць змены',
        exit: 'Выхад',
    },
    'message': {
        'error': 'Памылка захавання',
        'ok': 'Змены пацверджаны',
    }
}