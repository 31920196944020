import React, {useCallback, useEffect, useState} from 'react';
import s from './SearchPage.module.css';
import CompanyItem from "./CompanyItem/CompanyItem";
import ContactItem from "./ContactItem/ContactItem";
import FolderItem from "./FolderItem/FolderItem";
import FileItem from "./FileItem/FileItem";
import PaginationContainer from "../Pagination/PaginationContainer";
import BackButton from "../BackButton/BackButton";
import { searchSvg } from './img/SearchSvg';
import MainLogoPage from '../MainLogoPage/MainLogoPage';
import { getRequest } from '../../../api/appService';
import { useSelector } from 'react-redux';
import { useHttp } from '../../../hooks/useHttp';


let pagination = {
    currentPage: 1,
    totalPage: 0,
    changeCurrentPage: '',
};


const SearchPage = () => {

    // new code
    const {search: localText, language} = useSelector(state => state.app.appText);
    // old code


    const [searchString, setSearchString] = useState('');
    const [list, setList] = useState([]);
    const [openCardUser, setOpenCardUser] = useState(false);
    let { initRequest } = useHttp();
    let { searchInProfile } =  getRequest(initRequest);
    const [filter, setFilter] = useState({
        company: true,
        contacts: true,
        folders: true,
        files: true
    });


    pagination.changeCurrentPage = numPage => {
        pagination.currentPage = numPage;
        getData(pagination.currentPage);
    };


    let companyElement = list.filter((item) => {
        return item.type === 'company'
    })
        .map((c) => <CompanyItem key={c.name} name={c.name} photo={c.photo} link={c.link}/>);

    let contactsElement = list.filter((item) => {
        return item.type === 'contact'
    })
        .map((c) => {

         return <ContactItem setOpenCardUser={setOpenCardUser} id={c.id} key={c.email} firstName={c.name} lastName={c.surname} nick={c.nick}
                                 photo={c.avatarLink} company={c.company} email={c.email}/>});

    let folderElement = list.filter((item) => {
        return item.type === 'folder'
    })
        .map((f) => <FolderItem key={f.id} name={f.name} size={f.size}
                                modifiedTime={new Date(f.modifiedTime).toLocaleDateString()}/>);

    let filesElement = list.filter((item) => {
        return item.type === 'file'
    })
        .map((f) => <FileItem id={f.fileId} key={f.fileId} name={f.name} icon={f.icon} size={f.size}
                              modifiedTime={new Date(f.modifiedTime).toLocaleDateString()}/>);

    const getData = useCallback((currentPage = 1) => {
        pagination.currentPage = currentPage === 1 ? 1 : pagination.currentPage;
        searchInProfile(searchString, language, filter.company,
            filter.contacts, filter.folders, filter.files, currentPage)
            .then(async (data) => {
                const foundData = data.searchResult;

                pagination.totalPage = data.pageAmount;

                await setList(foundData);
            })
    },[filter.company, filter.contacts, filter.files, filter.folders, searchString])


    const onChangeChecked = async (event) => {
        await setFilter({
            ...filter,
            [event.target.name]: event.target.checked
        });
    };

    useEffect(() => {
        getData();
    }, [searchString, filter, getData]);


    const filterList = [
        {name: 'company', en: 'Company', ru: 'Компании', by: 'Кампанія'},
        {name: 'contacts', en: 'Contacts', ru: 'Контакты', by: 'Кантакты'},
        {name: 'folders', en: 'Folders', ru: 'Папки', by: 'Тэчкі'},
        {name: 'files', en: 'Files', ru: 'Файлы', by: 'Файлы'},
    ]

console.log('openCardUser', openCardUser)
    return (
        <div className={s.searchContainer}>
            <BackButton path={'/profile'}/>
            <div className={s.headerContainer}>
                <MainLogoPage localText={localText.search} logo={searchSvg.logo}/>
                <div className={s.filtersContainer}>
                    <div className={s.filterList}>
                        {filterList.map((item, i) => <label key={i} className={"checkboxOther"}>
                            <input type="checkbox" name={item.name} onClick={onChangeChecked}
                                   defaultChecked/>
                            <span>{item[language]}</span>
                        </label>)}
                    </div>
                    <div className={s.searchFieldContainer}>
                        <input type='text' placeholder={localText.search} value={searchString} className={s.searchInput}
                               onChange={(e) => {
                                   setSearchString(e.target.value);
                                   pagination.currentPage = 1
                               }}/>
                    </div>
                </div>
            </div>

            <div className={s.listFound}>
                {companyElement}
                {contactsElement}
                {folderElement}
                {filesElement}
            </div>
            <PaginationContainer paginationData={pagination}/>
        </div>)
}



export default SearchPage;