
const UNITY_PROGRESS = 'UNITY_PROGRESS';
const UNITY_IS_PROGRESS = 'UNITY_IS_PROGRESS';
const UNITY_CONFIG = 'UNITY_CONFIG';
const UNITY_LOADED = 'UNITY_LOADED';
const LOAD_PROGRESS_UNITY = 'LOAD_PROGRESS_UNITY';

// const CONTINUATION_CHOICE = 'CONTINUATION_CHOICE';

const SET_COMPANY_DATA = 'SET_COMPANY_DATA';

const SHOWN_CHAT = 'SHOWN_CHAT';

const BLOCKED_UNITY = 'BLOCKED_UNITY';

const SET_SCENE_OPENED = 'SET_SCENE_OPENED';

const MAKE_LOADING_STANDS = 'MAKE_LOADING_STANDS';

const OPEN_MEDIA_FROM_MANAGE_STAND = 'OPEN_MEDIA_FROM_MANAGE_STAND';
const STAND_EXISTS = 'STAND_EXISTS';
const RESET_STATE = 'RESET_STATE';
const CHANGE_PAVILION = 'CHANGE_PAVILION';
const CLOSE_CHANGENG_PAVILION = 'CLOSE_CHANGENG_PAVILION';


let initialState = {
    unityProvider: null,
    unityBuilds: null,
    unityProgress: 0,
    isUnityProgress: false,




    isLoadedUnity: false,
    loadProgressUnity: {open: false, progress: 0},
    // isContinuationChoice: false,
    idFromUnity: null,
    companyName: null,
    mediaTypeId: null,
    shownChat: false,
    isBlockedUnity: true,
    sceneOpened: false,
    unityVersion: null,
    standsLoading: false,
    mediaFromPath: false,
    standExists: {standExists: false,superStandExists: false},
    changingPavilion: false,
    openType: false,
};

const mainReducer = (state = initialState, action) => {
    switch (action.type) {
        case UNITY_IS_PROGRESS: {
            return {
                ...state,
                isUnityProgress: action.isProgress,
            }
        }
        case UNITY_PROGRESS: {
            return {
                ...state,
                unityProgress: action.progress,
            }
        }
        case UNITY_CONFIG: {
            return {
                ...state,
                unityProvider: action.config.provider,
                unityBuilds: {
                    ...state.unityBuilds,
                    [action.config.builds.key]: action.config.builds.value,
                }
            }
        }
        case UNITY_LOADED: {
            return {
                ...state,
                isLoadedUnity: action.boolean
            }
        }

        case LOAD_PROGRESS_UNITY: {
            return {
                ...state,
                loadProgressUnity: action.progress
            }
        }

        case BLOCKED_UNITY: {
            return {
                ...state,
                isBlockedUnity: action.boolean
            }
        }

        case SET_SCENE_OPENED: {
            return {
                ...state,
                sceneOpened: action.boolean
            }
        }

        case MAKE_LOADING_STANDS: {
            return {
                ...state,
                standsLoading: action.boolean
            }
        }

        case OPEN_MEDIA_FROM_MANAGE_STAND: {
            return {
                ...state,
                mediaFromPath: action.boolean
            }
        }

        case SET_COMPANY_DATA: {
            return {
                ...state,
                idFromUnity: action.company.id,
                companyName: action.company.name,
                mediaTypeId: action.company.itemType
            }
        }

        case SHOWN_CHAT: {
            return {
                ...state,
                shownChat: action.typeChat,
            }
        }

        case STAND_EXISTS: {
            return {
                ...state,
                standExists: action.boolean,
            }
        }
        case RESET_STATE: {
            return {
                ...initialState,
                isLoadedUnity: true,
                sceneOpened: false,
                isBlockedUnity: false,
                unityProvider: state.unityProvider,
                unityBuilds: state.unityBuilds,
            }
        }
        case CHANGE_PAVILION: {
            return {
                ...state,
                isLoadedUnity: true,
                sceneOpened: false,
                changingPavilion: true,
                openType: action.openType,
                // standsLoading: true,
            }
        }
        case CLOSE_CHANGENG_PAVILION: {
            return {
                ...state,
                sceneOpened: action.sceneOpened,
                changingPavilion: false,
                openType: false,
            }
        }
        default:
            return state;
    }
};


export const setUnityConfig = (config) => ({type: UNITY_CONFIG, config});

export const setUnityProgress = (progress) => ({type: UNITY_PROGRESS, progress});




export const unityLoaded = (boolean) => ({type: UNITY_LOADED, boolean});
export const setLoadProgressUnity = (progress) => ({type: LOAD_PROGRESS_UNITY, progress});
export const blockingUnity = (boolean) => ({type: BLOCKED_UNITY, boolean});
export const mediaFromManage = (boolean) => ({type: OPEN_MEDIA_FROM_MANAGE_STAND, boolean});
// export const continuationChoice = (boolean) => ({type: CONTINUATION_CHOICE, boolean});
export const setIdFromUnity = (company) => ({type: SET_COMPANY_DATA, company});
export const shownChats = (typeChat) => ({type: SHOWN_CHAT, typeChat});
const changeSceneOpen = (boolean) => ({type: SET_SCENE_OPENED, boolean});
const standLoading = (boolean) => ({type: MAKE_LOADING_STANDS, boolean});
export const resetState = () => ({type: RESET_STATE});
export const changePavilion = (openType) => ({type: CHANGE_PAVILION, openType});
export const closeChangingPavilion = (sceneOpened) => ({type: CLOSE_CHANGENG_PAVILION, sceneOpened});

export const setUnityLoaded = (boolean) => {
    return (dispatch) => {
        dispatch(unityLoaded(boolean));
    };
};

export const setBlockedUnity = (boolean) => {
    return (dispatch) => {
        dispatch(blockingUnity(boolean));
    };
};

export const setMediaFromPath = (boolean) => {
    return (dispatch) => {
        dispatch(mediaFromManage(boolean));
    };
};

export const setSceneOpened = (boolean) => {
    return (dispatch) => {
        dispatch(changeSceneOpen(boolean));
    };
};

export const makeLoadingStand = (boolean) => {
    return (dispatch) => {
        dispatch(standLoading(boolean));
    };
};

//
// export const setContinuationChoice = (boolean) => {
//     return (dispatch) => {
//         dispatch(continuationChoice(boolean));
//     };
// };

export const setUnityId = (company) => {
    return (dispatch) => {
        dispatch(setIdFromUnity(company));
    };
};

export const setShownChat = (type) => {
    if (type === false) localStorage.removeItem('contactId');
    return (dispatch) => {
        dispatch(shownChats(type));
    };
};


export default mainReducer;