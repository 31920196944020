import React from "react";
import { WrapLogin } from "./login.style.";
import { icons_svg } from "./../StandContent/icons-svg";
import { Formik, Form } from "formik";
import BaseFormField from "../BaseComponentsForm/BaseFormField";
import BaseFormBtns from './../BaseComponentsForm/BaseFormBtns';

const Login2 = ({ handlerActions, initValues, validate, settingsFields, settingsBtns }) => {

  const { close, moveArrow, } = icons_svg;


  return (
      <WrapLogin>
        <button className="close-btn" onClick={handlerActions("closeLogin")}>
          {close}
        </button>
        <h2>Добро пожаловать</h2>
        <p>Войдите в профиль <br/> используя ваш email и пароль</p>
        <div className="login-form">
          <Formik
            initialValues={initValues}
            validate={validate}
            onSubmit={handlerActions("login")}
          >
            {({ dirty, isValid}) => {
              return (
                <Form>
                  <div className="form-fields">
                    <BaseFormField settings={settingsFields.email} />
                    <BaseFormField
                      settings={settingsFields.password}
                      grow={2}
                      helpElem={<span className="field__help-elem">{"Забыли пароль?"}</span>}
                    />
                  </div>
                  <BaseFormBtns 
                    settings={settingsBtns} 
                    cancel={handlerActions("registration")} 
                    disabled={!dirty || !isValid}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
        <div className="login-or"><div/><span>или</span><div/></div>
        <button className="login__without-reg" onClick={handlerActions("loginWithoutReg")}>
          {moveArrow} Продолжить без регистрации
        </button>
      </WrapLogin>
  );
};

export default Login2;
