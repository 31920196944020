import preloader from "./img/spinner.svg";
import React from "react";

const wrapperStyle = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '1',
    background: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '18px'
}

let Preloader = (props) => {
    return <div style={wrapperStyle}>
        <img src={preloader} alt={'loading'}/>
    </div>
};

export default Preloader;