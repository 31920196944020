import React from 'react';
import s from './ContactItem.module.css'
import { filesUrl } from '../../../../api/generateDomenUrlsApp';

const ContactItem = (props) => {
    console.log(props.id)
    return (
        <>
            <hr/>
            <div className={s.container} onClick={() => props.setOpenCardUser(props.id)}>
                <img src={filesUrl + props.photo} className={s.avatar} alt={''}/>
                <p className={s.name}>{props.firstName} {props.lastName}</p>
                <p className={s.nick}>{props.nick}</p>
                <p className={s.email}>{props.email}</p>
                <p className={s.company}>{props.company == null ? '?' : props.company}</p>
            </div>

        </>
    )
}

export default ContactItem;
