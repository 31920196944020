import React, {useEffect, useRef} from 'react';
import s from './../MediaFiles.module.css';


function Iframe({src, onLoaded, setIsLoading}) {
    let count = 0;

    console.log(src)

    useEffect(() => {
        console.log(document.getElementById('frameID'))
        document.getElementById('frameID').src += '';
        if (count === 0) {
            setIsLoading && setIsLoading(true);
            count++;
        }
    }, [count])

    return (
        <div className={s.mediaContainer}>
            <iframe id={'frameID'} src={src} onLoad={onLoaded} className={s.iframe}/>
        </div>
    );
}

export default Iframe;