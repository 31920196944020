import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Hero from "./Hero";
import { getRequestsUser, setHeroModel } from "../../store/slices/profileSlice/profileExtraReducers";
import { herosModels } from "../../assets/heros/herosModels";
import { useNavigate } from "react-router-dom";

const HeroContainer = () => {

  let { userData, table, } = useSelector((state) => state.profile);

  const dispatch = useDispatch();
  let { language } = useSelector((state) => state.app.appText);
  const [{ selectGender, selectHeroModel, openMenu }, setLocalState] = useState({
    selectGender: 1,
    selectHeroModel: null,
    openMenu: "current" 
  });
  const navigate = useNavigate();

  const settingSelects = {
    select1: {
      options: [{ id: 1, value: "Заявки на консультацию" }],
      id: "select1",
    },
    select2: {
      options: [{ id: 1, value: "Все" }],
      id: "select2",
    },
  };


//   const savePerson = () => {
//     initRequest(`/simple_personalization?player=${selectedName}`, 'post')
//     .then(() => {
//         localStorage.setItem('selectedPlayer', selectedName);
//         window.parent.postMessage(personId, '*');
//         setLoad(selectedName);
//         return load;
//     })
// };

  const handlerActions = (type, data) => async (e) => {
    switch (type) {
      case "changeGender":
        setLocalState(state => ({...state, selectGender: e.id}))
        break;
      case "selectModel":
        setLocalState(state => ({...state, selectHeroModel: data}))
        break;
      case "closeSelectModel":
        setLocalState(state => ({...state, selectHeroModel: null, openMenu: "current"}))
        break;
      case "openSelectModel":
        setLocalState(state => ({...state, openMenu: "select"}))
        break;
      case "saveModel":
        
  console.log(userData)
  console.log(selectHeroModel)
        dispatch(setHeroModel(selectHeroModel))
        break;
      case "goBack":
        navigate(-1)
        break;
      default:
        break;
    }
  };


  return (
    <Hero
      handlerActions={handlerActions}
      table={table}
      settingSelects={settingSelects}
      language={language}

      userData={userData}
      selectHeroModel={selectHeroModel}
      herosModels={herosModels}
      openMenu={openMenu}
      selectGender={selectGender}
    />
  );
};

export default HeroContainer;
