import { appointmentPage_en } from './components_text/appointmentPage.en';
import { baseText_en } from './components_text/baseText.en';
import { contactPage_en } from './components_text/contactPage.en';
import { documentPage_en } from './components_text/documentPage.en';
import { dressingRoom_en } from './components_text/dressingRoom.en';
import { errors_en } from './components_text/errors.en';
import { loadUnity_en } from './components_text/loadUnity.en';
import { login_en } from './components_text/login.en';
import { mainNavigation_en } from './components_text/mainNavigation.en';
import { mediaPage_en } from './components_text/mediaPage.en';
import { messages_en } from './components_text/messages.en';
import { profile_en } from './components_text/profile.en';
import { registration_en } from './components_text/registration.en';
import { searchPage_en } from './components_text/searchPage.en';
import { statisticsPage_en } from './components_text/statisticsPage.en';
import { manageStand_en } from './components_text/manageStand.en';
import { companyStandInfo_en } from './components_text/companyStandInfo_en';
import { contextMenu_en } from './components_text/contextMenu_en';
import { requests_en } from './components_text/requests_en';

export const en = {
    language: "en",
    appointment: appointmentPage_en,
    contact: contactPage_en,
    document: documentPage_en,
    dressingRoom: dressingRoom_en,
    errors: errors_en,
    loadUnity: loadUnity_en,
    login: login_en,
    mainNavigation: mainNavigation_en,
    manageStand: manageStand_en,
    media: mediaPage_en,
    messages: messages_en,
    profile: profile_en,
    registration: registration_en,
    search: searchPage_en,
    statistics: statisticsPage_en,
    baseText: baseText_en,
    companyStandInfo: companyStandInfo_en,
    contextMenu: contextMenu_en,
    requests: requests_en,
}