import React, { useEffect, useRef, useState } from 'react';
import ContextMenu from '../Table/ContextMenu/ContextMenu';
import s from './Directory.module.css'
import { DirectorySvg } from './img/DirectorySvg';

const Directory = ({directoryFolder, getDocumentData, localText, addFile, createFolderModal, createFolder, nameNewfolder, setNameNewfolder, toggleSelectControl, openSelect}) => {

    let contextMenu = useRef({});
    const [openContextMenu, setOpenContextMenu] = useState(false);
    let directory = directoryFolder.current;

    const goPrevFolder = () => {
        if(directory.length === 1){
            return '';
        } else {
            directoryFolder.current.pop();
            getDocumentData();
        }
    }

    const openFolder = (folderId) => {
        directory.forEach((folder, i) => {
            if(folder.id === folderId){
                directoryFolder.current.splice((i + 1))
                getDocumentData();
            }
        })
    }

    const closeContextMenu = (e) => {
        if(e && (e?.target?.parentElement?.id === 'uploadFile' || e?.target?.id === 'uploadFile')){
            return;
        }
        if(openContextMenu) setOpenContextMenu(false);
        document.removeEventListener('click', closeContextMenu)
        document.removeEventListener('contextmenu', closeContextMenu)
    }

    const showContextMenu = (e) => {
        contextMenu.current.left = `${0}px`;
        contextMenu.current.bottom = `${0}px`;
        contextMenu.current.fileId = e.currentTarget.id;
        contextMenu.current.setOpenContextMenu = setOpenContextMenu;
        contextMenu.current.toggleSelectControl = toggleSelectControl;
        contextMenu.current.openSelect = openSelect;
        contextMenu.current.addFile = addFile;
        contextMenu.current.createFolder = createFolder;
        contextMenu.current.type = 'directory';
        setOpenContextMenu(true)
    }

    useEffect(() => {
        document.addEventListener('click', closeContextMenu)
        document.addEventListener('contextmenu', closeContextMenu)
        return () => {
            document.removeEventListener('click', closeContextMenu)
            document.removeEventListener('contextmenu', closeContextMenu)
        }
    })

    return (
        <div className={s.wrapDirectory}>
            <div className={s.controlDirectory}>
                <div onClick={goPrevFolder}>{DirectorySvg.leftArrow}</div>
            </div>
        <div className={s.currentDirectory}>
            {directory.map((folder, i) => {
                if(i === (directory.length - 1)){
                    return (
                    <div className={s.currentFolder} key={i}>
                        <p className={openContextMenu ? s.currentFolderName : ''} onClick={showContextMenu}>{folder.name}<span>{DirectorySvg.downArrowShort}</span></p>
                        {openContextMenu ? <ContextMenu 
                            contextMenu={contextMenu.current} 
                            localText={localText.directory.contextMenu}
                            /> : ''}
                    </div>
                        )
                } else {
                   return (<p onClick={() => openFolder(folder.id)} key={i}>
                            <span>{folder.name}</span>
                            <span>{DirectorySvg.rightArrowShort}</span>
                        </p>)
                }
            })}
        </div>
            {createFolderModal ? <div className={s.modalCreateFolder}>
                <h2>{localText.directory.newFolderModal.header}</h2>
                <input type="text" value={nameNewfolder} onChange={(e) => setNameNewfolder(e.target.value)}/>
                <div>
                    <button id='closeModalCreateFolder' onClick={createFolder}>{localText.directory.newFolderModal.closeModal}</button>
                    <button id='createFolder' onClick={createFolder}>{localText.directory.newFolderModal.createFolder}</button>
                </div>
            </div> : ''}
        </div>
    )
}

export default Directory;