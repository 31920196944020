import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
import arrowRight from "../imgs/arrow-right.png";
import arrowLeft from "../imgs/arrow-left.png";
import { MaterialImgSliderContainer } from "../companyStandMaterials.style";
import { filesUrl } from "../../../../api/generateDomenUrlsApp";


export const ImgSlider = ({images, mediaId}) => {


  if(!images) return null;

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: images.findIndex(img => +img.id === +mediaId),
  };

  return (
    <MaterialImgSliderContainer arrR={arrowRight} arrL={arrowLeft}>
      <Slider {...settings}>
        {images.map((image, index) => {
          return (
            <div className="slider" key={index}>
              <div className="material">
                <img
                  src={filesUrl + image.src}
                  className="material__img"
                  alt="material_img"
                ></img>
              </div>
            </div>
          );
        })}
      </Slider>
    </MaterialImgSliderContainer>
  );
};
