import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useUploadBuildsForUnityContext } from "../../hooks/useUploadUnityBuilds";
import LoaderUnity from "./../LoaderUnity/LoaderUnity";
import {
  setCopmanyStandFull,
  setUnityState,
} from "../../store/slices/unitySlice/unitySlice";
import { useSendUnity } from "../../hooks/useSendUnity";
import useWebSocketUnity from "../../hooks/useWebSocketUnity";
import {
  filesUrl,
  serverUrl,
  socketUnityUrl,
} from "../../api/generateDomenUrlsApp";
import { WrapUnity } from "./unity.style";
import ManageStandContainer from "./../../pages/ManageStand/ManageStandContainer";
import { getNetworkUser } from "../../store/slices/unitySlice/unityExtraReducers";
import { setAppState } from "../../store/slices/appSlice/appSlice";

window.informationAboutStand = (standItemTypes) => {
  console.log("standItemTypes = ", standItemTypes);
};

function UnityContainer() {
  //new code
  let dispatch = useDispatch();
  const {
    isScene,
    isProgress,
    provider,
    providerId,
    wrapCss,
    pavilions: { selectedPavilion },
  } = useSelector((state) => state.unity);
  let userData = useSelector((state) => state.profile.userData);
  let token = useSelector((state) => state.auth.token);
  let location = useLocation(),
    pathName = location.pathname;

  let [device, setDevice] = useState("");

  let iframeRef = useRef();
  let generateUnityConfig = useUploadBuildsForUnityContext();
  const navigate = useNavigate();
  const sendUnity = useSendUnity();
  useWebSocketUnity();

  window.sceneOpened = function () {
    let progressNode = document.querySelector("#progressUnity");
    progressNode.style.opacity = "0";
    console.log("CALL", userData);
    // setTimeout(() => {
    dispatch(setUnityState({ isProgress: false, isScene: true }));
    let loginJSON = {
      serverRestAddress: serverUrl,
      serverRestProtocol: "https",
      serverFileAddress: filesUrl,
      serverWebSocketAddress: socketUnityUrl,
      serverWebSocketProtocol: "wss",
      pavilionId: selectedPavilion.id,
    };
    if (userData) {
      loginJSON = {
        ...loginJSON,
        id: userData.id,
        token: token,
        nickName: userData.nickname,
        role: userData.role,
        player: userData.player,
      };
    }
    console.log("loginJSON", loginJSON);
    // sendUnity("LoadLogInScene");
    // userData &&
    sendUnity(
      "Login",
      JSON.stringify(loginJSON)
    );
    // }, 1500);
  };

  const styleIframeUnity = {
    position: "absolute",
    zIndex: "10",
    width: "100%",
    height: "100%",
    border: "none",
  };

  useEffect(() => {
    selectedPavilion.id && generateUnityConfig(selectedPavilion.id);
  }, [selectedPavilion.id]);

  const UnityIframeMemo = useMemo(
    () => () => {
      window.isReloadIframeUnity = false;
      if (provider) {
        // return <iframe
        // ref={iframeRef}
        // src={`https://dev.expo.nevozmozhnogo.net.by/unity?buildId=${selectedPavilion.id}`}
        // // onLoad={() => {}}
        // onError={()=>  {}}
        // style={styleIframeUnity}
        // className="unity-iframe"
        // id="unityIframe"
        // name="unity_iframe"/>

        // return (
        //   <iframe
        //     ref={iframeRef}
        //     src={`http://localhost:${
        //       window.location.href.includes("localhost:3666") ? "3666" : "3000"
        //     }/iframe/unity?buildId=${selectedPavilion.id}`}
        //     onError={() => {}}
        //     style={styleIframeUnity}
        //     // className={`unity-iframe ${Math.random().toFixed(2)}`}
        //     name="unity_iframe"
        // id="unityIframe"
        //     // onLoad={handlerOnLoadIframe}
        //   />
        // );
        return (
          <iframe
            ref={iframeRef}
            src={`${window.location.origin}/iframe/unity?buildId=${selectedPavilion.id}`}
            onError={() => {}}
            style={styleIframeUnity}
            id="unityIframe"
            name="unity_iframe"
            // onLoad={handlerOnLoadIframe}
          />
        );
      } else {
        return <div></div>;
      }
    },
    [provider]
  );

  useEffect(() => {
    if (!device) {
      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        // config.devicePixelRatio = 1;
        setDevice("mobile");
      } else {
        setDevice("pc");
      }
    }
  }, [device]);

  window.openStandInfo = function openStandInfo(
    name,
    objectStandId,
    objectStand
  ) {
    pathName = location.pathname;
    console.log("openStandInfo", name, objectStandId, objectStand);
    dispatch(
      setCopmanyStandFull({
        key: "object",
        value: { owner: name, id: objectStandId, name: objectStand },
      })
    );
    // openMediaSound['play']().then();
    if (name && (objectStand === "Assistant" || objectStand === "Tumba")) {
      dispatch(setCopmanyStandFull({ openInfoStand: name }));
      dispatch(
        setAppState({
          selectedTab: objectStand === "Assistant" ? "desc" : "docs",
        })
      );
      return;
    }
    pathName !== "/profile/manage-stand" && navigate("/media");
  };

  window.showOtherUserCard = (id) => {
    console.log(id);
    sendUnity("AllowCaptureAllKeyboardInput", "false");
    dispatch(getNetworkUser({ id }));
  };

  window.getCompanyName = () => {
    sendUnity("SetCompanyName_ins", userData?.company.companyNameKey);
  };

  window.showProgress = function (progress) {
    console.log("window.showProgress = ", progress);
  };

  window.standConfigLoaded = () => {
    if (pathName === "/profile/manage-stand") {
      dispatch(setUnityState({ isSceneStand: true }));
    }
    console.log("window.standConfigLoaded");
  };

  window.standLoaded = () => {};

  return (
    <>
      {isProgress && <LoaderUnity />}
      {device === "pc" && (
        <WrapUnity className="wrap-canvas-unity" wrapCss={wrapCss}>
          {providerId === selectedPavilion.id && <UnityIframeMemo />}
        </WrapUnity>
      )}
    </>
  );
}

export default UnityContainer;
