import axios from "axios";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UnityContext } from "react-unity-webgl";
import { setUnityState } from "../store/slices/unitySlice/unitySlice";
import { generateUrlsBuild } from "../utils/helpers";

export const useUploadBuildsForUnityContext = (ref) => {

  let dispatch = useDispatch();
  let { builds, pavilions: {selectedPavilion}} = useSelector((state) => state.unity);
  let controllers = useRef([]);

  useEffect(() => {
    return () => {
      console.log("ABORT API")
      controllers.current.forEach(controller => controller.abort())
    }
  }, [selectedPavilion.id])

  const generateUnityConfig = async (idPavilion = "default") => {

    dispatch(setUnityState({key: "isProgress", value: true}))

    let urls = generateUrlsBuild(idPavilion, window.location.href.includes("localhost:3666"));
    let progressBuild = {
      countUpLoad: 0,
      current: 1,
      fileProgress: {},
    };

    const uploadUnityFiles = async (url, key) => {
      const controller = new AbortController();
      progressBuild.countUpLoad++;
      controllers.current.push(controller)
      // const setProgressToIframe = (progress) => {

      //   let unity_iframe = window.frames.unity_iframe;
      //   try {
      //       unity_iframe.hiddenFunctions?.onProgress && unity_iframe.hiddenFunctions?.onProgress(progress);
      //   } catch (error) {
      //       unity_iframe?.postMessage(JSON.stringify({ type: "onProgress", value: progress}), "*");
      //       console.log(error)
      //   }
      // }

      if (
        builds &&
        builds[idPavilion] &&
        builds[idPavilion][key]
      ) {
        return builds[idPavilion][key];
      }

      try {
        let blob = await axios.request({
          url,
          method: "GET",
          signal: controller.signal,
          onDownloadProgress: (progressEvent) => {
            let totalSize = progressEvent.total || progressEvent?.target?.response?.size || progressEvent.loaded;
            if (progressEvent.loaded === totalSize) {
              progressBuild.current++;
            }
            progressBuild.fileProgress[progressBuild.countUpLoad] =
              (progressEvent.loaded * 100) / totalSize;

            let totalPercent = progressBuild.fileProgress
              ? Object.values(progressBuild.fileProgress).reduce(
                  (sum, num) => sum + num,
                  0
                )
              : 0;
            progressBuild.percent = parseInt(Math.round(totalPercent / 4));
            // console.log("PROGRESS_BUILD", progressBuild.percent, totalPercent, progressBuild.fileProgress, progressEvent?.target.response)
            dispatch(setUnityState({progress: progressBuild.percent, downloadBuild: true}));

            // setProgressToIframe(progressBuild.percent)
            return progressBuild;
          },
          responseType: "blob",
        });
        let file = new File([blob.data], "build", {
          type: blob.data.type,
        });
        return URL.createObjectURL(file);
      } catch (error) {
        console.log(error);

        error.message?.includes("Network Error") && generateUnityConfig(idPavilion)     
        throw error       
      }
    };

    let config = {};

    await new Promise(async (resolve, reject) => {
      try {

        config = {
          dataUrl: await uploadUnityFiles(urls.data, "dataUrl"),
          frameworkUrl: await uploadUnityFiles(urls.framework, "frameworkUrl"),
          codeUrl: await uploadUnityFiles(urls.wasm, "codeUrl"),
          loaderUrl: await uploadUnityFiles(urls.loader, "loaderUrl"),
        };
        console.log(config)
        resolve('ok')
      } catch (error) {
        reject(error)
      }
    });

    // console.log(config)
    let unityProvider = new UnityContext(config);

    window.unityProviderForIFrame = unityProvider;

    dispatch(setUnityState({
        provider: unityProvider,
        providerId: idPavilion,
        builds: {...builds, [idPavilion]: config}
    }));
    console.log("buildsApp",builds);
    return unityProvider;
  };

  return generateUnityConfig;
};
