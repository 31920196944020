import React from 'react';
import s from './../ChangeMedia.module.css';


function ThreeDModel({localText, setAssetBundles, setThreeD_modelImage, threeD_modelImage, assetBundles }) {
    let _10mb = 10485760;
    let _50mb = 52428800;

    const onAddFile = (event) => {
        console.log(event.target.files[0]);
        setThreeD_modelImage(event.target.files[0]);
    }

    const onAddBundleFile = (event) => {
        console.log(event.target.files[0]);
        setAssetBundles(event.target.files[0]);
    }

    return (
        <div className={s.container}>

            <span>{localText.chooseImage}</span>
            <label className={s.inputContainer}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" x="0" y="0"
                     viewBox="0 0 443.294 443.294" className="">
                    <g>
                        <path xmlns="http://www.w3.org/2000/svg"
                              d="m235.5 166.235h-27.706v69.265h-69.264v27.706h69.265v69.265h27.705v-69.265h69.265v-27.706h-69.265z"
                              fill="#808080" className=""/>
                        <path xmlns="http://www.w3.org/2000/svg"
                              d="m383.824 114.882-110.824-110.824c-2.597-2.597-6.114-4.058-9.794-4.058h-180.088c-15.274 0-27.706 12.432-27.706 27.706v387.882c0 15.273 12.432 27.706 27.706 27.706h277.059c15.273 0 27.706-12.432 27.706-27.706v-290.912c-.001-3.679-1.462-7.197-4.059-9.794zm-106.765-67.587 63.529 63.529h-63.529zm83.117 368.294h-277.058v-387.882h166.235v83.118c0 15.273 12.432 27.706 27.706 27.706h83.118v277.058z"
                              fill="#808080" className=""/>
                    </g>
                </svg>
                <span className={s.uploadFileSpan}>{localText.uploadPhoto}</span>
                <input onChange={onAddFile} type="file"/>
            </label>
            {threeD_modelImage && <span>{threeD_modelImage.name}</span>}
            {threeD_modelImage?.size > _10mb && <span style={{color: 'red'}}>{localText.allowedSize}{threeD_modelImage.size / 1048576}{localText.mb}</span>}

            <span style={{marginTop: '50px'}}>{localText.chooseAssetBundles}</span>
            <label className={s.inputContainer}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" x="0" y="0"
                     viewBox="0 0 443.294 443.294" className="">
                    <g>
                        <path xmlns="http://www.w3.org/2000/svg"
                              d="m235.5 166.235h-27.706v69.265h-69.264v27.706h69.265v69.265h27.705v-69.265h69.265v-27.706h-69.265z"
                              fill="#808080" className=""/>
                        <path xmlns="http://www.w3.org/2000/svg"
                              d="m383.824 114.882-110.824-110.824c-2.597-2.597-6.114-4.058-9.794-4.058h-180.088c-15.274 0-27.706 12.432-27.706 27.706v387.882c0 15.273 12.432 27.706 27.706 27.706h277.059c15.273 0 27.706-12.432 27.706-27.706v-290.912c-.001-3.679-1.462-7.197-4.059-9.794zm-106.765-67.587 63.529 63.529h-63.529zm83.117 368.294h-277.058v-387.882h166.235v83.118c0 15.273 12.432 27.706 27.706 27.706h83.118v277.058z"
                              fill="#808080" className=""/>
                    </g>
                </svg>
                <span className={s.uploadFileSpan}>{localText.uploadAssetBundles}</span>
                <input onChange={onAddBundleFile} type="file" multiple/>
            </label>
            {assetBundles && <span>{assetBundles.name}</span>}
            {assetBundles?.size > _50mb && <span style={{color: 'red'}}>{localText.allowedFileSize}{threeD_modelImage.size / 1048576}{localText.mb}</span>}

            <span className={s.infoAboutFile}>
                {localText.lincToAssetBundles}
                <a href={'https://docs.unity3d.com/ru/2019.4/Manual/BuildingAssetBundles4x.html'} target="_blank" >https://docs.unity3d.com/ru/2019.4...4x.html</a>
                {localText.contactUs}
                <a href={'https://clevr.by/contactus'} target="_blank" >https://clevr.by/contactus</a>
            </span>

        </div>
    );
}


export default ThreeDModel;
