
import { initialState } from './appChatSlice';

const reducers = {

  setAppChatState(state, action) {
    if(action.payload.key === "mobileVersion") {
      // it will delete later 
      window.mobileVersion = action.payload.value
    }
    state[action.payload.key] = action.payload.value
  },

  setOpenDialogState(state, action) {
    state.openDialog = {...state.openDialog, ...action.payload};
  },


  setDialogsChat(state, action) {
    switch (action.payload.key) {
      case "unshift":
        state.dialogs.unshift(action.payload.value)
        break;
      case "remove":
        
        break;
    
      default:
        state.dialogs = action.payload.value
        break;
    }
  },

  setNewMessage(state, action) {
    switch (action.payload.key ) {
      case "unshiftArr":
        state.openDialog.messages = [...action.payload.value, ...state.openDialog.messages];
        break;
      default:
        state.openDialog.messages?.push(action.payload.value);
        break;
    }
    
  },

  resetAppChatState(state, action) {
    switch (action.payload) {
      case "modal":
        state.modal = initialState.modal;
        break;
      case "contextMenu":
        state.contextMenu = initialState.contextMenu;
        break;
      case "openDialog":
        state.openDialog = initialState.openDialog;
        break;
    
      default:
        return initialState;
    }
  }


};

export default reducers;