import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Requests from "./Requests";
import { deleteMeetings, getRequestsUser } from "../../store/slices/profileSlice/profileExtraReducers";
import { setAppState } from "../../store/slices/appSlice/appSlice";
import { setProfileTableState } from "../../store/slices/profileSlice/profileSlice";

const RequestsContainer = () => {
  let { table } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  let { language, requests: localText } = useSelector((state) => state.app.appText);
  const [{ errorLogo }, setLocalState] = useState({
    errorLogo: {},
  });

  const settingSelects = {
    select1: {
      options: [
        { id: 1, value: localText.requestMeet},
        { id: 2, value: localText.requestConsult},
    ],
      id: "select1",
    },
    select2: {
      options: [
        { id: 0, value: localText.all },
        { id: "true", value: localText.incoming },
        { id: "false", value: localText.outgoing },
      ],
      id: "select2",
    },
  };

  const handlerActions = (type, data) => async (e) => {
    switch (type) {
      case "changeFilter":
        let filterSelect = e.id === 2 || e.id === 1 ? 
          {incoming: table.filter.incoming, consult: e.id === 2} :
          {incoming: e.id, consult: table.filter.consult};
        dispatch(
          setProfileTableState({
            filter: { ...table.filter, ...filterSelect},
          })
        );
        break;
      case "errorLogo":
        setLocalState(state => ({...state, errorLogo: {...state.errorLogo, [data]: true}}))
        break;
        case "contextMenu":
          dispatch(setAppState({contextMenu: {
            currentTarget: e.currentTarget,
            listMenu: [
              // { onClick: () => {console.log(data)}, icon: '', text: "Добавить в контакты" },
              // { onClick: () => {console.log(data)}, icon: '', text: "Написать сообщение" },
              // { onClick: () => {console.log(data)}, icon: '', text: "Заявка на встречу" },
              // { onClick: () => {console.log(data)}, icon: '', text: "Удалить из “Избранных”" },
              // { onClick: () => {console.log(data)}, icon: '', text: "Заблокировать " },
              { onClick: () => dispatch(deleteMeetings({id: data.id, consult: table.filter.consult})), icon: '', text: "remove", class: " error" },
            ]
          }}))
          break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(getRequestsUser(table.filter));
  }, [dispatch, table.filter]);

  return (
    <Requests
      handlerActions={handlerActions}
      table={table}
      settingSelects={settingSelects}
      language={language}
      errorLogo={errorLogo}
      localText={localText}
    />
  );
};

export default RequestsContainer;
