import { appointmentPage_ru } from './components_text/appointmentPage.ru';
import { baseText_ru } from './components_text/baseText.ru';
import { contactPage_ru } from './components_text/contactPage.ru';
import { documentPage_ru } from './components_text/documentPage.ru';
import { dressingRoom_ru } from './components_text/dressingRoom.ru';
import { errors_ru } from './components_text/errors.ru';
import { loadUnity_ru } from './components_text/loadUnity.ru';
import { login_ru } from './components_text/login.ru';
import { mainNavigation_ru } from './components_text/mainNavigation.ru';
import { mediaPage_ru } from './components_text/mediaPage.ru';
import { messages_ru } from './components_text/messages.ru';
import { profile_ru } from './components_text/profile.ru';
import { registration_ru } from './components_text/registration.ru';
import { searchPage_ru } from './components_text/searchPage.ru';
import { statisticsPage_ru } from './components_text/statisticsPage.ru';
import { manageStand_ru } from './components_text/manageStand.ru';
import { companyStandInfo_ru } from './components_text/companyStandInfo_ru';
import { contextMenu_ru } from './components_text/contextMenu_ru';
import { requests_ru } from './components_text/requests_ru';

export const ru = {
    language: "ru",
    appointment: appointmentPage_ru,
    contact: contactPage_ru,
    document: documentPage_ru,
    dressingRoom: dressingRoom_ru,
    errors: errors_ru,
    loadUnity: loadUnity_ru,
    login: login_ru,
    mainNavigation: mainNavigation_ru,
    manageStand: manageStand_ru,
    media: mediaPage_ru,
    messages: messages_ru,
    profile: profile_ru,
    registration: registration_ru,
    search: searchPage_ru,
    statistics: statisticsPage_ru,
    baseText: baseText_ru,
    companyStandInfo: companyStandInfo_ru,
    contextMenu: contextMenu_ru,
    requests: requests_ru,
}