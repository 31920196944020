import { appointmentPage_by } from './components_text/appointmentPage.by';
import { baseText_by } from './components_text/baseText.by';
import { contactPage_by } from './components_text/contactPage.by';
import { documentPage_by } from './components_text/documentPage.by';
import { dressingRoom_by } from './components_text/dressingRoom.by';
import { errors_by } from './components_text/errors.by';
import { loadUnity_by } from './components_text/loadUnity.by';
import { login_by } from './components_text/login.by';
import { mainNavigation_by } from './components_text/mainNavigation.by';
import { mediaPage_by } from './components_text/mediaPage.by';
import { messages_by } from './components_text/messages.by';
import { profile_by } from './components_text/profile.by';
import { registration_by } from './components_text/registration.by';
import { searchPage_by } from './components_text/searchPage.by';
import { statisticsPage_by } from './components_text/statisticsPage.by';
import { manageStand_by } from './components_text/manageStand.by';
import { companyStandInfo_by } from './components_text/companyStandInfo_by';
import { contextMenu_by } from './components_text/contextMenu_by';
import { requests_by } from './components_text/requests_by';


export const by = {
    language: "by",
    appointment: appointmentPage_by,
    contact: contactPage_by,
    document: documentPage_by,
    dressingRoom: dressingRoom_by,
    errors: errors_by,
    loadUnity: loadUnity_by,
    login: login_by,
    mainNavigation: mainNavigation_by,
    manageStand: manageStand_by,
    media: mediaPage_by,
    messages: messages_by,
    profile: profile_by,
    registration: registration_by,
    search: searchPage_by,
    statistics: statisticsPage_by,
    baseText: baseText_by,
    companyStandInfo: companyStandInfo_by,
    contextMenu: contextMenu_by,
    requests: requests_by
}