import React, { Fragment } from "react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { WrapRequestMeet } from "./requestsMeet.style";
import { svg_icons } from "../../../../assets/imgs/svg_icons";
import ModalBackground from "../../../ModalBackground/ModalBackground";
import BaseFormField from "../../../BaseComponentsForm/BaseFormField";
import BaseFormBtns from "../../../BaseComponentsForm/BaseFormBtns";

const RequestMeet = ({
  setOpenRequestsModal,
  companyId,
  initValues,
  handlerActions,
  settingsBtns,
  settingsFields,
}) => {
  const { closeIcon } = svg_icons;
  const dispatch = useDispatch();

  return (
    <ModalBackground onClose={handlerActions("close")}>
      <WrapRequestMeet>
        <h2>Заявка на встречу</h2>
        <button className="close-btn" onClick={handlerActions("close")}>
          {closeIcon}
        </button>

        <Formik
          initialValues={initValues}
          onSubmit={handlerActions("sendRequest")}
        >
          {({ dirty, isValid }) =>  (
            <Form>
              <div className="form-fields">
                <BaseFormField settings={settingsFields.theme} />
                <div className="fields">
                  <BaseFormField settings={settingsFields.time} />
                  <BaseFormField settings={settingsFields.timeFrom} />
                  <span className="fields__separator">-</span>
                  <BaseFormField settings={settingsFields.timeTo} />
                </div>
                <BaseFormField settings={settingsFields.description} />
              </div>
              <BaseFormBtns
                settings={settingsBtns}
                cancel={handlerActions("close")}
                disabled={!dirty || !isValid}
              />
            </Form>
          )}
        </Formik>
      </WrapRequestMeet>
    </ModalBackground>
  );
};

export default RequestMeet;
