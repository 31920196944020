import styled from "styled-components";


const WrapMaterials = styled.div`
    position: fixed;
    left: 18px;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
    max-height: 90vh;


.materials-header {
    position: relative; 
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px;
}

.header-text h3 {
    font-weight: 700;
    font-size: 13px;
    line-height: 110%;
}

.header-text p {
    font-weight: 500;
    font-size: 13px;
    line-height: 110%;
}



.header-btns {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 200px;
    right: 200px;
}


.header-btns button {
    border-radius: 14.1511px;
    background: #EFEFEF;
    backdrop-filter: blur(13.2666px);
    height: 32px;
    color: #363636;
    margin: 0 4px;
    padding: 2px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    cursor: pointer;
}

.header-btns .btns__imgs_active {
    background: #34C57F;
    color: #FFFFFF;
}

.btns__video_active{
    background: #34C57F !important;
    color: #FFFFFF !important;
    svg{
        path{
            fill: #FFFFFF;
            stroke: #FFFFFF;
        }
    }
}

.btns__imgs{
    svg{
        path{
            fill: #363636;
        }
    }
}

.header-btns button svg{
    margin-right: 6px;
}

.header__close {
    display: flex;
    align-items: center;
    height: 24px;
}
.header__close {
    display: flex;
    align-items: center;
    height: 24px;
    font-weight: 600;
    font-size: 13px;
    line-height: 110%;
    color: #363636;
}

.close__icon {
    display: flex;
    cursor: pointer;
    padding-left: 9px;
}

.slider {
    display: flex;
    flex-direction: column;
    background: #aac3d9;
}

.material {
    position: relative;
    height: 70vh;
    display: inline-block;
    margin: auto;
}

.material__img {
    height: 100%;
}

.material__video{
    height: 100% !important;
    width: 1130px !important;
}

.material-desc {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 320px;
    height: 159px;
    background: #FFFFFF;
    padding: 11px 16px;
}
.Btns_materials_container{
    display: flex;
    align-items: center;
    margin-top: 13px;
    gap: 10px;
}
.material-desc_bookConsult{
    border: 1px solid #34C57F;
    border-radius: 8px;
    color: #34C57F;
    background: #FFFFFF;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    height: 30px;
    width: 100%;
    cursor: pointer;
}

.material-desc .divider{
    height: 2px;
    margin: 10px 0;
}

.material-desc__name {
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
}

.material-desc__title {
    font-weight: 700;
    font-size: 12px;
    line-height: 110%;
}

.material-desc__text {
    font-size: 13px;
    line-height: 140%;
    color: #363636;
    letter-spacing: -0.6px;
    padding-top: 4px;
}

.material-desc__more-btn {
    width: 100%;
    height: 30px;
    background: #F4F4F4;
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    padding: 5px 12px;
    justify-content: space-between;
    font-family: 'Gilroy';
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    cursor: pointer;
}



.wrap-materials {
    height: 670px;
}

`

const MaterialImgSliderContainer = styled.div`
  .slick-prev {
    left: 32px;
    z-index: 1;
    background: url(${props => props.arrL});
    background-size: 100%;
    top: 50%;
    width: 32px;
    height: 33px;
    ::before{
        content: none;
    }
    :hover{
        background: url(${props => props.arrL});
    }
    :focus {
        background: url(${props => props.arrL});
    }
    }
  .slick-next {
    right: 32px;
    top: 50%;
    background: url(${props => props.arrR});
    background-size: 100%;
    width: 32px;
    height: 33px;
    ::before{
        content: none;
    }
    :hover{
        background: url(${props => props.arrR});
    }
    :focus{
        background: url(${props => props.arrR});
    }
  }
  .slider{
    display: flex !important;
  }
`

export { WrapMaterials, MaterialImgSliderContainer }
