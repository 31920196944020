import React from "react";
import { WrapEmailForm, WrapChangePassword } from "./profileModals.style";
import { FormFieldProps } from "../../../utils/classTempates";
import { Formik, Form } from "formik";
import BaseFormField from "../../../components/BaseComponentsForm/BaseFormField";
import BaseFormBtns from '../../../components/BaseComponentsForm/BaseFormBtns';

const EmailForm = ({handlerActions, sendType}) => {

    const settingsFields = {
        email: new FormFieldProps(
          "email",
          "Email",
          "Введите email",
          "email"
        ),
      };
    
      const settingsBtns= {
        cancelName: "Отменить",
        submitName: sendType === "forgotPassword" ? "Получить ссылку" : "Подтвердить email",
      };

  return (
    <WrapEmailForm>
          <Formik
            initialValues={{email: ""}}
            // validate={validate}
            onSubmit={handlerActions(`submit_${sendType}`)}
          >
            {({ dirty, isValid}) => {
              return (
                <Form>
                  <div className="form-fields">
                    <BaseFormField
                      settings={settingsFields.email}
                    />
                  </div>
                  <BaseFormBtns 
                    settings={settingsBtns} 
                    cancel={handlerActions("closeModal")} 
                    disabled={!dirty || !isValid}
                  />
                </Form>
              );
            }}
          </Formik>
    </WrapEmailForm>
  );
};

export default EmailForm;
