import React, { useEffect, useState } from "react";
import BaseTablePage from "./../../components/BaseTablePage/BaseTablePage";
import { WrapContacts, WrapFilterBtns } from "./contacts.style";
import { filesUrl } from "../../api/generateDomenUrlsApp";
import { svg_icons } from "../../assets/imgs/svg_icons";
import avatarDefault from "../../components/MainPage/avatarDefaultCircle.svg";

const Contacts = ({ handlerActions, table }) => {
  const { pointsIcon } = svg_icons;

  let online = table.data?.filter(contact => contact.online)

  return (
    <BaseTablePage
      head={`Все контакты ${table.data?.length ? `(${table.data.length})` : ""}`}
      selectFilter={
        <WrapFilterBtns activeBtn={table.filter.btn || "all"}>
          <div className="toggle-btns">
            <button
              className={`toggle-btns__all`}
              onClick={handlerActions("toggleFilterBtn", "all")}
            >
              {`Все контакты ${table.data?.length ? `(${table.data.length})` : ""}`}
            </button>
            <button
              className={`toggle-btns__online`}
              onClick={handlerActions("toggleFilterBtn", "online")}
            >
              {`Онлайн ${online?.length ? `(${online.length})` : ""}`}
            </button>
          </div>
        </WrapFilterBtns>
      }
    >
      <WrapContacts>
        {table.data && table.data.length
          ? ((table.filter.btn || "all") === "all" ?  table.data : online).map((contact) => (
              <div key={contact.id} className="wrap-contact">
                <img
                  src={
                    contact.avatar
                      ? `${filesUrl}${contact.avatar}`
                      : avatarDefault
                  }
                  alt="avatar"
                  className="contact__avatar"
                ></img>
                <div className="contact-info">
                  <div>
                    <p className="contact__name">
                      {contact.firstName} {contact.lastName}{" "}
                      {contact.online ? <span className="contact__online"/> : ""}
                    </p>
                    <p className="contact__role">{"Пользователь"}</p>
                  </div>
                  <button className="info__more" onClick={handlerActions("contextMenu", contact)}>{pointsIcon}</button>
                </div>
              </div>
            ))
          : <p className="not-data">У вас нет контактов</p>}
      </WrapContacts>
    </BaseTablePage>
  );
};

export default Contacts;
