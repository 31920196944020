export const init = (documentData) => {

    if(documentData){
        let foldersAndFiles = documentData && documentData.foldersAndFiles;

        let checkboxesSelect = foldersAndFiles.reduce((prevValue, item, i, array) => {
           let id = item.fileId || item.folderId;
           return [...prevValue, {id, checked: false, type: item.type}];
       }, [{id: 'all', checked: false, type: 'selectAll'}])
          return checkboxesSelect;
    } else {
        return [];
    }

  }

export  const reducer = (state, action) => {
    switch (action.type) {
      case 'selectAll':
          if(action.selectAll) {
            state = state.map(item => {
              return {...item, checked: true};
            })
          } else {
            state = state.map(item => {
              return {...item, checked: false};
            })
          }
        return [...state]
      case 'cancelAll':
            state = state.map((item) => ({...item, checked: action.cancelAll}))
        return [...state]
      case 'selectCheckbox':
        state = state.map(item => {
          return +item.id === +action.id ? {...item, checked: action.value} : {...item};
        })
        return [...state];
      case 'updateCheckboxs':
        return [...action.update]
      default:
        throw new Error();
    }
  }
