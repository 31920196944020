import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { WrapAppChat } from "./appChat.style";
import AppToast from "./components/AppToast/AppToast";
import LeftBoxContainer from "./LeftBox/LeftBoxContainer";
import RightBoxContainer from "./RightBox/RightBoxContainer";

const AppChat = ({mobileVersion}) => {


  return (
    <WrapAppChat mobile={mobileVersion}>
      <LeftBoxContainer mobileVersion={mobileVersion}/>
      {mobileVersion ? <RightBoxContainer mobileVersion={mobileVersion} /> : <Outlet/>}
      <AppToast />
    </WrapAppChat>

  );
};

export default AppChat;
