import React, { useEffect, useState } from "react";
import BaseTablePage from "../../components/BaseTablePage/BaseTablePage";
import { WrapDocuments, WrapFilterBtns } from "./documents.style";
import { filesUrl } from "../../api/generateDomenUrlsApp";
import { svg_icons } from "../../assets/imgs/svg_icons";
import avatarDefault from "../../components/MainPage/avatarDefaultCircle.svg";
import { useSelector } from "react-redux";
import { calcFileSize } from "../../utils/helpers";

const Documents = ({ handlerActions, table }) => {
  const { pointsIcon, pdfIcon, defaultFile } = svg_icons;
  const localText = useSelector(state => state.app.appText.document.table.bodyTable);

  const getDocIcon = (type) => {
    switch (type) {
      case "pdf":
        return pdfIcon;

      default:
        return defaultFile;
    }
  };

  return (
    <BaseTablePage
      head={`Документы ${table.data?.length ? `(${table.data.length})` : ""}`}
      styles={`.table-head {
        flex-direction: row;
        padding-bottom: 16px; 
          .table-filter {
            flex: 1;
            justify-content: end;
            padding: 0;
         }
    }`}
    >
      <WrapDocuments>
        {table.data && table.data.length ? (
          table.data.map((document) => (
            <div key={document.fileId} className="wrap-document">
              <div className="document__icon">
                {getDocIcon(document.extension)}
              </div>
              <div className="document-info">
                <div className="info-text">
                  <p className="document__name" title={document.name}>{document.name} </p>
                  <p className="document__size">{calcFileSize(document.size, localText)}</p>
                </div>
                <button className="info__more" onClick={handlerActions("contextMenu", document)}>{pointsIcon}</button>
              </div>
            </div>
          ))
        ) : (
          <p className="not-data">У вас нет документов</p>
        )}
      </WrapDocuments>
    </BaseTablePage>
  );
};

export default Documents;
