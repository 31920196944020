
export const documentPage_ru = {
    'headerPage': {
        'myContacts': 'Мои документы',
        'search': 'Поиск',
        'pageTitle': 'Список моих документов',
        'uploadFile': 'Загрузить файл',
        'createFolder': 'Создать папку',
    },
    'noData': "Папка пуста",
    'noDataFind': "Файлы не найдены",
    'modalLoadfile': 'Загрузка файла',
    'modalMoveFolder': {
        'btnMove': 'Переместить',
        'noMoveFolderPart1': 'Невозможно переместить папку',
        'noMoveFolderPart2': 'в саму себя',
        'noSelectFile': 'Невозможно выбрать, так как это не папка',
    },
    'directory': {
        'myDisk': 'Мой диск',
        'newFolderModal': {
            'header': 'Новая папка',
            'closeModal': 'Отмена',
            'createFolder': 'Создать',
        },
        'contextMenu': {
            'createFolder': 'Создать папку',
            'uploadFile': 'Загрузить файл',
            'select': 'Выделить',
            'closeSelect': 'Закрыть выделение',
        }
    },
    'table' :{
        'headerTable': {
            'name': 'Имя',
            'lastModified': 'Последнее изменение',
            'sizeFile': 'Размер',
            'type': 'Тип',
        },
        'bodyTable': {
            'byte': 'б',
            'kilobyte': 'Кб',
            'megabyte': 'Мб',
            'gigabyte': 'Гб',
            'typeFolder': 'Папка',
        },
        'contextMenu': {
            'deleteBtn': 'Удалить',
            'moveFolder': 'Переместить',
            'downloadBtn': 'Скачать',
            'renameBtn': 'Переименовать',
            'okBtn': 'Ок',
            'cancelBtn': 'Отмена',
            'select': 'Выделить',
            'closeSelect': 'Закрыть выделение',
        },
    },
    'selectControl': {
        'quantity': 'Количество',
        'actions': 'Действия',
        'apply': 'Применить',
        'deleteContent': 'Удалить',
        'moveContent': 'Переместить',
    },
    'modal': {
        'yes': 'Да',
        'no': 'Нет',
        'piece': 'шт.',
        'confirmDeleteObjects': 'Вы действительно хотите удалить эти объекты',
        'confirmDeleteObject': 'Вы действительно хотите удалить этот объект',
        'confirmDeleteFile': 'Выбранный вами файл используется в другом месте. Вы действительно хотите его удалить?',
        'confirmDeleteFolder': 'Вы действительно хотите удалить папку',
    }
}