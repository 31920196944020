import { configureStore } from '@reduxjs/toolkit'
import appReducer from './slices/appSlice/appSlice'
import authReducer from './slices/authSlice/authSlice'
import chatReducer from './slices/chatSlice/chatSlice'
import profileReducer from './slices/profileSlice/profileSlice'
import unityReducer from './slices/unitySlice/unitySlice'
import appChatReducer from './slices/appChatSlice/appChatSlice'

export const store = configureStore({
  reducer: {
    app: appReducer,
    unity: unityReducer,
    profile: profileReducer,
    auth: authReducer,
    chat: chatReducer,
    appChat: appChatReducer,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
})