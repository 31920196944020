import styled, { css } from "styled-components";

const WrapStatistics = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 16px;
  color: #363636;
  .app-modals {
    .modals__subhead {
      padding-bottom: 0;
    }
  }
  .statistics-head {
    .head__back {
      display: flex;
      align-items: center;
      padding-bottom: 13px;
      button {
        width: 26px;
        height: 26px;
        background: #f6f6f6;
        border-radius: 10px;
        margin-right: 10px;
      }
      h2 {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
      }
    }
    .head-desc {
      padding-bottom: 18px;
      h3 {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
      }
      p {
        font-size: 13px;
        line-height: 16px;
      }
    }
    .head-filter {
      display: flex;
      justify-content: space-between;
      .app-select {
        width: 250px;
      }
      .filter__download {
        width: 182px;
        height: 34px;
        background: #34c57f;
        border: 1px solid #34c57f;
        border-radius: 8px;
        color: #ffffff;
        align-self: flex-end;
      }
    }
  }

  .statistics-list {
    display: flex;
    max-width: 100%;
    gap: 16px;
    overflow-x: auto;
    overflow-y: hidden;
    padding-top: 16px;
    flex: 1;
    color: #363636;
    margin: auto;
    .wrap-statistics {
      display: flex;
      flex-direction: column;
      width: 269px;
      min-width: 269px;
      background: #f5f5f5;
      border-radius: 4px;
      overflow: hidden;
      padding: 16px;
      text-align: center;
        .statistics__head {
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          padding-bottom: 7px;
        }

        .statistics__date {
          font-size: 12px;
          line-height: 110%;
        }

        .statistics-desc {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding-top: 11px;
          padding-bottom: 16px;
          position: relative;
          overflow: hidden;
          text-align: center;
          flex: 1;
          .desc__head {
            font-weight: 500;
            font-size: 36px;
            line-height: 110%;
          }
          .desc__text {
            font-weight: 500;
            font-size: 13px;
            line-height: 110%;
            padding-top: 200px;
          }
        }
        .statistics__go {
          border: 1px solid #34c57f;
          border-radius: 8px;
          height: 36px;
          color: #34c57f;
          font-weight: 500;
          font-size: 13px;
          line-height: 140%;
          cursor: pointer;
        }
    }
  }
`;

export { WrapStatistics };
