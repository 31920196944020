import { getRequest} from "../api/appService";

const SET_IS_LOADED = 'SET_IS_LOADED';
const USER_LOADED = 'USER_LOADED';
const AUTH_SUCCESS = 'AUTH_SUCCESS';
const AUTO_LOGIN_FALSE = 'AUTO_LOGIN_FALSE';
const USER_LOADING = 'USER_LOADING';
const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

const LOGIN_FAIL = 'LOGIN_FAIL';
const WITHOUT_REGISTRATION = 'WITHOUT_REGISTRATION';

const SET_LANGUAGE = 'SET_LANGUAGE';

// const SET_REGISTER_SUCCESS = 'SET_REGISTER_SUCCESS';
const REGISTER_FAIL = 'REGISTER_FAIL';

const SET_ERROR = 'SET_ERROR';
const RESET_ERROR = 'RESET_ERROR';
const LOGOUT = 'LOGOUT';

let emptyUser = {
    alreadyLoggedIn: false,
    clothes: null,
    clothesStyle: null,
    footwear: null,
    hair: null,
    hairstyle: null,
    id: null,
    language: 'ru',
    mask: null,
    nickName: null,
    player: null,
    role: null,
    skin: null,
    token: localStorage.getItem('token'),
    type: null
};

let initialState = {
    user: {...emptyUser},
    captcha: localStorage.getItem('captcha'),
    isAuth: false,
    isAutoLogin: false,
    // isAlreadyLoggedIn: false,
    withoutRegistration: false,
    isLoading: false,
    loginError: false,
    registrationError: false,
    // registrationSuccess: false,
    error: '',
    language: 'en',
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_LOADING: {
            return {
                ...state,
                isLoading: true,
                loginError: false,
                error: ''
            };
        }

        case SET_IS_LOADED: {
            return {
                ...state,
                isLoading: action.boolean,
            }
        }

        case AUTH_SUCCESS: {
            return {
                ...state,
                isAuth: true,
                isAutoLogin: true,
                isLoading: false,
                loginError: false,
                user: {
                    ...action.user,
                    token: localStorage.getItem('token'),
                    id: localStorage.getItem('userId'),
                    player: localStorage.getItem('selectedPlayer')
                },
            };
        }



        case AUTO_LOGIN_FALSE: {
            return {
                ...state,
                isAutoLogin: false,
            }
        }

        case LOGOUT: {
            return {
                ...initialState,
                user: {...emptyUser},
                isAuth: false,
                isLoading: false,
            }
        }

        case USER_LOADED: {
            return {
                ...state,
                isAuth: true,
                isAutoLogin: false,
                // isLoading: false,
                user: action.user,
                loginError: false,
            };
        }

         case SET_LANGUAGE: {
            return {
                ...state,
                language: action.language,
            };
        }

        //change user value
        case WITHOUT_REGISTRATION: {

            localStorage.setItem('token', 'wow');
            localStorage.setItem('userId', '-1');
            localStorage.setItem('role', 'wow');
            localStorage.setItem('nickName', 'wow');
            localStorage.setItem('selectedPlayer', 'Undefined');


            return {
                ...state,
                isAuth: true,
                // isLoading: false,
                withoutRegistration: true,
                user: {
                    ...emptyUser,
                    id: -1,
                    nickName: 'wow',
                    player: 'Undefined',
                    role: 'wow',
                    token: 'wow',
                },
                loginError: false
            };
        }

        case LOGIN_FAIL:
        case REGISTER_FAIL: {
            return {
                ...state,
                user: {...emptyUser},
                isAuth: false,
                isLoading: false,
                loginError: true,
                error: action.error
            };
        }

        case SET_ERROR: {
            return {
                ...state,
                error: action.error
            }
        }

        case RESET_ERROR: {
            return {
                captcha: localStorage.getItem('captcha'),
                isAuth: false,
                withoutRegistration: false,
                isLoading: false,
                user: {...emptyUser},
                loginError: false,
                registrationError: false,
                error: ''
            };
        }

        case UPDATE_USER_DATA: {
            return {
                ...state,
                user: {...state.user,...action.user},
            };
        }


        default:
            return state;
    }
};


const setLoaded = (boolean) => ({type: SET_IS_LOADED, boolean});
export const setLoadingUser = () => ({type: USER_LOADING});
export const setAuthUserData = (user) => ({type: USER_LOADED, user});
export const setAuthSuccess = (user) => ({type: AUTH_SUCCESS, user});
export const setLoginFail = (error) => ({type: LOGIN_FAIL, error});
export const setRegistrationFail = (error) => ({type: REGISTER_FAIL, error});
export const setWithoutRegistration = () => ({type: WITHOUT_REGISTRATION});
const resetState = () => ({type: RESET_ERROR});
const setError = (error) => ({type: SET_ERROR, error});
const logout = () => ({type: LOGOUT});
const setLanguage = (language) => ({type: SET_LANGUAGE, language});


export const setAutoLoginFalse = () => (dispatch) => {
    dispatch({type: AUTO_LOGIN_FALSE});
}

export const setIsLoaded = (boolean) => (dispatch) => {
    dispatch(setLoaded(boolean));
}

export const setLang = (language) => (dispatch) => {
    dispatch(setLanguage(language));
}

export const updateProfileData = (initRequest) => (dispatch) => {

    let { authAPI, pavilionsAPI } =  getRequest(initRequest);
    // TODO other request
    return authAPI.getAuth().then(async (data) => {

        // crutch
        let dataPav = await pavilionsAPI.getStands(null);
        let companyFullName = "";
        let companyUrl = data['companyName'];
        localStorage.setItem('companyUrl', companyUrl);
        if(companyUrl) {
            let companies = dataPav['companies'];
            for(let i in companies) {
                let companiesItem = companies[i];
                if(companiesItem.url === companyUrl) {
                    companyFullName = companiesItem.name[localStorage.getItem('language')];
                    break;
                }
            }
        }
        data.companyFullName = companyFullName;
        localStorage.setItem('companyFullName', companyFullName);
        dispatch({type: UPDATE_USER_DATA, user: data});
        return data;
    }).catch(() => {

    });
}

export const getAuthUserData = (data, initRequest) => async (dispatch) => {

    let { authAPI } =  getRequest(initRequest);
    let error;
    await dispatch({type: USER_LOADING});

    return authAPI.getUser(data).then(response => {
        if (response.alreadyLoggedIn) {
            dispatch(setLoginFail('alreadyLoggedIn'));
        } else {
            console.log('AUTH', response)
            dispatch(setAuthUserData(response));
            // console.log(response.data)
            localStorage.setItem('userId', response.id);
            localStorage.setItem('selectedPlayer', response.player);
            localStorage.setItem('token', response.token);
            localStorage.setItem('tokenTime', Date.now());
            localStorage.setItem('role', response.role);
            localStorage.setItem('nickName', response.nickName);
        }

    }).catch((error) => {
        if (error?.response?.status === 401) {
            dispatch(setLoginFail('incorrectData'));
        } else {
            dispatch(setLoginFail('unknownError'));
        }
        throw error;
    });


};


export const continueWithoutRegistration = () => {
    return (dispatch) => {
        dispatch(setWithoutRegistration());
    }
};

export const setErrorField = (error) => {
    console.log(error)
    return (dispatch) => {
        dispatch(setError(error));
    }
};

export const resetAuthState = () => {
    return (dispatch) => {
        dispatch(resetState());
    }
};

export const setAlreadyLoggedIn = () => {
    return (dispatch) => {
        dispatch(setLoginFail('alreadyLoggedIn'));
    }
};

export const makeLogout = () => {
    return (dispatch) => {
        dispatch(logout());
        localStorage.removeItem('userId');
        localStorage.removeItem('token');
        localStorage.removeItem('tokenTime');
        localStorage.removeItem('pavilionId');
        localStorage.removeItem('selectedPavilion');
        localStorage.removeItem('nameKey');
    }
};


export default authReducer;
