import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Meetings from "./Meetings";
import { deleteMeetings, getMeetingsUser } from "../../store/slices/profileSlice/profileExtraReducers";
import { setAppState } from "../../store/slices/appSlice/appSlice";
import { setProfileTableState } from "../../store/slices/profileSlice/profileSlice";

const MeetingsContainer = () => {
  let { table } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  let { language, requests: localText } = useSelector((state) => state.app.appText);
  const [{ errorLogo }, setLocalState] = useState({
    errorLogo: {},
  });

  const settingsSelect = {
      options: [
        { id: 0, value: localText.all },
        { id: "true", value: localText.incoming },
        { id: "false", value: localText.outgoing },
      ],
  };

  const handlerActions = (type, data) => async (e) => {
    switch (type) {
      case "changeFilter":
        dispatch(
          setProfileTableState({
            filter: { ...table.filter, incoming: e.id},
          })
        );
        break;
      case "errorLogo":
        setLocalState(state => ({...state, errorLogo: {...state.errorLogo, [data]: true}}))
        break;
        case "contextMenu":
          dispatch(setAppState({contextMenu: {
            currentTarget: e.currentTarget,
            listMenu: [
              // { onClick: () => {console.log(data)}, icon: '', text: "Редактировать" },
              { onClick: () => dispatch(deleteMeetings({id: data.id})), icon: '', text: "Отменить встречу", class: " error" },
            ]
          }}))
          break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(getMeetingsUser(table.filter));
  }, [dispatch, table.filter]);

  return (
    <Meetings
      handlerActions={handlerActions}
      table={table}
      settingsSelect={settingsSelect}
      language={language}
      errorLogo={errorLogo}
    />
  );
};

export default MeetingsContainer;
