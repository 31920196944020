import { scroller } from "react-scroll";


export const dateToLocaleTimeString = (date, hidden) => {
    let result = date ? new Date(date).toLocaleTimeString() : "-";
    switch (hidden) {
      case "sec":
        return result.substring(0, result.length - 3);
      default:
        return result;
    }
};

export const getFormDataFileForBody = (file) => {
  const fileFromData = new FormData();
  fileFromData.append('file', file);
  return fileFromData;
};

export const dateToLocaleString = (date, text, hidden) => {
    let result = date
      ? new Date(date).toLocaleString().replace(",", text ? ` ${text}` : ",")
      : "-";
    switch (hidden) {
      case "sec":
        return result.substring(0, result.length - 3);
      default:
        return result;
    }
};

export const checkDateForToday = (date) =>
  new Date(date).toLocaleDateString() === new Date().toLocaleDateString();

export const scrollTo = (id, time = 0) => (
  scroller.scrollTo(id, {
    duration: time * 1000,
    smooth: true,
    containerId: 'messages-scroll',
}));


export const getTimebySecond = (sec) => {
  let hours = Math.trunc(sec / 3600);
  if (hours < 10) hours = '0' + hours;
  let minutes = Math.trunc((sec - hours * 3600) / 60);
  if (minutes < 10) minutes = '0' + minutes;
  let seconds = sec % 60;
  if (seconds < 10) seconds = '0' + seconds;
  return hours + ':' + minutes + ':' + seconds;
};

  