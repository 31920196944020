 import React, { useEffect, useState } from "react";
import { WrapProfileSecurity } from "../profile.style";

const ProfileSecurity = ({ userData, handlerActions }) => {
  return (
      <WrapProfileSecurity>
        <div className="security-form">
          <div className="security-password">
            <p className="security__label">Пароль</p>
            <button className="security__help" onClick={handlerActions("forgotPassword")} >Забыли пароль?</button>
            <div className="security-elem-wrap">
              <p>Текущий пароль</p> <button onClick={handlerActions("changePassword")}>Изменить</button>
            </div>
          </div>
          <div className="security-email">
            <p className="security__label">Email</p>
            <button className="security__help" onClick={handlerActions("changeEmail")}>Изменить</button>
            <div className="security-elem-wrap">
              <p>{userData?.email || "email"}</p>
            </div>
          </div>
        </div>
        <button className="security__close" onClick={handlerActions("cancel")}>Закрыть</button>
      </WrapProfileSecurity>
  );
};

export default ProfileSecurity;
