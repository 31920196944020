import React, {Component} from "react";
import * as THREE from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {FBXLoader} from "three/examples/jsm/loaders/FBXLoader";

import s from './ViewPerson.module.css';
import otherPerson from './people/undefined.fbx';
import alexander from './people/alexander.fbx';
import marina from './people/marina.fbx';
import claudia from './people/claudia2.fbx';
import andrey from './people/andrey.fbx';
import kirill from './people/kirill.fbx';
import sophia from './people/sophia2.fbx';
import robot from './people/robot.fbx';
import otherPerson_texture from './people/undefined.jpg';
import alexander_texture from './people/rp_alexander.jpg';
import kirill_texture from './people/rp_kirill.jpg';
import marina_texture from './people/rp_marina.jpg';
import claudia_texture from './people/rp_claudia.jpg';
import andrey_texture from './people/rp_andrey.jpg';
import sophia_texture from './people/rp_sophia.jpg';
import robot_arm_texture from './people/Armature_Arms_AlbedoTransparency.png';
import robot_body_texture from './people/Armature_Body_AlbedoTransparency.png';
import robot_legs_texture from './people/Armature_Legs_AlbedoTransparency.png';
import spinner from './people/spinner.svg';

class ViewPerson extends Component {
    constructor(props) {
        super(props);
        this.state = {
            myWidth: window.innerWidth,
            myHeight: window.innerHeight
        }
        this.myRef = React.createRef();
    };

    handleResize() {
        this.setState(() => {
            return {
                myWidth: window.innerWidth,
                myHeight: window.innerHeight
            }
        })
    };

    componentDidMount() {
        window.addEventListener("resize", this.handleResize.bind(this));
        this.updateCanvas();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updateCanvas();
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize.bind(this));
    };

    updateCanvas() {
        this.myRef.current.innerHTML = '';

        const peopleMap = new Map([
            [0, otherPerson],
            [1, kirill],
            [2, alexander],
            [3, andrey],
            [4, claudia],
            [5, marina],
            [6, sophia],
            [7, robot],
        ]);

        const texturePeopleMap = new Map([
            [0, otherPerson_texture],
            [1, kirill_texture],
            [2, alexander_texture],
            [3, andrey_texture],
            [4, claudia_texture],
            [5, marina_texture],
            [6, sophia_texture],
            [7, [robot_arm_texture, robot_body_texture, robot_legs_texture]],
        ]);

        const node = this.myRef.current;

        const scene = new THREE.Scene();
        scene.background = new THREE.Color(0xD4E3ED);

        const light = new THREE.PointLight();
        light.position.set(0.8, 1.4, 1.0);
        scene.add(light);

        const ambientLight = new THREE.AmbientLight();
        scene.add(ambientLight);

        const camera = new THREE.PerspectiveCamera(
            87,
            window.innerWidth * 0.3 / window.innerHeight,
            0.8,
            1000
        );
        camera.position.set(45, 135, 120);

        const renderer = new THREE.WebGLRenderer();
        renderer.setSize(this.myRef.current.clientWidth, this.myRef.current.clientHeight);
        let img = document.createElement('img');
        img.src = spinner;
        node.appendChild(img);

        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true;
        controls.enableZoom = false;
        controls.minPolarAngle = (Math.PI / 2) - 0.7;
        controls.maxPolarAngle = (Math.PI / 2);
        controls.target.set(0, 60, 0);

        const updateNodeChild = () => {
            node.removeChild(node.firstChild)
            node.appendChild(renderer.domElement);
        }

        const personId = this.props.personId;
        const fbxLoader = new FBXLoader();
        console.log('start loading');

        console.log("personId = ", personId);

        fbxLoader.load(
            peopleMap.get(personId),
            (object) => {
                object.traverse(function (child) {
                    if (child.isMesh) {
                        if (personId === 7) {
                            let load = 0;
                            child.material = [
                                new THREE.MeshBasicMaterial({
                                    map: new THREE.TextureLoader().load(texturePeopleMap.get(personId)[1], () => {
                                        load++;
                                        if (load === 3) updateNodeChild();
                                    })
                                }),
                                new THREE.MeshBasicMaterial({
                                    map: new THREE.TextureLoader().load(texturePeopleMap.get(personId)[0], () => {
                                        load++;
                                        if (load === 3) updateNodeChild();
                                    })
                                }),
                                new THREE.MeshBasicMaterial({
                                    map: new THREE.TextureLoader().load(texturePeopleMap.get(personId)[2], () => {
                                        load++;
                                        if (load === 3) updateNodeChild();
                                    })
                                })];
                        } else {
                            const texture = new THREE.TextureLoader().load(texturePeopleMap.get(personId), function () {
                                updateNodeChild();
                            });
                            child.material = new THREE.MeshBasicMaterial({map: texture});
                        }
                    }
                });
                object.position.set(0, -40, 0);
                scene.add(object);
            },
            (xhr) => {
                console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
            },
            (error) => {
                console.log(error);
            }
        );


        window.addEventListener('resize', onWindowResize, false);


        function onWindowResize() {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
            render();
        }

        function animate() {
            requestAnimationFrame(animate);
            controls.update();
            render();
        }

        function render() {
            renderer.render(scene, camera);
        }

        animate();
    };


    render() {
        return <div id={'canvas'} ref={this.myRef} className={s.container}/>;
    }
}

export default ViewPerson;
