import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalBackground from "../components/ModalBackground/ModalBackground";
import icons_svg from "../imgs/icons_svg";
import { WrapBaseModal } from "./modalsChat.style";
import phortUserTest from "../imgs/Ellipse.png";
import { resetAppChatState, setAppChatState, setOpenDialogState } from "../appChatSlice/appChatSlice";
import SendFile from "./SendFile";
import useSendFiles from "../hooks/useSendFiles";
import NewDialog from "./NewDialog";
import { useHttp } from "../../../hooks/useHttp";
import { updateCopmanyStandFullUsers } from './../../../store/slices/unitySlice/unityExtraReducers';
import { filesUrl } from "../../../api/generateDomenUrlsApp";
import { getContactsUser } from "../../../store/slices/profileSlice/profileExtraReducers";
import { createDialog, deleteDialog, getDialogs } from "../../../store/slices/appChatSlice/appChatExtraReducers";
import { useNavigate, useParams } from "react-router-dom";

const ModalsChat = () => {
  const { modal: {type, content: contentModal}, openDialog, mobileVersion } = useSelector((state) => state.appChat);
  const dispatch = useDispatch();
  const sendFiles = useSendFiles()
  const { closeModal } = icons_svg;
  let {initRequest} = useHttp();
  const copmanyStandFull = useSelector(state => state.unity.copmanyStandFull);
  const navigate = useNavigate()

  // console.log(contentModal)


  const hanlderActions = (key, data) => async () => {
    let companyId = copmanyStandFull.company?.id;
    switch (key) {
      case "blockUser":
        await initRequest(`/users/block?blockUserId=${data}&blockStatus=BLOCK`, 'post');
        !companyId ? dispatch(getDialogs())
        :
        dispatch(updateCopmanyStandFullUsers(companyId));
        openDialog?.user && dispatch(setOpenDialogState({...openDialog, user: {...openDialog.user, block: 1}}));
        break;
      case "blockUserFromContacts":
        await initRequest(`/users/block?blockUserId=${data}&blockStatus=BLOCK`, 'post');
        dispatch(getContactsUser());
        break;
      case "unblockUser":
        await initRequest(`/users/block?blockUserId=${data}&blockStatus=UNBLOCK`, 'post');
        !companyId ? dispatch(getDialogs())
        :
        dispatch(updateCopmanyStandFullUsers(companyId));
        openDialog?.user && dispatch(setOpenDialogState({...openDialog, user: {...openDialog.user, block: 0}}));
        break;
      case "unblockUserFromContacts":
        await initRequest(`/users/block?blockUserId=${data}&blockStatus=UNBLOCK`, 'post');
        dispatch(getContactsUser());
        break;
      case "addToContact":
        await initRequest(`/users/contacts/add?contactId=${data}`, 'post');
        companyId && dispatch(updateCopmanyStandFullUsers(companyId))
        break;
      case "removeContact":
      case "removeContactFromContacts":
        await initRequest(`/users/contacts/delete?contactId=${data}`, 'delete');
        (key === "removeContact" && companyId) ? dispatch(updateCopmanyStandFullUsers(companyId)) : dispatch(getContactsUser());
        break;
      case "addToContactFromUnity":
        await initRequest(`/users/contacts/add?contactId=${data}`, 'post');
        break;
      case "removeContactFromUnity":
        await initRequest(`/users/contacts/delete?contactId=${data}`, 'delete');
        break;
      case "closeModal":
        break;
      case "closeSendFile":
        dispatch(setOpenDialogState({sendFile: null}))
        break;
      case "sendFile":
        sendFiles(data);
        console.log(data)
        break;
      case "removeDialog":
        dispatch(deleteDialog(data));
        (!mobileVersion && data === openDialog.dialogId) && navigate("/profile/chat");
        (mobileVersion && data === openDialog.dialogId) && dispatch(resetAppChatState("openDialog"));
        break;
      case "createNewDialog":
        dispatch(createDialog({body: data, navigate: mobileVersion ? false : navigate}));
        break;
      default:
        break;
    }
    dispatch(resetAppChatState("modal"));
  }; 

  const buildModal = (key) => {
    const closeModalBtn = (
      <button
        className="close-modal-btn"
        onClick={hanlderActions("closeModal")}
      >
        {closeModal}
      </button>
    );
    let content;
    switch (key) {
      case "blockUser":
        console.log(contentModal)
        content = {
          h3: "Блокировка чата",
          srcImg: filesUrl + contentModal.avatar,
          name: contentModal.firstName,
          text1: "будет заблокирован(a)",
          text2: "и не сможет отправлять вам сообщения",
          btnCancel: "Отменить",
          btnSubmit: "Да, заблокировать",
          onApply: hanlderActions("blockUser", contentModal.userId || contentModal.id),
        };
        break;
      case "blockUserFromContacts":
        console.log(contentModal)
        content = {
          h3: "Блокировка",
          srcImg: filesUrl + contentModal.avatar,
          name: contentModal.firstName,
          text1: "будет заблокирован(a)",
          text2: "и не сможет отправлять вам сообщения",
          btnCancel: "Отменить",
          btnSubmit: "Да, заблокировать",
          onApply: hanlderActions("blockUserFromContacts", contentModal.id),
        };
        break;
      case "unblockUser":
        console.log(contentModal)
        content = {
          h3: "Разблокировка чата",
          srcImg: filesUrl + contentModal.avatar,
          name: contentModal.firstName,
          text1: "будет разблокирован(a)",
          text2: "и сможет отправлять вам сообщения",
          btnCancel: "Отменить",
          btnSubmit: "Да, разблокировать",
          onApply: hanlderActions("unblockUser", contentModal.userId || contentModal.id),
        };
        break;
      case "unblockUserFromContacts":
        console.log(contentModal)
        content = {
          h3: "Разблокировка",
          srcImg: filesUrl + contentModal.avatar,
          name: contentModal.firstName,
          text1: "будет разблокирован(a)",
          text2: "и сможет отправлять вам сообщения",
          btnCancel: "Отменить",
          btnSubmit: "Да, разблокировать",
          onApply: hanlderActions("unblockUserFromContacts", contentModal.id),
        };
        break;

      case "clearHistory":
        content = {
          h3: "Очистить переписку",
          srcImg: phortUserTest,
          name: "Анастасия и вы.",
          text1: "Ваша история переписки",
          text2: "будет безвозвратно очищена",
          btnCancel: "Отменить",
          btnSubmit: "Да, очистить",
          onApply: () => dispatch(setAppChatState({key: "toast", value: true})),
        };
        break;
      case "addToContact":
        content = {
            h3: `${contentModal.firstName || ""} ${contentModal.lastName || ""}`,
            role: `${contentModal.position || ""}`,
            srcImg: filesUrl + contentModal.avatar,
            text1: "Добавить пользователя в список",
            text2: "ваших контактов?",
            btnCancel: "Не добавлять",
            btnSubmit: "Да, добавить в контакты",
            onApply:  hanlderActions("addToContact", contentModal.userId || contentModal.id),
          };
          break;
      case "addToContactFromUnity":
        content = {
            h3: `${contentModal.firstName || ""} ${contentModal.lastName || ""}`,
            role: `${contentModal.position || ""}`,
            srcImg: filesUrl + contentModal.avatar,
            text1: "Добавить пользователя в список",
            text2: "ваших контактов?",
            btnCancel: "Не добавлять",
            btnSubmit: "Да, добавить в контакты",
            onApply:  hanlderActions(key, contentModal.id),
          };
          break;
      case "removeContact":   
      case "removeContactFromContacts":
        content = {
            h3: "Удалить контакт",
            srcImg: filesUrl + contentModal.avatar,
            name: contentModal.firstName || contentModal.head,
            text1: "будет удален(a) из списка",
            text2: "ваших контактов",
            btnCancel: "Нет, оставить",
            btnSubmit: "Да, удалить",
            onApply:  hanlderActions(key, contentModal.userId || contentModal.id),
          };
          break;
      case "removeContactFromUnity":   
        content = {
            h3: "Удалить контакт",
            srcImg: filesUrl + contentModal.avatar,
            name: contentModal.firstName || contentModal.head,
            text1: "будет удален(a) из списка",
            text2: "ваших контактов",
            btnCancel: "Нет, оставить",
            btnSubmit: "Да, удалить",
            onApply:  hanlderActions(key, contentModal.userId || contentModal.id),
          };
          break;
      case "removeDialog":  
        content = {
            h3: "Удалить чат",
            text1: "Желаете удалить этот чат? История переписки будет",
            text2: "удалена для вас и вашего собеседника",
            btnCancel: "Нет, оставить",
            btnSubmit: "Да, удалить",
            onApply: hanlderActions(key, contentModal.dialogId),
          };
          break;
      case "addFileToMessage":      
        return (<SendFile hanlderActions={hanlderActions}/>)
      case "createNewDialog":      
        return (<NewDialog hanlderActions={hanlderActions}/>)
      default:
        return null;
    }
    return (
      <WrapBaseModal type={key}>
        {closeModalBtn}
        <h3>{content.h3}</h3>
        {content.role && <p className="add__role">{content.role}</p>}
        {content.srcImg && <img src={content.srcImg} alt="user-photo" />}
        <p className="base-text">
          <span className="text__name">{content.name}</span> {content.text1}
          <span>{content.text2}</span>
        </p>
        <div className="base-btns">
          <button onClick={hanlderActions("closeModal")}>
            {content.btnCancel}
          </button>
          <button onClick={content.onApply}>{content.btnSubmit}</button>
        </div>
      </WrapBaseModal>
    );
  };

  if (!type) return null;

  return (
    <ModalBackground onClose={hanlderActions("closeModal")}>
      {buildModal(type)}
    </ModalBackground>
  );
};

export default ModalsChat;
