import React from "react";
import { WrapSendMessage } from "./sendMessage.style";
import { svg_icons } from "../../../../../assets/imgs/svg_icons";

const SendMessage = ({handlerAction, inputFileRef, localState, textAreaRef, mobileVersion}) => {

  let {sendMesInputIcon, addThingChat} = svg_icons;

  return (
    <WrapSendMessage>
      <div className="enter-file">
        <input
          type="file"
          // accept="image/*, audio/*, video/*"
          onChange={handlerAction("addFile")}
          className="enter-file__input-hidden"
          ref={inputFileRef}
        />
        <button className="btn-new-mes" onClick={handlerAction("addThing")}>{addThingChat}</button>
      </div>

      {/* <button className="btn-new-mes"onClick={handlerAction()}>{videoCall}</button> */}
        <textarea
          rows={"auto"}
          className="new-message"
          name="new-message"
          id="newMessage"
          placeholder="Введите  сообщение..."
          onChange={handlerAction("changeTextarea")}
          onKeyDown={handlerAction("changeTextarea")}
          value={localState.newMes}
          ref={textAreaRef}
        ></textarea>

        <button 
          onClick={handlerAction("sendMes", true)}
          disabled={!localState.newMes}
          className={`send-mes-btn${mobileVersion ? " mobile": ""}`}>
            {sendMesInputIcon}<span>{"Отправить"}</span>
        </button>

    </WrapSendMessage>
  );
};

export default SendMessage;
