import React, {useRef, useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import SendMessage from "./SendMessage";
// import { scrollTo } from './../../../utils/helpers';
import { setAppChatState, setOpenDialogState } from "../../../appChatSlice/appChatSlice";
import { sendMessage } from "../../../../../store/slices/appChatSlice/appChatExtraReducers";

const SendMessageContainer = () =>  {

    const [localState, setLocalState] = useState({newMes: "", typeTeaxtArea: "enter"});
    const dispatch = useDispatch();
    const inputFileRef = useRef();
    const {openDialog, mobileVersion} = useSelector(state => state.appChat);
    let textAreaRef = useRef();


    const handlerAction = (type, data) => (e) => {
        switch (type) {
            case "addThing":
                inputFileRef.current.click()
                break;
            case "addFile":
                let file = e.target.files[0];
                if(file) {
                    dispatch(setAppChatState({key: "modal", value: {type: "addFileToMessage"}}))
                    dispatch(setOpenDialogState({sendFile: file}))
                }
                break;
            case "changeTextarea":
            case "sendMes":
                if (((e.key === 'Enter' && !e.shiftKey) || data) && localState.newMes) {
                    dispatch(sendMessage({
                        id: openDialog.dialogId,
                        body: {
                        text: localState.newMes,
                    }}))                
                    setLocalState(state => ({...state, newMes: "", typeTeaxtArea: "trim"}));
                    data && textAreaRef.current.focus();          
                } else {
                    let newMes = localState.typeTeaxtArea === "enter" ? e.target.value : e.target.value.trim() 
                    setLocalState(state => ({...state, newMes, typeTeaxtArea: "enter"}))
                }
                break;
            default:
                dispatch(setAppChatState({key: "toast", value: true}))
                break;
        }
    }
  
    return <SendMessage 
        handlerAction={handlerAction} 
        inputFileRef={inputFileRef}
        localState={localState}
        textAreaRef={textAreaRef}
        mobileVersion={mobileVersion}
        />
}

export default SendMessageContainer