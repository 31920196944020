import React from "react";
import { FormBtnsWrap } from "./baseComponentsForm.style";

const BaseFormBtns = ({ settings = {}, cancel, disabled }) => {


  return (
    <FormBtnsWrap className="form-btns" direction={settings.direction}>
      <button
        className="btns__cancel"
        type="button"
        onClick={cancel}
      >
        {settings.cancelName}
      </button>
      <button
        className="btns__submit"
        type="submit"
        disabled={disabled}       
      >
        {settings.submitName}
      </button>
  </FormBtnsWrap>
  );
};

export default BaseFormBtns;
