import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import {Provider} from "react-redux";
// import store from './reducers/reduxStore';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import './index.css';
import "./fonts/Gilroy/Gilroystylesheet.css";
import App from './App.jsx';
import {reCAPTCHA_siteKey} from "./api/appService";

//new store
import { store } from './store/store';
import { generateDomenUrlsApp } from './api/generateDomenUrlsApp';


generateDomenUrlsApp();


ReactDOM.render(
    <GoogleReCaptchaProvider
        reCaptchaKey={reCAPTCHA_siteKey}
        scriptProps={{
            async: false, // optional, default to false,
            defer: false // optional, default to false
        }}>
            <BrowserRouter>
            <React.StrictMode>
                <Provider store={store}>
                    <App/>
                </Provider>
            </React.StrictMode>
        </BrowserRouter>
     </GoogleReCaptchaProvider>,
    document.getElementById('root')
);
