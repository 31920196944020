import { useDispatch, useSelector } from "react-redux";
import { setAppChatState, setOpenDialogState } from "../store/slices/appChatSlice/appChatSlice";
import { getRequest } from "../api/appService";
import { createDialog, getDialogs, openDialogById } from "../store/slices/appChatSlice/appChatExtraReducers";
import { useNavigate } from "react-router-dom";

export const useOpenDialogNotFromChat = () => {

  const dispatch = useDispatch();
  let dialogs = useSelector((state) => state.appChat.dialogs);
  const navigate = useNavigate();

  const openDialogNotFromChat = async ({mobile, dialogUser} = {}) => {
    try {
        let dialogsResponse = (await dispatch(getDialogs())).payload;
        dialogs = dialogsResponse || dialogs;
    } catch (error) {
        dialogs = dialogs;
    }

    let findedDialog = dialogs?.find((dialog) => dialog.user.id === dialogUser.id);
    if (findedDialog) {
      dispatch(openDialogById({id: findedDialog.dialogId, navigate: mobile ? undefined: navigate}));
    } else {
      await dispatch(createDialog({body: {
        userId: dialogUser.id,
        text: undefined,
      }, navigate: mobile ? undefined: navigate}));
    }
  };

  return openDialogNotFromChat;
};
